.close-button {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

#bottomToastPortal,
#topToastPortal {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 95%;
  z-index: 10;
}

#bottomToastPortal {
  bottom: 10px;
}

@media (min-width: 768px) {
  #bottomToastPortal,
  #topToastPortal {
    width: 99%;
  }
}

.seo-container {
  line-height: 1.5;
  margin: 30px 20px 5px;
}

.seo-content p {
  color: #7a7a7a;
  font-size: 0.857em;
  margin-bottom: 10px;
}

.seo-content p span {
  background-color: transparent;
}

.seo-content h1,
.seo-content h2,
.seo-content h3,
.seo-content h4 {
  color: #5c5c5c;
  font-family: sspBold;
  font-size: 1em;
  font-weight: 400;
}

.seo-content h1 {
  margin-bottom: 13px;
}

.seo-content h2,
.seo-content h3,
.seo-content h4 {
  margin: 23px 0 13px;
}

.seo-content a {
  color: #464646;
}

.seo-content ul {
  color: #7a7a7a;
  font-size: 0.857em;
}

.about-section {
  color: #888;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 22px;
}

.about-section h2,
.about-section h3,
.about-section h4 {
  color: #444;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 1.5;
  text-transform: capitalize;
}

.about-section h2 ~ p,
.about-section h3 ~ p,
.about-section h4 ~ p {
  margin-top: 5px;
  white-space: normal;
}

.about-section h1 {
  color: #444;
  font-family: sspBold;
  font-size: 1.143em;
  font-weight: 400;
  line-height: 1.8;
  text-transform: capitalize;
}

.about-section h1 ~ p {
  margin-top: 5px;
  white-space: normal;
}

.about-section h1 ~ ul {
  list-style-type: disc;
  padding: 3px 0 0 18px;
}

.about-section h1 ~ ul li {
  white-space: normal;
}

.about-section h1 ~ ol {
  list-style-type: decimal;
  padding: 3px 0 0 18px;
}

.about-section h1 ~ ol li {
  white-space: normal;
}

.about-section li,
.about-section p {
  color: #888;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 22px;
}

.about-section li strong,
.about-section p strong {
  color: #444;
  display: inline-block;
  font-family: sspBold;
  margin-top: 15px;
}

.about-section a {
  color: #464646;
}

.about-section div[itemtype="http://schema.org/FAQPage"]
{
  margin: 10px 0;
}

.about-section div[itemtype="http://schema.org/Question"]
{
  margin: 20px 0;
}

.about-section .faq-question {
  margin-bottom: 10px;
}

.about-section h1:not(:first-child),
.about-section h2:not(:first-child),
.about-section h3:not(:first-child),
.about-section h4:not(:first-child) {
  margin-top: 30px;
}

.about-section p:not(:first-child) {
  margin-top: 5px;
}

.about-section .utility-table {
  border: 1px solid #b0b0b0;
  margin: 23px 0 10px;
}

.about-section .utility-table tr {
  border: 1px solid #b0b0b0;
  padding: 10px;
}

.about-section .utility-table tr:first-child {
  background-color: #f5f5f5;
  border: none;
}

.about-section .utility-table tr:first-child td {
  border: none;
  font-family: sspLight;
}

.about-section .utility-table td {
  border: 1px solid #b0b0b0;
  padding: 10px;
}

.seo-wrapper {
  padding: 10px 15px 15px;
}

.seo-wrapper > div {
  margin-bottom: 15px;
}

.seo-wrapper .heading {
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 20px;
}

.seo-wrapper .sub-heading {
  line-height: 18px;
}

.seo-wrapper .section-main .content {
  line-height: 18px;
  margin-top: 12px;
}

.seo-wrapper .checklist .check-items {
  align-items: center;
  display: flex;
}

.seo-wrapper .checklist .check-items img {
  height: 16px;
  margin-right: 10px;
  width: 16px;
}

.seo-wrapper .checklist .check-items p {
  font-size: 1.143em;
}

.seo-wrapper .cards-container {
  display: flex;
}

.seo-wrapper .cards-container .cards-content {
  display: flex;
  flex-direction: row;
}

.seo-wrapper .cards-container .cards-content p {
  line-height: 18px;
}

.seo-wrapper .cards-container .cards-content img {
  height: 32px;
  width: 32px;
}

.seo-wrapper .cards-container .cards-content .card-header {
  font-family: sspBold;
  font-size: 1.07em;
  margin-bottom: 8px;
}

.seo-wrapper .seo-pointers-wrapper ul {
  list-style-type: disc;
}

.seo-wrapper .seo-pointers-wrapper ul li {
  margin: 0 0 20px 12px;
}

.seo-wrapper .seo-pointers-wrapper ul li .heading {
  font-family: sspBold;
  font-size: 1.07em;
  line-height: 19px;
}

.seo-wrapper .seo-pointers-wrapper ul li .label {
  line-height: 22px;
}

.seo-wrapper .seo-pointers-wrapper .list-item {
  display: flex;
  list-style: disc;
  margin-top: 24px;
}

.seo-wrapper .seo-pointers-wrapper .list-item img {
  height: 32px;
  margin-right: 15px;
  width: 32px;
}

.seo-wrapper .seo-pointers-wrapper .list-item .list-info {
  display: flex;
  flex-direction: column;
}

.seo-wrapper .seo-pointers-wrapper .list-item .list-info .heading {
  font-family: sspBold;
  font-size: 1.07em;
  line-height: 19px;
}

.seo-wrapper .seo-pointers-wrapper .list-item .list-info .label {
  line-height: 22px;
}

.seo-wrapper .faqs-component .heading {
  padding: 10px 0 6px;
}

.seo-wrapper .faqs-component .seo-faqs {
  border: 0;
  border-radius: 16px;
  margin-top: 10px;
  padding: 18px 20px;
  box-shadow: 0 2px 10px 0 rgba(69, 48, 32, 0.05);
}

.seo-wrapper .faqs-component .seo-faqs .accordion-upper-section {
  min-height: unset;
  padding: 0;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-upper-section
  .upper-section-content {
  color: #000;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 22px;
}

.seo-wrapper .faqs-component .seo-faqs .accordion-upper-section span.arrow {
  border-bottom: 1.5px solid #222;
  border-color: #222 currentcolor;
  border-right: 1.5px solid;
  right: 10px;
  top: 5px;
}

.seo-wrapper .faqs-component .seo-faqs .accordion-lower-section {
  padding: 0;
  transition: all 0.5s ease-in-out 0s;
}

.seo-wrapper .faqs-component .seo-faqs .accordion-lower-section.expanded {
  margin-top: 10px;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .accordion-separator-line {
  display: none;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content {
  color: #666;
  line-height: 22px;
  padding: 0;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  .bubbles {
  margin-top: 20px;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  span {
  display: inline-block;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  span:not(:first-child) {
  margin-top: 20px;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  ol {
  list-style: decimal;
  margin-left: 20px;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  ul {
  list-style: disc;
  margin-left: 20px;
}

@media (max-width: 767px) {
  .about-section {
    background-color: #fff;
    border-radius: 20px;
    margin: 10px 0;
    padding: 30px 20px;
    position: relative;
  }

  .about-section .content-section {
    overflow: hidden;
    transition: max-height 0.25s ease-in-out 0s;
  }

  .about-section .read-more-overlay {
    bottom: 48px;
    height: 3rem;
    left: 20px;
    position: absolute;
    right: 20px;
    background: hsla(0, 0%, 100%, 0);
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0) 0,
      hsla(0, 0%, 100%, 0) 30%,
      #fff 80%,
      #fff
    );
  }

  .about-section .about-section-read-more-toggle {
    color: #666;
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .about-section {
    margin: 35px 0 15px;
    padding: 25px;
  }
}

@media (min-width: 1025px) {
  .about-section {
    padding: 0;
  }

  .seo-wrapper {
    padding: 70px 0 10px;
  }

  .seo-wrapper > div {
    margin-bottom: 70px;
  }

  .seo-wrapper .heading {
    font-size: 2.143em;
    line-height: 38px;
  }

  .seo-wrapper .sub-heading {
    font-size: 1.143em;
    line-height: 30px;
  }

  .seo-wrapper .section-main .content {
    line-height: 22px;
    margin-top: 15px;
  }

  .seo-wrapper .checklist {
    -moz-column-gap: 20px;
    column-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    row-gap: 36px;
  }

  .seo-wrapper .checklist .check-items {
    align-items: center;
    display: flex;
    flex-basis: 30%;
    max-width: 30%;
  }

  .seo-wrapper .checklist .check-items img {
    height: 22px;
    width: 22px;
  }

  .seo-wrapper .seo-pointers-wrapper {
    align-items: center;
    -moz-column-gap: 100px;
    column-gap: 100px;
    display: flex;
    justify-content: space-between;
  }

  .seo-wrapper .seo-pointers-wrapper .main-image {
    flex-basis: 45%;
    width: 100%;
  }

  .seo-wrapper .seo-pointers-wrapper ul li {
    margin: 36px 0 0 20px;
  }

  .seo-wrapper .seo-pointers-wrapper ul li .heading {
    font-size: 1.143em;
    line-height: 22.5px;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item {
    margin-top: 36px;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item img {
    height: 42px;
    width: 42px;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item .list-info .heading {
    font-size: 1.143em;
    line-height: 22.5px;
  }

  .seo-wrapper .cards-container {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 22px;
    row-gap: 20px;
  }

  .seo-wrapper .cards-container .cards-content {
    border: 1px solid #f0f0f0;
    border-radius: 20px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.059);
    flex-direction: column;
    padding: 30px 25px;
    width: 310px;
  }

  .seo-wrapper .cards-container .cards-content p {
    line-height: 22px;
  }

  .seo-wrapper .cards-container .cards-content img {
    height: 42px;
    width: 42px;
  }

  .seo-wrapper .cards-container .cards-content .card-header {
    font-size: 1.286em;
    margin: 32px 0 16px;
  }

  .seo-wrapper .cards-container .cards-content .content {
    line-height: 22px;
  }

  .seo-wrapper .faqs-component .heading {
    padding: 0 0 15px;
  }

  .seo-wrapper
    .faqs-component
    .seo-faqs
    .accordion-upper-section
    .upper-section-content {
    font-size: 1.286em;
  }
}

@media (max-width: 1024px) {
  .seo-wrapper {
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    position: relative;
    top: -20px;
  }

  .seo-wrapper > div:not(.faqs-component) {
    border: 0.6px solid #e1ebff;
    border-radius: 20px;
    padding: 16px 12px 16px 16px;
  }

  .seo-wrapper .sub-heading {
    color: #666;
    margin-top: 16px;
  }

  .seo-wrapper .section-main .content {
    color: #666;
  }

  .seo-wrapper .checklist .check-items {
    margin-top: 15px;
  }

  .seo-wrapper .seo-pointers-wrapper .main-image {
    padding: 40px 16px 4px 12px;
    width: 100%;
  }

  .seo-wrapper .seo-pointers-wrapper ul {
    margin-top: 15px;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item .list-info .label,
  .seo-wrapper .seo-pointers-wrapper ul li .label {
    color: #666;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item img {
    margin-top: 2px;
  }

  .seo-wrapper .cards-container {
    flex-direction: column;
  }

  .seo-wrapper .cards-container .cards-content {
    margin-top: 24px;
  }

  .seo-wrapper .cards-container .cards-content .textual-data {
    margin-left: 20px;
  }

  .seo-wrapper .cards-container .cards-content .content {
    color: #666;
  }
}

.accordion-container {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
}

.accordion-upper-section {
  cursor: pointer;
  min-height: 90px;
  padding: 22px;
  position: relative;
}

.accordion-upper-section .upper-section-content {
  width: 80%;
}

.accordion-upper-section img.icon {
  position: absolute;
  right: 12px;
  top: calc(50% - 6px);
  transition: all 0.5s ease-in-out 0s;
}

.accordion-upper-section img.icon.expanded {
  transform: rotate(-180deg);
}

.accordion-upper-section img.icon.disable-transition {
  transition: none;
}

.accordion-upper-section img.expanded-icon {
  -o-object-fit: contain;
  object-fit: contain;
}

.accordion-upper-section img.visible {
  opacity: 1;
}

.accordion-upper-section img.invisible {
  opacity: 0;
}

.accordion-upper-section span.arrow {
  border-bottom: 2px solid #d8d8d8;
  border-right: 2px solid #d8d8d8;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 25px;
  top: 40%;
  transition: all 0.5s ease-in-out 0s;
  transform: rotate(45deg);
}

.accordion-upper-section span.arrow.expanded {
  transform: rotate(-135deg);
}

.accordion-lower-section {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out 0s;
}

.accordion-lower-section.disable-transition {
  transition: none;
}

.accordion-lower-section.expanded {
  max-height: 400px;
}

.accordion-lower-section .accordion-separator-line {
  border-top: 1px solid #e4e4e4;
}

.accordion-lower-section .lower-section-content {
  padding: 16px 13px;
}

.error-boundary-wrapper {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.error-boundary-wrapper img {
  margin-bottom: 48px;
  width: 260px;
}

.error-boundary-wrapper h1 {
  font-family: sspRegular;
  font-size: 1.429em;
  line-height: 26px;
  margin-bottom: 15px;
}

.error-boundary-wrapper .text-one {
  color: #666;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 16px;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.error-boundary-wrapper .text-two {
  color: #969696;
  font-family: sspBold;
  font-size: 1.286em;
  line-height: 22px;
  margin-bottom: 32px;
}

.error-boundary-wrapper button {
  border: none;
  color: #f74;
  font-family: sspBold;
  font-size: 1.286em;
  line-height: 18px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .error-boundary-wrapper img {
    margin-bottom: 43px;
    width: 312px;
  }

  .error-boundary-wrapper h1 {
    font-size: 2.29em;
    line-height: 40px;
  }

  .error-boundary-wrapper .text-one {
    font-size: 1.286em;
    line-height: 26px;
    margin-bottom: 15px;
  }

  .error-boundary-wrapper .text-two {
    font-size: 1.714em;
    line-height: 30px;
    margin-bottom: 40px;
  }
}

.loader {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
}

.loader .loader-gif {
  position: relative;
}

.loader img {
  width: 75px;
}

.loader .loader-text {
  bottom: -30px;
  color: #fcfcff;
  font-family: sspBold;
  font-size: 14px;
  left: calc(50% - 100px);
  line-height: 16px;
  position: absolute;
  text-align: center;
  width: 200px;
}

.pop-up-wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  bottom: -120%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 4;
}

.pop-up-wrapper.show-pop-up {
  bottom: 0;
  top: 0;
}

.pop-up-wrapper.show-pop-up .pop-up.show-arrow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.pop-up-wrapper.secondary-layout {
  background-color: rgba(34, 34, 34, 0.85);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.pop-up-wrapper.secondary-layout .pop-up {
  border-radius: 10px;
  margin: 5px;
  padding: 20px;
}

.pop-up-wrapper.secondary-layout .pop-up .close-pop-up-icon {
  right: 15px;
  top: 15px;
  width: 24px;
}

.pop-up-wrapper.top-rounded-layout .pop-up {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.pop-up-wrapper.top-rounded-layout .pop-up .close-pop-up-icon {
  width: 24px;
}

.pop-up-wrapper .pop-up {
  background: #fff;
  max-height: 500px;
  overflow: auto;
  position: relative;
  scrollbar-width: none;
  transition: transform 0.2s ease-out 0s;
}

.pop-up-wrapper .pop-up::-webkit-scrollbar {
  display: none;
}

.pop-up-wrapper .pop-up .down-icon-div {
  cursor: pointer;
  left: 50%;
  position: absolute;
  top: 8px;
  width: 20px;
  transform: translateX(-50%);
}

.pop-up-wrapper .pop-up .down-icon-div img {
  width: 100%;
}

.pop-up-wrapper .pop-up .close-pop-up-icon {
  width: 10px;
}

.pop-up-wrapper .pop-up .close-popup-icon-wrapper {
  cursor: pointer;
  padding: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}

@media (max-width: 767px) {
  .pop-up-wrapper.show-pop-up {
    margin-bottom: -15vh;
    padding-bottom: 15vh;
  }

  .pop-up-wrapper.show-pop-up.swipe-bottom .pop-up {
    transform: translateY(0);
  }

  .pop-up-wrapper.show-pop-up.swipe-right .pop-up {
    transform: translateX(0);
  }

  .pop-up-wrapper.swipe-bottom .pop-up {
    transform: translateY(100%);
  }

  .pop-up-wrapper.swipe-right .pop-up {
    transform: translateX(100%);
  }
}

@media (min-width: 768px) {
  .pop-up-wrapper {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: 0;
    top: 0;
    z-index: -1;
    transition: opacity 0.25s ease-in-out 0s;
  }

  .pop-up-wrapper.show-pop-up {
    opacity: 1;
    z-index: 4;
  }

  .pop-up-wrapper.show-pop-up .pop-up {
    border-radius: 20px;
    width: 500px;
  }

  .pop-up-wrapper.top-rounded-layout .pop-up {
    border-radius: 20px;
  }
}

#calendarPortal .calendar-container {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 12px #efecec;
  font-size: 1.143em;
  height: 336px;
  padding: 16px;
  position: absolute;
  width: 307px;
  z-index: 5;
}

#calendarPortal .calendar-container .calendar-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

#calendarPortal .calendar-container .calendar-header .calendar-month {
  color: #e3714d;
  cursor: pointer;
  font-size: 1.571em;
  font-weight: 500;
  padding: 0.5em 0.25em;
  text-align: center;
  word-spacing: 5px;
}

#calendarPortal .calendar-container .arrow {
  border: solid #e3714d;
  border-width: 0 3px 3px 0;
  cursor: pointer;
  display: inline-block;
  padding: 3px;
  position: relative;
}

#calendarPortal .calendar-container .arrow.left {
  transform: rotate(135deg);
  left: -40px;
}

#calendarPortal .calendar-container .arrow.right {
  transform: rotate(-45deg);
  left: -20px;
}

#calendarPortal .calendar-container .arrow.disabled {
  border: solid #e4e4e4;
  border-width: 0 3px 3px 0;
  cursor: auto;
}

#calendarPortal .calendar-container .calendar-grid {
  display: grid;
  transform: translateZ(0);
  transition: transform 0.2s ease-out 0s;
}

#calendarPortal .calendar-container .calendar-grid.date {
  grid-template: repeat(7, auto) / repeat(7, auto);
}

#calendarPortal .calendar-container .calendar-grid.year-month {
  grid-template-columns: auto auto auto;
  row-gap: 10px;
}

#calendarPortal .calendar-container .calendar-grid .calendar-cell {
  align-self: center;
  letter-spacing: 0.1rem;
  padding: 0.6em 0.25em;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#calendarPortal .calendar-container .calendar-grid .calendar-cell.date {
  align-items: center;
  display: flex;
  height: 38px;
  justify-content: center;
  width: 40px;
}

#calendarPortal .calendar-container .calendar-grid .calendar-cell.weekday {
  font-size: 0.714em;
  font-weight: 600;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date {
  color: #666;
  cursor: pointer;
  font-size: 1em;
  font-weight: 300;
  transition: all 0.1s ease-out 0s;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date
  :hover {
  background: #e1ebff;
  border-radius: 50px;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.in-month {
  color: #222;
  font-weight: 500;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.highlighted {
  background: rgba(227, 113, 78, 0.1);
  border-radius: 10px;
  color: #e3714d;
  font-weight: 600;
  position: relative;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.today {
  background: transparent;
  color: #e3714d;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.today
  :hover {
  background: #e1ebff;
  color: #e3714d;
}

#calendarPortal,
.date-picker {
  margin: 5px 0;
  position: relative;
}

#calendarPortal .date-picker-container,
.date-picker .date-picker-container {
  position: relative;
}

#calendarPortal .date-picker-container input,
.date-picker .date-picker-container input {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 0;
  color: #222;
  font-size: 0.857em;
}

#calendarPortal .date-picker-container input.layout-1,
.date-picker .date-picker-container input.layout-1 {
  border: 1px solid #e4e4e4;
}

#calendarPortal .date-picker-container input.layout-2,
.date-picker .date-picker-container input.layout-2 {
  border-bottom: 1px solid #e4e4e4;
}

#calendarPortal .date-picker-container input.error,
.date-picker .date-picker-container input.error {
  border-color: red;
}

#calendarPortal .date-picker-container input:disabled,
.date-picker .date-picker-container input:disabled {
  background-color: transparent;
  color: #222;
  opacity: 1;
  -webkit-text-fill-color: #222;
}

#calendarPortal
  .date-picker-container
  input:disabled.layout-3::-webkit-input-placeholder,
#calendarPortal .date-picker-container input:disabled.layout-3::placeholder,
#calendarPortal
  .date-picker-container
  input:disabled.layout-4::-webkit-input-placeholder,
#calendarPortal .date-picker-container input:disabled.layout-4::placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-3::-webkit-input-placeholder,
.date-picker .date-picker-container input:disabled.layout-3::placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-4::-webkit-input-placeholder,
.date-picker .date-picker-container input:disabled.layout-4::placeholder {
  color: #666;
  font-family: sspRegular;
  -webkit-text-fill-color: #666;
}

#calendarPortal
  .date-picker-container
  input:disabled.layout-3:-ms-input-placeholder,
#calendarPortal
  .date-picker-container
  input:disabled.layout-4:-ms-input-placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-3:-ms-input-placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-4:-ms-input-placeholder {
  color: #666;
  font-family: sspRegular;
}

#calendarPortal .date-picker-container input.layout-3,
.date-picker .date-picker-container input.layout-3 {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  font-family: sspBold;
  line-height: 14px;
}

#calendarPortal .date-picker-container input.layout-3:focus,
.date-picker .date-picker-container input.layout-3:focus {
  background-color: #fff;
  border-color: #ec7f51;
  box-shadow: 0 1px 6px #efd1c5;
  outline: none;
}

#calendarPortal .date-picker-container input.layout-4,
.date-picker .date-picker-container input.layout-4 {
  background-color: #f9f9fc;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  color: #222;
  font-family: sspBold;
  font-size: 1.143em;
}

#calendarPortal .date-picker-container input.layout-4:focus,
.date-picker .date-picker-container input.layout-4:focus {
  background-color: #fff;
  border-color: #ec7f51;
  box-shadow: 0 1px 6px #f7decf;
  outline: none;
}

#calendarPortal .date-picker-container input.layout-3:disabled,
.date-picker .date-picker-container input.layout-3:disabled {
  background-color: #f9f9fc;
}

#calendarPortal .date-picker-container label,
.date-picker .date-picker-container label {
  bottom: 5px;
  color: #aaa;
  font-size: 0.857em;
  position: absolute;
}

#calendarPortal .date-picker-container label.layout-1,
.date-picker .date-picker-container label.layout-1 {
  padding-bottom: 14px;
}

#calendarPortal .date-picker-container label.layout-1.animate,
.date-picker .date-picker-container label.layout-1.animate {
  transform: translateY(-39px);
}

#calendarPortal .date-picker-container label.layout-2.animate,
.date-picker .date-picker-container label.layout-2.animate {
  transform: translateY(-29px);
}

#calendarPortal .date-picker-container label.layout-4,
.date-picker .date-picker-container label.layout-4 {
  color: #222;
  display: block;
  font-family: sspBold;
  font-size: 1em;
  margin: 0 0 5px;
  position: static;
}

#calendarPortal .date-picker-container label.animate:not(.layout-4),
.date-picker .date-picker-container label.animate:not(.layout-4) {
  background-color: #fff;
  bottom: 0;
  font-size: 0.714em;
  padding: 0 1px;
}

#calendarPortal .date-picker-icon,
.date-picker .date-picker-icon {
  height: 16px;
  position: absolute;
  right: 14px;
  top: 37px;
  width: 16px;
}

#calendarPortal .date-picker-overlay,
.date-picker .date-picker-overlay {
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

#calendarPortal .error-message,
.date-picker .error-message {
  color: red;
  display: block;
  font-size: 0.857em;
  line-height: 16px;
  margin: 8px 0;
  text-transform: capitalize;
}

#calendarPortal .popup-heading,
.date-picker .popup-heading {
  color: #222;
  font-family: sspBold;
  font-size: 1em;
  margin: 5px;
}

#calendarPortal .datepicker-btn,
.date-picker .datepicker-btn {
  font-family: sspBold;
  font-size: 1em;
  text-transform: uppercase;
}

#calendarPortal .date-picker-popup,
.date-picker .date-picker-popup {
  z-index: 6;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#calendarPortal .date-picker-popup .close-pop-up-icon,
.date-picker .date-picker-popup .close-pop-up-icon {
  cursor: pointer;
  right: 20px !important;
  top: 20px !important;
}

#calendarPortal .layout-3 .popup-heading,
.date-picker .layout-3 .popup-heading {
  font-size: 1.143em;
}

#calendarPortal .layout-3 .datepicker-btn,
.date-picker .layout-3 .datepicker-btn {
  height: 54px;
}

#calendarPortal .layout-3 .list-container,
.date-picker .layout-3 .list-container {
  margin: 46px 0;
}

#calendarPortal .layout-3 .list-container li.active,
.date-picker .layout-3 .list-container li.active {
  font-size: 1.143em;
}

#calendarPortal .layout-3 .list-container li.active span,
.date-picker .layout-3 .list-container li.active span {
  text-align: center;
}

#calendarPortal .layout-3 .list-container li.active span:before,
.date-picker .layout-3 .list-container li.active span:before {
  border: 1px solid #f74;
  content: "";
  left: -1px;
  position: absolute;
  top: -18px;
  width: 100%;
}

#calendarPortal .layout-3 .list-container li.active span:after,
.date-picker .layout-3 .list-container li.active span:after {
  bottom: -18px;
}

#calendarPortal .layout-3 .list-container li.active .date-text,
.date-picker .layout-3 .list-container li.active .date-text {
  width: 40px;
}

#calendarPortal .layout-3 .list-container li.active .month-text,
.date-picker .layout-3 .list-container li.active .month-text {
  width: 100px;
}

#calendarPortal .layout-3 .list-container li.active .year-text,
.date-picker .layout-3 .list-container li.active .year-text {
  width: 64px;
}

#calendarPortal .list-container,
.date-picker .list-container {
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
}

#calendarPortal .list-container ul,
.date-picker .list-container ul {
  cursor: ns-resize;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 30%;
}

#calendarPortal .list-container ul li,
.date-picker .list-container ul li {
  align-items: center;
  color: #aeaeae;
  display: flex;
  font-family: sspRegular;
  font-size: 1em;
  justify-content: center;
  line-height: 14px;
}

#calendarPortal .list-container ul li::-moz-selection,
.date-picker .list-container ul li::-moz-selection {
  background-color: transparent;
}

#calendarPortal .list-container ul li::selection,
.date-picker .list-container ul li::selection {
  background-color: transparent;
}

#calendarPortal .list-container ul li.active,
.date-picker .list-container ul li.active {
  color: #222;
  font-family: sspBold;
}

#calendarPortal .list-container ul li.active span,
.date-picker .list-container ul li.active span {
  position: relative;
}

#calendarPortal .list-container ul li.active span:after,
.date-picker .list-container ul li.active span:after {
  border: 1px solid #f74;
  bottom: -10px;
  content: "";
  left: -1px;
  position: absolute;
  width: 100%;
}

#calendarPortal .dual-btns,
.date-picker .dual-btns {
  border-top: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

#calendarPortal .dual-btns p,
.date-picker .dual-btns p {
  color: #e3714d;
  cursor: pointer;
  font-family: sspBold;
  font-size: 1.143em;
  padding: 0 10px;
  text-transform: uppercase;
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip,
.date-picker .tooltip-date-picker .__react_component_tooltip {
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  position: absolute;
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.16);
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip.show,
.date-picker .tooltip-date-picker .__react_component_tooltip.show {
  min-width: 340px;
  opacity: 1;
  padding: 20px;
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip.show:after,
#calendarPortal .tooltip-date-picker .__react_component_tooltip.show:before,
.date-picker .tooltip-date-picker .__react_component_tooltip.show:after,
.date-picker .tooltip-date-picker .__react_component_tooltip.show:before {
  content: none;
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip .list-container,
.date-picker .tooltip-date-picker .__react_component_tooltip .list-container {
  margin: 0 0 25px;
}

#calendarPortal .tooltip-date-picker.hide-dp .__react_component_tooltip,
.date-picker .tooltip-date-picker.hide-dp .__react_component_tooltip {
  opacity: 0;
  visibility: hidden;
}

#calendarPortal .info-element,
.date-picker .info-element {
  font-size: 12px;
  margin: 4px 0 0;
}

#calendarPortal {
  margin: 0;
}

@media (max-width: 767px) {
  #calendarPortal .layout-3 .pop-up,
  .date-picker .layout-3 .pop-up {
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
  }
}

@media (min-width: 768px) {
  #calendarPortal .layout-3 .list-container,
  .date-picker .layout-3 .list-container {
    margin: 0 0 20px;
  }
}

@media (max-width: 1024px) {
  #calendarPortal .list-container ul,
  .date-picker .list-container ul {
    scrollbar-width: none;
  }

  #calendarPortal .list-container ul::-webkit-scrollbar,
  .date-picker .list-container ul::-webkit-scrollbar {
    display: none;
  }
}

.tnc-popup {
  padding: 25px 20px 20px;
}

.tnc-popup .popup-heading {
  color: #222;
  font-family: sspBold;
  font-size: 1.286em;
  margin-bottom: 10px;
}

.tnc-popup .popup-content {
  color: #666;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 20px;
}

.tnc-popup .popup-content b {
  color: #e3714d;
  cursor: pointer;
  font-family: sspBold;
}

.tnc-popup .popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.tnc-popup .popup-actions button {
  border-radius: 10px;
  font-family: sspBold;
  font-size: 1.143em;
  height: 54px;
  width: 48%;
}

.tnc-popup .popup-actions button.accept-btn {
  background: #f74;
}

.kyc-popup-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px;
}

.kyc-popup-container .kyc-popup-heading {
  color: #5c5c5c;
  font-family: sspBold;
  font-size: 1.143em;
  margin-bottom: 30px;
  position: relative;
}

.kyc-popup-container .kyc-popup-heading:after {
  border: 2px solid #e3714d;
  border-radius: 10px;
  bottom: -15px;
  content: "";
  left: calc(50% - 15px);
  position: absolute;
  width: 30px;
}

.kyc-popup-container .kyc-popup-text {
  color: #5c5c5c;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.kyc-popup-container .upgrade-btn {
  margin-bottom: 10px;
}

.kyc-popup-container .remind-later-btn {
  border: none;
  color: #aeaeae;
  font-family: sspBold;
  font-size: 1em;
  height: 42px;
  text-align: center;
  width: auto;
}

.progress {
  height: 2px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.progress .indicator {
  background-color: #ff7845;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.1s linear, opacity 0.3s linear 0.2s;
  width: 0;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  margin: 0;
  padding: 0;
}

@font-face {
  font-display: swap;
  font-family: sspBoldItalic;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBlack;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBlackIt;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBolder;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspExtraLight;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspLight;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBold;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspRegular;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: lobsterRegular;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: lobsterBold;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.svg)
      format("svg");
}

* {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background-color: #f2f5fc;
  font-family: sspRegular;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hide {
  display: none;
}

.show {
  display: block;
}

a,
button,
input,
select,
textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input,
select,
textarea {
  font-family: sspRegular;
}

button:focus,
input:focus {
  outline: none;
}

button:focus-visible,
input:focus-visible {
  outline: none;
}

button:focus-within,
input:focus-within {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
  background-color: #fff !important;
  box-shadow: inset 0 0 0 9999px #fff;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #aeaeae;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #aeaeae;
}

input::placeholder,
textarea::placeholder {
  color: #aeaeae;
}

a,
a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
}

img:-moz-loading {
  visibility: hidden;
}

.container,
.main-wrapper {
  width: 100%;
}

.storybook {
  background-color: #7a7a7a;
  max-width: 300px;
}

@media (min-width: 1025px) {
  .container {
    margin: 0 auto;
    max-width: 768px;
  }

  .content-wrapper,
  .main-wrapper {
    margin: 0 auto;
    max-width: 1366px;
  }
}

.close-button {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

#bottomToastPortal,
#topToastPortal {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 95%;
  z-index: 10;
}

#bottomToastPortal {
  bottom: 10px;
}

@media (min-width: 768px) {
  #bottomToastPortal,
  #topToastPortal {
    width: 99%;
  }
}

.seo-container {
  line-height: 1.5;
  margin: 30px 20px 5px;
}

.seo-content p {
  color: #7a7a7a;
  font-size: 0.857em;
  margin-bottom: 10px;
}

.seo-content p span {
  background-color: transparent;
}

.seo-content h1,
.seo-content h2,
.seo-content h3,
.seo-content h4 {
  color: #5c5c5c;
  font-family: sspBold;
  font-size: 1em;
  font-weight: 400;
}

.seo-content h1 {
  margin-bottom: 13px;
}

.seo-content h2,
.seo-content h3,
.seo-content h4 {
  margin: 23px 0 13px;
}

.seo-content a {
  color: #464646;
}

.seo-content ul {
  color: #7a7a7a;
  font-size: 0.857em;
}

.about-section {
  color: #888;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 22px;
}

.about-section h2,
.about-section h3,
.about-section h4 {
  color: #444;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 1.5;
  text-transform: capitalize;
}

.about-section h2 ~ p,
.about-section h3 ~ p,
.about-section h4 ~ p {
  margin-top: 5px;
  white-space: normal;
}

.about-section h1 {
  color: #444;
  font-family: sspBold;
  font-size: 1.143em;
  font-weight: 400;
  line-height: 1.8;
  text-transform: capitalize;
}

.about-section h1 ~ p {
  margin-top: 5px;
  white-space: normal;
}

.about-section h1 ~ ul {
  list-style-type: disc;
  padding: 3px 0 0 18px;
}

.about-section h1 ~ ul li {
  white-space: normal;
}

.about-section h1 ~ ol {
  list-style-type: decimal;
  padding: 3px 0 0 18px;
}

.about-section h1 ~ ol li {
  white-space: normal;
}

.about-section li,
.about-section p {
  color: #888;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 22px;
}

.about-section li strong,
.about-section p strong {
  color: #444;
  display: inline-block;
  font-family: sspBold;
  margin-top: 15px;
}

.about-section a {
  color: #464646;
}

.about-section div[itemtype="http://schema.org/FAQPage"]
{
  margin: 10px 0;
}

.about-section div[itemtype="http://schema.org/Question"]
{
  margin: 20px 0;
}

.about-section .faq-question {
  margin-bottom: 10px;
}

.about-section h1:not(:first-child),
.about-section h2:not(:first-child),
.about-section h3:not(:first-child),
.about-section h4:not(:first-child) {
  margin-top: 30px;
}

.about-section p:not(:first-child) {
  margin-top: 5px;
}

.about-section .utility-table {
  border: 1px solid #b0b0b0;
  margin: 23px 0 10px;
}

.about-section .utility-table tr {
  border: 1px solid #b0b0b0;
  padding: 10px;
}

.about-section .utility-table tr:first-child {
  background-color: #f5f5f5;
  border: none;
}

.about-section .utility-table tr:first-child td {
  border: none;
  font-family: sspLight;
}

.about-section .utility-table td {
  border: 1px solid #b0b0b0;
  padding: 10px;
}

.seo-wrapper {
  padding: 10px 15px 15px;
}

.seo-wrapper > div {
  margin-bottom: 15px;
}

.seo-wrapper .heading {
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 20px;
}

.seo-wrapper .sub-heading {
  line-height: 18px;
}

.seo-wrapper .section-main .content {
  line-height: 18px;
  margin-top: 12px;
}

.seo-wrapper .checklist .check-items {
  align-items: center;
  display: flex;
}

.seo-wrapper .checklist .check-items img {
  height: 16px;
  margin-right: 10px;
  width: 16px;
}

.seo-wrapper .checklist .check-items p {
  font-size: 1.143em;
}

.seo-wrapper .cards-container {
  display: flex;
}

.seo-wrapper .cards-container .cards-content {
  display: flex;
  flex-direction: row;
}

.seo-wrapper .cards-container .cards-content p {
  line-height: 18px;
}

.seo-wrapper .cards-container .cards-content img {
  height: 32px;
  width: 32px;
}

.seo-wrapper .cards-container .cards-content .card-header {
  font-family: sspBold;
  font-size: 1.07em;
  margin-bottom: 8px;
}

.seo-wrapper .seo-pointers-wrapper ul {
  list-style-type: disc;
}

.seo-wrapper .seo-pointers-wrapper ul li {
  margin: 0 0 20px 12px;
}

.seo-wrapper .seo-pointers-wrapper ul li .heading {
  font-family: sspBold;
  font-size: 1.07em;
  line-height: 19px;
}

.seo-wrapper .seo-pointers-wrapper ul li .label {
  line-height: 22px;
}

.seo-wrapper .seo-pointers-wrapper .list-item {
  display: flex;
  list-style: disc;
  margin-top: 24px;
}

.seo-wrapper .seo-pointers-wrapper .list-item img {
  height: 32px;
  margin-right: 15px;
  width: 32px;
}

.seo-wrapper .seo-pointers-wrapper .list-item .list-info {
  display: flex;
  flex-direction: column;
}

.seo-wrapper .seo-pointers-wrapper .list-item .list-info .heading {
  font-family: sspBold;
  font-size: 1.07em;
  line-height: 19px;
}

.seo-wrapper .seo-pointers-wrapper .list-item .list-info .label {
  line-height: 22px;
}

.seo-wrapper .faqs-component .heading {
  padding: 10px 0 6px;
}

.seo-wrapper .faqs-component .seo-faqs {
  border: 0;
  border-radius: 16px;
  margin-top: 10px;
  padding: 18px 20px;
  box-shadow: 0 2px 10px 0 rgba(69, 48, 32, 0.05);
}

.seo-wrapper .faqs-component .seo-faqs .accordion-upper-section {
  min-height: unset;
  padding: 0;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-upper-section
  .upper-section-content {
  color: #000;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 22px;
}

.seo-wrapper .faqs-component .seo-faqs .accordion-upper-section span.arrow {
  border-bottom: 1.5px solid #222;
  border-color: #222 currentcolor;
  border-right: 1.5px solid;
  right: 10px;
  top: 5px;
}

.seo-wrapper .faqs-component .seo-faqs .accordion-lower-section {
  padding: 0;
  transition: all 0.5s ease-in-out 0s;
}

.seo-wrapper .faqs-component .seo-faqs .accordion-lower-section.expanded {
  margin-top: 10px;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .accordion-separator-line {
  display: none;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content {
  color: #666;
  line-height: 22px;
  padding: 0;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  .bubbles {
  margin-top: 20px;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  span {
  display: inline-block;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  span:not(:first-child) {
  margin-top: 20px;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  ol {
  list-style: decimal;
  margin-left: 20px;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  ul {
  list-style: disc;
  margin-left: 20px;
}

@media (max-width: 767px) {
  .about-section {
    background-color: #fff;
    border-radius: 20px;
    margin: 10px 0;
    padding: 30px 20px;
    position: relative;
  }

  .about-section .content-section {
    overflow: hidden;
    transition: max-height 0.25s ease-in-out 0s;
  }

  .about-section .read-more-overlay {
    bottom: 48px;
    height: 3rem;
    left: 20px;
    position: absolute;
    right: 20px;
    background: hsla(0, 0%, 100%, 0);
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0) 0,
      hsla(0, 0%, 100%, 0) 30%,
      #fff 80%,
      #fff
    );
  }

  .about-section .about-section-read-more-toggle {
    color: #666;
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .about-section {
    margin: 35px 0 15px;
    padding: 25px;
  }
}

@media (min-width: 1025px) {
  .about-section {
    padding: 0;
  }

  .seo-wrapper {
    padding: 70px 0 10px;
  }

  .seo-wrapper > div {
    margin-bottom: 70px;
  }

  .seo-wrapper .heading {
    font-size: 2.143em;
    line-height: 38px;
  }

  .seo-wrapper .sub-heading {
    font-size: 1.143em;
    line-height: 30px;
  }

  .seo-wrapper .section-main .content {
    line-height: 22px;
    margin-top: 15px;
  }

  .seo-wrapper .checklist {
    -moz-column-gap: 20px;
    column-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    row-gap: 36px;
  }

  .seo-wrapper .checklist .check-items {
    align-items: center;
    display: flex;
    flex-basis: 30%;
    max-width: 30%;
  }

  .seo-wrapper .checklist .check-items img {
    height: 22px;
    width: 22px;
  }

  .seo-wrapper .seo-pointers-wrapper {
    align-items: center;
    -moz-column-gap: 100px;
    column-gap: 100px;
    display: flex;
    justify-content: space-between;
  }

  .seo-wrapper .seo-pointers-wrapper .main-image {
    flex-basis: 45%;
    width: 100%;
  }

  .seo-wrapper .seo-pointers-wrapper ul li {
    margin: 36px 0 0 20px;
  }

  .seo-wrapper .seo-pointers-wrapper ul li .heading {
    font-size: 1.143em;
    line-height: 22.5px;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item {
    margin-top: 36px;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item img {
    height: 42px;
    width: 42px;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item .list-info .heading {
    font-size: 1.143em;
    line-height: 22.5px;
  }

  .seo-wrapper .cards-container {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 22px;
    row-gap: 20px;
  }

  .seo-wrapper .cards-container .cards-content {
    border: 1px solid #f0f0f0;
    border-radius: 20px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.059);
    flex-direction: column;
    padding: 30px 25px;
    width: 310px;
  }

  .seo-wrapper .cards-container .cards-content p {
    line-height: 22px;
  }

  .seo-wrapper .cards-container .cards-content img {
    height: 42px;
    width: 42px;
  }

  .seo-wrapper .cards-container .cards-content .card-header {
    font-size: 1.286em;
    margin: 32px 0 16px;
  }

  .seo-wrapper .cards-container .cards-content .content {
    line-height: 22px;
  }

  .seo-wrapper .faqs-component .heading {
    padding: 0 0 15px;
  }

  .seo-wrapper
    .faqs-component
    .seo-faqs
    .accordion-upper-section
    .upper-section-content {
    font-size: 1.286em;
  }
}

@media (max-width: 1024px) {
  .seo-wrapper {
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    position: relative;
    top: -20px;
  }

  .seo-wrapper > div:not(.faqs-component) {
    border: 0.6px solid #e1ebff;
    border-radius: 20px;
    padding: 16px 12px 16px 16px;
  }

  .seo-wrapper .sub-heading {
    color: #666;
    margin-top: 16px;
  }

  .seo-wrapper .section-main .content {
    color: #666;
  }

  .seo-wrapper .checklist .check-items {
    margin-top: 15px;
  }

  .seo-wrapper .seo-pointers-wrapper .main-image {
    padding: 40px 16px 4px 12px;
    width: 100%;
  }

  .seo-wrapper .seo-pointers-wrapper ul {
    margin-top: 15px;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item .list-info .label,
  .seo-wrapper .seo-pointers-wrapper ul li .label {
    color: #666;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item img {
    margin-top: 2px;
  }

  .seo-wrapper .cards-container {
    flex-direction: column;
  }

  .seo-wrapper .cards-container .cards-content {
    margin-top: 24px;
  }

  .seo-wrapper .cards-container .cards-content .textual-data {
    margin-left: 20px;
  }

  .seo-wrapper .cards-container .cards-content .content {
    color: #666;
  }
}

.accordion-container {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
}

.accordion-upper-section {
  cursor: pointer;
  min-height: 90px;
  padding: 22px;
  position: relative;
}

.accordion-upper-section .upper-section-content {
  width: 80%;
}

.accordion-upper-section img.icon {
  position: absolute;
  right: 12px;
  top: calc(50% - 6px);
  transition: all 0.5s ease-in-out 0s;
}

.accordion-upper-section img.icon.expanded {
  transform: rotate(-180deg);
}

.accordion-upper-section img.icon.disable-transition {
  transition: none;
}

.accordion-upper-section img.expanded-icon {
  -o-object-fit: contain;
  object-fit: contain;
}

.accordion-upper-section img.visible {
  opacity: 1;
}

.accordion-upper-section img.invisible {
  opacity: 0;
}

.accordion-upper-section span.arrow {
  border-bottom: 2px solid #d8d8d8;
  border-right: 2px solid #d8d8d8;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 25px;
  top: 40%;
  transition: all 0.5s ease-in-out 0s;
  transform: rotate(45deg);
}

.accordion-upper-section span.arrow.expanded {
  transform: rotate(-135deg);
}

.accordion-lower-section {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out 0s;
}

.accordion-lower-section.disable-transition {
  transition: none;
}

.accordion-lower-section.expanded {
  max-height: 400px;
}

.accordion-lower-section .accordion-separator-line {
  border-top: 1px solid #e4e4e4;
}

.accordion-lower-section .lower-section-content {
  padding: 16px 13px;
}

.error-boundary-wrapper {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.error-boundary-wrapper img {
  margin-bottom: 48px;
  width: 260px;
}

.error-boundary-wrapper h1 {
  font-family: sspRegular;
  font-size: 1.429em;
  line-height: 26px;
  margin-bottom: 15px;
}

.error-boundary-wrapper .text-one {
  color: #666;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 16px;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.error-boundary-wrapper .text-two {
  color: #969696;
  font-family: sspBold;
  font-size: 1.286em;
  line-height: 22px;
  margin-bottom: 32px;
}

.error-boundary-wrapper button {
  border: none;
  color: #f74;
  font-family: sspBold;
  font-size: 1.286em;
  line-height: 18px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .error-boundary-wrapper img {
    margin-bottom: 43px;
    width: 312px;
  }

  .error-boundary-wrapper h1 {
    font-size: 2.29em;
    line-height: 40px;
  }

  .error-boundary-wrapper .text-one {
    font-size: 1.286em;
    line-height: 26px;
    margin-bottom: 15px;
  }

  .error-boundary-wrapper .text-two {
    font-size: 1.714em;
    line-height: 30px;
    margin-bottom: 40px;
  }
}

.loader {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
}

.loader .loader-gif {
  position: relative;
}

.loader img {
  width: 75px;
}

.loader .loader-text {
  bottom: -30px;
  color: #fcfcff;
  font-family: sspBold;
  font-size: 14px;
  left: calc(50% - 100px);
  line-height: 16px;
  position: absolute;
  text-align: center;
  width: 200px;
}

.pop-up-wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  bottom: -120%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 4;
}

.pop-up-wrapper.show-pop-up {
  bottom: 0;
  top: 0;
}

.pop-up-wrapper.show-pop-up .pop-up.show-arrow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.pop-up-wrapper.secondary-layout {
  background-color: rgba(34, 34, 34, 0.85);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.pop-up-wrapper.secondary-layout .pop-up {
  border-radius: 10px;
  margin: 5px;
  padding: 20px;
}

.pop-up-wrapper.secondary-layout .pop-up .close-pop-up-icon {
  right: 15px;
  top: 15px;
  width: 24px;
}

.pop-up-wrapper.top-rounded-layout .pop-up {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.pop-up-wrapper.top-rounded-layout .pop-up .close-pop-up-icon {
  width: 24px;
}

.pop-up-wrapper .pop-up {
  background: #fff;
  max-height: 500px;
  overflow: auto;
  position: relative;
  scrollbar-width: none;
  transition: transform 0.2s ease-out 0s;
}

.pop-up-wrapper .pop-up::-webkit-scrollbar {
  display: none;
}

.pop-up-wrapper .pop-up .down-icon-div {
  cursor: pointer;
  left: 50%;
  position: absolute;
  top: 8px;
  width: 20px;
  transform: translateX(-50%);
}

.pop-up-wrapper .pop-up .down-icon-div img {
  width: 100%;
}

.pop-up-wrapper .pop-up .close-pop-up-icon {
  width: 10px;
}

.pop-up-wrapper .pop-up .close-popup-icon-wrapper {
  cursor: pointer;
  padding: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}

@media (max-width: 767px) {
  .pop-up-wrapper.show-pop-up {
    margin-bottom: -15vh;
    padding-bottom: 15vh;
  }

  .pop-up-wrapper.show-pop-up.swipe-bottom .pop-up {
    transform: translateY(0);
  }

  .pop-up-wrapper.show-pop-up.swipe-right .pop-up {
    transform: translateX(0);
  }

  .pop-up-wrapper.swipe-bottom .pop-up {
    transform: translateY(100%);
  }

  .pop-up-wrapper.swipe-right .pop-up {
    transform: translateX(100%);
  }
}

@media (min-width: 768px) {
  .pop-up-wrapper {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: 0;
    top: 0;
    z-index: -1;
    transition: opacity 0.25s ease-in-out 0s;
  }

  .pop-up-wrapper.show-pop-up {
    opacity: 1;
    z-index: 4;
  }

  .pop-up-wrapper.show-pop-up .pop-up {
    border-radius: 20px;
    width: 500px;
  }

  .pop-up-wrapper.top-rounded-layout .pop-up {
    border-radius: 20px;
  }
}

#calendarPortal .calendar-container {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 12px #efecec;
  font-size: 1.143em;
  height: 336px;
  padding: 16px;
  position: absolute;
  width: 307px;
  z-index: 5;
}

#calendarPortal .calendar-container .calendar-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

#calendarPortal .calendar-container .calendar-header .calendar-month {
  color: #e3714d;
  cursor: pointer;
  font-size: 1.571em;
  font-weight: 500;
  padding: 0.5em 0.25em;
  text-align: center;
  word-spacing: 5px;
}

#calendarPortal .calendar-container .arrow {
  border: solid #e3714d;
  border-width: 0 3px 3px 0;
  cursor: pointer;
  display: inline-block;
  padding: 3px;
  position: relative;
}

#calendarPortal .calendar-container .arrow.left {
  transform: rotate(135deg);
  left: -40px;
}

#calendarPortal .calendar-container .arrow.right {
  transform: rotate(-45deg);
  left: -20px;
}

#calendarPortal .calendar-container .arrow.disabled {
  border: solid #e4e4e4;
  border-width: 0 3px 3px 0;
  cursor: auto;
}

#calendarPortal .calendar-container .calendar-grid {
  display: grid;
  transform: translateZ(0);
  transition: transform 0.2s ease-out 0s;
}

#calendarPortal .calendar-container .calendar-grid.date {
  grid-template: repeat(7, auto) / repeat(7, auto);
}

#calendarPortal .calendar-container .calendar-grid.year-month {
  grid-template-columns: auto auto auto;
  row-gap: 10px;
}

#calendarPortal .calendar-container .calendar-grid .calendar-cell {
  align-self: center;
  letter-spacing: 0.1rem;
  padding: 0.6em 0.25em;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#calendarPortal .calendar-container .calendar-grid .calendar-cell.date {
  align-items: center;
  display: flex;
  height: 38px;
  justify-content: center;
  width: 40px;
}

#calendarPortal .calendar-container .calendar-grid .calendar-cell.weekday {
  font-size: 0.714em;
  font-weight: 600;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date {
  color: #666;
  cursor: pointer;
  font-size: 1em;
  font-weight: 300;
  transition: all 0.1s ease-out 0s;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date
  :hover {
  background: #e1ebff;
  border-radius: 50px;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.in-month {
  color: #222;
  font-weight: 500;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.highlighted {
  background: rgba(227, 113, 78, 0.1);
  border-radius: 10px;
  color: #e3714d;
  font-weight: 600;
  position: relative;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.today {
  background: transparent;
  color: #e3714d;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.today
  :hover {
  background: #e1ebff;
  color: #e3714d;
}

#calendarPortal,
.date-picker {
  margin: 5px 0;
  position: relative;
}

#calendarPortal .date-picker-container,
.date-picker .date-picker-container {
  position: relative;
}

#calendarPortal .date-picker-container input,
.date-picker .date-picker-container input {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 0;
  color: #222;
  font-size: 0.857em;
}

#calendarPortal .date-picker-container input.layout-1,
.date-picker .date-picker-container input.layout-1 {
  border: 1px solid #e4e4e4;
}

#calendarPortal .date-picker-container input.layout-2,
.date-picker .date-picker-container input.layout-2 {
  border-bottom: 1px solid #e4e4e4;
}

#calendarPortal .date-picker-container input.error,
.date-picker .date-picker-container input.error {
  border-color: red;
}

#calendarPortal .date-picker-container input:disabled,
.date-picker .date-picker-container input:disabled {
  background-color: transparent;
  color: #222;
  opacity: 1;
  -webkit-text-fill-color: #222;
}

#calendarPortal
  .date-picker-container
  input:disabled.layout-3::-webkit-input-placeholder,
#calendarPortal .date-picker-container input:disabled.layout-3::placeholder,
#calendarPortal
  .date-picker-container
  input:disabled.layout-4::-webkit-input-placeholder,
#calendarPortal .date-picker-container input:disabled.layout-4::placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-3::-webkit-input-placeholder,
.date-picker .date-picker-container input:disabled.layout-3::placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-4::-webkit-input-placeholder,
.date-picker .date-picker-container input:disabled.layout-4::placeholder {
  color: #666;
  font-family: sspRegular;
  -webkit-text-fill-color: #666;
}

#calendarPortal
  .date-picker-container
  input:disabled.layout-3:-ms-input-placeholder,
#calendarPortal
  .date-picker-container
  input:disabled.layout-4:-ms-input-placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-3:-ms-input-placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-4:-ms-input-placeholder {
  color: #666;
  font-family: sspRegular;
}

#calendarPortal .date-picker-container input.layout-3,
.date-picker .date-picker-container input.layout-3 {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  font-family: sspBold;
  line-height: 14px;
}

#calendarPortal .date-picker-container input.layout-3:focus,
.date-picker .date-picker-container input.layout-3:focus {
  background-color: #fff;
  border-color: #ec7f51;
  box-shadow: 0 1px 6px #efd1c5;
  outline: none;
}

#calendarPortal .date-picker-container input.layout-4,
.date-picker .date-picker-container input.layout-4 {
  background-color: #f9f9fc;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  color: #222;
  font-family: sspBold;
  font-size: 1.143em;
}

#calendarPortal .date-picker-container input.layout-4:focus,
.date-picker .date-picker-container input.layout-4:focus {
  background-color: #fff;
  border-color: #ec7f51;
  box-shadow: 0 1px 6px #f7decf;
  outline: none;
}

#calendarPortal .date-picker-container input.layout-3:disabled,
.date-picker .date-picker-container input.layout-3:disabled {
  background-color: #f9f9fc;
}

#calendarPortal .date-picker-container label,
.date-picker .date-picker-container label {
  bottom: 5px;
  color: #aaa;
  font-size: 0.857em;
  position: absolute;
}

#calendarPortal .date-picker-container label.layout-1,
.date-picker .date-picker-container label.layout-1 {
  padding-bottom: 14px;
}

#calendarPortal .date-picker-container label.layout-1.animate,
.date-picker .date-picker-container label.layout-1.animate {
  transform: translateY(-39px);
}

#calendarPortal .date-picker-container label.layout-2.animate,
.date-picker .date-picker-container label.layout-2.animate {
  transform: translateY(-29px);
}

#calendarPortal .date-picker-container label.layout-4,
.date-picker .date-picker-container label.layout-4 {
  color: #222;
  display: block;
  font-family: sspBold;
  font-size: 1em;
  margin: 0 0 5px;
  position: static;
}

#calendarPortal .date-picker-container label.animate:not(.layout-4),
.date-picker .date-picker-container label.animate:not(.layout-4) {
  background-color: #fff;
  bottom: 0;
  font-size: 0.714em;
  padding: 0 1px;
}

#calendarPortal .date-picker-icon,
.date-picker .date-picker-icon {
  height: 16px;
  position: absolute;
  right: 14px;
  top: 37px;
  width: 16px;
}

#calendarPortal .date-picker-overlay,
.date-picker .date-picker-overlay {
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

#calendarPortal .error-message,
.date-picker .error-message {
  color: red;
  display: block;
  font-size: 0.857em;
  line-height: 16px;
  margin: 8px 0;
  text-transform: capitalize;
}

#calendarPortal .popup-heading,
.date-picker .popup-heading {
  color: #222;
  font-family: sspBold;
  font-size: 1em;
  margin: 5px;
}

#calendarPortal .datepicker-btn,
.date-picker .datepicker-btn {
  font-family: sspBold;
  font-size: 1em;
  text-transform: uppercase;
}

#calendarPortal .date-picker-popup,
.date-picker .date-picker-popup {
  z-index: 6;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#calendarPortal .date-picker-popup .close-pop-up-icon,
.date-picker .date-picker-popup .close-pop-up-icon {
  cursor: pointer;
  right: 20px !important;
  top: 20px !important;
}

#calendarPortal .layout-3 .popup-heading,
.date-picker .layout-3 .popup-heading {
  font-size: 1.143em;
}

#calendarPortal .layout-3 .datepicker-btn,
.date-picker .layout-3 .datepicker-btn {
  height: 54px;
}

#calendarPortal .layout-3 .list-container,
.date-picker .layout-3 .list-container {
  margin: 46px 0;
}

#calendarPortal .layout-3 .list-container li.active,
.date-picker .layout-3 .list-container li.active {
  font-size: 1.143em;
}

#calendarPortal .layout-3 .list-container li.active span,
.date-picker .layout-3 .list-container li.active span {
  text-align: center;
}

#calendarPortal .layout-3 .list-container li.active span:before,
.date-picker .layout-3 .list-container li.active span:before {
  border: 1px solid #f74;
  content: "";
  left: -1px;
  position: absolute;
  top: -18px;
  width: 100%;
}

#calendarPortal .layout-3 .list-container li.active span:after,
.date-picker .layout-3 .list-container li.active span:after {
  bottom: -18px;
}

#calendarPortal .layout-3 .list-container li.active .date-text,
.date-picker .layout-3 .list-container li.active .date-text {
  width: 40px;
}

#calendarPortal .layout-3 .list-container li.active .month-text,
.date-picker .layout-3 .list-container li.active .month-text {
  width: 100px;
}

#calendarPortal .layout-3 .list-container li.active .year-text,
.date-picker .layout-3 .list-container li.active .year-text {
  width: 64px;
}

#calendarPortal .list-container,
.date-picker .list-container {
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
}

#calendarPortal .list-container ul,
.date-picker .list-container ul {
  cursor: ns-resize;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 30%;
}

#calendarPortal .list-container ul li,
.date-picker .list-container ul li {
  align-items: center;
  color: #aeaeae;
  display: flex;
  font-family: sspRegular;
  font-size: 1em;
  justify-content: center;
  line-height: 14px;
}

#calendarPortal .list-container ul li::-moz-selection,
.date-picker .list-container ul li::-moz-selection {
  background-color: transparent;
}

#calendarPortal .list-container ul li::selection,
.date-picker .list-container ul li::selection {
  background-color: transparent;
}

#calendarPortal .list-container ul li.active,
.date-picker .list-container ul li.active {
  color: #222;
  font-family: sspBold;
}

#calendarPortal .list-container ul li.active span,
.date-picker .list-container ul li.active span {
  position: relative;
}

#calendarPortal .list-container ul li.active span:after,
.date-picker .list-container ul li.active span:after {
  border: 1px solid #f74;
  bottom: -10px;
  content: "";
  left: -1px;
  position: absolute;
  width: 100%;
}

#calendarPortal .dual-btns,
.date-picker .dual-btns {
  border-top: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

#calendarPortal .dual-btns p,
.date-picker .dual-btns p {
  color: #e3714d;
  cursor: pointer;
  font-family: sspBold;
  font-size: 1.143em;
  padding: 0 10px;
  text-transform: uppercase;
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip,
.date-picker .tooltip-date-picker .__react_component_tooltip {
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  position: absolute;
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.16);
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip.show,
.date-picker .tooltip-date-picker .__react_component_tooltip.show {
  min-width: 340px;
  opacity: 1;
  padding: 20px;
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip.show:after,
#calendarPortal .tooltip-date-picker .__react_component_tooltip.show:before,
.date-picker .tooltip-date-picker .__react_component_tooltip.show:after,
.date-picker .tooltip-date-picker .__react_component_tooltip.show:before {
  content: none;
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip .list-container,
.date-picker .tooltip-date-picker .__react_component_tooltip .list-container {
  margin: 0 0 25px;
}

#calendarPortal .tooltip-date-picker.hide-dp .__react_component_tooltip,
.date-picker .tooltip-date-picker.hide-dp .__react_component_tooltip {
  opacity: 0;
  visibility: hidden;
}

#calendarPortal .info-element,
.date-picker .info-element {
  font-size: 12px;
  margin: 4px 0 0;
}

#calendarPortal {
  margin: 0;
}

@media (max-width: 767px) {
  #calendarPortal .layout-3 .pop-up,
  .date-picker .layout-3 .pop-up {
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
  }
}

@media (min-width: 768px) {
  #calendarPortal .layout-3 .list-container,
  .date-picker .layout-3 .list-container {
    margin: 0 0 20px;
  }
}

@media (max-width: 1024px) {
  #calendarPortal .list-container ul,
  .date-picker .list-container ul {
    scrollbar-width: none;
  }

  #calendarPortal .list-container ul::-webkit-scrollbar,
  .date-picker .list-container ul::-webkit-scrollbar {
    display: none;
  }
}

.tnc-popup {
  padding: 25px 20px 20px;
}

.tnc-popup .popup-heading {
  color: #222;
  font-family: sspBold;
  font-size: 1.286em;
  margin-bottom: 10px;
}

.tnc-popup .popup-content {
  color: #666;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 20px;
}

.tnc-popup .popup-content b {
  color: #e3714d;
  cursor: pointer;
  font-family: sspBold;
}

.tnc-popup .popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.tnc-popup .popup-actions button {
  border-radius: 10px;
  font-family: sspBold;
  font-size: 1.143em;
  height: 54px;
  width: 48%;
}

.tnc-popup .popup-actions button.accept-btn {
  background: #f74;
}

.kyc-popup-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px;
}

.kyc-popup-container .kyc-popup-heading {
  color: #5c5c5c;
  font-family: sspBold;
  font-size: 1.143em;
  margin-bottom: 30px;
  position: relative;
}

.kyc-popup-container .kyc-popup-heading:after {
  border: 2px solid #e3714d;
  border-radius: 10px;
  bottom: -15px;
  content: "";
  left: calc(50% - 15px);
  position: absolute;
  width: 30px;
}

.kyc-popup-container .kyc-popup-text {
  color: #5c5c5c;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.kyc-popup-container .upgrade-btn {
  margin-bottom: 10px;
}

.kyc-popup-container .remind-later-btn {
  border: none;
  color: #aeaeae;
  font-family: sspBold;
  font-size: 1em;
  height: 42px;
  text-align: center;
  width: auto;
}

.progress {
  height: 2px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.progress .indicator {
  background-color: #ff7845;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.1s linear, opacity 0.3s linear 0.2s;
  width: 0;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  margin: 0;
  padding: 0;
}

@font-face {
  font-display: swap;
  font-family: sspBoldItalic;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBlack;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBlackIt;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBolder;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspExtraLight;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspLight;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBold;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspRegular;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: lobsterRegular;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: lobsterBold;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.svg)
      format("svg");
}

* {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background-color: #f2f5fc;
  font-family: sspRegular;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hide {
  display: none;
}

.show {
  display: block;
}

a,
button,
input,
select,
textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input,
select,
textarea {
  font-family: sspRegular;
}

button:focus,
input:focus {
  outline: none;
}

button:focus-visible,
input:focus-visible {
  outline: none;
}

button:focus-within,
input:focus-within {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
  background-color: #fff !important;
  box-shadow: inset 0 0 0 9999px #fff;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #aeaeae;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #aeaeae;
}

input::placeholder,
textarea::placeholder {
  color: #aeaeae;
}

a,
a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
}

img:-moz-loading {
  visibility: hidden;
}

.container,
.main-wrapper {
  width: 100%;
}

.storybook {
  background-color: #7a7a7a;
  max-width: 300px;
}

@media (min-width: 1025px) {
  .container {
    margin: 0 auto;
    max-width: 768px;
  }

  .content-wrapper,
  .main-wrapper {
    margin: 0 auto;
    max-width: 1366px;
  }
}
.close-button {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

#bottomToastPortal,
#topToastPortal {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 95%;
  z-index: 10;
}

#bottomToastPortal {
  bottom: 10px;
}

@media (min-width: 768px) {
  #bottomToastPortal,
  #topToastPortal {
    width: 99%;
  }
}

.seo-container {
  line-height: 1.5;
  margin: 30px 20px 5px;
}

.seo-content p {
  color: #7a7a7a;
  font-size: 0.857em;
  margin-bottom: 10px;
}

.seo-content p span {
  background-color: transparent;
}

.seo-content h1,
.seo-content h2,
.seo-content h3,
.seo-content h4 {
  color: #5c5c5c;
  font-family: sspBold;
  font-size: 1em;
  font-weight: 400;
}

.seo-content h1 {
  margin-bottom: 13px;
}

.seo-content h2,
.seo-content h3,
.seo-content h4 {
  margin: 23px 0 13px;
}

.seo-content a {
  color: #464646;
}

.seo-content ul {
  color: #7a7a7a;
  font-size: 0.857em;
}

.about-section {
  color: #888;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 22px;
}

.about-section h2,
.about-section h3,
.about-section h4 {
  color: #444;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 1.5;
  text-transform: capitalize;
}

.about-section h2 ~ p,
.about-section h3 ~ p,
.about-section h4 ~ p {
  margin-top: 5px;
  white-space: normal;
}

.about-section h1 {
  color: #444;
  font-family: sspBold;
  font-size: 1.143em;
  font-weight: 400;
  line-height: 1.8;
  text-transform: capitalize;
}

.about-section h1 ~ p {
  margin-top: 5px;
  white-space: normal;
}

.about-section h1 ~ ul {
  list-style-type: disc;
  padding: 3px 0 0 18px;
}

.about-section h1 ~ ul li {
  white-space: normal;
}

.about-section h1 ~ ol {
  list-style-type: decimal;
  padding: 3px 0 0 18px;
}

.about-section h1 ~ ol li {
  white-space: normal;
}

.about-section li,
.about-section p {
  color: #888;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 22px;
}

.about-section li strong,
.about-section p strong {
  color: #444;
  display: inline-block;
  font-family: sspBold;
  margin-top: 15px;
}

.about-section a {
  color: #464646;
}

.about-section div[itemtype="http://schema.org/FAQPage"]
{
  margin: 10px 0;
}

.about-section div[itemtype="http://schema.org/Question"]
{
  margin: 20px 0;
}

.about-section .faq-question {
  margin-bottom: 10px;
}

.about-section h1:not(:first-child),
.about-section h2:not(:first-child),
.about-section h3:not(:first-child),
.about-section h4:not(:first-child) {
  margin-top: 30px;
}

.about-section p:not(:first-child) {
  margin-top: 5px;
}

.about-section .utility-table {
  border: 1px solid #b0b0b0;
  margin: 23px 0 10px;
}

.about-section .utility-table tr {
  border: 1px solid #b0b0b0;
  padding: 10px;
}

.about-section .utility-table tr:first-child {
  background-color: #f5f5f5;
  border: none;
}

.about-section .utility-table tr:first-child td {
  border: none;
  font-family: sspLight;
}

.about-section .utility-table td {
  border: 1px solid #b0b0b0;
  padding: 10px;
}

.seo-wrapper {
  padding: 10px 15px 15px;
}

.seo-wrapper > div {
  margin-bottom: 15px;
}

.seo-wrapper .heading {
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 20px;
}

.seo-wrapper .sub-heading {
  line-height: 18px;
}

.seo-wrapper .section-main .content {
  line-height: 18px;
  margin-top: 12px;
}

.seo-wrapper .checklist .check-items {
  align-items: center;
  display: flex;
}

.seo-wrapper .checklist .check-items img {
  height: 16px;
  margin-right: 10px;
  width: 16px;
}

.seo-wrapper .checklist .check-items p {
  font-size: 1.143em;
}

.seo-wrapper .cards-container {
  display: flex;
}

.seo-wrapper .cards-container .cards-content {
  display: flex;
  flex-direction: row;
}

.seo-wrapper .cards-container .cards-content p {
  line-height: 18px;
}

.seo-wrapper .cards-container .cards-content img {
  height: 32px;
  width: 32px;
}

.seo-wrapper .cards-container .cards-content .card-header {
  font-family: sspBold;
  font-size: 1.07em;
  margin-bottom: 8px;
}

.seo-wrapper .seo-pointers-wrapper ul {
  list-style-type: disc;
}

.seo-wrapper .seo-pointers-wrapper ul li {
  margin: 0 0 20px 12px;
}

.seo-wrapper .seo-pointers-wrapper ul li .heading {
  font-family: sspBold;
  font-size: 1.07em;
  line-height: 19px;
}

.seo-wrapper .seo-pointers-wrapper ul li .label {
  line-height: 22px;
}

.seo-wrapper .seo-pointers-wrapper .list-item {
  display: flex;
  list-style: disc;
  margin-top: 24px;
}

.seo-wrapper .seo-pointers-wrapper .list-item img {
  height: 32px;
  margin-right: 15px;
  width: 32px;
}

.seo-wrapper .seo-pointers-wrapper .list-item .list-info {
  display: flex;
  flex-direction: column;
}

.seo-wrapper .seo-pointers-wrapper .list-item .list-info .heading {
  font-family: sspBold;
  font-size: 1.07em;
  line-height: 19px;
}

.seo-wrapper .seo-pointers-wrapper .list-item .list-info .label {
  line-height: 22px;
}

.seo-wrapper .faqs-component .heading {
  padding: 10px 0 6px;
}

.seo-wrapper .faqs-component .seo-faqs {
  border: 0;
  border-radius: 16px;
  margin-top: 10px;
  padding: 18px 20px;
  box-shadow: 0 2px 10px 0 rgba(69, 48, 32, 0.05);
}

.seo-wrapper .faqs-component .seo-faqs .accordion-upper-section {
  min-height: unset;
  padding: 0;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-upper-section
  .upper-section-content {
  color: #000;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 22px;
}

.seo-wrapper .faqs-component .seo-faqs .accordion-upper-section span.arrow {
  border-bottom: 1.5px solid #222;
  border-color: #222 currentcolor;
  border-right: 1.5px solid;
  right: 10px;
  top: 5px;
}

.seo-wrapper .faqs-component .seo-faqs .accordion-lower-section {
  padding: 0;
  transition: all 0.5s ease-in-out 0s;
}

.seo-wrapper .faqs-component .seo-faqs .accordion-lower-section.expanded {
  margin-top: 10px;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .accordion-separator-line {
  display: none;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content {
  color: #666;
  line-height: 22px;
  padding: 0;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  .bubbles {
  margin-top: 20px;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  span {
  display: inline-block;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  span:not(:first-child) {
  margin-top: 20px;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  ol {
  list-style: decimal;
  margin-left: 20px;
}

.seo-wrapper
  .faqs-component
  .seo-faqs
  .accordion-lower-section
  .lower-section-content
  ul {
  list-style: disc;
  margin-left: 20px;
}

@media (max-width: 767px) {
  .about-section {
    background-color: #fff;
    border-radius: 20px;
    margin: 10px 0;
    padding: 30px 20px;
    position: relative;
  }

  .about-section .content-section {
    overflow: hidden;
    transition: max-height 0.25s ease-in-out 0s;
  }

  .about-section .read-more-overlay {
    bottom: 48px;
    height: 3rem;
    left: 20px;
    position: absolute;
    right: 20px;
    background: hsla(0, 0%, 100%, 0);
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0) 0,
      hsla(0, 0%, 100%, 0) 30%,
      #fff 80%,
      #fff
    );
  }

  .about-section .about-section-read-more-toggle {
    color: #666;
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .about-section {
    margin: 35px 0 15px;
    padding: 25px;
  }
}

@media (min-width: 1025px) {
  .about-section {
    padding: 0;
  }

  .seo-wrapper {
    padding: 70px 0 10px;
  }

  .seo-wrapper > div {
    margin-bottom: 70px;
  }

  .seo-wrapper .heading {
    font-size: 2.143em;
    line-height: 38px;
  }

  .seo-wrapper .sub-heading {
    font-size: 1.143em;
    line-height: 30px;
  }

  .seo-wrapper .section-main .content {
    line-height: 22px;
    margin-top: 15px;
  }

  .seo-wrapper .checklist {
    -moz-column-gap: 20px;
    column-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    row-gap: 36px;
  }

  .seo-wrapper .checklist .check-items {
    align-items: center;
    display: flex;
    flex-basis: 30%;
    max-width: 30%;
  }

  .seo-wrapper .checklist .check-items img {
    height: 22px;
    width: 22px;
  }

  .seo-wrapper .seo-pointers-wrapper {
    align-items: center;
    -moz-column-gap: 100px;
    column-gap: 100px;
    display: flex;
    justify-content: space-between;
  }

  .seo-wrapper .seo-pointers-wrapper .main-image {
    flex-basis: 45%;
    width: 100%;
  }

  .seo-wrapper .seo-pointers-wrapper ul li {
    margin: 36px 0 0 20px;
  }

  .seo-wrapper .seo-pointers-wrapper ul li .heading {
    font-size: 1.143em;
    line-height: 22.5px;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item {
    margin-top: 36px;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item img {
    height: 42px;
    width: 42px;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item .list-info .heading {
    font-size: 1.143em;
    line-height: 22.5px;
  }

  .seo-wrapper .cards-container {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 22px;
    row-gap: 20px;
  }

  .seo-wrapper .cards-container .cards-content {
    border: 1px solid #f0f0f0;
    border-radius: 20px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.059);
    flex-direction: column;
    padding: 30px 25px;
    width: 310px;
  }

  .seo-wrapper .cards-container .cards-content p {
    line-height: 22px;
  }

  .seo-wrapper .cards-container .cards-content img {
    height: 42px;
    width: 42px;
  }

  .seo-wrapper .cards-container .cards-content .card-header {
    font-size: 1.286em;
    margin: 32px 0 16px;
  }

  .seo-wrapper .cards-container .cards-content .content {
    line-height: 22px;
  }

  .seo-wrapper .faqs-component .heading {
    padding: 0 0 15px;
  }

  .seo-wrapper
    .faqs-component
    .seo-faqs
    .accordion-upper-section
    .upper-section-content {
    font-size: 1.286em;
  }
}

@media (max-width: 1024px) {
  .seo-wrapper {
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    position: relative;
    top: -20px;
  }

  .seo-wrapper > div:not(.faqs-component) {
    border: 0.6px solid #e1ebff;
    border-radius: 20px;
    padding: 16px 12px 16px 16px;
  }

  .seo-wrapper .sub-heading {
    color: #666;
    margin-top: 16px;
  }

  .seo-wrapper .section-main .content {
    color: #666;
  }

  .seo-wrapper .checklist .check-items {
    margin-top: 15px;
  }

  .seo-wrapper .seo-pointers-wrapper .main-image {
    padding: 40px 16px 4px 12px;
    width: 100%;
  }

  .seo-wrapper .seo-pointers-wrapper ul {
    margin-top: 15px;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item .list-info .label,
  .seo-wrapper .seo-pointers-wrapper ul li .label {
    color: #666;
  }

  .seo-wrapper .seo-pointers-wrapper .list-item img {
    margin-top: 2px;
  }

  .seo-wrapper .cards-container {
    flex-direction: column;
  }

  .seo-wrapper .cards-container .cards-content {
    margin-top: 24px;
  }

  .seo-wrapper .cards-container .cards-content .textual-data {
    margin-left: 20px;
  }

  .seo-wrapper .cards-container .cards-content .content {
    color: #666;
  }
}

.accordion-container {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
}

.accordion-upper-section {
  cursor: pointer;
  min-height: 90px;
  padding: 22px;
  position: relative;
}

.accordion-upper-section .upper-section-content {
  width: 80%;
}

.accordion-upper-section img.icon {
  position: absolute;
  right: 12px;
  top: calc(50% - 6px);
  transition: all 0.5s ease-in-out 0s;
}

.accordion-upper-section img.icon.expanded {
  transform: rotate(-180deg);
}

.accordion-upper-section img.icon.disable-transition {
  transition: none;
}

.accordion-upper-section img.expanded-icon {
  -o-object-fit: contain;
  object-fit: contain;
}

.accordion-upper-section img.visible {
  opacity: 1;
}

.accordion-upper-section img.invisible {
  opacity: 0;
}

.accordion-upper-section span.arrow {
  border-bottom: 2px solid #d8d8d8;
  border-right: 2px solid #d8d8d8;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 25px;
  top: 40%;
  transition: all 0.5s ease-in-out 0s;
  transform: rotate(45deg);
}

.accordion-upper-section span.arrow.expanded {
  transform: rotate(-135deg);
}

.accordion-lower-section {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out 0s;
}

.accordion-lower-section.disable-transition {
  transition: none;
}

.accordion-lower-section.expanded {
  max-height: 400px;
}

.accordion-lower-section .accordion-separator-line {
  border-top: 1px solid #e4e4e4;
}

.accordion-lower-section .lower-section-content {
  padding: 16px 13px;
}

.error-boundary-wrapper {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.error-boundary-wrapper img {
  margin-bottom: 48px;
  width: 260px;
}

.error-boundary-wrapper h1 {
  font-family: sspRegular;
  font-size: 1.429em;
  line-height: 26px;
  margin-bottom: 15px;
}

.error-boundary-wrapper .text-one {
  color: #666;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 16px;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.error-boundary-wrapper .text-two {
  color: #969696;
  font-family: sspBold;
  font-size: 1.286em;
  line-height: 22px;
  margin-bottom: 32px;
}

.error-boundary-wrapper button {
  border: none;
  color: #f74;
  font-family: sspBold;
  font-size: 1.286em;
  line-height: 18px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .error-boundary-wrapper img {
    margin-bottom: 43px;
    width: 312px;
  }

  .error-boundary-wrapper h1 {
    font-size: 2.29em;
    line-height: 40px;
  }

  .error-boundary-wrapper .text-one {
    font-size: 1.286em;
    line-height: 26px;
    margin-bottom: 15px;
  }

  .error-boundary-wrapper .text-two {
    font-size: 1.714em;
    line-height: 30px;
    margin-bottom: 40px;
  }
}

.loader {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
}

.loader .loader-gif {
  position: relative;
}

.loader img {
  width: 75px;
}

.loader .loader-text {
  bottom: -30px;
  color: #fcfcff;
  font-family: sspBold;
  font-size: 14px;
  left: calc(50% - 100px);
  line-height: 16px;
  position: absolute;
  text-align: center;
  width: 200px;
}

.pop-up-wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  bottom: -120%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 4;
}

.pop-up-wrapper.show-pop-up {
  bottom: 0;
  top: 0;
}

.pop-up-wrapper.show-pop-up .pop-up.show-arrow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.pop-up-wrapper.secondary-layout {
  background-color: rgba(34, 34, 34, 0.85);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.pop-up-wrapper.secondary-layout .pop-up {
  border-radius: 10px;
  margin: 5px;
  padding: 20px;
}

.pop-up-wrapper.secondary-layout .pop-up .close-pop-up-icon {
  right: 15px;
  top: 15px;
  width: 24px;
}

.pop-up-wrapper.top-rounded-layout .pop-up {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.pop-up-wrapper.top-rounded-layout .pop-up .close-pop-up-icon {
  width: 24px;
}

.pop-up-wrapper .pop-up {
  background: #fff;
  max-height: 500px;
  overflow: auto;
  position: relative;
  scrollbar-width: none;
  transition: transform 0.2s ease-out 0s;
}

.pop-up-wrapper .pop-up::-webkit-scrollbar {
  display: none;
}

.pop-up-wrapper .pop-up .down-icon-div {
  cursor: pointer;
  left: 50%;
  position: absolute;
  top: 8px;
  width: 20px;
  transform: translateX(-50%);
}

.pop-up-wrapper .pop-up .down-icon-div img {
  width: 100%;
}

.pop-up-wrapper .pop-up .close-pop-up-icon {
  width: 10px;
}

.pop-up-wrapper .pop-up .close-popup-icon-wrapper {
  cursor: pointer;
  padding: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}

@media (max-width: 767px) {
  .pop-up-wrapper.show-pop-up {
    margin-bottom: -15vh;
    padding-bottom: 15vh;
  }

  .pop-up-wrapper.show-pop-up.swipe-bottom .pop-up {
    transform: translateY(0);
  }

  .pop-up-wrapper.show-pop-up.swipe-right .pop-up {
    transform: translateX(0);
  }

  .pop-up-wrapper.swipe-bottom .pop-up {
    transform: translateY(100%);
  }

  .pop-up-wrapper.swipe-right .pop-up {
    transform: translateX(100%);
  }
}

@media (min-width: 768px) {
  .pop-up-wrapper {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: 0;
    top: 0;
    z-index: -1;
    transition: opacity 0.25s ease-in-out 0s;
  }

  .pop-up-wrapper.show-pop-up {
    opacity: 1;
    z-index: 4;
  }

  .pop-up-wrapper.show-pop-up .pop-up {
    border-radius: 20px;
    width: 500px;
  }

  .pop-up-wrapper.top-rounded-layout .pop-up {
    border-radius: 20px;
  }
}

#calendarPortal .calendar-container {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 12px #efecec;
  font-size: 1.143em;
  height: 336px;
  padding: 16px;
  position: absolute;
  width: 307px;
  z-index: 5;
}

#calendarPortal .calendar-container .calendar-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

#calendarPortal .calendar-container .calendar-header .calendar-month {
  color: #e3714d;
  cursor: pointer;
  font-size: 1.571em;
  font-weight: 500;
  padding: 0.5em 0.25em;
  text-align: center;
  word-spacing: 5px;
}

#calendarPortal .calendar-container .arrow {
  border: solid #e3714d;
  border-width: 0 3px 3px 0;
  cursor: pointer;
  display: inline-block;
  padding: 3px;
  position: relative;
}

#calendarPortal .calendar-container .arrow.left {
  transform: rotate(135deg);
  left: -40px;
}

#calendarPortal .calendar-container .arrow.right {
  transform: rotate(-45deg);
  left: -20px;
}

#calendarPortal .calendar-container .arrow.disabled {
  border: solid #e4e4e4;
  border-width: 0 3px 3px 0;
  cursor: auto;
}

#calendarPortal .calendar-container .calendar-grid {
  display: grid;
  transform: translateZ(0);
  transition: transform 0.2s ease-out 0s;
}

#calendarPortal .calendar-container .calendar-grid.date {
  grid-template: repeat(7, auto) / repeat(7, auto);
}

#calendarPortal .calendar-container .calendar-grid.year-month {
  grid-template-columns: auto auto auto;
  row-gap: 10px;
}

#calendarPortal .calendar-container .calendar-grid .calendar-cell {
  align-self: center;
  letter-spacing: 0.1rem;
  padding: 0.6em 0.25em;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#calendarPortal .calendar-container .calendar-grid .calendar-cell.date {
  align-items: center;
  display: flex;
  height: 38px;
  justify-content: center;
  width: 40px;
}

#calendarPortal .calendar-container .calendar-grid .calendar-cell.weekday {
  font-size: 0.714em;
  font-weight: 600;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date {
  color: #666;
  cursor: pointer;
  font-size: 1em;
  font-weight: 300;
  transition: all 0.1s ease-out 0s;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date
  :hover {
  background: #e1ebff;
  border-radius: 50px;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.in-month {
  color: #222;
  font-weight: 500;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.highlighted {
  background: rgba(227, 113, 78, 0.1);
  border-radius: 10px;
  color: #e3714d;
  font-weight: 600;
  position: relative;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.today {
  background: transparent;
  color: #e3714d;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.today
  :hover {
  background: #e1ebff;
  color: #e3714d;
}

#calendarPortal,
.date-picker {
  margin: 5px 0;
  position: relative;
}

#calendarPortal .date-picker-container,
.date-picker .date-picker-container {
  position: relative;
}

#calendarPortal .date-picker-container input,
.date-picker .date-picker-container input {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 0;
  color: #222;
  font-size: 0.857em;
}

#calendarPortal .date-picker-container input.layout-1,
.date-picker .date-picker-container input.layout-1 {
  border: 1px solid #e4e4e4;
}

#calendarPortal .date-picker-container input.layout-2,
.date-picker .date-picker-container input.layout-2 {
  border-bottom: 1px solid #e4e4e4;
}

#calendarPortal .date-picker-container input.error,
.date-picker .date-picker-container input.error {
  border-color: red;
}

#calendarPortal .date-picker-container input:disabled,
.date-picker .date-picker-container input:disabled {
  background-color: transparent;
  color: #222;
  opacity: 1;
  -webkit-text-fill-color: #222;
}

#calendarPortal
  .date-picker-container
  input:disabled.layout-3::-webkit-input-placeholder,
#calendarPortal .date-picker-container input:disabled.layout-3::placeholder,
#calendarPortal
  .date-picker-container
  input:disabled.layout-4::-webkit-input-placeholder,
#calendarPortal .date-picker-container input:disabled.layout-4::placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-3::-webkit-input-placeholder,
.date-picker .date-picker-container input:disabled.layout-3::placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-4::-webkit-input-placeholder,
.date-picker .date-picker-container input:disabled.layout-4::placeholder {
  color: #666;
  font-family: sspRegular;
  -webkit-text-fill-color: #666;
}

#calendarPortal
  .date-picker-container
  input:disabled.layout-3:-ms-input-placeholder,
#calendarPortal
  .date-picker-container
  input:disabled.layout-4:-ms-input-placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-3:-ms-input-placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-4:-ms-input-placeholder {
  color: #666;
  font-family: sspRegular;
}

#calendarPortal .date-picker-container input.layout-3,
.date-picker .date-picker-container input.layout-3 {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  font-family: sspBold;
  line-height: 14px;
}

#calendarPortal .date-picker-container input.layout-3:focus,
.date-picker .date-picker-container input.layout-3:focus {
  background-color: #fff;
  border-color: #ec7f51;
  box-shadow: 0 1px 6px #efd1c5;
  outline: none;
}

#calendarPortal .date-picker-container input.layout-4,
.date-picker .date-picker-container input.layout-4 {
  background-color: #f9f9fc;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  color: #222;
  font-family: sspBold;
  font-size: 1.143em;
}

#calendarPortal .date-picker-container input.layout-4:focus,
.date-picker .date-picker-container input.layout-4:focus {
  background-color: #fff;
  border-color: #ec7f51;
  box-shadow: 0 1px 6px #f7decf;
  outline: none;
}

#calendarPortal .date-picker-container input.layout-3:disabled,
.date-picker .date-picker-container input.layout-3:disabled {
  background-color: #f9f9fc;
}

#calendarPortal .date-picker-container label,
.date-picker .date-picker-container label {
  bottom: 5px;
  color: #aaa;
  font-size: 0.857em;
  position: absolute;
}

#calendarPortal .date-picker-container label.layout-1,
.date-picker .date-picker-container label.layout-1 {
  padding-bottom: 14px;
}

#calendarPortal .date-picker-container label.layout-1.animate,
.date-picker .date-picker-container label.layout-1.animate {
  transform: translateY(-39px);
}

#calendarPortal .date-picker-container label.layout-2.animate,
.date-picker .date-picker-container label.layout-2.animate {
  transform: translateY(-29px);
}

#calendarPortal .date-picker-container label.layout-4,
.date-picker .date-picker-container label.layout-4 {
  color: #222;
  display: block;
  font-family: sspBold;
  font-size: 1em;
  margin: 0 0 5px;
  position: static;
}

#calendarPortal .date-picker-container label.animate:not(.layout-4),
.date-picker .date-picker-container label.animate:not(.layout-4) {
  background-color: #fff;
  bottom: 0;
  font-size: 0.714em;
  padding: 0 1px;
}

#calendarPortal .date-picker-icon,
.date-picker .date-picker-icon {
  height: 16px;
  position: absolute;
  right: 14px;
  top: 37px;
  width: 16px;
}

#calendarPortal .date-picker-overlay,
.date-picker .date-picker-overlay {
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

#calendarPortal .error-message,
.date-picker .error-message {
  color: red;
  display: block;
  font-size: 0.857em;
  line-height: 16px;
  margin: 8px 0;
  text-transform: capitalize;
}

#calendarPortal .popup-heading,
.date-picker .popup-heading {
  color: #222;
  font-family: sspBold;
  font-size: 1em;
  margin: 5px;
}

#calendarPortal .datepicker-btn,
.date-picker .datepicker-btn {
  font-family: sspBold;
  font-size: 1em;
  text-transform: uppercase;
}

#calendarPortal .date-picker-popup,
.date-picker .date-picker-popup {
  z-index: 6;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#calendarPortal .date-picker-popup .close-pop-up-icon,
.date-picker .date-picker-popup .close-pop-up-icon {
  cursor: pointer;
  right: 20px !important;
  top: 20px !important;
}

#calendarPortal .layout-3 .popup-heading,
.date-picker .layout-3 .popup-heading {
  font-size: 1.143em;
}

#calendarPortal .layout-3 .datepicker-btn,
.date-picker .layout-3 .datepicker-btn {
  height: 54px;
}

#calendarPortal .layout-3 .list-container,
.date-picker .layout-3 .list-container {
  margin: 46px 0;
}

#calendarPortal .layout-3 .list-container li.active,
.date-picker .layout-3 .list-container li.active {
  font-size: 1.143em;
}

#calendarPortal .layout-3 .list-container li.active span,
.date-picker .layout-3 .list-container li.active span {
  text-align: center;
}

#calendarPortal .layout-3 .list-container li.active span:before,
.date-picker .layout-3 .list-container li.active span:before {
  border: 1px solid #f74;
  content: "";
  left: -1px;
  position: absolute;
  top: -18px;
  width: 100%;
}

#calendarPortal .layout-3 .list-container li.active span:after,
.date-picker .layout-3 .list-container li.active span:after {
  bottom: -18px;
}

#calendarPortal .layout-3 .list-container li.active .date-text,
.date-picker .layout-3 .list-container li.active .date-text {
  width: 40px;
}

#calendarPortal .layout-3 .list-container li.active .month-text,
.date-picker .layout-3 .list-container li.active .month-text {
  width: 100px;
}

#calendarPortal .layout-3 .list-container li.active .year-text,
.date-picker .layout-3 .list-container li.active .year-text {
  width: 64px;
}

#calendarPortal .list-container,
.date-picker .list-container {
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
}

#calendarPortal .list-container ul,
.date-picker .list-container ul {
  cursor: ns-resize;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 30%;
}

#calendarPortal .list-container ul li,
.date-picker .list-container ul li {
  align-items: center;
  color: #aeaeae;
  display: flex;
  font-family: sspRegular;
  font-size: 1em;
  justify-content: center;
  line-height: 14px;
}

#calendarPortal .list-container ul li::-moz-selection,
.date-picker .list-container ul li::-moz-selection {
  background-color: transparent;
}

#calendarPortal .list-container ul li::selection,
.date-picker .list-container ul li::selection {
  background-color: transparent;
}

#calendarPortal .list-container ul li.active,
.date-picker .list-container ul li.active {
  color: #222;
  font-family: sspBold;
}

#calendarPortal .list-container ul li.active span,
.date-picker .list-container ul li.active span {
  position: relative;
}

#calendarPortal .list-container ul li.active span:after,
.date-picker .list-container ul li.active span:after {
  border: 1px solid #f74;
  bottom: -10px;
  content: "";
  left: -1px;
  position: absolute;
  width: 100%;
}

#calendarPortal .dual-btns,
.date-picker .dual-btns {
  border-top: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

#calendarPortal .dual-btns p,
.date-picker .dual-btns p {
  color: #e3714d;
  cursor: pointer;
  font-family: sspBold;
  font-size: 1.143em;
  padding: 0 10px;
  text-transform: uppercase;
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip,
.date-picker .tooltip-date-picker .__react_component_tooltip {
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  position: absolute;
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.16);
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip.show,
.date-picker .tooltip-date-picker .__react_component_tooltip.show {
  min-width: 340px;
  opacity: 1;
  padding: 20px;
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip.show:after,
#calendarPortal .tooltip-date-picker .__react_component_tooltip.show:before,
.date-picker .tooltip-date-picker .__react_component_tooltip.show:after,
.date-picker .tooltip-date-picker .__react_component_tooltip.show:before {
  content: none;
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip .list-container,
.date-picker .tooltip-date-picker .__react_component_tooltip .list-container {
  margin: 0 0 25px;
}

#calendarPortal .tooltip-date-picker.hide-dp .__react_component_tooltip,
.date-picker .tooltip-date-picker.hide-dp .__react_component_tooltip {
  opacity: 0;
  visibility: hidden;
}

#calendarPortal .info-element,
.date-picker .info-element {
  font-size: 12px;
  margin: 4px 0 0;
}

#calendarPortal {
  margin: 0;
}

@media (max-width: 767px) {
  #calendarPortal .layout-3 .pop-up,
  .date-picker .layout-3 .pop-up {
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
  }
}

@media (min-width: 768px) {
  #calendarPortal .layout-3 .list-container,
  .date-picker .layout-3 .list-container {
    margin: 0 0 20px;
  }
}

@media (max-width: 1024px) {
  #calendarPortal .list-container ul,
  .date-picker .list-container ul {
    scrollbar-width: none;
  }

  #calendarPortal .list-container ul::-webkit-scrollbar,
  .date-picker .list-container ul::-webkit-scrollbar {
    display: none;
  }
}

.tnc-popup {
  padding: 25px 20px 20px;
}

.tnc-popup .popup-heading {
  color: #222;
  font-family: sspBold;
  font-size: 1.286em;
  margin-bottom: 10px;
}

.tnc-popup .popup-content {
  color: #666;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 20px;
}

.tnc-popup .popup-content b {
  color: #e3714d;
  cursor: pointer;
  font-family: sspBold;
}

.tnc-popup .popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.tnc-popup .popup-actions button {
  border-radius: 10px;
  font-family: sspBold;
  font-size: 1.143em;
  height: 54px;
  width: 48%;
}

.tnc-popup .popup-actions button.accept-btn {
  background: #f74;
}

.kyc-popup-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px;
}

.kyc-popup-container .kyc-popup-heading {
  color: #5c5c5c;
  font-family: sspBold;
  font-size: 1.143em;
  margin-bottom: 30px;
  position: relative;
}

.kyc-popup-container .kyc-popup-heading:after {
  border: 2px solid #e3714d;
  border-radius: 10px;
  bottom: -15px;
  content: "";
  left: calc(50% - 15px);
  position: absolute;
  width: 30px;
}

.kyc-popup-container .kyc-popup-text {
  color: #5c5c5c;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.kyc-popup-container .upgrade-btn {
  margin-bottom: 10px;
}

.kyc-popup-container .remind-later-btn {
  border: none;
  color: #aeaeae;
  font-family: sspBold;
  font-size: 1em;
  height: 42px;
  text-align: center;
  width: auto;
}

.progress {
  height: 2px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.progress .indicator {
  background-color: #ff7845;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.1s linear, opacity 0.3s linear 0.2s;
  width: 0;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  margin: 0;
  padding: 0;
}

@font-face {
  font-display: swap;
  font-family: sspBoldItalic;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBlack;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBlackIt;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBolder;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspExtraLight;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspLight;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBold;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspRegular;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: lobsterRegular;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: lobsterBold;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.svg)
      format("svg");
}

* {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background-color: #f2f5fc;
  font-family: sspRegular;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hide {
  display: none;
}

.show {
  display: block;
}

a,
button,
input,
select,
textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input,
select,
textarea {
  font-family: sspRegular;
}

button:focus,
input:focus {
  outline: none;
}

button:focus-visible,
input:focus-visible {
  outline: none;
}

button:focus-within,
input:focus-within {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
  background-color: #fff !important;
  box-shadow: inset 0 0 0 9999px #fff;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #aeaeae;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #aeaeae;
}

input::placeholder,
textarea::placeholder {
  color: #aeaeae;
}

a,
a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
}

img:-moz-loading {
  visibility: hidden;
}

.container,
.main-wrapper {
  width: 100%;
}

.storybook {
  background-color: #7a7a7a;
  max-width: 300px;
}

@media (min-width: 1025px) {
  .container {
    margin: 0 auto;
    max-width: 768px;
  }

  .content-wrapper,
  .main-wrapper {
    margin: 0 auto;
    max-width: 1366px;
  }
}
