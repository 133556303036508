.container {
  align-items: center;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}

a {
  color: #f74;
  font-size: 20px;
  line-height: 18px;
  font-weight: 600;
  text-decoration: none;
  margin-top: 40px;
}

h1 {
  line-height: 18px;
  font-size: 36px;
  font-weight: 600;
  color: #858585;
}
