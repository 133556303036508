.header {
  position: sticky;
  top: 0;
  z-index: 4;
  box-shadow: 0 0 20px 0 hsla(0, 0%, 94%, 0.6);
  background-color: #fff;
  padding-left: 30px;
}
.recharge-nav {
  list-style-type: none;
}

.recharge-nav a {
  color: #5a6876;
  flex: 0 0 82%;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 18px;
  text-decoration: none;
}

.main-section {
  min-height: 80vh;
}

.login-btn a {
  font-size: 16px;
  cursor: pointer;
  color: green;
}
.flex {
  display: flex;
}

.h-center {
  display: flex;
  justify-content: center;
}
.v-center {
  display: flex;
  align-items: center;
}

.recharge-title {
  color: #222;
  font-family: sspRegular;
  font-size: 1.286em;
  line-height: 28px;
}

.nextBtn {
  box-shadow: 0 6px 14px 0 rgba(255, 119, 68, 0.5);
  margin-top: 15px;
  max-width: 200px;
}

.operator option:first-child {
  /* CSS properties */
  color: #5a6876;
}

ul.recharge-steps {
  list-style-type: none;
}
ul.recharge-steps li {
  margin-bottom: 10px;
}
ul.recharge-steps li:before {
  content: "- " !important;
}

ul.recharge-steps li.done:before {
  color: green;
  font-weight: bold;
  content: "✓ " !important;
}

.recharge a label {
  color: black !important;
}
.summary {
  font-size: 1rem;
  margin-bottom: 10px;
}

.blog-section {
  margin-top: 10px;
  margin-bottom: 40px;
  overflow: hidden;
  padding: 0 15px; /* Add padding to prevent horizontal overflow */
}

.sub-title {
  text-align: center;
}

/* Blog style */

.blog-section {
  margin-top: 10px;
  margin-bottom: 40px;
  overflow: hidden;
  padding: 0 15px; /* Add padding to prevent horizontal overflow */
}

.sub-title {
  text-align: center;
}

.item {
  padding: 10px;
}

.card-image {
  width: 100%;
  height: 230px;
  display: block;
  margin: 0 auto;
}

/* Custom styles for the dots */
.slick-dots {
  bottom: -30px; /* Adjust the position of the dots */
}

.slick-dots li button:before {
  font-size: 12px; /* Adjust the size of the dots */
  color: #000; /* Change the color of the dots */
}

.slick-dots li.slick-active button:before {
  color: #000; /* Change the color of the active dot */
}

.blog-title a {
  color: var(--main-color);
  font-size: 1.3rem;
}
.about-title a {
  color: var(--main-color);
}

.slide-title {
  font-size: 1.3rem;
}
.hover:hover {
  color: var(--hover-color);
}

.slick-prev,
.slick-next {
  color: green; /* Change arrow color here */
  font-size: 24px; /* Optional: change size */
}

.slick-prev:before,
.slick-next:before {
  color: green; /* Change arrow color here */
}

:root {
  --main-color: #2c6d64;
  --hover-color: green;
}

.blog-card {
  height: 400px; /* Adjust height to accommodate image and text */
  overflow: hidden; /* Ensure content doesn't overflow */
}

.pointer {
  cursor: pointer;
}
