.company-background {
  background-color: #e3f0ea !important;
}

/* 
Slide show 
 */

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.fc-logo {
  width: 170px !important;
}
.navbar-nav a {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #212529 !important;
  font-family: sspRegular !important;
}
/* End slide show */

.sub-title {
  color: #000;
  font-family: sspBold;
  font-size: 2em;
  line-height: 35px;
  margin-bottom: 25px;
  text-align: center;
}

.custome-footer {
  color: white !important;
  background-color: #1d1e25 !important;
}
.footer-section .upper-section li a,
.footer-section .upper-section li span,
.footer-section h3 {
  color: white !important;
}

.footer-section .upper-section li a:hover {
  color: green !important;
}

.footer-list a:hover,
.navbar-nav a:hover {
  color: green !important;
}

.dropdown-menu {
  margin-top: -10px !important;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 12px !important;
  border: 0 !important;
}

.recharge {
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 135px;
  padding: 30px 10px;
  text-align: center;
}
.recharge label {
  font-size: 1.143em;
  line-height: 22px;
}
@media only screen and (max-width: 600px) {
  .dropdown-menu {
    margin-top: -10px !important;
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 12px !important;
  }
  .recharge {
    margin-bottom: 20px;
  }
}

.login-btn {
  color: green !important;
}
