.button {
  background-color: #13bfa6;
  color: white;
  padding: 15px 40px;
  border-radius: 5px;
  border-color: transparent;
}

.button:hover {
  cursor: pointer;
  background-color: #21cbb1;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 4px 10px 0px;
}
