.close-button {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

#bottomToastPortal,
#topToastPortal {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 95%;
  z-index: 10;
}

#bottomToastPortal {
  bottom: 10px;
}

@media (min-width: 768px) {
  #bottomToastPortal,
  #topToastPortal {
    width: 99%;
  }
}

.seo-container {
  line-height: 1.5;
  margin: 30px 20px 5px;
}

.seo-content p {
  color: #7a7a7a;
  font-size: 0.857em;
  margin-bottom: 10px;
}

.seo-content p span {
  background-color: transparent;
}

.seo-content h1,
.seo-content h2,
.seo-content h3,
.seo-content h4 {
  color: #5c5c5c;
  font-family: sspBold;
  font-size: 1em;
  font-weight: 400;
}

.seo-content h1 {
  margin-bottom: 13px;
}

.seo-content h2,
.seo-content h3,
.seo-content h4 {
  margin: 23px 0 13px;
}

.seo-content a {
  color: #464646;
}

.seo-content ul {
  color: #7a7a7a;
  font-size: 0.857em;
}

.about-section {
  color: #888;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 22px;
}

.about-section h2,
.about-section h3,
.about-section h4 {
  color: #444;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 1.5;
  text-transform: capitalize;
}

.about-section h2 ~ p,
.about-section h3 ~ p,
.about-section h4 ~ p {
  margin-top: 5px;
  white-space: normal;
}

.about-section h1 {
  color: #444;
  font-family: sspBold;
  font-size: 1.143em;
  font-weight: 400;
  line-height: 1.8;
  text-transform: capitalize;
}

.about-section h1 ~ p {
  margin-top: 5px;
  white-space: normal;
}

.about-section h1 ~ ul {
  list-style-type: disc;
  padding: 3px 0 0 18px;
}

.about-section h1 ~ ul li {
  white-space: normal;
}

.about-section h1 ~ ol {
  list-style-type: decimal;
  padding: 3px 0 0 18px;
}

.about-section h1 ~ ol li {
  white-space: normal;
}

.about-section li,
.about-section p {
  color: #888;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 22px;
}

.about-section li strong,
.about-section p strong {
  color: #444;
  display: inline-block;
  font-family: sspBold;
  margin-top: 15px;
}

.about-section a {
  color: #464646;
}

.about-section div[itemtype="http://schema.org/FAQPage"]
{
  margin: 10px 0;
}

.about-section div[itemtype="http://schema.org/Question"]
{
  margin: 20px 0;
}

.about-section .faq-question {
  margin-bottom: 10px;
}

.about-section h1:not(:first-child),
.about-section h2:not(:first-child),
.about-section h3:not(:first-child),
.about-section h4:not(:first-child) {
  margin-top: 30px;
}

.about-section p:not(:first-child) {
  margin-top: 5px;
}

.about-section .utility-table {
  border: 1px solid #b0b0b0;
  margin: 23px 0 10px;
}

.about-section .utility-table tr {
  border: 1px solid #b0b0b0;
  padding: 10px;
}

.about-section .utility-table tr:first-child {
  background-color: #f5f5f5;
  border: none;
}

.about-section .utility-table tr:first-child td {
  border: none;
  font-family: sspLight;
}

.about-section .utility-table td {
  border: 1px solid #b0b0b0;
  padding: 10px;
}

@media (max-width: 767px) {
  .about-section {
    background-color: #fff;
    border-radius: 20px;
    margin: 10px 0;
    padding: 30px 20px;
    position: relative;
  }

  .about-section .content-section {
    overflow: hidden;
    transition: max-height 0.25s ease-in-out 0s;
  }

  .about-section .read-more-overlay {
    bottom: 48px;
    height: 3rem;
    left: 20px;
    position: absolute;
    right: 20px;
    background: hsla(0, 0%, 100%, 0);
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0) 0,
      hsla(0, 0%, 100%, 0) 30%,
      #fff 80%,
      #fff
    );
  }

  .about-section .about-section-read-more-toggle {
    color: #666;
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .about-section {
    margin: 35px 0 15px;
    padding: 25px;
  }
}

@media (min-width: 1025px) {
  .about-section {
    padding: 0;
  }
}

.error-boundary-wrapper {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.error-boundary-wrapper img {
  margin-bottom: 48px;
  width: 260px;
}

.error-boundary-wrapper h1 {
  font-family: sspRegular;
  font-size: 1.429em;
  line-height: 26px;
  margin-bottom: 15px;
}

.error-boundary-wrapper .text-one {
  color: #666;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 16px;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.error-boundary-wrapper .text-two {
  color: #969696;
  font-family: sspBold;
  font-size: 1.286em;
  line-height: 22px;
  margin-bottom: 32px;
}

.error-boundary-wrapper button {
  border: none;
  color: #f74;
  font-family: sspBold;
  font-size: 1.286em;
  line-height: 18px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .error-boundary-wrapper img {
    margin-bottom: 43px;
    width: 312px;
  }

  .error-boundary-wrapper h1 {
    font-size: 2.29em;
    line-height: 40px;
  }

  .error-boundary-wrapper .text-one {
    font-size: 1.286em;
    line-height: 26px;
    margin-bottom: 15px;
  }

  .error-boundary-wrapper .text-two {
    font-size: 1.714em;
    line-height: 30px;
    margin-bottom: 40px;
  }
}

.loader {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
}

.loader .loader-gif {
  position: relative;
}

.loader img {
  width: 75px;
}

.loader .loader-text {
  bottom: -30px;
  color: #fcfcff;
  font-family: sspBold;
  font-size: 14px;
  left: calc(50% - 100px);
  line-height: 16px;
  position: absolute;
  text-align: center;
  width: 200px;
}

.pop-up-wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  bottom: -120%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 4;
}

.pop-up-wrapper.show-pop-up {
  bottom: 0;
  top: 0;
}

.pop-up-wrapper.show-pop-up .pop-up.show-arrow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.pop-up-wrapper.secondary-layout {
  background-color: rgba(34, 34, 34, 0.85);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.pop-up-wrapper.secondary-layout .pop-up {
  border-radius: 10px;
  margin: 5px;
  padding: 20px;
}

.pop-up-wrapper.secondary-layout .pop-up .close-pop-up-icon {
  right: 15px;
  top: 15px;
  width: 24px;
}

.pop-up-wrapper.top-rounded-layout .pop-up {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.pop-up-wrapper.top-rounded-layout .pop-up .close-pop-up-icon {
  width: 24px;
}

.pop-up-wrapper .pop-up {
  background: #fff;
  max-height: 500px;
  overflow: auto;
  position: relative;
  scrollbar-width: none;
  transition: transform 0.2s ease-out 0s;
}

.pop-up-wrapper .pop-up::-webkit-scrollbar {
  display: none;
}

.pop-up-wrapper .pop-up .down-icon-div {
  cursor: pointer;
  left: 50%;
  position: absolute;
  top: 8px;
  width: 20px;
  transform: translateX(-50%);
}

.pop-up-wrapper .pop-up .down-icon-div img {
  width: 100%;
}

.pop-up-wrapper .pop-up .close-pop-up-icon {
  width: 10px;
}

.pop-up-wrapper .pop-up .close-popup-icon-wrapper {
  cursor: pointer;
  padding: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}

@media (max-width: 767px) {
  .pop-up-wrapper.show-pop-up {
    margin-bottom: -15vh;
    padding-bottom: 15vh;
  }

  .pop-up-wrapper.show-pop-up.swipe-bottom .pop-up {
    transform: translateY(0);
  }

  .pop-up-wrapper.show-pop-up.swipe-right .pop-up {
    transform: translateX(0);
  }

  .pop-up-wrapper.swipe-bottom .pop-up {
    transform: translateY(100%);
  }

  .pop-up-wrapper.swipe-right .pop-up {
    transform: translateX(100%);
  }
}

@media (min-width: 768px) {
  .pop-up-wrapper {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: 0;
    top: 0;
    z-index: -1;
    transition: opacity 0.25s ease-in-out 0s;
  }

  .pop-up-wrapper.show-pop-up {
    opacity: 1;
    z-index: 4;
  }

  .pop-up-wrapper.show-pop-up .pop-up {
    border-radius: 20px;
    width: 500px;
  }

  .pop-up-wrapper.top-rounded-layout .pop-up {
    border-radius: 20px;
  }
}

#calendarPortal .calendar-container {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 12px #efecec;
  font-size: 1.143em;
  height: 336px;
  padding: 16px;
  position: absolute;
  width: 307px;
  z-index: 5;
}

#calendarPortal .calendar-container .calendar-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

#calendarPortal .calendar-container .calendar-header .calendar-month {
  color: #e3714d;
  cursor: pointer;
  font-size: 1.571em;
  font-weight: 500;
  padding: 0.5em 0.25em;
  text-align: center;
  word-spacing: 5px;
}

#calendarPortal .calendar-container .arrow {
  border: solid #e3714d;
  border-width: 0 3px 3px 0;
  cursor: pointer;
  display: inline-block;
  padding: 3px;
  position: relative;
}

#calendarPortal .calendar-container .arrow.left {
  transform: rotate(135deg);
  left: -40px;
}

#calendarPortal .calendar-container .arrow.right {
  transform: rotate(-45deg);
  left: -20px;
}

#calendarPortal .calendar-container .arrow.disabled {
  border: solid #e4e4e4;
  border-width: 0 3px 3px 0;
  cursor: auto;
}

#calendarPortal .calendar-container .calendar-grid {
  display: grid;
  transform: translateZ(0);
  transition: transform 0.2s ease-out 0s;
}

#calendarPortal .calendar-container .calendar-grid.date {
  grid-template: repeat(7, auto) / repeat(7, auto);
}

#calendarPortal .calendar-container .calendar-grid.year-month {
  grid-template-columns: auto auto auto;
  row-gap: 10px;
}

#calendarPortal .calendar-container .calendar-grid .calendar-cell {
  align-self: center;
  letter-spacing: 0.1rem;
  padding: 0.6em 0.25em;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#calendarPortal .calendar-container .calendar-grid .calendar-cell.date {
  align-items: center;
  display: flex;
  height: 38px;
  justify-content: center;
  width: 40px;
}

#calendarPortal .calendar-container .calendar-grid .calendar-cell.weekday {
  font-size: 0.714em;
  font-weight: 600;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date {
  color: #666;
  cursor: pointer;
  font-size: 1em;
  font-weight: 300;
  transition: all 0.1s ease-out 0s;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date
  :hover {
  background: #e1ebff;
  border-radius: 50px;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.in-month {
  color: #222;
  font-weight: 500;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.highlighted {
  background: rgba(227, 113, 78, 0.1);
  border-radius: 10px;
  color: #e3714d;
  font-weight: 600;
  position: relative;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.today {
  background: transparent;
  color: #e3714d;
}

#calendarPortal
  .calendar-container
  .calendar-grid
  .calendar-cell.calendar-date.today
  :hover {
  background: #e1ebff;
  color: #e3714d;
}

#calendarPortal,
.date-picker {
  margin: 5px 0;
  position: relative;
}

#calendarPortal .date-picker-container,
.date-picker .date-picker-container {
  position: relative;
}

#calendarPortal .date-picker-container input,
.date-picker .date-picker-container input {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 0;
  color: #222;
  font-size: 0.857em;
}

#calendarPortal .date-picker-container input.layout-1,
.date-picker .date-picker-container input.layout-1 {
  border: 1px solid #e4e4e4;
}

#calendarPortal .date-picker-container input.layout-2,
.date-picker .date-picker-container input.layout-2 {
  border-bottom: 1px solid #e4e4e4;
}

#calendarPortal .date-picker-container input.error,
.date-picker .date-picker-container input.error {
  border-color: red;
}

#calendarPortal .date-picker-container input:disabled,
.date-picker .date-picker-container input:disabled {
  background-color: transparent;
  color: #222;
  opacity: 1;
  -webkit-text-fill-color: #222;
}

#calendarPortal
  .date-picker-container
  input:disabled.layout-3::-webkit-input-placeholder,
#calendarPortal .date-picker-container input:disabled.layout-3::placeholder,
#calendarPortal
  .date-picker-container
  input:disabled.layout-4::-webkit-input-placeholder,
#calendarPortal .date-picker-container input:disabled.layout-4::placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-3::-webkit-input-placeholder,
.date-picker .date-picker-container input:disabled.layout-3::placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-4::-webkit-input-placeholder,
.date-picker .date-picker-container input:disabled.layout-4::placeholder {
  color: #666;
  font-family: sspRegular;
  -webkit-text-fill-color: #666;
}

#calendarPortal
  .date-picker-container
  input:disabled.layout-3:-ms-input-placeholder,
#calendarPortal
  .date-picker-container
  input:disabled.layout-4:-ms-input-placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-3:-ms-input-placeholder,
.date-picker
  .date-picker-container
  input:disabled.layout-4:-ms-input-placeholder {
  color: #666;
  font-family: sspRegular;
}

#calendarPortal .date-picker-container input.layout-3,
.date-picker .date-picker-container input.layout-3 {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  font-family: sspBold;
  line-height: 14px;
}

#calendarPortal .date-picker-container input.layout-3:focus,
.date-picker .date-picker-container input.layout-3:focus {
  background-color: #fff;
  border-color: #ec7f51;
  box-shadow: 0 1px 6px #efd1c5;
  outline: none;
}

#calendarPortal .date-picker-container input.layout-4,
.date-picker .date-picker-container input.layout-4 {
  background-color: #f9f9fc;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  color: #222;
  font-family: sspBold;
  font-size: 1.143em;
}

#calendarPortal .date-picker-container input.layout-4:focus,
.date-picker .date-picker-container input.layout-4:focus {
  background-color: #fff;
  border-color: #ec7f51;
  box-shadow: 0 1px 6px #f7decf;
  outline: none;
}

#calendarPortal .date-picker-container input.layout-3:disabled,
.date-picker .date-picker-container input.layout-3:disabled {
  background-color: #f9f9fc;
}

#calendarPortal .date-picker-container label,
.date-picker .date-picker-container label {
  bottom: 5px;
  color: #aaa;
  font-size: 0.857em;
  position: absolute;
}

#calendarPortal .date-picker-container label.layout-1,
.date-picker .date-picker-container label.layout-1 {
  padding-bottom: 14px;
}

#calendarPortal .date-picker-container label.layout-1.animate,
.date-picker .date-picker-container label.layout-1.animate {
  transform: translateY(-39px);
}

#calendarPortal .date-picker-container label.layout-2.animate,
.date-picker .date-picker-container label.layout-2.animate {
  transform: translateY(-29px);
}

#calendarPortal .date-picker-container label.layout-4,
.date-picker .date-picker-container label.layout-4 {
  color: #222;
  display: block;
  font-family: sspBold;
  font-size: 1em;
  margin: 0 0 5px;
  position: static;
}

#calendarPortal .date-picker-container label.animate:not(.layout-4),
.date-picker .date-picker-container label.animate:not(.layout-4) {
  background-color: #fff;
  bottom: 0;
  font-size: 0.714em;
  padding: 0 1px;
}

#calendarPortal .date-picker-icon,
.date-picker .date-picker-icon {
  height: 16px;
  position: absolute;
  right: 14px;
  top: 37px;
  width: 16px;
}

#calendarPortal .date-picker-overlay,
.date-picker .date-picker-overlay {
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

#calendarPortal .error-message,
.date-picker .error-message {
  color: red;
  display: block;
  font-size: 0.857em;
  line-height: 16px;
  margin: 8px 0;
  text-transform: capitalize;
}

#calendarPortal .popup-heading,
.date-picker .popup-heading {
  color: #222;
  font-family: sspBold;
  font-size: 1em;
  margin: 5px;
}

#calendarPortal .datepicker-btn,
.date-picker .datepicker-btn {
  font-family: sspBold;
  font-size: 1em;
  text-transform: uppercase;
}

#calendarPortal .date-picker-popup,
.date-picker .date-picker-popup {
  z-index: 6;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#calendarPortal .date-picker-popup .close-pop-up-icon,
.date-picker .date-picker-popup .close-pop-up-icon {
  cursor: pointer;
  right: 20px !important;
  top: 20px !important;
}

#calendarPortal .layout-3 .popup-heading,
.date-picker .layout-3 .popup-heading {
  font-size: 1.143em;
}

#calendarPortal .layout-3 .datepicker-btn,
.date-picker .layout-3 .datepicker-btn {
  height: 54px;
}

#calendarPortal .layout-3 .list-container,
.date-picker .layout-3 .list-container {
  margin: 46px 0;
}

#calendarPortal .layout-3 .list-container li.active,
.date-picker .layout-3 .list-container li.active {
  font-size: 1.143em;
}

#calendarPortal .layout-3 .list-container li.active span,
.date-picker .layout-3 .list-container li.active span {
  text-align: center;
}

#calendarPortal .layout-3 .list-container li.active span:before,
.date-picker .layout-3 .list-container li.active span:before {
  border: 1px solid #f74;
  content: "";
  left: -1px;
  position: absolute;
  top: -18px;
  width: 100%;
}

#calendarPortal .layout-3 .list-container li.active span:after,
.date-picker .layout-3 .list-container li.active span:after {
  bottom: -18px;
}

#calendarPortal .layout-3 .list-container li.active .date-text,
.date-picker .layout-3 .list-container li.active .date-text {
  width: 40px;
}

#calendarPortal .layout-3 .list-container li.active .month-text,
.date-picker .layout-3 .list-container li.active .month-text {
  width: 100px;
}

#calendarPortal .layout-3 .list-container li.active .year-text,
.date-picker .layout-3 .list-container li.active .year-text {
  width: 64px;
}

#calendarPortal .list-container,
.date-picker .list-container {
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
}

#calendarPortal .list-container ul,
.date-picker .list-container ul {
  cursor: ns-resize;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 30%;
}

#calendarPortal .list-container ul li,
.date-picker .list-container ul li {
  align-items: center;
  color: #aeaeae;
  display: flex;
  font-family: sspRegular;
  font-size: 1em;
  justify-content: center;
  line-height: 14px;
}

#calendarPortal .list-container ul li::-moz-selection,
.date-picker .list-container ul li::-moz-selection {
  background-color: transparent;
}

#calendarPortal .list-container ul li::selection,
.date-picker .list-container ul li::selection {
  background-color: transparent;
}

#calendarPortal .list-container ul li.active,
.date-picker .list-container ul li.active {
  color: #222;
  font-family: sspBold;
}

#calendarPortal .list-container ul li.active span,
.date-picker .list-container ul li.active span {
  position: relative;
}

#calendarPortal .list-container ul li.active span:after,
.date-picker .list-container ul li.active span:after {
  border: 1px solid #f74;
  bottom: -10px;
  content: "";
  left: -1px;
  position: absolute;
  width: 100%;
}

#calendarPortal .dual-btns,
.date-picker .dual-btns {
  border-top: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

#calendarPortal .dual-btns p,
.date-picker .dual-btns p {
  color: #e3714d;
  cursor: pointer;
  font-family: sspBold;
  font-size: 1.143em;
  padding: 0 10px;
  text-transform: uppercase;
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip,
.date-picker .tooltip-date-picker .__react_component_tooltip {
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  position: absolute;
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.16);
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip.show,
.date-picker .tooltip-date-picker .__react_component_tooltip.show {
  min-width: 340px;
  opacity: 1;
  padding: 20px;
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip.show:after,
#calendarPortal .tooltip-date-picker .__react_component_tooltip.show:before,
.date-picker .tooltip-date-picker .__react_component_tooltip.show:after,
.date-picker .tooltip-date-picker .__react_component_tooltip.show:before {
  content: none;
}

#calendarPortal .tooltip-date-picker .__react_component_tooltip .list-container,
.date-picker .tooltip-date-picker .__react_component_tooltip .list-container {
  margin: 0 0 25px;
}

#calendarPortal .tooltip-date-picker.hide-dp .__react_component_tooltip,
.date-picker .tooltip-date-picker.hide-dp .__react_component_tooltip {
  opacity: 0;
  visibility: hidden;
}

#calendarPortal .info-element,
.date-picker .info-element {
  font-size: 12px;
  margin: 4px 0 0;
}

#calendarPortal {
  margin: 0;
}

@media (max-width: 767px) {
  #calendarPortal .layout-3 .pop-up,
  .date-picker .layout-3 .pop-up {
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
  }
}

@media (min-width: 768px) {
  #calendarPortal .layout-3 .list-container,
  .date-picker .layout-3 .list-container {
    margin: 0 0 20px;
  }
}

@media (max-width: 1024px) {
  #calendarPortal .list-container ul,
  .date-picker .list-container ul {
    scrollbar-width: none;
  }

  #calendarPortal .list-container ul::-webkit-scrollbar,
  .date-picker .list-container ul::-webkit-scrollbar {
    display: none;
  }
}

.tnc-popup {
  padding: 25px 20px 20px;
}

.tnc-popup .popup-heading {
  color: #222;
  font-family: sspBold;
  font-size: 1.286em;
  margin-bottom: 10px;
}

.tnc-popup .popup-content {
  color: #666;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 20px;
}

.tnc-popup .popup-content b {
  color: #e3714d;
  cursor: pointer;
  font-family: sspBold;
}

.tnc-popup .popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.tnc-popup .popup-actions button {
  border-radius: 10px;
  font-family: sspBold;
  font-size: 1.143em;
  height: 54px;
  width: 48%;
}

.tnc-popup .popup-actions button.accept-btn {
  background: #f74;
}

.kyc-popup-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px;
}

.kyc-popup-container .kyc-popup-heading {
  color: #5c5c5c;
  font-family: sspBold;
  font-size: 1.143em;
  margin-bottom: 30px;
  position: relative;
}

.kyc-popup-container .kyc-popup-heading:after {
  border: 2px solid #e3714d;
  border-radius: 10px;
  bottom: -15px;
  content: "";
  left: calc(50% - 15px);
  position: absolute;
  width: 30px;
}

.kyc-popup-container .kyc-popup-text {
  color: #5c5c5c;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.kyc-popup-container .upgrade-btn {
  margin-bottom: 10px;
}

.kyc-popup-container .remind-later-btn {
  border: none;
  color: #aeaeae;
  font-family: sspBold;
  font-size: 1em;
  height: 42px;
  text-align: center;
  width: auto;
}

.progress {
  height: 2px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.progress .indicator {
  background-color: #ff7845;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.1s linear, opacity 0.3s linear 0.2s;
  width: 0;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  margin: 0;
  padding: 0;
}

@font-face {
  font-display: swap;
  font-family: sspBoldItalic;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-SemiBoldItalic.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBlack;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Black.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBlackIt;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-BlackIt.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBolder;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Bold.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspExtraLight;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-ExtraLight.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspLight;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Light.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspBold;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Semibold.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: sspRegular;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/sourcesanspro/SourceSansPro-Regular.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: lobsterRegular;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Regular.svg)
      format("svg");
}

@font-face {
  font-display: swap;
  font-family: lobsterBold;
  font-style: normal;
  font-weight: 400;
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.eot);
  src: url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.eot?#iefix)
      format("embedded-opentype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.woff2)
      format("woff2"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.woff)
      format("woff"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.ttf)
      format("truetype"),
    url(https://fc-cdn.freecharge.in/pwa/fonts/lobstertwo/LobsterTwo-Bold.svg)
      format("svg");
}

* {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background-color: #f2f5fc;
  font-family: sspRegular;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hide {
  display: none;
}

.show {
  display: block;
}

a,
button,
input,
select,
textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input,
select,
textarea {
  font-family: sspRegular;
}

button:focus,
input:focus {
  outline: none;
}

button:focus-visible,
input:focus-visible {
  outline: none;
}

button:focus-within,
input:focus-within {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
  background-color: #fff !important;
  box-shadow: inset 0 0 0 9999px #fff;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #aeaeae;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #aeaeae;
}

input::placeholder,
textarea::placeholder {
  color: #aeaeae;
}

a,
a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
}

img:-moz-loading {
  visibility: hidden;
}

.container,
.main-wrapper {
  width: 100%;
}

.storybook {
  background-color: #7a7a7a;
  max-width: 300px;
}

@media (min-width: 1025px) {
  .container {
    margin: 0 auto;
    max-width: 768px;
  }

  .content-wrapper,
  .main-wrapper {
    margin: 0 auto;
    max-width: 1366px;
  }
}

.footer-section {
  background: #f6f8f9;
  background: linear-gradient(180deg, #f6f8f9, #f4f6f7 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
}

.footer-section h3 {
  color: #222;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 22px;
  margin-bottom: 5px;
}

.footer-section .upper-section {
  padding: 25px 25px 10px;
}

.footer-section .upper-section li.single-list .footer-tooltip .tooltip {
  min-width: 175px;
}

.footer-section .upper-section li a,
.footer-section .upper-section li span {
  color: #5a6876;
  cursor: pointer;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 30px;
}

.footer-section .upper-section li a:hover,
.footer-section .upper-section li span:hover {
  color: #e3714d;
}

.footer-section .upper-section .footer-list-container {
  display: flex;
  margin-bottom: 15px;
}

.footer-section .upper-section .footer-list-container .footer-list {
  flex: 0 0 100%;
}

.footer-section
  .upper-section
  .footer-list-container
  .footer-list:last-child
  > .links-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(max-content, 1fr));
}

.footer-section
  .upper-section
  .footer-list-container
  .footer-list:last-child
  > .links-list
  h3 {
  grid-column-end: 3;
  grid-column-start: 1;
}

.footer-section .upper-section .footer-list-container .footer-tooltip .tooltip {
  display: flex;
  min-width: 350px;
}

.footer-section
  .upper-section
  .footer-list-container
  .footer-tooltip
  .tooltip
  .links-list {
  flex: 1 1;
}

.footer-section .upper-section .social-links .app-links,
.footer-section .upper-section .social-links .social-medias-links {
  margin-bottom: 15px;
}

.footer-section .upper-section .social-links .social-medias-links .images-list {
  margin-top: 10px;
}

.footer-section
  .upper-section
  .social-links
  .social-medias-links
  .images-list
  a {
  display: inline-block;
  margin-right: 10px;
}

.footer-section
  .upper-section
  .social-links
  .social-medias-links
  .images-list
  a:last-child {
  margin-right: 0;
}

.footer-section
  .upper-section
  .social-links
  .social-medias-links
  .images-list
  a
  img {
  border-radius: 5px;
  cursor: pointer;
  width: 40px;
}

.footer-section .upper-section .social-links .app-links .images-list a {
  display: inline-block;
}

.footer-section .upper-section .social-links .app-links .images-list a img {
  cursor: pointer;
}

.footer-section
  .upper-section
  .social-links
  .app-links
  .images-list
  .google-play {
  margin-right: 15px;
}

.footer-section .upper-section .social-links .certified-image {
  border-top: 1px solid hsla(0, 0%, 59%, 0.16);
  padding-top: 15px;
  width: 100%;
}

.footer-section .lower-section .text-content {
  color: #888;
  font-family: sspRegular;
  font-size: 0.714em;
  line-height: 20px;
  margin: auto;
  padding: 0 25px 10px;
}

.footer-section .lower-section .text-content img {
  margin-left: 3px;
  vertical-align: middle;
  width: 60px;
}

.footer-section .footer-error-view {
  padding-bottom: 0;
}

.footer-section .footer-error-view button {
  margin-top: 34px;
  width: 100%;
}

@media (max-width: 767px) {
  .footer-section .lower-section .text-content {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .footer-section .upper-section .footer-list-container .footer-list {
    flex: 0 0 25%;
  }

  .footer-section
    .upper-section
    .footer-list-container
    .footer-list:last-child {
    flex: auto;
  }

  .footer-section
    .upper-section
    .social-links
    .social-medias-links
    .images-list
    a {
    margin-right: 9px;
  }

  .footer-section
    .upper-section
    .social-links
    .social-medias-links
    .images-list
    a
    img {
    width: 33px;
  }
}

@media (min-width: 768px) {
  .footer-section .upper-section .social-links {
    align-items: center;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 28% auto 38%;
    justify-content: space-between;
    margin-top: 30px;
  }

  .footer-section .upper-section .social-links .app-links,
  .footer-section .upper-section .social-links .social-medias-links {
    margin-bottom: 0;
  }

  .footer-section .upper-section .social-links .app-links {
    order: 1;
  }

  .footer-section .upper-section .social-links .app-links .images-list {
    align-items: stretch;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }

  .footer-section
    .upper-section
    .social-links
    .app-links
    .images-list
    .app-store,
  .footer-section
    .upper-section
    .social-links
    .app-links
    .images-list
    .google-play {
    width: 100%;
  }

  .footer-section .upper-section .social-links .social-medias-links {
    order: 2;
  }

  .footer-section
    .upper-section
    .social-links
    .social-medias-links
    .images-list {
    margin-top: 0;
  }

  .footer-section .upper-section .social-links .certified-image {
    border: none;
    order: 3;
    padding-top: 0;
  }
}

@media (min-width: 1025px) {
  .footer-section .footer-error-view {
    padding-bottom: 80px;
  }

  .footer-section .footer-error-view button {
    width: 330px;
  }

  .footer-section hr {
    background: hsla(0, 0%, 40%, 0.3);
    border: none;
    height: 1px;
    margin: 38px 0 0;
  }

  .footer-section .upper-section {
    background-color: #fafafa;
    padding: 25px 14.5px;
  }

  .footer-section .upper-section .footer-list-container .download-and-follow {
    margin-left: 88px;
  }

  .footer-section
    .upper-section
    .footer-list-container
    .download-and-follow
    .download-app-content {
    display: flex;
    margin-top: 22px;
  }

  .footer-section
    .upper-section
    .footer-list-container
    .download-and-follow
    .download-app-content
    .app-qr-code {
    background: linear-gradient(#fafafa, #fafafa) padding-box,
      linear-gradient(180deg, #ffd6c7 41.41%, rgba(255, 214, 199, 0)) border-box;
    border: 6px solid transparent;
    border-bottom: none;
    border-radius: 20px 20px 0 0;
    padding: 18px 18px 0;
    text-align: center;
  }

  .footer-section
    .upper-section
    .footer-list-container
    .download-and-follow
    .download-app-content
    .app-qr-code
    img {
    height: 105px;
    width: 105px;
  }

  .footer-section
    .upper-section
    .footer-list-container
    .download-and-follow
    .download-app-content
    .app-qr-code
    .scan-text {
    font-size: 0.857em;
    line-height: 22px;
    margin-top: 6px;
  }

  .footer-section
    .upper-section
    .footer-list-container
    .download-and-follow
    .download-app-content
    .app-links {
    margin-left: 15px;
    margin-top: 26px;
  }

  .footer-section
    .upper-section
    .footer-list-container
    .download-and-follow
    .download-app-content
    .app-links
    img {
    cursor: pointer;
    margin-bottom: 15px;
  }

  .footer-section
    .upper-section
    .footer-list-container
    .download-and-follow
    .social-medias-links {
    margin-top: 42px;
  }

  .footer-section
    .upper-section
    .footer-list-container
    .download-and-follow
    .social-medias-links
    .images-list {
    margin-top: 19px;
  }

  .footer-section
    .upper-section
    .footer-list-container
    .download-and-follow
    .social-medias-links
    .images-list
    a:not(:last-child) {
    margin-right: 10px;
  }

  .footer-section
    .upper-section
    .footer-list-container
    .download-and-follow
    .social-medias-links
    .images-list
    a {
    cursor: pointer;
  }

  .footer-section .upper-section .footer-list-container .footer-list {
    flex: 0 0 22%;
  }

  .footer-section
    .upper-section
    .footer-list-container
    .footer-list:nth-last-child(2) {
    flex: auto;
  }

  .footer-section
    .upper-section
    .footer-list-container
    .footer-list:nth-last-child(2)
    > .links-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(max-content, 1fr));
  }

  .footer-section
    .upper-section
    .footer-list-container
    .footer-list:nth-last-child(2)
    > .links-list
    h3 {
    grid-column-end: 3;
    grid-column-start: 1;
  }

  .footer-section .upper-section .footer-list-container .footer-list h3 {
    margin-bottom: 10px;
  }

  .footer-section .seo-links-section {
    background-color: #fafafa;
    padding: 20px 14.5px 0;
  }

  .footer-section .seo-links-section hr {
    margin-top: 43px;
  }

  .footer-section .seo-links-section .seo-links h3 {
    margin-bottom: 15px;
  }

  .footer-section .seo-links-section .seo-links .link-group {
    margin-bottom: 12px;
  }

  .footer-section .seo-links-section .seo-links .link-group a {
    color: #666;
    text-transform: uppercase;
  }

  .footer-section .seo-links-section .seo-links .link-group p {
    display: inline-block;
    font-family: sspBold;
    margin-right: 8px;
    text-transform: capitalize;
  }

  .footer-section .seo-links-section .seo-links .link-group a,
  .footer-section .seo-links-section .seo-links .link-group p {
    cursor: pointer;
    font-size: 1em;
    line-height: 22px;
  }

  .footer-section .seo-links-section .seo-links .link-group a:hover,
  .footer-section .seo-links-section .seo-links .link-group p:hover {
    color: #e3714d;
  }

  .footer-section .seo-links-section .seo-links .link-group a:after,
  .footer-section .seo-links-section .seo-links .link-group p:after {
    color: #666;
  }

  .footer-section
    .seo-links-section
    .seo-links
    .link-group
    a:not(:last-child):after {
    content: "|";
    margin: 0 8px;
  }

  .footer-section .lower-section {
    background-color: #fafafa;
    padding: 0 14.5px;
  }

  .footer-section .lower-section .lower-section-content {
    display: flex;
    justify-content: space-between;
  }

  .footer-section .lower-section .text-content {
    color: #444;
    font-family: sspRegular;
    font-size: 1em;
    margin: 0 10px 0 0;
    padding: 20px 0;
  }

  .footer-section .footer-content,
  .footer-section .lower-section-content,
  .footer-section .seo-links {
    margin: 0 auto;
    max-width: 1320px;
  }
}

@media (min-width: 1300px) {
  .footer-section .footer-list-container .download-and-follow {
    width: 24%;
  }

  .footer-section
    .footer-list-container
    .footer-list:nth-last-child(2)
    > .links-list {
    grid-column-gap: 62px;
  }
}

.tooltip-wrapper {
  display: inline-block;
  position: relative;
}

.tooltip-arrow-top {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
  left: calc(50% - 8px);
}

.tooltip-arrow-left,
.tooltip-arrow-right {
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  top: calc(50% - 8px);
}

.tooltip-arrow-right {
  border-right: 8px solid #fff;
}

.tooltip-arrow-left {
  border-left: 8px solid #fff;
}

.tooltip-arrow-bottom {
  border-bottom: 8px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  left: calc(50% - 8px);
}

.tooltip-label {
  cursor: pointer;
  position: relative;
}

.tooltip-label.tooltip-show-arrow {
  padding-right: 15px;
}

.tooltip-label.tooltip-show-arrow:after {
  border-bottom: 2px solid #5a6876;
  border-right: 2px solid #5a6876;
  box-sizing: border-box;
  content: "";
  height: 6px;
  opacity: 0.7;
  position: absolute;
  right: 0;
  top: calc(50% - 3px);
  width: 6px;
  transform: rotate(-45deg);
}

.tooltip-content-wrapper {
  position: fixed;
  z-index: 2;
  transition: opacity 0.2s ease-in-out 0s;
}

.tooltip-content-wrapper.visible {
  opacity: 1;
  visibility: visible;
}

.tooltip-content-wrapper.hidden {
  opacity: 0;
  visibility: hidden;
}

.tooltip {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 3px;
  display: flex;
  font-size: 13px;
  padding: 10px 15px;
  box-shadow: 0 0 5px 0 #eaeaea;
}

.tooltip > span {
  height: 0;
  position: absolute;
  width: 0;
}

.main-header {
  align-items: center;
  background-color: #fff;
  display: flex;
  min-height: 62px;
  position: relative;
}

.main-header.revamped-header .hamburger-icon.show-menu span {
  background: #000;
  margin-top: 0;
}

.main-header.revamped-header .hamburger-icon.show-menu span:nth-child(2) {
  display: none;
}

.main-header.revamped-header .hamburger-icon span {
  background: #000;
  border-radius: 10px;
  margin-top: 3px;
  width: 15px;
}

.main-header.revamped-header .hamburger-icon span:first-child {
  margin: 0;
}

.main-header.revamped-header .hamburger-icon span:nth-child(2) {
  width: 11px;
}

.main-header.revamped-header .header-content .header-login button {
  background: #f16122;
  border-radius: 50px;
  font-family: sspBold;
  font-size: 0.857em;
  height: auto;
  line-height: 16px;
  padding: 8px 14px;
}

.main-header .hamburger-icon {
  left: 25px;
  position: absolute;
  top: 25px;
  z-index: 4;
}

.main-header .hamburger-icon span {
  background: #ccc;
  display: block;
  height: 2px;
  width: 18px;
  transition: all 0.5s ease-in-out 0s;
}

.main-header .hamburger-icon span:first-child {
  margin-bottom: 5px;
}

.main-header .hamburger-icon.show-menu {
  top: 30px;
}

.main-header .hamburger-icon.show-menu span {
  background: #aeaeae;
}

.main-header .hamburger-icon.show-menu span:first-child {
  margin-bottom: -1px;
  transform: rotate(135deg) translateX(1px);
}

.main-header .hamburger-icon.show-menu span:last-child {
  margin-bottom: 1px;
  transform: rotate(45deg);
}

.main-header .hamburger-icon.show-menu.move-top {
  top: -40px;
}

.main-header .header-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  padding: 2px 28px 2px 38px;
}

.main-header .header-content .header-logo {
  cursor: pointer;
}

.main-header .header-content .header-logo .fc-logo {
  width: 130px;
}

.main-header .header-content .header-profile {
  align-items: center;
  display: flex;
}

.main-header .header-content .header-profile .header-notification {
  margin-right: 20px;
  margin-top: 3px;
}

.main-header .header-content .header-profile .header-notification img {
  width: 15px;
}

.main-header .header-content .header-profile .profile-details .profile-detail {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.main-header .header-content .header-profile .profile-details .profile-avatar {
  width: 34px;
}

.main-header .header-content .header-profile .profile-details .profile-name {
  color: #222;
  font-family: sspRegular;
  font-size: 1em;
  margin-left: 10px;
  text-transform: capitalize;
}

.main-header .header-content .header-login {
  align-items: center;
  display: flex;
}

.main-header .header-content .header-login button {
  border-radius: 5px;
  font-family: sspRegular;
  font-size: 1em;
  padding: 7px 18px;
}

.main-header .header-content .header-login img {
  width: 38px;
}

.main-header .header-content .header-login .login-btn {
  color: #e3714d;
  cursor: pointer;
  font-family: sspRegular;
  font-size: 1em;
  margin-left: 10px;
  text-transform: capitalize;
}

.profile-dropdown {
  max-width: 350px;
  min-width: 330px;
  opacity: 0;
  padding-top: 15px;
  position: absolute;
  right: -10%;
  top: 100%;
  visibility: hidden;
  transition: all 0.2s ease-in-out 0s;
}

.profile-dropdown .content-box {
  background-color: #fff;
  border: 1px solid hsla(0, 1%, 62%, 0.19);
  border-radius: 12px;
  padding: 25px;
}

.profile-dropdown .user-details {
  align-items: flex-start;
  border-bottom: 1px solid #e7eefa;
  display: flex;
  padding-bottom: 22px;
}

.profile-dropdown .user-details img {
  width: 44px;
}

.profile-dropdown .user-details .upi-loader {
  margin-top: 10px;
  width: 18px;
}

.profile-dropdown .user-details .content-section {
  flex: auto;
  margin-left: 18px;
}

.profile-dropdown .user-details .content-section .user-name {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.profile-dropdown .user-details .content-section h3 {
  color: #444;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  width: 175px;
}

.profile-dropdown .user-details .content-section .upi-field,
.profile-dropdown .user-details .content-section p {
  color: #666;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 14px;
  margin-top: 5px;
}

.profile-dropdown .user-details .content-section .entity-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
}

.profile-dropdown .user-details .content-section .upi-field .copy-btn {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: auto;
}

.profile-dropdown .user-details .content-section .upi-field .copy-btn img {
  width: 11px;
}

.profile-dropdown
  .user-details
  .content-section
  .upi-field
  .copy-btn
  .copy-text {
  color: #ff7845;
  font-family: sspBold;
  line-height: 12px;
  margin-left: 5px;
}

.profile-dropdown .user-details .content-section .edit-btn {
  color: #f74;
  cursor: pointer;
  font-family: sspBold;
  font-size: 1em;
}

.profile-dropdown .user-details .content-section .email-field {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 215px;
}

.profile-dropdown .user-details .content-section .account-field-labels {
  display: flex;
}

.profile-dropdown
  .user-details
  .content-section
  .account-field-labels
  .account-label-texts {
  width: 35px;
}

.profile-dropdown .dropdown-list {
  padding: 10px 0;
}

.profile-dropdown .dropdown-list li {
  border-radius: 15px;
  cursor: pointer;
}

.profile-dropdown .dropdown-list li:first-child img {
  width: 18px;
}

.profile-dropdown .dropdown-list li:last-child {
  margin-bottom: 0;
}

.profile-dropdown .dropdown-list li.arrow {
  max-height: 50px;
  overflow: hidden;
  position: relative;
  transition: max-height 0.25s ease-in-out 0s;
}

.profile-dropdown .dropdown-list li.arrow > .list-item:after {
  border-bottom: 2px solid hsla(0, 0%, 40%, 0.5);
  border-right: 2px solid hsla(0, 0%, 40%, 0.5);
  content: "";
  height: 6px;
  position: absolute;
  right: 20px;
  top: 21px;
  width: 6px;
  transition: transform 0.25s ease-in-out 0s;
  transform: rotate(-45deg);
}

.profile-dropdown .dropdown-list li.expanded {
  max-height: 300px;
}

.profile-dropdown .dropdown-list li.expanded .list-item:after {
  transform: rotate(45deg);
}

.profile-dropdown .dropdown-list li.active .label-text {
  color: #e3714d;
  font-family: sspBold;
}

.profile-dropdown .dropdown-list li.logout-btn:last-of-type .label-text {
  color: #f74;
  font-family: sspBold;
}

.profile-dropdown .dropdown-list li.logout-btn img {
  width: 18px;
}

.profile-dropdown .dropdown-list li ul {
  padding-left: 25px;
}

.profile-dropdown .dropdown-list li .list-item {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  padding: 15px 10px 15px 15px;
}

.profile-dropdown .dropdown-list li .list-icon {
  margin-right: 12px;
  width: 20px;
}

.profile-dropdown .dropdown-list li .label-text {
  color: #222;
  flex: auto;
  font-family: sspRegular;
  font-size: 1em;
}

.profile-dropdown .dropdown-list li .kyc-status,
.profile-dropdown .dropdown-list li .wallet-status {
  align-items: center;
  display: flex;
}

.profile-dropdown .dropdown-list li .kyc-status span,
.profile-dropdown .dropdown-list li .wallet-status span {
  color: #666;
  font-family: sspBold;
  font-size: 1em;
}

.profile-dropdown .dropdown-list li .kyc-status img,
.profile-dropdown .dropdown-list li .wallet-status img {
  margin-left: 5px;
  width: 18px;
}

@media (min-width: 768px) {
  .main-header.revamped-header .header-content .header-login button {
    font-size: 1.143em;
    line-height: 20px;
    padding: 9px 25px;
  }
}

@media (min-width: 1025px) {
  .main-header {
    position: sticky;
    top: 0;
    z-index: 4;
    box-shadow: 0 0 20px 0 hsla(0, 0%, 94%, 0.6);
  }

  .main-header .header-content {
    margin-left: auto;
    padding: 2px 10px;
  }

  .main-header .header-content .header-logo .fc-logo {
    width: 150px;
  }

  .main-header .header-content .header-links {
    flex: 0 1 660px;
  }

  .main-header .header-content .header-links ul {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .main-header .header-content .header-links ul li {
    margin-right: 30px;
    position: relative;
  }

  .main-header .header-content .header-links ul li:after {
    content: " ";
    height: 20px;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
  }

  .main-header .header-content .header-links ul li:last-child {
    margin-right: 0;
  }

  .main-header .header-content .header-links ul li:hover span {
    color: #f16122;
  }

  .main-header
    .header-content
    .header-links
    ul
    li:hover
    span.dropdown-link:after {
    border-color: #f16122;
    margin-top: 5px;
    transform: rotate(-135deg);
  }

  .main-header .header-content .header-links ul li:hover .dropdown-section {
    opacity: 1;
    visibility: visible;
  }

  .main-header .header-content .header-links ul li span {
    color: #000;
    cursor: pointer;
    font-family: sspRegular;
    font-size: 1.143em;
    line-height: 20px;
    position: relative;
    transition: all 0.25s ease-in-out 0s;
  }

  .main-header .header-content .header-links ul li span.dropdown-link {
    padding-right: 20px;
  }

  .main-header .header-content .header-links ul li span.dropdown-link:after {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    content: "";
    height: 8px;
    position: absolute;
    right: 0;
    top: 3px;
    width: 8px;
    transition: all 0.25s ease-in-out 0s;
    transform: rotate(45deg);
  }

  .main-header .header-content .header-links ul li .dropdown-section {
    background-color: #fff;
    border-radius: 20px;
    left: -20px;
    opacity: 0;
    padding: 60px;
    position: absolute;
    top: calc(100% + 10px);
    transition: visibility 0s, opacity 0.25s linear;
    visibility: hidden;
    box-shadow: 0 20px 50px 0 rgba(2, 32, 71, 0.15);
  }

  .main-header
    .header-content
    .header-links
    ul
    li
    .dropdown-section.dropdown-left {
    left: auto;
    right: -100px;
  }

  .main-header
    .header-content
    .header-links
    ul
    li
    .dropdown-section.dropdown-center {
    left: -330px;
  }

  .main-header .header-content .payments-dropdown-section {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }

  .main-header .header-content .payments-dropdown-section > div {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-top: 10px;
    width: 215px;
  }

  .main-header .header-content .payments-dropdown-section h5 {
    color: #000;
    font-family: sspBold;
    font-size: 1.143em;
    line-height: 20px;
    margin-top: 15px;
  }

  .main-header .header-content .payments-dropdown-section p {
    color: #000;
    font-family: sspRegular;
    font-size: 1em;
    line-height: 20px;
    margin: 10px 0 25px;
  }

  .main-header .header-content .payments-dropdown-section a {
    margin-top: auto;
  }

  .main-header .header-content .payments-dropdown-section button {
    background: #f16122;
    border-radius: 10px;
    font-family: sspRegular;
    font-size: 1.143em;
    height: 46px;
    margin-top: auto;
    width: 130px;
  }

  .main-header .header-content .header-options {
    align-items: center;
    display: flex;
    gap: 20px;
  }

  .main-header .header-content .header-profile .header-notification img {
    width: 18px;
  }

  .main-header .header-content .header-profile .profile-details {
    position: relative;
  }

  .main-header
    .header-content
    .header-profile
    .profile-details:hover
    .profile-dropdown {
    opacity: 1;
    visibility: visible;
  }

  .main-header
    .header-content
    .header-profile
    .profile-details:hover
    .profile-dropdown
    .content-box {
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.05);
  }

  .main-header
    .header-content
    .header-profile
    .profile-details
    .profile-detail:after {
    border-bottom: 1px solid #666;
    border-right: 1px solid #666;
    content: "";
    cursor: pointer;
    height: 7px;
    margin-bottom: 3px;
    margin-left: 20px;
    width: 7px;
    transform: rotate(45deg);
  }

  .main-header
    .header-content
    .header-profile
    .profile-details
    .profile-avatar {
    width: 45px;
  }

  .main-header .header-content .header-profile .profile-details .profile-name {
    font-size: 1.143em;
  }

  .main-header .header-content .header-login img {
    width: 45px;
  }

  .main-header .header-content .header-login .login-btn {
    font-size: 1.143em;
  }
}

.progress-bar-content {
  padding: 14px 20px 10px;
}

.progress-bar-content .progress-step-details {
  font-family: sspBold;
  font-size: 0.857em;
  margin: 0 0 10px 2px;
}

.progress-bar-content .progress-bar {
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 15px;
  height: 6px;
}

.progress-bar-content .progress-bar.second {
  background-color: unset;
  border: 0;
  display: flex;
  flex-direction: row;
  height: 4px;
}

.progress-bar-content .progress-bar .progress-bar-line {
  background-color: #fc7944;
  border-radius: 15px;
  height: 100%;
}

.progress-bar-content .progress-bar .progress-bar-line.second {
  background-color: #f2f2f2;
  margin-right: auto;
}

.progress-bar-content .progress-bar .progress-bar-line.active {
  background-color: #fc7944;
}

.progress-bar-content .progress-bar .progress-bar-line.second:last-child {
  margin-right: 0;
}

@media (min-width: 768px) {
  .progress-bar-content .progress-bar.second {
    height: 5px;
  }
}

.install-app-sidebar .mob-icon {
  width: 44px;
}

.install-app-sidebar .title {
  color: #222;
  font-family: sspBold;
  font-size: 0.928em;
  line-height: 18px;
  margin-top: 10px;
}

.install-app-sidebar .cta {
  border-radius: 30px;
  font-size: 0.785em;
  height: 30px;
  margin-top: 10px;
  max-width: 112px;
  text-transform: uppercase;
}

.install-app-homepage {
  background-color: #f4f6f7;
  padding-bottom: 10px;
}

.install-app-homepage .heading-wrapper {
  padding: 20px 0 10px 20px;
}

.install-app-homepage .heading-wrapper .section-title {
  color: #222;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 20px;
}

.install-app-homepage .section-content {
  background-color: #fff;
  border-radius: 12px;
  padding: 30px 20px;
}

.install-app-homepage .section-content .utilities-section .utility {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: auto 1fr;
  margin: 0 0 15px;
}

.install-app-homepage .section-content .utilities-section .utility .util-icon {
  width: 40px;
}

.install-app-homepage .section-content .utilities-section .utility .util-text {
  line-height: 22px;
}

.install-app-homepage
  .section-content
  .utilities-section
  .utility
  .util-text
  .heading {
  color: #222;
  font-family: sspBold;
  font-size: 1em;
}

.install-app-homepage
  .section-content
  .utilities-section
  .utility
  .util-text
  .description {
  color: #666;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 16px;
}

.install-app-homepage .section-content .cta-btn {
  margin-top: 30px;
}

.install-app-homepage .section-content .cta-btn button {
  background-color: #f74;
  border-radius: 10px;
  color: #fff;
  font-family: sspBold;
  font-size: 1em;
  height: 44px;
  line-height: 20px;
  text-transform: uppercase;
}

.install-app-header {
  background-color: #fff;
  justify-content: space-between;
  margin-bottom: 4px;
  padding: 12px 30px 12px 20px;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.install-app-header,
.install-app-header .icon-text {
  align-items: center;
  display: flex;
}

.install-app-header .icon-text .mob-icon {
  width: 44px;
}

.install-app-header .icon-text .banner-text {
  color: #222;
  font-family: sspBold;
  font-size: 0.928em;
  line-height: 18px;
  margin: 0 10px;
}

.install-app-header .cta {
  border-radius: 30px;
  font-size: 0.785em;
  height: 30px;
  max-width: 112px;
  text-transform: uppercase;
}

.install-app-header .close-btn {
  border: 1px solid #aeaeae;
  border-radius: 50%;
  height: 18px;
  padding: 4px;
  position: absolute;
  right: 8px;
  top: 8px;
}

.install-app-payment {
  background-color: #fff;
  border-radius: 20px;
  margin: 0 8px 10px;
  padding: 20px;
}

.install-app-payment .section-wrapper .section-title {
  color: #222;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 22px;
}

.install-app-payment .section-wrapper .benefits-section {
  display: grid;
  grid-gap: 15px 0;
  gap: 15px 0;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 15px;
}

.install-app-payment .section-wrapper .benefits-section .benefit {
  align-items: center;
  display: flex;
}

.install-app-payment .section-wrapper .benefits-section .benefit .tick {
  margin-right: 10px;
  width: 16px;
}

.install-app-payment .section-wrapper .benefits-section .benefit .text {
  color: #222;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 18px;
}

.install-app-payment .section-wrapper .cta-btn {
  align-items: center;
  display: flex;
  margin-top: 20px;
}

.install-app-payment .section-wrapper .cta-btn .download-arrow {
  width: 15px;
}

.install-app-payment .section-wrapper .cta-btn span {
  color: #e3714d;
  font-family: sspBold;
  margin: 0 15px 0 8px;
}

.install-app-payment .section-wrapper .cta-btn .download-stores {
  width: 35px;
}

.install-app-payment.fulfilment {
  margin: 30px 0 20px;
  box-shadow: 0 0 20px 0 rgba(228, 239, 239, 0.937);
}

.install-app-login {
  align-items: center;
  background: rgba(255, 119, 68, 0.02);
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;
  padding: 10px 15px;
}

.install-app-login .text-section .heading {
  color: #222;
  font-family: sspBold;
  font-size: 1em;
  line-height: 20px;
}

.install-app-login .text-section .subheading {
  color: #666;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 18px;
}

.install-app-login .icon-section .stores {
  width: 53px;
}

@media (min-width: 768px) {
  .install-app-payment {
    background-color: #fff9f7;
    border-radius: 0;
    border-top: 1px solid #e4e4e4;
    display: flex;
    margin: 0;
    padding: 20px 45px;
  }

  .install-app-payment .section-wrapper .section-title-wrapper {
    align-items: center;
    display: flex;
  }

  .install-app-payment .section-wrapper .store-icons {
    margin-left: 15px;
  }

  .install-app-payment .section-wrapper .store-icons .stores {
    width: 38px;
  }

  .install-app-payment .qr-code {
    margin-left: 15px;
  }

  .install-app-payment .qr-code .qr {
    width: 92px;
  }

  .install-app-payment.fulfilment {
    background: #fff;
    border-radius: 20px;
    border-top: none;
  }

  .install-app-login.spaced {
    margin: 40px 0 0;
  }
}

@media (min-width: 1025px) {
  .install-app-homepage {
    background-color: #fff;
    border-radius: 20px;
    margin-top: 30px;
    padding: 30px;
  }

  .install-app-homepage .heading-wrapper {
    padding: 0;
  }

  .install-app-homepage .heading-wrapper .section-title {
    line-height: 22px;
    margin: 0;
  }

  .install-app-homepage .section-content {
    display: flex;
    margin-top: 20px;
    padding: 0;
  }

  .install-app-homepage .section-content .utilities-section {
    margin-right: 110px;
  }

  .install-app-homepage .section-content .utilities-section .utility {
    grid-column-gap: 25px;
    margin: 0 25px 20px 0;
  }

  .install-app-homepage
    .section-content
    .utilities-section
    .utility
    .util-icon {
    width: 44px;
  }

  .install-app-homepage
    .section-content
    .utilities-section
    .utility
    .util-text
    .heading {
    font-size: 1.143em;
  }

  .install-app-homepage
    .section-content
    .utilities-section
    .utility
    .util-text
    .description {
    font-size: 1em;
    line-height: 18px;
  }

  .install-app-homepage .section-content .qr-wrapper {
    background: linear-gradient(#fff, #fff) padding-box,
      linear-gradient(180deg, #ffd6c7 41.41%, rgba(255, 214, 199, 0)) border-box;
    border: 6px solid transparent;
    border-bottom: none;
    border-radius: 20px 20px 0 0;
    margin: 0 30px 25px 0;
    padding: 25px 15px 0;
    text-align: center;
  }

  .install-app-homepage .section-content .qr-wrapper img {
    height: 132px;
    width: 132px;
  }

  .install-app-homepage .section-content .qr-wrapper .scan-text {
    font-family: sspRegular;
    font-size: 1em;
    line-height: 20px;
    margin-top: 8px;
  }

  .install-app-payment .qr-code {
    margin: 0 0 0 55px;
  }

  .install-app-login {
    padding: 10px 25px;
  }

  .install-app-login .text-section .heading {
    font-size: 1.143em;
  }

  .install-app-login .text-section .subheading {
    font-size: 1em;
  }

  .install-app-login .icon-section .app-qr {
    width: 81px;
  }
}

.otp-wrapper .error-message {
  color: #d0021b;
  font-family: sspRegular;
  font-size: 1em;
  margin-top: 15px;
}

.otp-boxes {
  display: flex;
  justify-content: space-between;
}

.otp-boxes .otp-box:not(:last-child) {
  margin-right: 10px;
}

.otp-boxes .otp-box {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 1.143em;
  height: 54px;
  text-align: center;
  width: 58px;
}

.otp-boxes .otp-box.layout-2 {
  border-left: none;
  border-radius: 0;
  border-right: none;
  border-top: none;
}

.otp-boxes .otp-box.layout-3 {
  background-color: #f9f9fc;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  height: 42px;
  width: 42px;
}

.otp-boxes .otp-box.layout-3:not(:last-child) {
  margin-right: 9px;
}

.otp-boxes .otp-box.layout-3.has-value,
.otp-boxes .otp-box.layout-3:focus {
  background-color: #fff;
  border-color: #ec7f51;
  box-shadow: 0 0 10px 0 rgba(236, 127, 81, 0.5);
}

@media (max-width: 360px) {
  .otp-boxes .otp-box {
    height: 46px;
    width: 50px;
  }
}

@media (min-width: 768px) {
  .otp-boxes .otp-box.layout-3 {
    height: 48px;
    width: 48px;
  }
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 28px 24px;
}

.login-wrapper .cross-icon {
  margin-bottom: 33px;
  width: 25px;
}

.login-wrapper .form-section {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: space-between;
}

.login-wrapper .form-section.sign-up .text-content {
  display: none;
}

.login-wrapper .form-section.sign-up .page-heading {
  margin-bottom: 18px;
}

.login-wrapper .form-section .action-fields {
  margin-top: 16px;
}

.login-wrapper .form-section .input-fields .input-wrapper {
  margin-bottom: 20px;
}

.login-wrapper .form-section .input-fields .input-wrapper .middle-name-cta {
  color: #f74;
  cursor: pointer;
  display: block;
  font-family: sspBold;
  margin-top: 27px;
  text-transform: uppercase;
}

.login-wrapper
  .form-section
  .input-fields
  .input-container
  input[name$="Name"] {
  text-transform: capitalize;
}

.login-wrapper .form-section .input-fields .input-container input:disabled {
  cursor: not-allowed;
}

.login-wrapper .form-section .input-fields .input-container p {
  line-height: 20px;
  text-transform: capitalize;
}

.login-wrapper .form-section .input-fields .sign-up-pincode-wrapper {
  margin-bottom: 4px;
}

.login-wrapper .form-section .input-fields .sign-up-pincode-wrapper img {
  height: 20px;
  top: 42px;
  width: 20px;
}

.login-wrapper .form-section .input-fields .sign-up-pincode-wrapper span {
  text-transform: none;
}

.login-wrapper .form-section .input-fields .sign-up-state-details {
  color: #666;
  font-size: 0.857em;
  margin-top: 10px;
}

.login-wrapper .form-section .input-fields .sign-up-state-details span {
  color: #222;
  font-family: sspBold;
}

.login-wrapper .form-section .pincode-info {
  color: #666;
  font-size: 0.857em;
  line-height: 20px;
  text-align: left;
}

.login-wrapper .page-heading {
  color: #444;
  font-family: sspBolder;
  font-size: 1.429em;
  margin: 0 6px 7px;
}

.login-wrapper .text-content {
  color: #666;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 21px;
  margin: 10px 6px 40px;
}

.login-wrapper .text-content b {
  color: #222;
  font-family: sspBold;
}

.login-wrapper .text-content span {
  color: #e3714d;
  cursor: pointer;
  font-family: sspBold;
}

.login-wrapper .otp-container {
  margin-top: 50px;
}

.login-wrapper .otp-container .otp-boxes .otp-box {
  border-bottom-color: #aaa;
  color: #222;
  font-size: 55px;
  width: 60px;
}

.login-wrapper .otp-container .otp-actions {
  margin-top: 35px;
}

.login-wrapper .otp-container .otp-actions p,
.login-wrapper .otp-container .otp-actions span {
  align-items: center;
  color: #666;
  display: flex;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 21px;
}

.login-wrapper .otp-container .otp-actions p {
  margin-bottom: 5px;
}

.login-wrapper .otp-container .otp-actions .call-otp,
.login-wrapper .otp-container .otp-actions .resend-otp {
  color: #e3714d;
  cursor: pointer;
  font-family: sspBold;
}

.login-wrapper .otp-container .otp-actions .resend-otp {
  margin-left: 5px;
}

.login-wrapper .otp-container .otp-actions .call-otp {
  margin-top: 20px;
}

.login-wrapper .otp-container .otp-actions .call-otp img {
  margin-right: 5px;
  width: 15px;
}

.login-wrapper .otp-container .otp-actions .clock-icon {
  margin: 0 5px;
  width: 20px;
}

.login-wrapper .g-recaptcha-container {
  text-align: center;
}

.login-wrapper .policy-checkbox {
  margin-bottom: 19px;
  position: relative;
}

.login-wrapper .policy-checkbox label {
  align-items: flex-start;
  margin-right: 0;
}

.login-wrapper .policy-checkbox label:first-child {
  margin-bottom: 8px;
}

.login-wrapper .policy-checkbox label span {
  color: #666;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 18px;
}

.login-wrapper .policy-checkbox label span a {
  color: #e3714d;
  font-family: sspBold;
}

.login-wrapper .policy-checkbox .error-message {
  margin: 0 0 0 23px;
}

.login-wrapper .submit-btn {
  border-radius: 10px;
  margin-top: 10px;
  min-height: 48px;
  text-transform: uppercase;
  z-index: 2;
  box-shadow: 0 8px 16px 0 #f1b8a0;
}

.login-wrapper .submit-btn:disabled {
  box-shadow: none;
}

@media (min-width: 768px) {
  .login-wrapper {
    flex-direction: row;
    min-height: inherit;
    overflow: hidden;
    padding: 0;
    position: relative;
  }

  .login-wrapper:after {
    background-color: #fcf6f4;
    border-top-left-radius: 60%;
    bottom: -90px;
    content: "";
    height: 180px;
    position: absolute;
    right: -70px;
    width: 140px;
  }

  .login-wrapper .image-section {
    align-items: flex-end;
    background-color: #fff9f7;
    display: flex;
    flex: 1 1 50%;
  }

  .login-wrapper .image-section.signUp-image {
    justify-content: center;
  }

  .login-wrapper .image-section.signUp-image img {
    height: 331px;
    width: 357px;
  }

  .login-wrapper .image-section.captcha-image {
    align-items: center;
    justify-content: center;
  }

  .login-wrapper .image-section.captcha-image img {
    width: 70%;
  }

  .login-wrapper .image-section img {
    width: 90%;
  }

  .login-wrapper .form-section {
    flex: 1 1 50%;
    overflow-y: auto;
    padding: 45px;
  }

  .login-wrapper .form-section .input-fields {
    margin-bottom: 12px;
  }

  .login-wrapper .form-section .pincode-info {
    text-align: left;
  }

  .login-wrapper .form-section.sign-up {
    padding: 40px 45px;
  }

  .login-wrapper .form-section.sign-up .input-fields .input-wrapper {
    margin-bottom: 15px;
  }

  .login-wrapper .form-section.sign-up .input-fields .sign-up-pincode-wrapper {
    margin-bottom: 5px;
  }

  .login-wrapper .form-section.sign-up .action-fields .policy-checkbox {
    margin: 10px auto 0;
  }

  .login-wrapper .form-section.sign-up .install-app-login {
    margin-top: 27px;
  }
}

.star-ratings {
  display: flex;
}

.star-ratings .star {
  height: 12px;
  margin-right: 8px;
  width: 8px;
}

.star-ratings .star:last-child {
  margin-right: 0;
}

.reviews-container {
  background-color: #fff;
  border-radius: 20px;
  padding: 25px 20px;
}

.reviews-container .heading {
  color: #222;
  font-family: sspBolder;
  font-size: 1.143em;
  line-height: 20px;
}

.reviews-container .heading.center-aligned {
  font-size: 1.429em;
  text-align: center;
}

.reviews-container .heading span {
  color: #f74;
}

.reviews-container .reviews-section {
  margin: 20px 0;
}

.reviews-container .reviews-section .review-wrapper {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 25px 20px 20px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.04);
}

.reviews-container .reviews-section .review-wrapper .rating {
  align-items: center;
  display: flex;
}

.reviews-container .reviews-section .review-wrapper .rating .star-ratings {
  margin-bottom: 3px;
}

.reviews-container
  .reviews-section
  .review-wrapper
  .rating
  .star-ratings
  .star {
  width: 16px;
}

.reviews-container .reviews-section .review-wrapper .rating .value {
  color: #222;
  font-family: sspBold;
  font-size: 1em;
  line-height: 18px;
  margin-left: 15px;
}

.reviews-container .reviews-section .review-wrapper .comment-user {
  color: #222;
  font-family: sspRegular;
  margin-top: 15px;
}

.reviews-container .reviews-section .review-wrapper .comment-user .comment {
  font-size: 1em;
  line-height: 20px;
  word-break: break-word;
}

.reviews-container
  .reviews-section
  .review-wrapper
  .comment-user
  .user-details {
  font-size: 1em;
  line-height: 20px;
  margin-top: 15px;
}

.reviews-container
  .reviews-section
  .review-wrapper
  .comment-user
  .user-details
  .username {
  font-size: 0.857em;
  line-height: 18px;
  text-transform: capitalize;
}

.reviews-container
  .reviews-section
  .review-wrapper
  .comment-user
  .user-details
  .designation {
  color: #666;
  font-size: 0.857em;
  line-height: 18px;
  margin-top: 8px;
  text-transform: capitalize;
  word-break: break-word;
}

.reviews-container .bottom-content {
  font-size: 1em;
  line-height: 20px;
}

.reviews-container .bottom-content p {
  color: #222;
  font-family: sspRegular;
  margin-bottom: 8px;
}

.reviews-container .bottom-content a {
  color: #f74;
  cursor: pointer;
  font-family: sspBold;
}

.reviews-container .full-screen-popup {
  margin: 0;
  padding: 0;
}

.reviews-container .full-screen-popup .pop-up {
  border-radius: 0;
  margin: 0;
  min-height: 100%;
  padding: 0;
}

.reviews-container .flexed-popup .pop-up {
  display: flex;
  flex-direction: column;
  position: relative;
}

.rm-submit-btn-container {
  background-color: #fff;
  bottom: 0;
  left: 0;
  max-width: 100%;
  padding: 20px;
  position: absolute;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}

.review-form-container {
  overflow-y: auto;
  padding: 66px 20px 120px;
}

.review-form-container .text-center {
  text-align: center;
}

.review-form-container .d-none {
  display: none;
}

.review-form-container .welcome-msg {
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 22px;
  margin-bottom: 4px;
}

.review-form-container .welcome-msg span {
  margin-left: 5px;
}

.review-form-container h6 {
  font-family: sspBold;
  font-size: 1.429em;
  line-height: 26px;
}

.review-form-container .rating-field {
  margin-bottom: 16px;
}

.review-form-container .rating-field .star {
  cursor: pointer;
  height: 40px;
  width: 40px;
}

.review-form-container .rating-field .star-ratings {
  cursor: pointer;
  justify-content: center;
}

.review-form-container .rating-field.rating-spaced {
  margin-top: 20px;
}

.review-form-container .rating-text {
  font-family: sspBold;
  font-size: 1.571em;
  margin-bottom: 20px;
}

.review-form-container .rating-text.good-rating-text {
  color: #11d1ac;
}

.review-form-container .rating-text.bad-rating-text {
  color: #ff383c;
}

.review-form-container .cloud-field-label {
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 22px;
  margin-bottom: 14px;
}

.review-form-container .cloud-checkbox {
  border: 1px solid #d8d8d8;
  border-radius: 20px;
  display: inline-block;
  line-height: 20px;
  margin: 0 5px 10px;
  padding: 10px 20px;
  width: -moz-fit-content;
  width: fit-content;
}

.review-form-container .cloud-checkbox.active {
  background: rgba(255, 119, 68, 0.1);
  border: 1px solid #f74;
}

.review-form-container .cloud-checkbox-section {
  margin-bottom: 10px;
}

.review-form-container .comment-box {
  margin-bottom: 20px;
}

.review-form-container .comment-box textarea {
  background-color: #fff;
  padding: 12px 16px;
}

.review-form-container .comment-field-title {
  font-family: sspBolder;
  font-size: 1.143em;
  line-height: 22px;
  margin-bottom: 14px;
  text-align: center;
}

.review-form-container .additional-info-container {
  background: #f9f9fc;
  border-radius: 10px;
  padding: 24px;
}

.review-form-container .additional-info-checkbox {
  color: #222;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 22px;
}

.review-form-container .designation-field {
  margin-bottom: 20px;
  margin-top: 14px;
}

.review-form-container .designation-field input {
  background-color: #fff;
}

.review-form-container .success-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70vh;
}

.review-form-container .success-container .icon {
  height: 68px;
  margin-bottom: 22px;
  width: 68px;
}

.review-form-container .success-container h3 {
  font-family: sspBold;
  font-size: 1.714em;
  line-height: 30px;
  margin-bottom: 4px;
}

.review-form-container .success-container p {
  color: #666;
  line-height: 20px;
}

.review-form-container .success-container .feedback-response {
  margin-top: 25px;
  text-align: center;
}

.review-form-container .success-container .redirect-label {
  color: #f74;
  cursor: pointer;
  font-family: sspBold;
  font-size: 1em;
  margin-top: 120px;
}

.review-form-container .rating-reaction {
  min-height: 200px;
}

@media (max-width: 767px) {
  .reviews-container {
    margin: 10px 0;
  }
}

@media (min-width: 768px) {
  .reviews-container {
    background-color: unset;
    padding: 25px 0;
  }

  .reviews-container .heading {
    border-bottom: 1px solid hsla(0, 0%, 86%, 0.64);
    padding-bottom: 15px;
  }

  .reviews-container .reviews-section {
    -moz-column-gap: 15px;
    column-gap: 15px;
    -moz-columns: 3;
    column-count: 3;
  }

  .reviews-container .reviews-section .review-wrapper {
    page-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid;
    float: left;
    margin-bottom: 15px;
    width: 100%;
  }

  .reviews-container .reviews-section .review-wrapper .rating .star-ratings {
    margin-bottom: 7px;
  }

  .reviews-container
    .reviews-section
    .review-wrapper
    .rating
    .star-ratings
    .star {
    width: 20px;
  }

  .reviews-container .reviews-section .review-wrapper .rating .value {
    line-height: 20px;
  }

  .reviews-container .reviews-section .review-wrapper .comment-user {
    margin-top: 25px;
  }

  .reviews-container
    .reviews-section
    .review-wrapper
    .comment-user
    .user-details {
    align-items: unset;
    margin-top: 30px;
  }

  .reviews-container
    .reviews-section
    .review-wrapper
    .comment-user
    .user-details
    .designation {
    margin-top: 5px;
  }

  .reviews-container .bottom-content {
    display: flex;
    font-size: 1.143em;
    line-height: 22px;
  }

  .reviews-container .bottom-content a {
    margin-left: 5px;
  }

  .rm-submit-btn-container {
    box-shadow: none;
    padding: 0 80px 54px;
    position: static;
  }

  .review-form-container {
    padding: 54px 80px 0;
  }

  .review-form-container .welcome-msg {
    font-size: 1.286em;
    line-height: 24px;
  }

  .review-form-container h6 {
    font-size: 1.571em;
    line-height: 28px;
  }

  .review-form-container .rating-field.rating-spaced {
    margin-bottom: 220px;
  }

  .review-form-container .rating-field.rating-spaced-desktop {
    margin-top: 20px;
  }

  .review-form-container .rating-field.rating-btn-spaced {
    margin-bottom: 40px;
  }

  .review-form-container .rating-text {
    font-size: 1.857em;
    margin-bottom: 30px;
  }

  .review-form-container .cloud-field-label {
    font-size: 1.286em;
    line-height: 24px;
  }

  .review-form-container .cloud-checkbox {
    border-radius: 30px;
    font-size: 1.143em;
    line-height: 22px;
    padding: 12px 24px;
  }

  .review-form-container .cloud-checkbox-section,
  .review-form-container .comment-box {
    margin-bottom: 30px;
  }

  .review-form-container .additional-info-checkbox {
    font-size: 1.286em;
    line-height: 24px;
  }

  .review-form-container .additional-info-container {
    margin-bottom: 30px;
  }

  .review-form-container .success-container {
    min-height: 440px;
  }

  .review-form-container .rating-reaction {
    min-height: 240px;
  }
}

.main {
  display: flex;
  flex-direction: column;
}

.main .main-container {
  flex: auto;
}

.main-container .main-navigation {
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
}

.main-container .main-navigation .navigation-list {
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
}

.main-container .main-navigation .navigation-list .header-logo {
  left: 50px;
  position: absolute;
  top: 15px;
}

.main-container .main-navigation .navigation-list .header-logo img {
  width: 120px;
}

.main-container .main-navigation .sidebar-list .list-divider {
  border-bottom: 1px dashed #e5e4e4;
  margin: 20px 0;
  padding: 0;
}

.main-container .main-navigation .sidebar-list a {
  display: block;
  padding: 16px 10px 16px 0;
}

.main-container .main-navigation .sidebar-list a .sidebar-item {
  align-items: center;
  color: #5e5e5e;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.main-container .main-navigation .sidebar-list a .sidebar-item > img {
  grid-row-end: 2;
  grid-row-start: 1;
  width: 18px;
}

.main-container .main-navigation .sidebar-list a .sidebar-item .label-text {
  color: #5a6876;
  flex: 0 0 82%;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 18px;
  text-decoration: none;
}

.main-container .main-navigation .sidebar-list a .sidebar-item .label-new {
  background-color: #1277ee;
  border-radius: 3px;
  bottom: 0;
  color: #fff;
  font-family: sspBold;
  font-size: 0.714em;
  padding: 2px 5px;
  position: absolute;
}

.main-container .main-navigation .sidebar-list a .sidebar-item .kyc-status,
.main-container .main-navigation .sidebar-list a .sidebar-item .wallet-status {
  align-items: center;
  display: flex;
  grid-column-start: 2;
  margin-top: 5px;
}

.main-container .main-navigation .sidebar-list a .sidebar-item .kyc-status img,
.main-container
  .main-navigation
  .sidebar-list
  a
  .sidebar-item
  .wallet-status
  img {
  margin-left: 5px;
  width: 15px;
}

.main-container .main-navigation .sidebar-list a .sidebar-item .kyc-status span,
.main-container
  .main-navigation
  .sidebar-list
  a
  .sidebar-item
  .wallet-status
  span {
  font-size: 0.857em;
  text-transform: capitalize;
}

.main-container .main-navigation .sidebar-list .sidebar-list-item:first-child {
  margin-top: 40px;
}

.main-container .main-navigation .sidebar-list .sidebar-list-item:last-child {
  margin-bottom: 20px;
}

.main-container
  .main-navigation
  .sidebar-list
  .sidebar-list-item:last-child
  .label-text {
  border-bottom: none;
}

.main-container .main-navigation .sidebar-list .sidebar-list-item .label-text {
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #000;
  display: flex;
  font-family: sspRegular;
  font-size: 1.143em;
  line-height: 20px;
  padding: 18px 0;
  position: relative;
}

.main-container
  .main-navigation
  .sidebar-list
  .sidebar-list-item
  .label-text.action-link:after {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  content: "";
  height: 7px;
  position: absolute;
  right: 5px;
  width: 7px;
  transform: rotate(-45deg);
  transition: all 0.2s ease-in-out 0s;
}

.main-container
  .main-navigation
  .sidebar-list
  .sidebar-list-item
  .label-text.expand {
  color: #f16122;
}

.main-container
  .main-navigation
  .sidebar-list
  .sidebar-list-item
  .label-text.expand:after {
  border-color: #f16122;
  transform: rotate(45deg);
}

.main-container
  .main-navigation
  .sidebar-list
  .sidebar-list-item
  .label-text
  img {
  margin-right: 12px;
  width: 30px;
}

.main-container
  .main-navigation
  .sidebar-list
  .sidebar-list-item
  .sidebar-dropdown-list {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out 0s;
}

.main-container
  .main-navigation
  .sidebar-list
  .sidebar-list-item
  .sidebar-dropdown-list.expand {
  max-height: 100vh;
}

.main-container .main-navigation .logout-btn img {
  width: 20px;
}

.main-container .main-navigation .logout-btn .label-text {
  color: #e3714d;
}

.main-container .main-navigation .more-option {
  padding: 15px 20px;
  position: relative;
  text-transform: capitalize;
}

.main-container .main-navigation .more-option:after {
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  border-right: 2px solid rgba(0, 0, 0, 0.5);
  content: "";
  height: 5px;
  position: absolute;
  right: 20px;
  top: 22px;
  width: 5px;
  transform: rotate(-45deg);
}

.main-container .main-navigation .back-label {
  align-items: center;
  border-bottom: 1px dashed #e5e4e4;
  color: #5a6876;
  cursor: pointer;
  display: flex;
  font-family: sspRegular;
  font-size: 1.143em;
  margin: 0 15px;
  padding: 15px 5px 25px;
  text-transform: capitalize;
}

.main-container .main-navigation .back-label img {
  margin-right: 15px;
  width: 20px;
}

.main-container .main-navigation h6 {
  color: #5a6876;
  font-family: sspBold;
  font-size: 1em;
  margin: 20px;
  text-transform: uppercase;
}

.main-container .main-content .heading-section {
  align-items: center;
  display: flex;
  padding: 25px 20px 0;
}

.main-container .main-content .heading-section .heading-image {
  height: 36px;
  margin-right: 12px;
}

.main-container .main-content .heading-section .heading-content .heading-text {
  color: #222;
  font-family: sspBold;
  font-size: 1.143em;
}

.main-container
  .main-content
  .heading-section
  .heading-content
  .sub-heading-text {
  color: #666;
  font-size: 0.857em;
  line-height: 14px;
  margin-top: 7px;
}

.profile-details-popup {
  padding: 25px 10px;
}

.profile-details-popup .header-section {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
  margin-left: 20px;
}

.profile-details-popup .header-section img {
  margin-right: 15px;
  padding: 5px;
  width: 18px;
}

.profile-details-popup .header-section h3 {
  color: #222;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 16px;
}

.profile-details-popup .user-details {
  border: 1px solid #e7eefa;
  border-radius: 20px;
  padding: 15px 15px 5px;
  box-shadow: 0 2px 6px 0 rgba(231, 238, 250, 0.67);
}

.profile-details-popup .user-details .upper-section {
  align-items: flex-start;
  border-bottom: 1px solid hsla(0, 0%, 59%, 0.1);
  display: flex;
  justify-content: space-between;
  padding-bottom: 14px;
}

.profile-details-popup .user-details .upper-section img {
  width: 45px;
}

.profile-details-popup .user-details .upper-section .content-section {
  margin: 0 15px;
  padding: 5px 0;
  width: calc(100% - 150px);
}

.profile-details-popup .user-details .upper-section .content-section h3 {
  color: #444;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  width: 100%;
}

.profile-details-popup .user-details .upper-section .content-section p {
  color: #666;
  font-family: sspRegular;
  font-size: 0.857em;
  margin-top: 7px;
}

.profile-details-popup .user-details .upper-section .edit-btn {
  border: 1px solid #ff7845;
  border-radius: 15px;
  color: #ff7845;
  font-family: sspBold;
  font-size: 0.857em;
  margin-top: 5px;
  padding: 5px 20px;
}

.profile-details-popup .user-details .lower-section {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 15px 5px;
}

.profile-details-popup .user-details .lower-section .account-label-texts {
  width: 33px;
}

.profile-details-popup .user-details .lower-section.verified p {
  width: calc(100% - 75px);
}

.profile-details-popup .user-details .lower-section:not(.verified) span {
  align-items: center;
  color: #eebd12;
  display: flex;
  padding: 0;
}

.profile-details-popup .user-details .lower-section:not(.verified) span:after {
  border: 1px solid #eebd12;
  border-radius: 50%;
  color: #eebd12;
  content: "!";
  font-size: 0.714em;
  height: 10px;
  line-height: 11px;
  margin-left: 5px;
  padding: 1px;
  position: static;
  text-align: center;
  transform: none;
  width: 10px;
}

.profile-details-popup
  .user-details
  .lower-section:not(.verified)
  .account-label-texts {
  color: #666;
}

.profile-details-popup
  .user-details
  .lower-section:not(.verified)
  .account-label-texts:after {
  content: unset;
}

.profile-details-popup .user-details .lower-section:not(.verified) p {
  display: flex;
  flex-wrap: wrap;
}

.profile-details-popup .user-details .lower-section.upi-status {
  line-height: 20px;
  padding-top: 0;
}

.profile-details-popup .user-details .lower-section.upi-status .copy-btn {
  align-items: center;
  display: flex;
  padding: 0 5px;
}

.profile-details-popup
  .user-details
  .lower-section.upi-status
  .copy-btn
  .copy-text {
  color: #ff7845;
  font-family: sspBold;
  font-size: 0.714em;
  line-height: 12px;
  margin-left: 5px;
}

.profile-details-popup .user-details .lower-section.upi-status span:after {
  content: unset;
}

.profile-details-popup .user-details .lower-section p {
  color: #666;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.profile-details-popup .user-details .lower-section .verified-text {
  color: #187bef;
  font-family: sspBold;
  font-size: 0.857em;
  padding-right: 15px;
  position: relative;
}

.profile-details-popup .user-details .lower-section .verified-text:after {
  border-bottom: 2px solid #187bef;
  border-left: 2px solid #187bef;
  content: "";
  height: 3px;
  position: absolute;
  right: 0;
  top: 2px;
  width: 8px;
  transform: rotate(-45deg);
}

.profile-details-popup .routes-list li {
  position: relative;
}

.profile-details-popup .routes-list li:first-child .list-icon {
  width: 18px;
}

.profile-details-popup .routes-list li .list-item:after {
  border-bottom: 2px solid #d8d8d8;
  border-right: 2px solid #d8d8d8;
  content: "";
  height: 7px;
  position: absolute;
  right: 5px;
  top: 25px;
  width: 7px;
  transform: rotate(-45deg);
}

.profile-details-popup .actions-list li .list-icon {
  width: 18px;
}

.profile-details-popup .actions-list,
.profile-details-popup .routes-list {
  border-radius: 20px;
  margin: 15px 0;
  padding: 0 15px;
  box-shadow: 0 0 6px 0 hsla(0, 2%, 79%, 0.38);
}

.profile-details-popup .actions-list li:last-child,
.profile-details-popup .routes-list li:last-child {
  border-bottom: none;
}

.profile-details-popup .actions-list li.expandable-list,
.profile-details-popup .routes-list li.expandable-list {
  max-height: 60px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out 0s;
}

.profile-details-popup .actions-list li.expandable-list .list-item:after,
.profile-details-popup .routes-list li.expandable-list .list-item:after {
  transition: transform 0.25s ease-in-out 0s;
}

.profile-details-popup .actions-list li.expandable-list.expanded,
.profile-details-popup .routes-list li.expandable-list.expanded {
  max-height: 300px;
}

.profile-details-popup
  .actions-list
  li.expandable-list.expanded
  > .list-item:after,
.profile-details-popup
  .routes-list
  li.expandable-list.expanded
  > .list-item:after {
  transform: rotate(45deg);
}

.profile-details-popup .actions-list li .list-item,
.profile-details-popup .routes-list li .list-item {
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
  display: flex;
  height: 60px;
  padding: 0 12px;
}

.profile-details-popup .actions-list li .list-icon,
.profile-details-popup .routes-list li .list-icon {
  margin-right: 12px;
  width: 20px;
}

.profile-details-popup .actions-list li .label-text,
.profile-details-popup .routes-list li .label-text {
  color: #222;
  font-family: sspRegular;
  font-size: 1em;
}

.profile-details-popup .actions-list li .kyc-status,
.profile-details-popup .actions-list li .wallet-status,
.profile-details-popup .routes-list li .kyc-status,
.profile-details-popup .routes-list li .wallet-status {
  align-items: center;
  display: flex;
  margin-left: 10px;
}

.profile-details-popup .actions-list li .kyc-status span,
.profile-details-popup .actions-list li .wallet-status span,
.profile-details-popup .routes-list li .kyc-status span,
.profile-details-popup .routes-list li .wallet-status span {
  color: #666;
  font-family: sspBold;
  font-size: 0.857em;
}

.profile-details-popup .actions-list li .kyc-status img,
.profile-details-popup .actions-list li .wallet-status img,
.profile-details-popup .routes-list li .kyc-status img,
.profile-details-popup .routes-list li .wallet-status img {
  margin-left: 3px;
  width: 18px;
}

.profile-details-popup .upi-loader {
  width: 18px;
}

.profile-details-popup .logout-btn {
  align-items: center;
  color: #e3714d;
  cursor: pointer;
  display: flex;
  font-family: sspBold;
  font-size: 1.143em;
  justify-content: center;
  margin: 20px auto 0;
  padding: 10px;
  width: 100px;
}

.profile-details-popup .logout-btn .power-icon {
  border: 2px solid #e3714d;
  border-radius: 50%;
  height: 18px;
  margin-right: 5px;
  margin-top: 2px;
  position: relative;
  width: 18px;
}

.profile-details-popup .logout-btn .power-icon:after {
  border: 1px solid #e3714d;
  border-radius: 5px;
  content: "";
  height: 7px;
  left: 6px;
  position: absolute;
  top: -3px;
  box-shadow: 0 0 0 3px #fff;
}

@media (max-width: 767px) {
  .main-container .main-navigation .sidebar-list:first-child {
    margin-top: 40px;
  }

  .main-container .main-navigation .sidebar-list:first-child a:first-child {
    padding-top: 0;
  }

  .main-container
    .main-navigation
    .sidebar-list
    a
    .sidebar-item.active-option
    .label-text {
    color: #e3714d;
  }

  .login-popup .pop-up,
  .my-account-popup .pop-up {
    max-height: none;
    min-height: 100%;
  }
}

@media (max-width: 1024px) {
  .main.transparent-header-container {
    background-color: #fff;
    min-height: 100vh;
  }

  .main.transparent-header-container .main-header {
    background-color: hsla(0, 0%, 100%, 0.8);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    position: sticky;
    top: 0;
    z-index: 3;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 1px 0 0 0 transparent;
  }

  .main.transparent-header-container .main-header .header-content {
    margin-left: 0;
    padding: 14px 20px 14px 30px;
  }

  .main.transparent-header-container .main-header .header-content > div {
    align-items: center;
    display: flex;
  }

  .main.transparent-header-container .main-header .header-section {
    color: #222;
    font-family: sspBold;
    font-size: 1.143em;
    text-transform: capitalize;
  }

  .main.transparent-header-container .main-header .header-section p {
    color: #222;
    font-family: sspBold;
    font-size: 0.714em;
    line-height: 12px;
    margin: 5px 0;
  }

  .main.transparent-header-container .back-icon-img img {
    margin: 3px 20px 0 0;
    width: 8px;
  }

  .main.transparent-header-container .back-icon-img:after {
    content: "";
    height: 45px;
    left: 10px;
    position: absolute;
    top: 8px;
    width: 45px;
  }

  .main.secondary-container .main-header {
    color: #fff;
    min-height: 62px;
    position: sticky;
    top: 0;
    z-index: 3;
    box-shadow: 1px 0 0 0 transparent;
    background: #f6693f;
    background: linear-gradient(90deg, #f6693f 0, #eb8345);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=0);
  }

  .main.secondary-container .main-header .header-content {
    margin-left: 0;
    padding: 14px 20px;
  }

  .main.secondary-container .main-header .header-content > div {
    align-items: center;
    display: flex;
  }

  .main.secondary-container .main-header .header-section {
    color: #fff;
    font-family: sspBold;
    font-size: 1em;
    text-transform: capitalize;
  }

  .main.secondary-container .main-header .more-options {
    font-family: sspBold;
  }

  .main.secondary-container .main-header .more-options .more-options-text {
    margin-right: 5px;
    text-transform: uppercase;
  }

  .main.secondary-container .main-header .more-options .more-options-icon {
    height: 11px;
    width: 19px;
  }

  .main.secondary-container .back-icon-img img {
    margin: 2px 12px 0 0;
    width: 16px;
  }

  .main.secondary-container .back-icon-img:after {
    content: "";
    height: 40px;
    left: 10px;
    position: absolute;
    top: 11px;
    width: 36px;
  }

  .main.primary-bg {
    background-color: #fff;
  }

  .main .main-container .main-navigation {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 100%;
    top: 0;
  }

  .main .main-container .main-navigation.show-menu {
    right: 0;
  }

  .main .main-container .main-navigation.show-menu .navigation-list {
    transition: transform 0.2s ease-in-out 0s;
    box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.16);
    transform: translateX(0);
  }

  .main .main-container .main-navigation .navigation-list {
    background-color: #fafafa;
    border-radius: 0 15px 15px 0;
    overflow-y: scroll;
    padding: 30px 25px 25px;
    width: 275px;
    transform: translateX(-100%);
  }

  .main .main-container .main-navigation .navigation-list::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main-container .main-navigation .sidebar-list:first-child {
    margin-top: 40px;
  }

  .main-container .main-navigation .sidebar-list:first-child a:first-child {
    padding-top: 0;
  }

  .pop-up-wrapper.my-account-popup .pop-up .close-popup-icon-wrapper {
    right: 30px;
    top: 15px;
  }

  .profile-details-popup {
    padding-top: 10px;
  }

  .profile-details-popup .header-section {
    margin-bottom: 30px;
  }

  .profile-details-popup .header-section img {
    display: none;
  }

  .profile-details-popup .header-section h3 {
    font-size: 1.286em;
  }
}

@media (min-width: 768px) {
  .main-container {
    background: #fbfbfb;
    background: linear-gradient(180deg, #fbfbfb 75%, #fff);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
  }

  .main-container
    .main-navigation
    .sidebar-list
    .sidebar-item.active-option
    .label-text {
    color: #e3714d;
  }

  .main-container .list-track .primary-list .sidebar-heading {
    font-family: sspBold;
    font-size: 1.286em;
    line-height: 24px;
    margin: 25px 0 20px 30px;
  }

  .pop-up-wrapper.login-popup {
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(0);
    backdrop-filter: blur(0);
  }

  .pop-up-wrapper.login-popup .pop-up {
    padding: 0;
  }
}

@media (min-width: 1025px) {
  .main {
    min-height: 100vh;
  }

  .main.container-fluid .main-container {
    padding: 0;
  }

  .main.container-fluid .main-container .main-wrapper {
    max-width: 100%;
    padding: 0;
  }

  .main.container-fluid .main-container .main-wrapper .main-content {
    margin: 0;
  }

  .main.no-header-container .main-navigation {
    min-height: calc(100vh - 70px);
    top: 35px;
  }

  .main.revamped-container .main-header .main-wrapper {
    padding: 20px;
  }

  .main .main-container .main-wrapper .main-content.desktop-view-pending {
    background-color: #fff;
    border-radius: 20px;
    margin-right: 0;
    max-width: 450px;
    min-width: 450px;
    padding: 10px;
    box-shadow: 0 0 20px 0 hsla(0, 0%, 94%, 0.6);
  }

  .main
    .main-container
    .main-wrapper
    .view-pending-section
    .view-pending-container-new {
    background-color: #fff;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    margin: 20px 0;
    padding: 10px;
  }

  .main
    .main-container
    .main-wrapper
    .view-pending-section
    .view-pending-container-new
    img {
    width: 100%;
  }

  .main-header .main-wrapper {
    padding: 13px 20px;
  }

  .main-container {
    padding: 35px 10px;
  }

  .main-container .main-wrapper {
    align-items: flex-start;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
  }

  .main-container .main-wrapper > :first-child.main-content,
  .main-container .main-wrapper > :last-child.main-content {
    width: 80%;
  }

  .main-container .main-wrapper > :only-child.main-content {
    width: 100%;
  }

  .main-container .main-navigation {
    background-color: #fff;
    border-radius: 15px;
    height: 100vh;
    max-height: calc(100vh - 145px);
    max-width: 240px;
    position: sticky;
    top: 110px;
    width: 18%;
    box-shadow: 0 0 20px 0 hsla(0, 0%, 94%, 0.6);
  }

  .main-container .main-navigation .navigation-list {
    background-color: #fff;
    border-radius: 15px;
    margin: 0 auto 0 0;
    overflow: hidden;
  }

  .main-container
    .main-navigation
    .navigation-list.navigation-secondary
    .list-track {
    transform: translate3d(-100%, 0, 0);
  }

  .main-container .main-navigation .navigation-list.animate-list .list-track {
    transition: transform 0.5s ease-in-out 0s;
  }

  .main-container .main-navigation .list-track {
    display: flex;
    height: 100%;
  }

  .main-container .main-navigation .list-track .primary-list,
  .main-container .main-navigation .list-track .secondary-list {
    flex: 0 0 100%;
    overflow: hidden scroll;
    padding: 15px;
    width: 100%;
  }

  .main-container .main-navigation .list-track .primary-list::-webkit-scrollbar,
  .main-container
    .main-navigation
    .list-track
    .secondary-list::-webkit-scrollbar {
    display: none;
  }

  .main-container .main-navigation .sidebar-list {
    padding-bottom: 15px;
  }

  .main-container .main-navigation .sidebar-list:first-child a:first-child {
    margin-top: 0;
  }

  .main-container .main-navigation .sidebar-list:last-child a:last-child {
    margin-bottom: 0;
  }

  .main-container .main-navigation .sidebar-list .list-divider {
    margin: 20px 15px 10px;
  }

  .main-container .main-navigation .sidebar-list a {
    display: block;
    margin: 10px 0;
    padding: 0;
    width: 100%;
  }

  .main-container
    .main-navigation
    .sidebar-list
    a:nth-child(3)
    .sidebar-item
    img {
    width: 26px;
  }

  .main-container .main-navigation .sidebar-list a .sidebar-item {
    padding: 15px 20px;
  }

  .main-container .main-navigation .sidebar-list a .sidebar-item.active-option {
    background-color: rgba(227, 113, 78, 0.06);
    border-radius: 12px;
    color: rgba(34, 34, 34, 0.76);
    box-shadow: 0 0 20px 0 rgba(255, 218, 202, 0.08);
  }

  .main-container
    .main-navigation
    .sidebar-list
    a
    .sidebar-item.active-option
    .label-text {
    font-family: sspBold;
  }

  .main-container .main-navigation .sidebar-list a .sidebar-item img {
    width: 20px;
  }

  .main-container .main-navigation .sidebar-list a .sidebar-item .label-text {
    flex: 0 0 76%;
  }

  .main-container .main-navigation .logout-btn {
    margin: 10px 0;
    padding: 15px 20px;
  }

  .main-container .main-navigation .more-option .label-text {
    font-family: sspRegular;
  }

  .main-container .main-navigation .more-option img {
    width: 20px;
  }

  .main-container .main-aside {
    height: 100vh;
    max-height: calc(100vh - 145px);
    overflow-y: auto;
    position: sticky;
    top: 110px;
    width: 20%;
  }

  .main-container .main-aside::-webkit-scrollbar {
    -webkit-appearance: none;
    appearance: none;
    display: block;
    height: 4px;
    width: 4px;
  }

  .main-container .main-aside::-webkit-scrollbar-thumb {
    background: none;
    border-radius: 4px;
  }

  .main-container .main-aside:hover::-webkit-scrollbar-thumb {
    background: #e4e4e4;
  }

  .main-container .main-content {
    margin: 0 25px;
    width: 60%;
  }
}

@keyframes shimmer {
  0% {
    background-position: 100% 0;
  }

  to {
    background-position: -100% 0;
  }
}

.slider-wrapper {
  box-sizing: border-box;
  display: block;
  margin: 5px;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slider-wrapper :focus {
  outline: none;
}

.slider-wrapper.slider-loading {
  visibility: hidden;
}

.slider-wrapper.slider-arrows {
  margin-left: 5px;
  margin-right: 5px;
  padding: 0 15px;
}

.slider-wrapper.slider-arrows .slider-track {
  position: relative;
}

.slider-wrapper.slider-arrows .slider-track .arrow {
  align-items: center;
  background: transparent;
  border: none;
  color: #aeaeae;
  cursor: pointer;
  display: flex;
  padding: 0;
  position: absolute;
}

.slider-wrapper.slider-arrows .slider-track .arrow:disabled span {
  cursor: not-allowed;
  opacity: 0.5;
}

.slider-wrapper.slider-arrows .slider-track .arrow:focus {
  outline: none;
}

.slider-wrapper.slider-arrows .slider-track .arrow.left-arrow {
  bottom: 0;
  left: -12px;
  top: 0;
}

.slider-wrapper.slider-arrows .slider-track .arrow.left-arrow span {
  transform: rotate(135deg);
}

.slider-wrapper.slider-arrows .slider-track .arrow.right-arrow {
  bottom: 0;
  right: -12px;
  top: 0;
}

.slider-wrapper.slider-arrows .slider-track .arrow.right-arrow span {
  transform: rotate(315deg);
}

.slider-wrapper.slider-arrows .slider-track .arrow span {
  border-bottom: 3px solid #aeaeae;
  border-right: 3px solid #aeaeae;
  display: inline-block;
  height: 12px;
  width: 12px;
}

.slider-wrapper.slider-indicators-inside .slider-indicators {
  bottom: 5px;
  left: 0;
  position: absolute;
  right: 0;
}

.slider-wrapper .slider-track .slider-section {
  overflow: hidden;
  transform: translateZ(0);
}

.slider-wrapper .slider-track .slider-section .slider {
  display: flex;
  scrollbar-width: none;
  width: 100%;
  transform: translateZ(0);
}

.slider-wrapper .slider-track .slider-section .slider::-webkit-scrollbar {
  display: none;
}

.slider-wrapper .slider-track .slider-section .slider .slide-shimmer {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: linear-gradient(90deg, #eee 2%, #c5c5c5 18%, #eee 33%);
  background-size: 200%;
  overflow: hidden;
  width: 100%;
}

.slider-wrapper .slider-track .slider-section .slider .slide {
  flex: 0 0 auto;
}

.slider-wrapper .slider-track .slider-section .slider .slide:first-child {
  margin-left: 0 !important;
}

.slider-wrapper .slider-track .slider-section .slider .slide:last-child {
  margin-right: 0 !important;
}

.slider-wrapper .slider-indicators {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.slider-wrapper .slider-indicators ul {
  display: flex;
}

.slider-wrapper .slider-indicators ul li {
  line-height: 6px;
  margin-right: 5px;
  position: relative;
}

.slider-wrapper .slider-indicators ul li:last-child {
  margin-right: 0;
}

.slider-wrapper .slider-indicators ul li.active span.indicator {
  background-color: #e3714d;
}

.slider-wrapper .slider-indicators ul li span.indicator {
  background-color: #d8d8d8;
  border-radius: 50%;
  display: inline-block;
  height: 6px;
  width: 6px;
}

.slider-wrapper .slider-indicators ul li span.indicator.timer {
  position: absolute;
}

.slider-wrapper .slider-indicators ul li span.indicator .indicator-progress {
  background: #f74;
  border-radius: 10px;
  bottom: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 0;
  transition: width 1s linear 0s;
}

.form-wrapper {
  padding: 20px;
}

.form-wrapper h3 {
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 22px;
  margin-bottom: 15px;
}

.form-wrapper .form {
  display: flex;
  flex-direction: column;
}

.form-wrapper .form .field-wrapper {
  margin-bottom: 15px;
}

.form-wrapper .form .field-wrapper .input-wrapper input {
  background-color: #fff;
  border: 1px solid #d8d8d8;
}

.form-wrapper .form .field-wrapper .input-wrapper input::-moz-placeholder {
  color: #222;
}

.form-wrapper .form .field-wrapper .input-wrapper input:-ms-input-placeholder {
  color: #222;
}

.form-wrapper .form .field-wrapper .input-wrapper input::placeholder {
  color: #222;
}

.form-wrapper .form .field-wrapper .input-wrapper .input-container img {
  height: 18px;
  width: 18px;
}

.form-wrapper .form .submit-cta {
  background: #f74;
  border-radius: 10px;
  font-family: sspBold;
  font-size: 1em;
  margin-top: 5px;
}

.form-wrapper .form .submit-cta:disabled {
  background: #aeaeae;
}

@keyframes animate-left {
  0% {
    transform: translateZ(0);
  }

  50% {
    transform: translate3d(-20px, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@keyframes animate-right {
  0% {
    transform: translateZ(0);
  }

  50% {
    transform: translate3d(20px, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.client-page-wrapper.show-cta {
  margin-bottom: 84px;
}

.client-page-wrapper.show-cta.footer-info {
  margin-bottom: 110px;
}

.client-page-wrapper.show-cta.footer-underlining-info {
  margin-bottom: 132px;
}

.client-page-wrapper.homepage .main-header {
  background: #ebf2fb;
}

.client-page-container {
  background-color: #fff;
}

.client-page-container > section:last-child {
  margin-bottom: 0;
}

.client-page-container .layout-1 {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out 0s;
}

.client-page-container .layout-1 .slider-wrapper {
  margin: 0;
}

.client-page-container .layout-1 .layout-slide {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
}

.client-page-container .layout-1 .layout-slide.slide-1 .content-wrapper {
  padding: 50px 40px;
}

.client-page-container .layout-1 .layout-slide.slide-2 .content-wrapper,
.client-page-container .layout-1 .layout-slide.slide-3 .content-wrapper {
  padding: 30px 20px;
}

.client-page-container .layout-1 .layout-slide.slide-5 .content-wrapper {
  padding: 43px 20px 73px;
}

.client-page-container .layout-1 .layout-slide.slide-5 p {
  font-size: 0.857em;
  line-height: 18px;
  max-width: 55%;
}

.client-page-container .layout-1 .image-section {
  align-items: center;
  display: flex;
  margin-top: 15px;
  padding-top: 20px;
}

.client-page-container .layout-1 .image-section .secured-img {
  width: 26px;
}

.client-page-container .layout-1 .image-section .iso-img {
  margin-left: 10px;
  width: 40px;
}

.client-page-container .layout-1 .video-wrapper {
  border-radius: 10px;
  overflow: hidden;
}

.client-page-container .layout-1 .light-theme .banner-image span,
.client-page-container .layout-1 .light-theme h3,
.client-page-container .layout-1 .white-theme .banner-image span,
.client-page-container .layout-1 .white-theme h3 {
  color: #fff;
}

.client-page-container .layout-1 .light-theme p,
.client-page-container .layout-1 .white-theme p {
  color: hsla(0, 0%, 100%, 0.5);
}

.client-page-container .layout-1 .white-theme p {
  color: #fff;
}

.client-page-container .layout-1 .dark-theme h2 {
  color: #222;
}

.client-page-container .layout-1 .banner-image {
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}

.client-page-container .layout-1 .banner-image img {
  height: 30px;
  margin-right: 10px;
}

.client-page-container .layout-1 .banner-image span {
  color: #222;
  font-family: sspBold;
  font-size: 0.714em;
  line-height: 14px;
}

.client-page-container .layout-1 .banner-image span b {
  display: block;
  font-family: sspBolder;
}

.client-page-container .layout-1 h2 {
  color: #fff;
  font-family: sspBolder;
  font-size: 1.429em;
  line-height: 28px;
  margin-bottom: 4px;
  max-width: 55%;
}

.client-page-container .layout-1 h2 span {
  color: #f74;
}

.client-page-container .layout-1 h3 {
  color: #222;
  font-family: sspBolder;
  font-size: 1em;
  line-height: 16px;
  margin-bottom: 5px;
  max-width: 55%;
}

.client-page-container .layout-1 h3 span {
  color: #f74;
}

.client-page-container .layout-1 p {
  color: rgba(34, 34, 34, 0.5);
  font-family: sspRegular;
  font-size: 0.714em;
  line-height: 14px;
  max-width: 66%;
}

.client-page-container .layout-1 .slider-indicators {
  bottom: 15px;
}

.client-page-container .layout-1 .slider-indicators ul li {
  margin-right: 12px;
}

.client-page-container .layout-1 .slider-indicators ul li:last-child {
  margin-right: 0;
}

.client-page-container .layout-1 .slider-indicators ul li.active .indicator {
  background-color: #fff;
}

.client-page-container .layout-1 .slider-indicators ul li .indicator {
  background-color: #666;
  border-radius: 3px;
  height: 8px;
  width: 8px;
}

.client-page-container .layout-1 .slide-btn {
  background-color: #fff;
  border: 1px solid #e3714d;
  color: #e3714d;
  display: inline-block;
  font-family: sspBold;
  text-align: center;
  border-radius: 25px;
  font-size: 0.714em;
  line-height: 16px;
  margin-top: 15px;
  padding: 7px 16px;
  width: auto;
}

.client-page-container .layout-2 {
  background-color: #fff;
}

.client-page-container .layout-2 .tab-labels {
  background-color: #222;
  padding-top: 5px;
  position: sticky;
  top: 0;
}

.client-page-container .layout-2 .tab-labels .tab-option {
  color: #fff;
}

.client-page-container .layout-2.scrollable-tabs-wrapper .tab-labels,
.client-page-container .layout-2.swipeable-tabs-wrapper .tab-labels {
  justify-content: center;
  z-index: 2;
  transition: all 0.2s ease-in-out 0s;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .tab-labels
  .tab-option,
.client-page-container
  .layout-2.swipeable-tabs-wrapper
  .tab-labels
  .tab-option {
  color: #aaa;
  font-family: sspRegular;
  font-size: 1.143em;
  line-height: 22px;
  padding: 15px 5px 10px;
  text-transform: none;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .tab-labels
  .tab-option.active,
.client-page-container
  .layout-2.swipeable-tabs-wrapper
  .tab-labels
  .tab-option.active {
  border-bottom-color: #f74;
  color: #f74;
  font-family: sspBolder;
}

.client-page-container .layout-2.scrollable-tabs-wrapper .tab-labels {
  align-items: center;
  display: flex;
  background: #ebf2fb;
  justify-content: space-between;
  overflow: auto;
  flex-wrap: nowrap;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .tab-labels
  .tab-option:not(:last-child) {
  margin-right: 24px;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .tab-labels
  .tab-option {
  cursor: pointer;
  line-height: 22px;
  font-size: 1em;
  color: #222;
  padding: 22px 10px;
  white-space: nowrap;
  position: relative;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .tab-labels
  .tab-option:after {
  background: #861f41;
  border-radius: 2px 2px 0 0;
  bottom: 0;
  content: "";
  height: 3px;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
  transition: opacity 0.5s ease-in-out 0s;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .tab-labels
  .tab-option.active {
  color: #861f41;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .tab-labels
  .tab-option.active.active:after {
  opacity: 1;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .tab-labels::-webkit-scrollbar {
  display: none;
}

.client-page-container .layout-2.scrollable-tabs-wrapper .cta-btn {
  width: 162px;
  height: 48px;
  align-self: center;
  border-radius: 10px;
  background: #f74;
  box-shadow: 0 0 10px 0 rgba(135, 104, 80, 0.03);
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .services-overview
  .service {
  padding: 50px 26px;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .services-overview
  .service
  h3 {
  margin-bottom: 28px;
  font-size: 1.857em;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .services-overview
  .service
  .service-features
  h3 {
  line-height: 30px;
  font-family: sspBold;
  font-size: 1.143em;
  margin-bottom: 14px;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .services-overview
  .service
  .service-features
  .sub-list {
  list-style-type: disc;
  padding-left: 26px;
  margin-bottom: 32px;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .services-overview
  .service
  .service-features
  .sub-list
  li:not(:last-child) {
  margin-bottom: 12px;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .services-overview
  .service
  .service-features
  .sub-list
  li {
  padding-left: 0;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .services-overview
  .service
  .service-features
  .sub-list
  li:after,
.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .services-overview
  .service
  .service-features
  .sub-list
  li:before {
  content: none;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .services-overview
  .service
  .btn-section {
  display: none;
}

.client-page-container .layout-2.scrollable-tabs-wrapper .security-container {
  height: auto;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .security-container
  .security-info {
  text-align: center;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .security-container
  .security-info
  .header {
  line-height: 34px;
  font-size: 1.857em;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .security-container
  .security-info
  .header,
.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .security-container
  .security-info
  .sub-header {
  width: 100%;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .security-container
  .security-info
  .sub-header {
  opacity: 0.7;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .security-container
  .content-wrapper {
  padding: 50px 45px 0;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .security-container
  .security-badge {
  margin-top: 0;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .security-container
  .security-badge
  img {
  animation-delay: 0.9s;
  animation-direction: alternate;
  animation-duration: 1.5s;
  animation-fill-mode: none;
  animation-iteration-count: infinite;
  animation-name: bounce;
  animation-timing-function: ease-out;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  to {
    transform: translateY(30px);
  }
}

.client-page-container .layout-2.scrollable-tabs-wrapper .layout-8 h3 {
  font-size: 1.857em;
  line-height: 34px;
  margin: 0 0 24px;
  max-width: unset;
}

.client-page-container .layout-2.scrollable-tabs-wrapper .layout-8 .list-data {
  padding-left: 20px;
  padding-right: 20px;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .layout-8
  .list-data
  .list-item {
  border-radius: 15px;
  border: 1px solid #f1f1f1;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .layout-8
  .list-data
  .list-item.active
  .upper-section {
  padding-bottom: 12px;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .layout-8
  .list-data
  .list-item.active
  .upper-section
  p {
  font-family: sspBold;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .layout-8
  .list-data
  .list-item
  .upper-section
  p {
  line-height: 22px;
  font-size: 1.143em;
  font-family: sspRegular;
}

.client-page-container
  .layout-2.scrollable-tabs-wrapper
  .layout-8
  .list-data
  .list-item
  .lower-section
  p {
  line-height: 24px;
  font-size: 1em;
  color: #666;
}

.client-page-container .layout-2.scrollable-tabs-wrapper .layout-8 button {
  border: 1px solid #f16122;
  border-radius: 10px;
  color: #f16122;
  font-family: sspBold;
  font-size: 1em;
  line-height: 30px;
  padding: 5px 27px;
  text-transform: uppercase;
  width: auto;
}

.client-page-container .layout-3 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.client-page-container .layout-3.refer {
  padding: 60px 15px 30px;
}

.client-page-container .layout-3.refer .content-wrapper {
  background: #2e2a5b;
  border-radius: 20px;
  padding: 52px 20px 0;
}

.client-page-container .layout-3.refer .download-icons {
  margin-bottom: 80px;
}

.client-page-container .layout-3.refer .download-icons img {
  height: 50px;
  width: 155px;
}

.client-page-container .layout-3.refer .content-section {
  align-items: flex-start;
}

.client-page-container .layout-3.refer .overview-image-section {
  margin-top: 30px;
  text-align: center;
  width: 100%;
}

.client-page-container .layout-3.refer .overview-image-section img {
  width: 100%;
}

.client-page-container .layout-3.refer h3,
.client-page-container .layout-3.refer p {
  color: #fff;
  text-align: left;
}

.client-page-container .layout-3.refer h3 {
  font-family: sspBold;
  font-size: 2em;
  line-height: 35px;
  margin-bottom: 17px;
}

.client-page-container .layout-3.refer p {
  font-size: 1.143em;
  line-height: 22px;
  margin-bottom: 35px;
  opacity: 0.5;
  padding-left: 0;
}

.client-page-container .layout-3.left-aligned {
  padding-bottom: 30px;
  padding-top: 15px;
}

.client-page-container .layout-3.left-aligned .download-icons {
  margin-bottom: 50px;
  margin-top: 15px;
}

.client-page-container .layout-3.left-aligned .download-icons img {
  height: 50px;
  width: 155px;
}

.client-page-container .layout-3.left-aligned .content-wrapper {
  background: #ebf2fb;
  border-radius: 15px;
  margin: 0 15px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-top: 45px;
}

.client-page-container .layout-3.left-aligned .content-section {
  align-items: flex-start;
}

.client-page-container .layout-3.left-aligned h3 {
  font-family: sspBold;
  font-size: 2.428em;
  line-height: 40px;
  padding-right: 50px;
  text-align: left;
}

.client-page-container .layout-3.left-aligned p {
  color: #222;
  font-size: 1.143em;
  line-height: 22px;
  padding: 0 25px 0 0;
  text-align: left;
}

.client-page-container .layout-3.layout-id-2 p {
  color: #222;
  font-size: 0.857em;
  line-height: 18px;
}

.client-page-container .layout-3.layout-id-2 .overview-image-section {
  text-align: right;
}

.client-page-container
  .layout-3.layout-id-2
  .overview-image-section.image-layout-1 {
  text-align: center;
}

.client-page-container
  .layout-3.layout-id-2
  .overview-image-section.image-layout-1
  img {
  width: 75%;
}

.client-page-container .layout-3.layout-id-2 .overview-image-section img {
  width: 90%;
}

.client-page-container .layout-3.layout-id-3,
.client-page-container .layout-3.layout-id-4 {
  padding: 0;
}

.client-page-container .layout-3.layout-id-3 .content-wrapper,
.client-page-container .layout-3.layout-id-4 .content-wrapper {
  display: flex;
  flex-direction: column-reverse;
  padding: 20px;
}

.client-page-container .layout-3.layout-id-3 .overview-image-section img,
.client-page-container .layout-3.layout-id-4 .overview-image-section img {
  width: 240px;
}

.client-page-container .layout-3.layout-id-3 .content-section,
.client-page-container .layout-3.layout-id-4 .content-section {
  align-items: flex-start;
  background-color: transparent;
}

.client-page-container .layout-3.layout-id-3 .content-section h3,
.client-page-container .layout-3.layout-id-4 .content-section h3 {
  font-size: 1.429em;
  line-height: 22px;
  margin-top: 40px;
  text-align: left;
}

.client-page-container .layout-3.layout-id-3 .content-section .extra-info,
.client-page-container .layout-3.layout-id-4 .content-section .extra-info {
  background: #fff;
  border-radius: 12px;
  padding: 12px 14px;
}

.client-page-container .layout-3.layout-id-3 .content-section .extra-info p,
.client-page-container .layout-3.layout-id-4 .content-section .extra-info p {
  color: #444;
  font-size: 0.857em;
  line-height: normal;
  margin: 0;
  padding: 0;
  text-align: left;
}

.client-page-container .layout-3.layout-id-3 .text-content p,
.client-page-container .layout-3.layout-id-4 .text-content p {
  color: #444;
  font-size: 0.857em;
  line-height: 18px;
  margin-bottom: 30px;
  padding: 0;
  text-align: left;
}

.client-page-container .layout-3.layout-id-5 .content-wrapper,
.client-page-container .layout-3.layout-id-6 .content-wrapper {
  padding: 0 20px;
}

.client-page-container .layout-3.layout-id-5 .content-wrapper .content-section,
.client-page-container .layout-3.layout-id-6 .content-wrapper .content-section {
  align-items: flex-start;
  justify-content: flex-start;
}

.client-page-container
  .layout-3.layout-id-5
  .content-wrapper
  .content-section
  h3,
.client-page-container
  .layout-3.layout-id-6
  .content-wrapper
  .content-section
  h3 {
  margin-bottom: 8px;
  text-align: left;
}

.client-page-container
  .layout-3.layout-id-5
  .content-wrapper
  .content-section
  .text-content
  p,
.client-page-container
  .layout-3.layout-id-6
  .content-wrapper
  .content-section
  .text-content
  p {
  line-height: 32px;
  margin-bottom: 18px;
  padding: 0;
  text-align: left;
}

.client-page-container
  .layout-3.layout-id-5
  .content-wrapper
  .content-section
  .list-content
  li,
.client-page-container
  .layout-3.layout-id-6
  .content-wrapper
  .content-section
  .list-content
  li {
  line-height: 24px;
  margin-bottom: 22px;
  padding-left: 44px;
  position: relative;
}

.client-page-container
  .layout-3.layout-id-5
  .content-wrapper
  .content-section
  .list-content
  li:before,
.client-page-container
  .layout-3.layout-id-6
  .content-wrapper
  .content-section
  .list-content
  li:before {
  border-radius: 1px;
  border: solid #f74;
  border-width: 0 2px 2px 0;
  content: "";
  display: block;
  height: 8px;
  left: 8px;
  position: absolute;
  top: 7px;
  width: 4px;
  z-index: 1;
  transform: rotate(45deg);
}

.client-page-container
  .layout-3.layout-id-5
  .content-wrapper
  .content-section
  .list-content
  li:after,
.client-page-container
  .layout-3.layout-id-6
  .content-wrapper
  .content-section
  .list-content
  li:after {
  background: #fff3df;
  border-radius: 50%;
  content: "";
  display: block;
  height: 22px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 22px;
}

.client-page-container
  .layout-3.layout-id-5
  .content-wrapper
  .content-section
  .list-content
  li:last-child,
.client-page-container
  .layout-3.layout-id-6
  .content-wrapper
  .content-section
  .list-content
  li:last-child {
  margin-bottom: 0;
}

.client-page-container
  .layout-3.layout-id-5
  .content-wrapper
  .content-section
  .extra-info,
.client-page-container
  .layout-3.layout-id-6
  .content-wrapper
  .content-section
  .extra-info {
  align-self: center;
  margin-top: 2px;
}

.client-page-container
  .layout-3.layout-id-5
  .content-wrapper
  .content-section
  .extra-info
  p,
.client-page-container
  .layout-3.layout-id-6
  .content-wrapper
  .content-section
  .extra-info
  p {
  color: #666;
  margin-bottom: 0;
}

.client-page-container
  .layout-3.layout-id-5
  .content-wrapper
  .content-section
  .overview-btn,
.client-page-container
  .layout-3.layout-id-6
  .content-wrapper
  .content-section
  .overview-btn {
  box-shadow: 5px 5px 20px 0 rgba(255, 119, 68, 0.3);
}

.client-page-container .layout-3 .content-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.client-page-container .layout-3 h3 {
  color: #222;
  font-family: sspBolder;
  font-size: 1.714em;
  line-height: 32px;
  margin-bottom: 10px;
  text-align: center;
}

.client-page-container .layout-3 h3 b {
  color: #f74;
}

.client-page-container .layout-3 p {
  color: #aaa;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 20px;
  margin-bottom: 20px;
  padding: 0 20px;
  text-align: center;
}

.client-page-container .layout-3 .image-section {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.client-page-container .layout-3 .image-section .secured-img {
  width: 26px;
}

.client-page-container .layout-3 .image-section .iso-img {
  margin-left: 12px;
  width: 40px;
}

.client-page-container .layout-3 .overview-image-section {
  text-align: center;
}

.client-page-container .layout-3 .overview-image-section img {
  width: 80%;
}

.client-page-container .layout-4 {
  margin: 40px auto;
  padding: 0 40px;
}

.client-page-container .layout-4 .heading {
  color: #222;
  font-family: sspBolder;
  font-size: 1.714em;
  line-height: 32px;
}

.client-page-container .layout-4 .heading b {
  color: #f74;
}

.client-page-container .layout-4 .step-items {
  margin-top: 32px;
}

.client-page-container .layout-4 .step-item {
  margin-bottom: 30px;
}

.client-page-container .layout-4 .step-item:last-child {
  margin-bottom: 0;
}

.client-page-container .layout-4 .step-item-header {
  align-items: center;
  display: flex;
  position: relative;
}

.client-page-container .layout-4 .step-title {
  font-family: sspBolder;
  font-size: 1.714em;
  line-height: 32px;
}

.client-page-container .layout-4 .step-item-oval {
  align-items: center;
  background-color: #222;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-family: sspBolder;
  font-size: 18px;
  justify-content: center;
  min-height: 24px;
  min-width: 24px;
  margin-right: 14px;
}

.client-page-container .layout-4 .step-subtitle {
  font-family: sspRegular;
  font-size: 1em;
  line-height: 22px;
  margin-top: 6px;
}

.client-page-container .layout-4.how-to-check {
  padding: 35px 32px 35px 20px;
  margin: 0;
  background-color: #fefaf7;
}

.client-page-container .layout-4.how-to-check .heading {
  font-family: sspBolder;
  font-size: 1.857em;
  line-height: 32px;
}

.client-page-container .layout-4.how-to-check .heading b {
  color: #f74;
}

.client-page-container .layout-4.how-to-check .step-items {
  margin-top: 18px;
}

.client-page-container .layout-4.how-to-check .step-subtitle {
  font-size: 1em;
  margin-top: 0;
  line-height: 20px;
  color: #222;
}

.client-page-container .layout-4.how-to-check .step-item:not(:last-child) {
  margin-bottom: 20px;
}

.client-page-container .layout-4.how-to-check .step-item {
  display: flex;
  align-items: center;
}

.client-page-container .layout-4.how-to-check .step-item:before {
  content: "";
  background-image: none;
}

.client-page-container .layout-4.how-to-check .step-item-oval {
  background: none;
  color: #666;
  line-height: 56px;
  font-size: 3.289em;
  opacity: 0.5;
}

.client-page-container .layout-4.how-to-check .step-item-oval:after {
  content: ".";
}

.client-page-container .layout-4.stepper {
  text-align: center;
  padding: 36px 65px 15px;
  margin-bottom: 0;
}

.client-page-container .layout-4.stepper .step-item {
  margin-bottom: 36px;
}

.client-page-container .layout-4.stepper .step-title {
  line-height: 26px;
  font-size: 1.286em;
  font-family: sspBold;
  margin-bottom: 14px;
}

.client-page-container .layout-4.stepper .step-subtitle {
  margin-top: 0;
  line-height: 20px;
}

.client-page-container .layout-4.stepper .heading {
  line-height: 34px;
  font-size: 1.857em;
  font-family: sspBold;
  margin-bottom: 10px;
}

.client-page-container .layout-4.stepper .sub-heading {
  line-height: 22px;
  font-size: 1.143em;
  opacity: 0.7;
}

.client-page-container .layout-4.stepper .step-items {
  margin-top: 43px;
}

.client-page-container .layout-4.stepper .step-item-oval {
  width: 54px;
  height: 54px;
  background-color: #fff;
  color: #861f41;
  font-size: 1.857em;
  font-family: sspBold;
  margin: auto auto 24px;
}

.client-page-container .layout-4.stepper .step-item-header {
  display: block;
}

.client-page-container .layout-5 {
  margin: 50px auto;
  padding: 0 50px;
}

.client-page-container .layout-5 .users-count-section {
  position: relative;
  text-align: center;
}

.client-page-container .layout-5 .bg-image {
  width: 100%;
}

.client-page-container .layout-5 .users-count-container {
  background-color: #fff;
  border-radius: 22px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 36px;
  min-width: 150px;
  overflow: hidden;
  padding: 20px;
  position: absolute;
  right: 60px;
  text-align: left;
  top: 30px;
  box-shadow: 3px 3px 12px 0 rgba(255, 119, 68, 0.3);
}

.client-page-container .layout-5 .users-count-container img {
  margin-bottom: 10px;
  width: 46px;
}

.client-page-container .layout-5 .users-count-container span {
  color: #222;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 16px;
}

.client-page-container .layout-5 .users-count-container .count {
  font-family: sspBolder;
  font-size: 2em;
  line-height: 32px;
  margin-bottom: 5px;
}

.client-page-container .layout-5 .content-section {
  margin-top: 20px;
}

.client-page-container .layout-5 h3 {
  color: #222;
  font-family: sspBolder;
  font-size: 1.714em;
  line-height: 32px;
  margin-bottom: 30px;
}

.client-page-container .layout-5 h3 b {
  color: #f74;
}

.client-page-container .layout-5 .features-list {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.client-page-container .layout-5 .features-list img {
  width: 48px;
}

.client-page-container .layout-5 .text-content {
  color: #222;
  font-family: sspBolder;
  font-size: 1em;
  line-height: 20px;
  margin-top: 5px;
}

.client-page-container .layout-6 {
  margin: 50px 0;
  background: #fb905c;
  background: linear-gradient(90deg, #fb905c 0, #df5349);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#000000",GradientType=0);
}

.client-page-container .layout-6 .content-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0 20px;
  position: relative;
}

.client-page-container .layout-6 .content-wrapper .after-img,
.client-page-container .layout-6 .content-wrapper .before-img {
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.client-page-container .layout-6 .content-wrapper .before-img {
  background-position: 100%;
  right: 64%;
}

.client-page-container .layout-6 .content-wrapper .after-img {
  background-position: 0;
  left: 64%;
}

.client-page-container .layout-6 .actionable-desc-title {
  color: #fff;
  font-family: sspBolder;
  font-size: 1em;
  line-height: 18px;
  margin-bottom: 10px;
  text-align: center;
  z-index: 1;
}

.client-page-container .layout-6 .actionable-desc-subtitle {
  color: #fff;
  font-family: sspRegular;
  font-size: 0.714em;
  line-height: 14px;
  margin-bottom: 15px;
  max-width: 175px;
  text-align: center;
  z-index: 1;
}

.client-page-container .layout-6 .actionable-desc-button {
  background-color: #fff;
  border: 1px solid #e3714d;
  color: #e3714d;
  display: inline-block;
  font-family: sspBold;
  text-align: center;
  border-radius: 25px;
  font-size: 0.714em;
  line-height: 16px;
  margin: 0 auto;
  padding: 7px 16px;
  width: auto;
  z-index: 1;
}

.client-page-container .layout-7 {
  background-position: left 25px;
  background-repeat: no-repeat;
  background-size: auto 70%;
  margin-bottom: 60px;
  margin-top: 60px;
}

.client-page-container .layout-7 h3 {
  color: #222;
  font-family: sspBolder;
  font-size: 1.714em;
  line-height: 32px;
  margin: 0 0 40px;
  text-align: center;
}

.client-page-container .layout-7 h3 b {
  color: #f74;
}

.client-page-container .layout-7 .slider-wrapper {
  margin: 0;
}

.client-page-container .layout-7 .slider-wrapper .slider-indicators {
  margin-top: 20px;
}

.client-page-container
  .layout-7
  .slider-wrapper
  .slider-indicators
  .active
  .indicator {
  background-color: #f74;
}

.client-page-container .layout-7 .slider-wrapper .slider-indicators .indicator {
  background-color: #aaa;
  border-radius: 3px;
  height: 3px;
  width: 16px;
}

.client-page-container .layout-7 .layout-slide {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.client-page-container .layout-7 .layout-slide img {
  margin-bottom: 24px;
  width: 125px;
}

.client-page-container .layout-7 .layout-slide h4 {
  color: #222;
  font-family: sspBolder;
  font-size: 1.286em;
  line-height: 24px;
  margin-bottom: 10px;
}

.client-page-container .layout-7 .layout-slide p {
  color: #000;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 20px;
  text-align: center;
  width: 280px;
}

.client-page-container .layout-8 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.client-page-container .layout-8 h3 {
  color: #222;
  font-family: sspBolder;
  font-size: 1.429em;
  line-height: 28px;
  margin: 0 auto 20px;
  max-width: 70%;
  text-align: center;
}

.client-page-container .layout-8 h3 b {
  color: #f74;
}

.client-page-container .layout-8 .sub-heading {
  font-size: 1em;
  line-height: 1.2;
  margin-bottom: 37px;
  text-align: center;
}

.client-page-container .layout-8 .list-data {
  padding: 10px;
}

.client-page-container .layout-8 .list-data .list-item {
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 0 25px 0 hsla(0, 0%, 87%, 0.5);
}

.client-page-container .layout-8 .list-data .list-item:last-child {
  margin-bottom: 0;
}

.client-page-container
  .layout-8
  .list-data
  .list-item.active
  .upper-section:after {
  transform: rotate(-135deg);
}

.client-page-container .layout-8 .list-data .list-item.active .lower-section {
  opacity: 1;
  padding-bottom: 20px;
}

.client-page-container .layout-8 .list-data .list-item .upper-section {
  cursor: pointer;
  padding: 22px 45px 22px 20px;
  position: relative;
}

.client-page-container .layout-8 .list-data .list-item .upper-section:after {
  border-bottom: 2px solid #aaa;
  border-right: 2px solid #aaa;
  content: "";
  height: 7px;
  position: absolute;
  right: 25px;
  top: 40%;
  transform-origin: 65% 65%;
  width: 7px;
  transform: rotate(45deg);
  transition: transform 0.5s ease-in-out 0s;
}

.client-page-container .layout-8 .list-data .list-item .upper-section p {
  color: #222;
  font-family: sspBold;
  font-size: 1em;
  line-height: 18px;
}

.client-page-container .layout-8 .list-data .list-item .lower-section {
  box-sizing: content-box;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0 20px;
  transition: all 0.5s ease-in-out 0s;
}

.client-page-container .layout-8 .list-data .list-item .lower-section p {
  color: #222;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 18px;
  margin-bottom: 15px;
}

.client-page-container
  .layout-8
  .list-data
  .list-item
  .lower-section
  p:last-child {
  margin-bottom: 0;
}

.client-page-container .layout-8 .btn-section {
  margin-top: 10px;
  text-align: center;
}

.client-page-container .layout-8 button {
  border-radius: 25px;
  color: #f74;
  font-family: sspBold;
  font-size: 1em;
  line-height: 20px;
  padding: 5px 15px;
  text-transform: capitalize;
  width: auto;
}

.client-page-container .layout-9 {
  background-repeat: no-repeat;
  background-size: 100% 130px;
  margin: 50px 0;
}

.client-page-container .layout-9 h3 {
  color: #fff;
  font-family: sspBolder;
  font-size: 1em;
  line-height: 20px;
  margin: 12px 0 10px;
  text-align: center;
}

.client-page-container .layout-9 h3 b {
  color: #f74;
}

.client-page-container .layout-9 .content-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.client-page-container .layout-9 .million-container,
.client-page-container .layout-9 .video-player-container {
  margin: 0 auto;
  position: relative;
  width: 80%;
}

.client-page-container .layout-9 .million-container img {
  left: -35px;
  position: absolute;
  top: -50px;
  width: 105px;
}

.client-page-container .layout-9 .video-wrapper {
  border-radius: 15px;
  font-size: 0;
  overflow: hidden;
  box-shadow: 0 5px 5px 1px rgba(0, 0, 0, 0.25);
}

.client-page-container .layout-10 {
  margin-bottom: 40px;
  margin-top: 40px;
  padding: 0 20px;
}

.client-page-container .layout-10 .expandable-info-title {
  color: #222;
  font-family: sspBolder;
  font-size: 1.714em;
  line-height: 32px;
}

.client-page-container .layout-10 .expandable-info-title b {
  color: #f74;
}

.client-page-container .layout-10 .expandable-info-para {
  color: #222;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 20px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out 0s;
}

.client-page-container .layout-10 .expandable-info-para h1 {
  display: none;
}

.client-page-container .layout-10 .expandable-info-para a {
  color: #f74;
  font-family: sspBolder;
}

.client-page-container .layout-10 .expandable-info-para b,
.client-page-container .layout-10 .expandable-info-para h2,
.client-page-container .layout-10 .expandable-info-para h3,
.client-page-container .layout-10 .expandable-info-para p {
  margin-top: 10px;
}

.client-page-container .layout-10 .expandable-info-para b,
.client-page-container .layout-10 .expandable-info-para h2,
.client-page-container .layout-10 .expandable-info-para h3 {
  font-family: sspBolder;
}

.client-page-container .layout-10 .expandable-info-button {
  border-radius: 25px;
  color: #f74;
  font-family: sspBold;
  font-size: 1em;
  height: 54px;
  line-height: 20px;
  margin-top: 20px;
}

.client-page-container .layout-11 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.client-page-container .layout-11 h3 {
  font-family: sspBolder;
  font-size: 1.714em;
  line-height: 32px;
  margin-bottom: 10px;
  padding: 0 40px;
  text-align: center;
}

.client-page-container .layout-11 h4 {
  font-family: sspBolder;
  font-size: 1.429em;
  line-height: 28px;
  margin-bottom: 10px;
  padding: 0 20px;
  text-align: center;
}

.client-page-container .layout-11 .subtitle {
  font-family: sspRegular;
  font-size: 1em;
  line-height: 20px;
  margin-bottom: 32px;
  padding: 0 40px;
  text-align: center;
}

.client-page-container .layout-11 .steps {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.client-page-container .layout-11 .step {
  flex: 1 1;
  margin-bottom: 48px;
  position: relative;
  text-align: center;
}

.client-page-container .layout-11 .step:last-child {
  margin-bottom: 0;
}

.client-page-container .layout-11 .screen {
  margin-bottom: 40px;
  width: 100%;
}

.client-page-container .layout-11 h6 {
  font-family: sspBolder;
  font-size: 1.714em;
  line-height: 32px;
  margin-bottom: 10px;
  padding: 0 40px;
}

.client-page-container .layout-11 .step-subtitle {
  font-family: sspRegular;
  font-size: 1.143em;
  line-height: 22px;
  padding: 0 40px;
}

.client-page-container .layout-11 .divider-img {
  margin-top: 25px;
  width: 55px;
}

.client-page-container .layout-11.secondary .content-wrapper {
  background-color: #ffeee8;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
}

.client-page-container .layout-11.secondary h3 {
  font-size: 1.429em;
  line-height: 24px;
  padding: 0;
  text-align: left;
}

.client-page-container .layout-11.secondary h3 b {
  color: #f74;
}

.client-page-container .layout-11.secondary h6 {
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 22px;
  margin: 0;
  padding: 0;
  text-align: left;
}

.client-page-container .layout-11.secondary .subtitle {
  color: #666;
  font-size: 0.857em;
  line-height: 18px;
  margin-bottom: 20px;
  padding: 0;
  text-align: left;
}

.client-page-container .layout-11.secondary .steps {
  display: block;
}

.client-page-container .layout-11.secondary .step {
  background: #fff;
  background: linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0.54) 100% 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 16px 16px 0;
  text-align: left;
}

.client-page-container .layout-11.secondary .step-header {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.client-page-container .layout-11.secondary .screen {
  height: auto;
  margin-bottom: 0;
  margin-right: 10px;
  width: 50px;
}

.client-page-container .layout-11.secondary .sub-steps {
  padding-bottom: 20px;
}

.client-page-container .layout-11.secondary .sub-step {
  align-items: flex-start;
  display: flex;
  margin-bottom: 10px;
}

.client-page-container .layout-11.secondary .sub-step:last-child {
  margin: 0;
}

.client-page-container .layout-11.secondary .sub-step p {
  font-size: 0.857em;
  line-height: 16px;
}

.client-page-container .layout-11.secondary .sub-step-index-oval {
  align-items: center;
  background-color: #222;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-family: sspBold;
  font-size: 0.857em;
  justify-content: center;
  min-height: 16px;
  min-width: 16px;
  margin-right: 6px;
}

.client-page-container .layout-11.secondary .desc-wrapper {
  background-color: #fff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin: 0 -16px;
  padding: 16px;
}

.client-page-container .layout-11.secondary .desc-wrapper p {
  font-size: 0.857em;
  line-height: 18px;
}

.client-page-container .layout-11.secondary .desc-wrapper p b {
  font-family: sspBolder;
}

.client-page-container .layout-12 {
  margin-bottom: 40px;
  margin-top: 40px;
  padding: 20px 5px 0 20px;
}

.client-page-container .layout-12 .recommended-blogs-title {
  font-family: sspBolder;
  font-size: 1.429em;
  line-height: 28px;
}

.client-page-container .layout-12 .recommended-blogs-title b {
  color: #f74;
}

.client-page-container .layout-12 .recommended-blogs-button {
  border: 1px solid #f74;
  border-radius: 44px;
  color: #f74;
  display: inline-block;
  font-family: sspBold;
  font-size: 1em;
  line-height: 20px;
  margin-top: 20px;
  padding: 18px;
  text-align: center;
  width: calc(100% - 20px);
}

.client-page-container .layout-12 .recommended-blogs-slider {
  margin-top: 20px;
}

.client-page-container .layout-12 .recommended-blogs-slider .slider-wrapper {
  margin: 0;
}

.client-page-container .layout-12 .recommended-blog-slide {
  color: #222;
  cursor: pointer;
}

.client-page-container .layout-12 .recommended-blog-slide img {
  border-radius: 10px;
  width: 100%;
}

.client-page-container .layout-12 .recommended-blog-slide-title {
  display: block;
  font-family: sspBolder;
  font-size: 1.143em;
  line-height: 20px;
  margin-top: 10px;
}

.client-page-container .layout-12 .recommended-blog-slide-subtitle {
  display: block;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 20px;
  margin-top: 10px;
}

.client-page-container .layout-13 {
  background-color: #372f2f;
}

.client-page-container .layout-13 .content-wrapper {
  padding: 60px 0 40px;
}

.client-page-container .layout-13 h1 {
  color: #fff;
  font-family: sspBolder;
  font-size: 1.714em;
  line-height: 32px;
  max-width: 100%;
  text-align: center;
}

.client-page-container .layout-13 h1 b {
  color: #f74;
}

.client-page-container .layout-13 p {
  color: #bea4a4;
  font-size: 1em;
  line-height: 20px;
  margin: 10px auto 70px;
  text-align: center;
  width: 75%;
}

.client-page-container .layout-13 .offers-slider {
  position: relative;
}

.client-page-container .layout-13 .slider-wrapper {
  margin: 5px 0;
}

.client-page-container .layout-13 .slide:not(.active-slide) {
  transform: scale(0.8);
}

.client-page-container .layout-13 .slide:not(.active-slide) img {
  opacity: 0.5;
}

.client-page-container .layout-13 .slider-indicators {
  margin-top: 15px;
}

.client-page-container .layout-13 .slider-indicators ul li.active .indicator {
  background-color: #fff;
}

.client-page-container .layout-13 .slider-indicators ul li .indicator {
  background-color: hsla(0, 0%, 85%, 0.5);
  height: 8px;
  width: 8px;
}

.client-page-container .layout-13 .slider .slide img {
  width: 100%;
}

.client-page-container .layout-14 {
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 0 30px;
}

.client-page-container .layout-14 .content-section {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  text-align: center;
}

.client-page-container .layout-14 .content-section img {
  margin-bottom: 20px;
  width: 100%;
}

.client-page-container .layout-14 h3 {
  font-family: sspBolder;
  font-size: 1.714em;
  line-height: 32px;
  margin-bottom: 10px;
}

.client-page-container .layout-14 h3 b {
  color: #f74;
}

.client-page-container .layout-14 p {
  color: #000;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 20px;
  margin: 0 auto;
  width: 90%;
}

.client-page-container .layout-14 .items-list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
}

.client-page-container .layout-14 .item {
  margin: 0 12px 20px;
}

.client-page-container .layout-14 .item:first-child {
  margin-left: 0;
}

.client-page-container .layout-14 .item h6 {
  color: #222;
  font-size: 1em;
  line-height: 20px;
  text-align: center;
}

.client-page-container .layout-14 .extra-info {
  align-self: flex-start;
  color: #f74;
  cursor: pointer;
  font-family: sspBold;
  font-size: 1.429em;
  margin-left: 24px;
  margin-top: 15px;
}

.client-page-container .layout-14 .image-container {
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-bottom: 5px;
  width: 48px;
  box-shadow: 0 2px 24px 0 rgba(255, 119, 68, 0.15);
}

.client-page-container .layout-14 .image-container img {
  height: 24px;
}

.client-page-container .layout-14.secondary {
  margin-bottom: 0;
  margin-top: 0;
  padding: 20px 20px 48px;
}

.client-page-container .layout-14.secondary h3 {
  font-size: 1.429em;
}

.client-page-container .layout-14.secondary p {
  color: #444;
  font-size: 0.857em;
  line-height: 18px;
  width: 100%;
}

.client-page-container .layout-14.secondary .content-section {
  text-align: left;
}

.client-page-container .layout-14.secondary .items-list {
  display: block;
}

.client-page-container .layout-14.secondary .item {
  display: flex;
  margin: 0 0 36px;
}

.client-page-container .layout-14.secondary .item:last-child {
  margin-bottom: 0;
}

.client-page-container .layout-14.secondary .item p {
  color: #666;
  line-height: 16px;
}

.client-page-container .layout-14.secondary h6 {
  color: #444;
  margin-bottom: 8px;
  text-align: left;
}

.client-page-container .layout-14.secondary h6 b {
  font-family: sspBolder;
}

.client-page-container .layout-14.secondary .image-container {
  background-color: transparent;
  border-radius: 0;
  height: 36px;
  margin: 0 12px 0 0;
  width: 36px;
}

.client-page-container .layout-14.secondary .image-container img {
  height: 36px;
  width: 36px;
}

.client-page-container .layout-15 {
  background-color: #fff;
}

.client-page-container .layout-15 .content-wrapper {
  padding: 20px 0 30px;
}

.client-page-container .layout-15 .light-theme h3 {
  color: #fff;
}

.client-page-container .layout-15 h3 {
  color: #222;
  font-family: sspBolder;
  font-size: 1.143em;
  margin-bottom: 20px;
  text-align: center;
}

.client-page-container .layout-15 .slider-wrapper {
  margin: 5px 0;
}

.client-page-container .layout-15 .slider-wrapper .slider-indicators {
  margin-top: 15px;
}

.client-page-container
  .layout-15
  .slider-wrapper
  .slider-indicators
  ul
  li.active
  .indicator {
  background-color: #f74;
}

.client-page-container
  .layout-15
  .slider-wrapper
  .slider-indicators
  ul
  li
  .indicator {
  background-color: #d8d8d8;
  height: 7px;
  width: 7px;
}

.client-page-container .layout-15 .slider .slide img {
  border-radius: 20px;
  width: 100%;
}

.client-page-container .layout-16 {
  padding: 30px 0;
}

.client-page-container .layout-16 .layout-heading h3 {
  color: #222;
  font-family: sspBolder;
  font-size: 1.429em;
  line-height: 28px;
  margin: 0 auto 20px;
  max-width: 70%;
  text-align: center;
}

.client-page-container .layout-16 .layout-heading h3 b {
  color: #f74;
}

.client-page-container .layout-16 .list-data {
  padding: 10px;
}

.client-page-container .layout-16 .list-data li {
  align-items: flex-start;
  border-radius: 8px;
  display: flex;
  margin-bottom: 10px;
  padding: 20px;
  box-shadow: 5px 5px 20px 0 rgba(34, 34, 34, 0.2);
}

.client-page-container .layout-16 .list-data li:last-child {
  margin-bottom: 0;
}

.client-page-container .layout-16 .list-data li h3 {
  color: #222;
  font-family: sspBolder;
  font-size: 1.143em;
  line-height: 20px;
  margin-bottom: 3px;
}

.client-page-container .layout-16 .list-data li p {
  color: #222;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 18px;
}

.client-page-container .layout-16 .list-data .image-section {
  margin-right: 10px;
}

.client-page-container .layout-16 .list-data .image-section img {
  width: 40px;
}

.client-page-container .layout-16 .list-btn {
  background-color: #f74;
  color: #fff;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  font-family: sspBold;
  font-size: 1.286em;
  line-height: 24px;
  padding: 17px 32px;
}

.client-page-container .layout-17 {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}

.client-page-container .layout-17 .text-content {
  margin: 0 20px;
}

.client-page-container .layout-17 h3,
.client-page-container .layout-17 h4 {
  color: #222;
  font-family: sspBolder;
  font-size: 1.429em;
  line-height: 28px;
  margin: 0 auto 20px;
  max-width: 70%;
  text-align: center;
}

.client-page-container .layout-17 h3 b,
.client-page-container .layout-17 h4 b {
  color: #f74;
}

.client-page-container .layout-17 p {
  color: #222;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.client-page-container .layout-17 p:last-child {
  margin-bottom: 0;
}

.client-page-container .layout-17 p b {
  font-family: sspBolder;
}

.client-page-container .layout-17 .image-section {
  margin: 30px 0;
  text-align: left;
}

.client-page-container .layout-17 .image-section.image-layout-1 {
  text-align: center;
}

.client-page-container .layout-17 .image-section.image-layout-1 img {
  width: 40%;
}

.client-page-container .layout-17 .image-section img {
  width: 90%;
}

.client-page-container .layout-17 .external-link {
  border: 1px solid #f74;
  border-radius: 25px;
  color: #f74;
  display: inline-block;
  font-family: sspBold;
  font-size: 1em;
  line-height: 20px;
  margin: 20px 20px 0;
  padding: 15px;
  text-align: center;
  width: calc(100% - 40px);
}

.client-page-container .layout-17.remove-padding-bottom {
  padding-bottom: 0;
}

.client-page-container .layout-17.remove-padding-top {
  padding-top: 0;
}

.client-page-container .layout-18 {
  margin-top: 20px;
  padding: 10px 0;
}

.client-page-container .layout-18 .image-section {
  text-align: center;
}

.client-page-container .layout-18 .image-section img {
  width: 90%;
}

.client-page-container .layout-18 .content-section {
  margin-bottom: 10px;
  padding: 20px;
}

.client-page-container .layout-18 h3 {
  color: #222;
  font-family: sspBolder;
  font-size: 1.429em;
  line-height: 28px;
  margin: 0 auto 20px;
  max-width: 70%;
  text-align: center;
}

.client-page-container .layout-18 h3 b {
  color: #f74;
}

.client-page-container .layout-18 p {
  color: #222;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.client-page-container .layout-18 p:last-child {
  margin-bottom: 0;
}

.client-page-container .layout-18 p b {
  font-family: sspBolder;
}

.client-page-container .layout-19 {
  margin: 30px 0;
}

.client-page-container .layout-19 .slider-wrapper .slider-section:before {
  background-color: #fff3e5;
  border-radius: 50%;
  content: "";
  height: 100px;
  position: absolute;
  right: 15%;
  top: 20%;
  width: 100px;
  z-index: -1;
  transform: scale(3.5);
}

.client-page-container .layout-19 .slider-wrapper .slider-indicators ul {
  align-items: center;
}

.client-page-container .layout-19 .slider-wrapper .slider-indicators ul li {
  margin-right: 25px;
}

.client-page-container
  .layout-19
  .slider-wrapper
  .slider-indicators
  ul
  li:last-child {
  margin-right: 0;
}

.client-page-container
  .layout-19
  .slider-wrapper
  .slider-indicators
  ul
  li.active
  .indicator {
  background-color: #f74;
  border: 1px solid #f74;
  height: 10px;
  width: 10px;
}

.client-page-container
  .layout-19
  .slider-wrapper
  .slider-indicators
  ul
  li
  .indicator {
  background-color: transparent;
  border: 1px solid #aaa;
  height: 8px;
  width: 8px;
}

.client-page-container .layout-19 .images-section {
  margin-bottom: 10px;
  position: relative;
  text-align: center;
}

.client-page-container .layout-19 .images-section img {
  width: 170px;
}

.client-page-container .layout-19 .content-section {
  padding: 20px;
  text-align: center;
}

.client-page-container .layout-19 .content-section h3 {
  color: #222;
  font-family: sspBolder;
  font-size: 1.429em;
  line-height: 28px;
  margin: 0 auto 20px;
  width: 200px;
}

.client-page-container .layout-19 .text-content p {
  color: #222;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 18px;
  margin-bottom: 15px;
}

.client-page-container .layout-19 .text-content p:last-child {
  margin-bottom: 0;
}

.client-page-container .layout-20 img {
  cursor: pointer;
  width: 100%;
}

.client-page-container .layout-21 .accountRegSteps-container {
  padding: 10px 10px 0;
}

.client-page-container .layout-21 .accountRegSteps-main-content {
  background: #ffeee8;
  border-radius: 8px;
  padding: 20px 10px;
}

.client-page-container .layout-21 .accountRegSteps-main-content h3 {
  font-family: sspBold;
  font-size: 1.429em;
  line-height: 24px;
  margin-left: 10px;
}

.client-page-container .layout-21 .accountRegSteps-main-content h3 b {
  color: #f74;
}

.client-page-container
  .layout-21
  .accountRegSteps-main-content
  .text-content
  p {
  color: #222;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 14px;
  padding: 10px 0 0;
}

.client-page-container .layout-21 .regSteps-main-item-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
}

.client-page-container
  .layout-21
  .regSteps-main-item-container
  .image-section
  img {
  height: 40px;
  width: 40px;
}

.client-page-container .layout-21 .accountRegSteps-content {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.client-page-container
  .layout-21
  .accountRegSteps-content
  .regSteps-heading-container {
  margin: 0 0 15px;
}

.client-page-container
  .layout-21
  .accountRegSteps-content
  .regSteps-heading-container
  h3 {
  font-family: sspBold;
  font-size: 0.857em;
  line-height: 16px;
  margin: 0;
}

.client-page-container
  .layout-21
  .accountRegSteps-content
  .regSteps-title-container {
  align-items: baseline;
  display: flex;
  flex-direction: row;
}

.client-page-container
  .layout-21
  .accountRegSteps-content
  .regSteps-title-container
  h3 {
  color: #f6a78b;
  font-size: 1.714em;
  line-height: normal;
}

.client-page-container
  .layout-21
  .accountRegSteps-content
  .regSteps-title-container
  p {
  font-family: sspBold;
  font-size: 1em;
  line-height: normal;
  margin-left: 2px;
}

.client-page-container
  .layout-21
  .accountRegSteps-content.registration-bg-white {
  background: #fff;
  border-radius: 8px;
  padding: 15px 10px;
}

.client-page-container
  .layout-21
  .accountRegSteps-content.registration-bg-white
  .regSteps-title-container {
  align-items: center;
}

.client-page-container
  .layout-21
  .accountRegSteps-content.registration-bg-white
  .regSteps-title-container
  h3 {
  color: #222;
  font-family: sspBold;
  font-size: 0.857em;
  line-height: 10px;
  margin-left: 5px;
}

.client-page-container
  .layout-21
  .accountRegSteps-content.registration-bg-white
  .regSteps-title-container
  p {
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 10px;
  margin-left: 5px;
}

.client-page-container
  .layout-21
  .accountRegSteps-content.registration-bg-white
  .regSteps-title-count-wrapper {
  background-color: #222;
  border-radius: 50%;
  height: 16px;
  text-align: center;
  width: 16px;
  z-index: 0;
}

.client-page-container
  .layout-21
  .accountRegSteps-content.registration-bg-white
  .regSteps-title-count-wrapper
  h3 {
  color: #fff;
  font-size: 0.857em;
  line-height: normal;
  margin: 0;
}

.client-page-container
  .layout-21
  .accountRegSteps-content.registration-bg-white
  .innerSteps-container {
  margin: 25px 0 10px;
  position: relative;
}

.client-page-container
  .layout-21
  .accountRegSteps-content.registration-bg-white
  .innerSteps-container:before {
  background-color: rgba(255, 201, 180, 0.44);
  bottom: 10px;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 10px;
  width: 18px;
}

.client-page-container
  .layout-21
  .accountRegSteps-content.registration-bg-white
  .innerSteps-container
  .regSteps-main-item-container {
  margin: 15px 0;
}

.client-page-container
  .layout-21
  .accountRegSteps-content.registration-bg-white
  .innerSteps-container
  .regSteps-main-item-container:first-child {
  margin: 5px 0 15px;
}

.client-page-container
  .layout-21
  .accountRegSteps-content.registration-bg-white
  .innerSteps-container
  .regSteps-main-item-container:last-child {
  margin: 15px 0 5px;
}

.client-page-container
  .layout-21
  .accountRegSteps-content.registration-bg-white
  .innerSteps-container
  .regSteps-title-count-wrapper {
  background: #fff;
  border: 1px solid #f6a78b;
  height: 18px;
  min-height: 18px;
  min-width: 18px;
  width: 18px;
}

.client-page-container
  .layout-21
  .accountRegSteps-content.registration-bg-white
  .innerSteps-container
  .regSteps-title-count-wrapper
  h3 {
  color: #894242;
}

.client-page-container .why-section {
  margin: auto;
  padding: 30px 20px 22px;
}

.client-page-container .why-section .section-img {
  border-radius: 20px;
  box-shadow: 15px 15px 0 0 #fef8f5;
  height: 100%;
  margin-right: 66px;
  max-height: 521px;
  max-width: 430px;
}

.client-page-container .why-section .sub-heading {
  color: #444;
  font-size: 1.143em;
  line-height: 22px;
}

.client-page-container .why-section h2 {
  font-family: sspBolder;
  font-size: 1.429em;
  line-height: 1.4;
  margin-bottom: 30px;
}

.client-page-container .why-section h2 span {
  color: #f36f40;
}

.client-page-container .why-section .icon {
  height: 100%;
  margin-right: 17px;
  margin-top: 9px;
  width: 30px;
}

.client-page-container .why-section .points {
  line-height: 1.57;
}

.client-page-container .why-section .points h5 {
  font-family: sspBold;
  font-size: 1.143em;
}

.client-page-container .why-section .points p {
  color: #444;
  font-size: 1em;
  margin-bottom: 28px;
}

.client-page-container .why-section ul li {
  display: flex;
}

.client-page-container .why-section ul li:last-child p {
  margin-bottom: 0;
}

.client-page-container .benefit-section {
  flex-direction: column;
  padding: 28px 20px 14px;
}

.client-page-container .benefit-section .content .desc {
  color: #666;
  font-size: 1em;
  line-height: 1.71;
  margin-bottom: 18px;
  text-align: center;
}

.client-page-container .benefit-section .sup-heading {
  color: #aaa;
  font-family: sspBold;
  font-size: 1.286em;
  line-height: 1.67;
}

.client-page-container .benefit-section h3 {
  font-family: sspBolder;
  font-size: 1.429em;
  line-height: 1.4;
  margin: 0 auto 18px;
  max-width: 210px;
  text-align: center;
}

.client-page-container .benefit-section h3 span {
  color: #f74;
}

.client-page-container .benefit-section .btn {
  background-color: #f74;
  color: #fff;
  display: inline-block;
  font-family: sspBold;
  text-align: center;
  border-radius: 10px;
  font-size: 1.143em;
  padding: 19px 32px;
  width: 241px;
}

.client-page-container .benefit-section .card {
  border: 1px solid #ebebeb;
  border-radius: 10px;
  margin-bottom: 12px;
  padding: 24px 48px;
  text-align: center;
}

.client-page-container .benefit-section .card img {
  height: 100%;
  margin-bottom: 20px;
  width: 60px;
}

.client-page-container .benefit-section .card h6 {
  font-family: sspBolder;
  font-size: 1.143em;
  line-height: 1.63;
  margin-bottom: 6px;
}

.client-page-container .benefit-section .card .info {
  color: #666;
  font-size: 1em;
  line-height: 1.57;
}

.client-page-container .layout-22 {
  padding: 24px 20px 30px;
}

.client-page-container .layout-22 h3 {
  font-family: sspBolder;
  font-size: 1.571em;
  margin-bottom: 6px;
}

.client-page-container .layout-22 h3 b {
  color: #f74;
}

.client-page-container .layout-22 p {
  color: #444;
  font-size: 1em;
  line-height: 18px;
}

.client-page-container .layout-22 .cards-section {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 18px;
}

.client-page-container .layout-22 .card-images {
  display: flex;
  justify-content: space-between;
}

.client-page-container .layout-22 .primary-image {
  height: 30px;
  margin-bottom: 14px;
  max-width: 100%;
}

.client-page-container .layout-22 .secondary-image {
  height: 85px;
  margin: -22px -20px 14px 0;
  max-width: 100%;
}

.client-page-container .layout-22 .card-item {
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 18px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.client-page-container .layout-22 h6 {
  color: #3ea913;
  font-family: sspBolder;
  font-size: 1.143em;
  line-height: 22px;
  margin-bottom: 6px;
}

.client-page-container .layout-22 span {
  color: #666;
  font-size: 1em;
  line-height: 18px;
}

.client-page-container .layout-22 span b {
  font-family: sspBolder;
}

.client-page-container .layout-22 .grocery-item-card-btn {
  background-color: #fff;
  border: 1px solid #e3714d;
  color: #e3714d;
  display: inline-block;
  font-family: sspBold;
  text-align: center;
  border-radius: 18px;
  font-size: 0.857em;
  margin-top: 16px;
  padding: 8px 16px;
  width: 100%;
}

.client-page-container .partners-section {
  background-color: #fff0eb;
}

.client-page-container .partners-section .content-wrapper {
  padding: 50px 30px;
}

.client-page-container .partners-section .content-wrapper h3 {
  color: #222;
  font-family: sspBolder;
  font-size: 1.714em;
  line-height: 32px;
}

.client-page-container .partners-section .content-wrapper h3 b {
  color: #f74;
}

.client-page-container
  .partners-section
  .content-wrapper
  .partners-container
  .partner {
  margin-top: 30px;
}

.client-page-container
  .partners-section
  .content-wrapper
  .partners-container
  .partner
  .partner-heading {
  color: #192e1e;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 18px;
  margin-bottom: 20px;
}

.client-page-container
  .partners-section
  .content-wrapper
  .partners-container
  .partner
  .partners-list {
  align-items: center;
  display: grid;
  grid-gap: 30px 0;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
}

.client-page-container .card-layout .content-wrapper {
  padding: 30px;
}

.client-page-container .card-layout .content-wrapper h2 {
  color: #222;
  font-family: sspBolder;
  font-size: 1.714em;
  line-height: 32px;
  margin-bottom: 8px;
}

.client-page-container .card-layout .content-wrapper h2 span {
  color: #f74;
}

.client-page-container .card-layout .content-wrapper .subtitle {
  color: #aaa;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 20px;
  margin-bottom: 24px;
  padding: 0 16px 0 0;
}

.client-page-container .card-layout .content-wrapper .card-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.client-page-container
  .card-layout
  .content-wrapper
  .card-section
  .card-body:not(:last-child) {
  margin-bottom: 23px;
}

.client-page-container .card-layout .content-wrapper .card-body {
  border-radius: 25px;
  padding: 30px;
  position: relative;
  width: 100%;
}

.client-page-container .card-layout .content-wrapper .card-body .title {
  color: #222;
  font-family: sspBolder;
  font-size: 1.714em;
  line-height: 26px;
  margin: 10px 0 20px 5px;
}

.client-page-container .card-layout .content-wrapper .card-body .desc {
  color: #222;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 20px;
  margin: 17px 10px 0 5px;
}

.client-page-container .footer-btn {
  background-color: #fff;
  bottom: 0;
  left: 0;
  padding: 15px;
  position: fixed;
  right: 0;
  z-index: 2;
  box-shadow: 5px 5px 20px 0 rgba(255, 119, 68, 0.3);
}

.client-page-container .footer-btn p {
  color: #666;
  font-family: sspBold;
  font-size: 1em;
  margin: 0 20px 10px;
  text-align: center;
}

.client-page-container .footer-btn p.btn-underlining-text {
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 18px;
  margin: 12px 0 0;
}

.client-page-container .footer-btn p.btn-underlining-text a {
  background-color: unset;
  border-radius: 0;
  display: unset;
  font-size: unset;
  line-height: unset;
  padding: unset;
  text-align: unset;
  width: unset;
  color: #f74;
  cursor: pointer;
  font-family: sspBolder;
}

.client-page-container .footer-btn a {
  background-color: #f74;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 20px;
  padding: 17px;
  text-align: center;
  width: 100%;
}

.client-page-container .banner-section {
  text-align: center;
}

.client-page-container .banner-section.vcc-banner-1 {
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  margin: 0 10px 24px;
  padding-bottom: 40px;
}

.client-page-container .banner-section.vcc-banner-1 .banner-content h1 {
  line-height: 28px;
  font-size: 1.714em;
  font-family: sspBold;
  max-width: 224px;
  margin-bottom: 52px;
}

.client-page-container
  .banner-section.vcc-banner-1
  .banner-content
  .section-icon {
  width: 177px;
  margin: 40px 0 24px 40px;
}

.client-page-container .banner-section.vcc-banner-1 .banner-content p {
  display: none;
}

.client-page-container .banner-section.vcc-banner-1 .vcc-card-image {
  width: 100%;
}

.client-page-container .banner-section.vcc-banner-2 {
  margin: 0 20px 50px;
}

.client-page-container .banner-section.vcc-banner-2 .vcc-card-image {
  width: 100%;
}

.client-page-container .banner-section.vcc-banner-3 {
  margin-bottom: 75px;
  padding: 40px;
}

.client-page-container .banner-section.vcc-banner-3 img {
  margin-top: 25px;
  width: 200px;
}

.client-page-container .banner-section.vcc-banner-3 .banner-content {
  text-align: left;
}

.client-page-container .banner-section.vcc-banner-3 h1 {
  background: linear-gradient(137.54deg, #ffa62e 13.76%, #ea4d2c 79.55%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: sspBolder;
  font-size: 3.428em;
  line-height: 56px;
  margin-bottom: 14px;
}

.client-page-container .banner-section.vcc-banner-3 h2 {
  margin-bottom: 24px;
  line-height: 25px;
  font-size: 1.429em;
  width: 170px;
}

.client-page-container .banner-section.vcc-banner-3 p {
  font-family: sspRegular;
  font-size: 1.429em;
  line-height: 26px;
  width: 60%;
}

.client-page-container .vcc-features-wrapper {
  margin-bottom: 30px;
  padding: 0 20px 18px;
}

.client-page-container .vcc-features-wrapper.dark-theme {
  padding: 50px 40px 70px;
  background: #000;
  margin-bottom: 0;
}

.client-page-container
  .vcc-features-wrapper.dark-theme
  .vcc-features
  .feature-card
  .feature-heading {
  color: #fff;
}

.client-page-container
  .vcc-features-wrapper.dark-theme
  .vcc-features
  .feature-card
  .feature-sub-heading {
  color: #fff;
  opacity: 0.6;
}

.client-page-container
  .vcc-features-wrapper.dark-theme
  .vcc-features
  .feature-card
  img {
  width: 24px;
  height: auto;
}

.client-page-container
  .vcc-features-wrapper.dark-theme
  .vcc-features
  .feature-card:nth-child(3)
  img {
  margin-right: 25px;
}

.client-page-container
  .vcc-features-wrapper.light-theme
  .feature-card:nth-child(2)
  img {
  width: 22px;
  height: 24px;
  margin-right: 18px;
}

.client-page-container .vcc-features-wrapper .vcc-features .feature-card {
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
}

.client-page-container
  .vcc-features-wrapper
  .vcc-features
  .feature-card:last-child {
  margin-bottom: 0;
}

.client-page-container .vcc-features-wrapper .vcc-features .feature-card img {
  margin-right: 16px;
}

.client-page-container
  .vcc-features-wrapper
  .vcc-features
  .feature-card
  .feature-heading {
  font-size: 1.286em;
  line-height: 20px;
  font-family: sspBold;
  margin-bottom: 4px;
}

.client-page-container
  .vcc-features-wrapper
  .vcc-features
  .feature-card
  .feature-sub-heading {
  color: #666;
  font-size: 1em;
  line-height: 20px;
}

.client-page-container .vcc-image-section .brand-icons-wrapper .img-wrapper {
  background: #fff;
  border: 1px solid #dfdede;
  border-radius: 20px;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 0 15px 15px 0;
}

.client-page-container
  .vcc-image-section
  .brand-icons-wrapper
  .img-wrapper
  img {
  max-width: 100%;
  max-height: 100%;
}

.client-page-container .vcc-image-section.template_scroll {
  border-radius: 25px;
  padding: 36px 25px 25px 30px;
  margin: 0 20px 20px;
  overflow: hidden;
  box-shadow: 0 8px 35px 0 rgba(0, 0, 0, 0.1);
}

.client-page-container .vcc-image-section.template_scroll .heading {
  background: linear-gradient(98.92deg, #0cbdfd 7.26%, #15f42c 73.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  line-height: 54px;
  font-size: 3.143em;
  font-family: sspBolder;
  margin-bottom: 18px;
}

.client-page-container
  .vcc-image-section.template_scroll
  .marquee
  .marquee-content {
  display: flex;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  to {
    transform: translateX(-680px);
  }
}

.client-page-container .vcc-image-section.template_scroll .marquee .animate {
  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 10s;
  animation-fill-mode: none;
  animation-iteration-count: infinite;
  animation-name: marquee;
  animation-timing-function: linear;
}

.client-page-container
  .vcc-image-section.template_scroll
  .marquee
  .animate:hover {
  animation-play-state: paused;
}

.client-page-container .vcc-image-section.template_scroll .sub-heading {
  font-size: 1.429em;
  line-height: 24px;
  margin-bottom: 35px;
}

.client-page-container .vcc-image-section.template_scroll .brand-icons-wrapper {
  display: grid;
  grid-template-columns: repeat(8, 85px);
}

.client-page-container .vcc-image-section.template_fixed {
  padding: 0 25px 0 30px;
  margin-bottom: 35px;
}

.client-page-container .vcc-image-section.template_fixed .heading,
.client-page-container .vcc-image-section.template_fixed .sub-heading {
  font-size: 3.143em;
  font-family: sspBolder;
}

.client-page-container .vcc-image-section.template_fixed .heading {
  background: linear-gradient(93.4deg, #f16122 6.72%, #ff2a0d 69.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.client-page-container .vcc-image-section.template_fixed .sub-heading {
  margin-bottom: 29px;
}

.client-page-container .vcc-image-section.template_fixed .brand-icons-wrapper {
  display: flex;
}

.client-page-container
  .vcc-image-section.template_fixed
  .brand-icons-wrapper
  .img-wrapper {
  width: 65px;
  height: 65px;
}

.client-page-container .cta-section {
  display: flex;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  padding: 20px 20px 20px 24px;
  position: fixed;
  right: 0;
  z-index: 2;
  background: #fff;
  border-top: 1px solid #efefef;
}

.client-page-container .cta-section .heading {
  font-size: 1em;
  line-height: 22px;
  color: #000;
}

.client-page-container .cta-section .sub-heading {
  font-size: 1.714em;
  line-height: 22px;
  font-family: sspBolder;
  color: #0ac88f;
}

.client-page-container .cta-section .cta-button {
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: sspBold;
  color: #fff;
  width: 150px;
  font-size: 1.143em;
  line-height: 22px;
  text-transform: uppercase;
  border-radius: 10px;
  background: #f74;
  padding: 14px;
  height: auto;
}

.client-page-container .benefits-main-container {
  background-color: #fff;
  padding: 40px 20px;
}

.client-page-container .benefits-main-container .upper-container h1 {
  color: #222;
  display: inline;
  font-family: sspBolder;
  font-size: 1.857em;
  line-height: 32px;
}

.client-page-container .benefits-main-container .upper-container h1 b {
  color: #f74;
}

.client-page-container .benefits-main-container .upper-container img {
  margin-left: 5px;
  vertical-align: bottom;
  width: 31px;
}

.client-page-container .benefits-main-container .upper-container p {
  color: #666;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 22px;
  margin: 5px 20px 0 0;
}

.client-page-container .benefits-main-container .lower-container {
  margin-top: 25px;
}

.client-page-container
  .benefits-main-container
  .lower-container
  .benefit-wrapper {
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.04);
  display: flex;
  margin-bottom: 10px;
  padding: 25px 20px;
}

.client-page-container
  .benefits-main-container
  .lower-container
  .benefit-wrapper
  .image-container
  img {
  height: auto;
  width: 24px;
}

.client-page-container
  .benefits-main-container
  .lower-container
  .benefit-wrapper
  .benefit-details {
  margin: 0 0 0 15px;
}

.client-page-container
  .benefits-main-container
  .lower-container
  .benefit-wrapper
  .benefit-details
  h3 {
  color: #222;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 20px;
}

.client-page-container
  .benefits-main-container
  .lower-container
  .benefit-wrapper
  .benefit-details
  p {
  color: #222;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 20px;
  margin: 5px 20px 0 0;
  opacity: 0.6;
}

.client-page-container
  .benefits-main-container.layoutId-2
  .lower-container
  .benefit-wrapper {
  padding: 18px;
}

.client-page-container
  .benefits-main-container.layoutId-2
  .lower-container
  .benefit-wrapper
  .benefit-details {
  margin-top: 5px;
}

.client-page-container
  .benefits-main-container.layoutId-2
  .lower-container
  .benefit-wrapper
  .benefit-details
  p {
  margin: 6px 0 0;
}

.client-page-container
  .benefits-main-container.layoutId-2
  .lower-container
  .benefit-wrapper
  .benefit-details
  h3 {
  line-height: 22px;
}

.client-page-container
  .benefits-main-container.layoutId-2
  .lower-container
  .benefit-wrapper
  .image-container
  img {
  height: 32px;
  width: 32px;
}

.client-page-container .benefits-main-container.layoutId-3 .upper-container {
  text-align: center;
}

.client-page-container .benefits-main-container.layoutId-3 .upper-container h3 {
  font-family: sspBold;
  font-size: 1.857em;
  line-height: 34px;
  margin-bottom: 10px;
}

.client-page-container .benefits-main-container.layoutId-3 .upper-container p {
  font-size: 1.143em;
  line-height: 22px;
  margin: 0 0 36px;
}

.client-page-container
  .benefits-main-container.layoutId-3
  .lower-container
  .benefit-wrapper {
  border: none;
  border-radius: 15px;
  box-shadow: none;
  display: block;
  margin-bottom: 20px;
  padding-top: 12px;
  text-align: center;
}

.client-page-container
  .benefits-main-container.layoutId-3
  .lower-container
  .benefit-wrapper
  .image-container {
  margin-bottom: 14px;
}

.client-page-container
  .benefits-main-container.layoutId-3
  .lower-container
  .benefit-wrapper
  .image-container
  img {
  height: 45px;
  width: 45px;
}

.client-page-container
  .benefits-main-container.layoutId-3
  .lower-container
  .benefit-wrapper
  .benefit-details
  h3 {
  line-height: 18px;
  margin-bottom: 14px;
}

.client-page-container
  .benefits-main-container.layoutId-3
  .lower-container
  .benefit-wrapper
  .benefit-details
  p {
  margin: 5px auto 0;
  opacity: 1;
  width: 86%;
}

.client-page-container .single-banner {
  background-color: #fff;
  padding: 20px 30px 30px;
}

.client-page-container .single-banner .upper-section {
  display: flex;
  justify-content: center;
}

.client-page-container .single-banner .heading-text h4 {
  color: #222;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 18px;
  margin-bottom: 3px;
}

.client-page-container .single-banner .heading-text .banner-text {
  color: #222;
  font-family: sspBolder;
  font-size: 2.571em;
  line-height: 38px;
  margin: 0 20px 30px 0;
}

.client-page-container .single-banner .heading-text .banner-text h1 {
  display: inline;
}

.client-page-container .single-banner .heading-text .banner-text span {
  color: #f16122;
}

.client-page-container .single-banner .heading-text .banner-text img {
  margin-left: 5px;
  vertical-align: bottom;
  width: 29px;
}

.client-page-container .single-banner .feature-text li {
  display: flex;
  margin-bottom: 15px;
}

.client-page-container .single-banner .feature-text li:last-child {
  margin-bottom: 0;
}

.client-page-container .single-banner .feature-text li img {
  margin-right: 20px;
  width: 20px;
}

.client-page-container .single-banner .feature-text li p {
  color: #222;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 18px;
}

.client-page-container .single-banner .banner-img {
  margin-top: 25px;
}

.client-page-container .single-banner .banner-img img {
  width: 100%;
}

.client-page-container .single-banner .images-section {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.client-page-container .single-banner .images-section .secure-img {
  width: 35px;
}

.client-page-container .single-banner .images-section .certificate-img {
  width: 70px;
}

.client-page-container .single-banner.layoutId-2,
.client-page-container .single-banner.layoutId-3 {
  padding: 40px 0 0;
  background: #fdece1;
  background: linear-gradient(274.55deg, #fdece1 7.36%, #eef4e8 92.64%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
}

.client-page-container .single-banner.layoutId-2 .image-section,
.client-page-container .single-banner.layoutId-2 .images-section,
.client-page-container .single-banner.layoutId-3 .image-section,
.client-page-container .single-banner.layoutId-3 .images-section {
  display: none;
}

.client-page-container .single-banner.layoutId-2 .upper-section,
.client-page-container .single-banner.layoutId-3 .upper-section {
  display: block;
}

.client-page-container .single-banner.layoutId-2 .upper-section .left-side,
.client-page-container .single-banner.layoutId-3 .upper-section .left-side {
  padding-left: 20px;
  padding-right: 20px;
}

.client-page-container
  .single-banner.layoutId-2
  .upper-section
  .section-img
  img,
.client-page-container
  .single-banner.layoutId-3
  .upper-section
  .section-img
  img {
  max-width: 100%;
}

.client-page-container
  .single-banner.layoutId-2
  .upper-section.with-form
  .left-side,
.client-page-container
  .single-banner.layoutId-2
  .upper-section.with-form
  .section-img,
.client-page-container
  .single-banner.layoutId-3
  .upper-section.with-form
  .left-side,
.client-page-container
  .single-banner.layoutId-3
  .upper-section.with-form
  .section-img {
  text-align: center;
}

.client-page-container
  .single-banner.layoutId-2
  .upper-section.with-form
  .section-img
  img,
.client-page-container
  .single-banner.layoutId-3
  .upper-section.with-form
  .section-img
  img {
  margin-top: 10px;
  width: 232px;
}

.client-page-container
  .single-banner.layoutId-2
  .upper-section.with-form
  .heading-text,
.client-page-container
  .single-banner.layoutId-3
  .upper-section.with-form
  .heading-text {
  padding: 0 30px;
}

.client-page-container
  .single-banner.layoutId-2
  .upper-section.with-form
  .heading-text
  .banner-text,
.client-page-container
  .single-banner.layoutId-3
  .upper-section.with-form
  .heading-text
  .banner-text {
  color: #fff;
  font-family: sspBold;
  font-size: 2.143em;
  line-height: 34px;
  margin-bottom: 20px;
}

.client-page-container
  .single-banner.layoutId-2
  .upper-section.with-form
  .cta-btn,
.client-page-container
  .single-banner.layoutId-3
  .upper-section.with-form
  .cta-btn {
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  height: 50px;
  margin: auto;
  padding: 15px 22px;
  width: 118px;
}

.client-page-container
  .single-banner.layoutId-2
  .upper-section.with-form
  .cta-btn
  span,
.client-page-container
  .single-banner.layoutId-3
  .upper-section.with-form
  .cta-btn
  span {
  color: #f74;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 20px;
}

.client-page-container .single-banner.layoutId-2 .heading-text .banner-text,
.client-page-container .single-banner.layoutId-3 .heading-text .banner-text {
  font-size: 2.143em;
  line-height: 36px;
  margin: 0 0 20px;
}

.client-page-container
  .single-banner.layoutId-2
  .heading-text
  .banner-text
  span,
.client-page-container
  .single-banner.layoutId-3
  .heading-text
  .banner-text
  span {
  color: #f74;
}

.client-page-container
  .single-banner.layoutId-2
  .heading-text
  .banner-paragraph,
.client-page-container
  .single-banner.layoutId-3
  .heading-text
  .banner-paragraph {
  color: #222;
  font-size: 1em;
  line-height: 20px;
  margin-bottom: 20px;
}

.client-page-container .single-banner.layoutId-2 .feature-text,
.client-page-container .single-banner.layoutId-3 .feature-text {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 30px;
}

.client-page-container .single-banner.layoutId-2 .feature-text li,
.client-page-container .single-banner.layoutId-3 .feature-text li {
  align-items: center;
  margin-bottom: 20px;
}

.client-page-container .single-banner.layoutId-2 .feature-text li img,
.client-page-container .single-banner.layoutId-3 .feature-text li img {
  height: 17px;
  margin-right: 10px;
  width: 17px;
}

.client-page-container .single-banner.layoutId-2 .feature-text li p,
.client-page-container .single-banner.layoutId-3 .feature-text li p {
  line-height: 20px;
}

.client-page-container .single-banner.layoutId-3 {
  background-repeat: no-repeat;
  background-size: cover;
}

.client-page-container .single-banner.tips-banner {
  padding: 32px 20px;
}

.client-page-container .single-banner.tips-banner .heading-text {
  margin-bottom: 28px;
}

.client-page-container .single-banner.tips-banner .heading-text .banner-text {
  font-size: 1.857em;
  line-height: 32px;
  margin-bottom: 8px;
}

.client-page-container
  .single-banner.tips-banner
  .heading-text
  .banner-paragraph {
  color: #666;
  font-size: 1.143em;
  line-height: 22px;
  margin-bottom: 27px;
}

.client-page-container .single-banner.tips-banner .feature-text {
  display: block;
}

.client-page-container .single-banner.tips-banner .feature-text li {
  margin-bottom: 14px;
}

.client-page-container .single-banner.tips-banner .feature-text li img {
  height: 24px;
  width: 24px;
}

.client-page-container .single-banner.tips-banner .upper-section {
  display: block;
}

.client-page-container .single-banner.tips-banner .upper-section .left-side {
  padding: 0;
}

.client-page-container .single-banner.tips-banner .section-img img {
  height: 260px;
  margin: 0 4px;
  max-width: 375px;
}

.client-page-container .single-banner.tips-banner.layoutId-2 .section-img {
  flex: unset;
}

.client-page-container .faq .heading h3,
.client-page-container .faq2 .heading h3 {
  color: #222;
  font-family: sspBolder;
  font-size: 1.857em;
  line-height: 32px;
}

.client-page-container .faq .heading h3 b,
.client-page-container .faq2 .heading h3 b {
  color: #f16122;
}

.client-page-container .faq .heading h3 img,
.client-page-container .faq2 .heading h3 img {
  height: 32px;
  vertical-align: bottom;
}

.client-page-container .faq .content-wrapper .heading span,
.client-page-container .faq2 .content-wrapper .heading span {
  margin-right: 11px;
}

.client-page-container .faq .content-wrapper .sub-heading,
.client-page-container .faq2 .content-wrapper .sub-heading {
  color: #666;
  font-size: 1em;
  line-height: 22px;
  margin-top: 8px;
}

.client-page-container .faq .content-wrapper .list-data,
.client-page-container .faq2 .content-wrapper .list-data {
  margin-top: 24px;
}

.client-page-container .faq .content-wrapper .list-data .list-item,
.client-page-container .faq2 .content-wrapper .list-data .list-item {
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.04);
}

.client-page-container .faq .content-wrapper .list-data .list-item:last-child,
.client-page-container .faq2 .content-wrapper .list-data .list-item:last-child {
  margin-bottom: 0;
}

.client-page-container
  .faq
  .content-wrapper
  .list-data
  .list-item
  .upper-section,
.client-page-container
  .faq2
  .content-wrapper
  .list-data
  .list-item
  .upper-section {
  cursor: pointer;
  padding: 22px 45px 22px 20px;
  position: relative;
}

.client-page-container
  .faq
  .content-wrapper
  .list-data
  .list-item
  .upper-section:after,
.client-page-container
  .faq2
  .content-wrapper
  .list-data
  .list-item
  .upper-section:after {
  border-bottom: 2px solid #222;
  border-right: 2px solid #222;
  content: "";
  height: 7px;
  position: absolute;
  right: 25px;
  top: 40%;
  transform-origin: 65% 65%;
  width: 7px;
  transform: rotate(45deg);
  transition: transform 0.5s ease-in-out 0s;
}

.client-page-container
  .faq
  .content-wrapper
  .list-data
  .list-item
  .upper-section
  p,
.client-page-container
  .faq2
  .content-wrapper
  .list-data
  .list-item
  .upper-section
  p {
  color: #222;
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 20px;
  padding-right: 15px;
}

.client-page-container
  .faq
  .content-wrapper
  .list-data
  .list-item
  .lower-section,
.client-page-container
  .faq2
  .content-wrapper
  .list-data
  .list-item
  .lower-section {
  box-sizing: content-box;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0 24px;
  transition: all 0.5s ease-in-out 0s;
}

.client-page-container
  .faq
  .content-wrapper
  .list-data
  .list-item
  .lower-section
  p,
.client-page-container
  .faq2
  .content-wrapper
  .list-data
  .list-item
  .lower-section
  p {
  color: #000;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 22px;
  opacity: 0.6;
}

.client-page-container
  .faq
  .content-wrapper
  .list-data
  .list-item
  .lower-section
  h5,
.client-page-container
  .faq2
  .content-wrapper
  .list-data
  .list-item
  .lower-section
  h5 {
  color: #222;
  font-family: sspBold;
  font-size: 1em;
  line-height: 20px;
  margin-bottom: 5px;
}

.client-page-container .faq .content-wrapper .list-data .active .upper-section,
.client-page-container
  .faq2
  .content-wrapper
  .list-data
  .active
  .upper-section {
  padding-bottom: 14px;
}

.client-page-container
  .faq
  .content-wrapper
  .list-data
  .active
  .upper-section:after,
.client-page-container
  .faq2
  .content-wrapper
  .list-data
  .active
  .upper-section:after {
  transform: rotate(-135deg);
}

.client-page-container .faq .content-wrapper .list-data .active .lower-section,
.client-page-container
  .faq2
  .content-wrapper
  .list-data
  .active
  .lower-section {
  opacity: 1;
  padding-bottom: 20px;
}

.client-page-container .faq .content-wrapper .btn-section,
.client-page-container .faq2 .content-wrapper .btn-section {
  margin-top: 34px;
  text-align: center;
}

.client-page-container .faq .content-wrapper button,
.client-page-container .faq2 .content-wrapper button {
  border: 1px solid #f16122;
  border-radius: 10px;
  color: #f16122;
  font-family: sspBold;
  font-size: 1em;
  line-height: 30px;
  padding: 5px 27px;
  text-transform: uppercase;
  width: auto;
}

.client-page-container .faq.section-wrapper,
.client-page-container .faq2.section-wrapper {
  background-color: #fefaf7;
  padding: 55px 20px;
}

.client-page-container .faq.layoutId-2,
.client-page-container .faq.layoutId-3,
.client-page-container .faq2.layoutId-2,
.client-page-container .faq2.layoutId-3 {
  padding: 42px 20px 50px;
}

.client-page-container .faq.layoutId-2 .list-data .list-item,
.client-page-container .faq.layoutId-3 .list-data .list-item,
.client-page-container .faq2.layoutId-2 .list-data .list-item,
.client-page-container .faq2.layoutId-3 .list-data .list-item {
  overflow: hidden;
}

.client-page-container .faq.layoutId-2 .list-data .list-item .upper-section,
.client-page-container .faq.layoutId-3 .list-data .list-item .upper-section,
.client-page-container .faq2.layoutId-2 .list-data .list-item .upper-section,
.client-page-container .faq2.layoutId-3 .list-data .list-item .upper-section {
  padding: 18px 20px;
}

.client-page-container .faq.layoutId-2 .list-data .list-item .lower-section,
.client-page-container .faq.layoutId-3 .list-data .list-item .lower-section,
.client-page-container .faq2.layoutId-2 .list-data .list-item .lower-section,
.client-page-container .faq2.layoutId-3 .list-data .list-item .lower-section {
  padding: 0 20px;
}

.client-page-container
  .faq.layoutId-2
  .list-data
  .list-item
  .lower-section
  ul
  li,
.client-page-container
  .faq.layoutId-3
  .list-data
  .list-item
  .lower-section
  ul
  li,
.client-page-container
  .faq2.layoutId-2
  .list-data
  .list-item
  .lower-section
  ul
  li,
.client-page-container
  .faq2.layoutId-3
  .list-data
  .list-item
  .lower-section
  ul
  li {
  color: #666;
  font-size: 1em;
  line-height: 20px;
  margin-bottom: 14px;
  padding-left: 28px;
  position: relative;
}

.client-page-container
  .faq.layoutId-2
  .list-data
  .list-item
  .lower-section
  ul
  li:before,
.client-page-container
  .faq.layoutId-3
  .list-data
  .list-item
  .lower-section
  ul
  li:before,
.client-page-container
  .faq2.layoutId-2
  .list-data
  .list-item
  .lower-section
  ul
  li:before,
.client-page-container
  .faq2.layoutId-3
  .list-data
  .list-item
  .lower-section
  ul
  li:before {
  border-radius: 1px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  content: "";
  display: block;
  height: 8px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 4px;
  z-index: 1;
  transform: rotate(45deg);
}

.client-page-container
  .faq.layoutId-2
  .list-data
  .list-item
  .lower-section
  ul
  li:after,
.client-page-container
  .faq.layoutId-3
  .list-data
  .list-item
  .lower-section
  ul
  li:after,
.client-page-container
  .faq2.layoutId-2
  .list-data
  .list-item
  .lower-section
  ul
  li:after,
.client-page-container
  .faq2.layoutId-3
  .list-data
  .list-item
  .lower-section
  ul
  li:after {
  background: #11d1ac;
  border-radius: 50%;
  content: "";
  display: block;
  height: 16px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 16px;
}

.client-page-container .faq.layoutId-2 .list-data .list-item .lower-section a,
.client-page-container .faq.layoutId-3 .list-data .list-item .lower-section a,
.client-page-container .faq2.layoutId-2 .list-data .list-item .lower-section a,
.client-page-container .faq2.layoutId-3 .list-data .list-item .lower-section a {
  background: #f74;
  border: none;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-family: sspBold;
  font-size: 1em;
  line-height: 22px;
  margin-bottom: 20px;
  margin-top: 4px;
  padding: 13px 30px;
  text-align: center;
  text-transform: none;
}

.client-page-container .faq.layoutId-2 .list-data .list-item .tab-track,
.client-page-container .faq.layoutId-3 .list-data .list-item .tab-track,
.client-page-container .faq2.layoutId-2 .list-data .list-item .tab-track,
.client-page-container .faq2.layoutId-3 .list-data .list-item .tab-track {
  border-radius: 10px;
  height: 3px;
  position: relative;
  width: 100%;
  box-shadow: 0 2px 12px 0 2px 0 0 rgba(0, 0, 0, 0.05) #000;
}

.client-page-container
  .faq.layoutId-2
  .list-data
  .list-item
  .tab-track
  .tab-progress,
.client-page-container
  .faq.layoutId-3
  .list-data
  .list-item
  .tab-track
  .tab-progress,
.client-page-container
  .faq2.layoutId-2
  .list-data
  .list-item
  .tab-track
  .tab-progress,
.client-page-container
  .faq2.layoutId-3
  .list-data
  .list-item
  .tab-track
  .tab-progress {
  background: #f74;
  border-radius: 10px;
  height: 100%;
  width: 0;
  transition: width 1s linear 0s;
}

.client-page-container .faq.layoutId-2 .list-data .active .upper-section,
.client-page-container .faq.layoutId-3 .list-data .active .upper-section,
.client-page-container .faq2.layoutId-2 .list-data .active .upper-section,
.client-page-container .faq2.layoutId-3 .list-data .active .upper-section {
  padding-bottom: 15px;
}

.client-page-container .faq.layoutId-2 .list-data .active .lower-section,
.client-page-container .faq.layoutId-3 .list-data .active .lower-section,
.client-page-container .faq2.layoutId-2 .list-data .active .lower-section,
.client-page-container .faq2.layoutId-3 .list-data .active .lower-section {
  padding-bottom: 24px;
}

.client-page-container
  .faq.layoutId-3
  .content-wrapper
  .list-data
  .lower-section,
.client-page-container
  .faq.layoutId-3
  .content-wrapper
  .list-data
  .upper-section,
.client-page-container
  .faq2.layoutId-3
  .content-wrapper
  .list-data
  .lower-section,
.client-page-container
  .faq2.layoutId-3
  .content-wrapper
  .list-data
  .upper-section {
  cursor: default;
}

.client-page-container
  .faq.layoutId-3
  .content-wrapper
  .list-data
  .lower-section:after,
.client-page-container
  .faq.layoutId-3
  .content-wrapper
  .list-data
  .upper-section:after,
.client-page-container
  .faq2.layoutId-3
  .content-wrapper
  .list-data
  .lower-section:after,
.client-page-container
  .faq2.layoutId-3
  .content-wrapper
  .list-data
  .upper-section:after {
  content: unset;
}

.client-page-container .investment-nudge-category-container {
  background-color: #fff;
  padding: 50px 20px;
}

.client-page-container .investment-nudge-category-container .heading img {
  margin-left: 5px;
  vertical-align: bottom;
  width: 25px;
}

.client-page-container .investment-nudge-category-container h3 {
  color: #222;
  display: inline;
  font-family: sspBolder;
  font-size: 1.857em;
  line-height: 32px;
}

.client-page-container .investment-nudge-category-container h3 b {
  color: #f16122;
}

.client-page-container .investment-nudge-category-container p {
  color: #666;
  font-size: 1em;
  line-height: 22px;
  margin-top: 10px;
}

.client-page-container .investment-nudge-category-container .input-wrapper {
  margin-top: 25px;
}

.client-page-container
  .investment-nudge-category-container
  .input-wrapper
  input {
  background-color: #fff;
  border-color: #e8e8e8;
  border-radius: 100px;
  height: 54px;
  line-height: 20px;
  padding: 14px 50px 14px 30px;
  box-shadow: 0 8px 20px 0 hsla(0, 0%, 59%, 0.15);
}

.client-page-container
  .investment-nudge-category-container
  .input-wrapper
  input::-moz-placeholder {
  color: #222;
}

.client-page-container
  .investment-nudge-category-container
  .input-wrapper
  input:-ms-input-placeholder {
  color: #222;
}

.client-page-container
  .investment-nudge-category-container
  .input-wrapper
  input::placeholder {
  color: #222;
}

.client-page-container .investment-nudge-category-container .input-wrapper img {
  height: 20px;
  left: unset;
  right: 30px;
  top: 17px;
  width: 20px;
}

.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper {
  position: relative;
}

.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper
  .search-result-container {
  background-color: #fff;
  border-radius: 20px;
  left: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 65px;
  z-index: 1;
  transition: max-height 0.25s ease-in 0s;
}

.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper
  .search-result-container.expand {
  max-height: 600px;
  opacity: 1;
  padding: 10px 0;
  box-shadow: 0 2px 10px 1px rgba(34, 34, 34, 0.25);
}

.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper
  .search-result-container
  ul {
  max-height: 240px;
  overflow: auto;
}

.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper
  .search-result-container
  ul
  li {
  border-bottom: 1px solid hsla(0, 0%, 87%, 0.8);
  color: #222;
  font-family: sspRegular;
  font-size: 0.857em;
  line-height: 18px;
  padding: 15px 20px;
  text-align: left;
}

.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper
  .search-result-container
  ul
  li.highlighted-text {
  color: #b2b2b2;
}

.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper
  .search-result-container
  ul
  li
  b {
  color: #222;
  font-family: sspBolder;
}

.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper
  .search-result-container
  ul
  li:last-child {
  border-bottom: none;
}

.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper
  .search-result-container
  .no-data-text {
  max-width: 100%;
}

.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper
  .search-result-container
  h4 {
  text-align: left;
}

.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper
  .search-result-container
  .recommended-funds
  li {
  padding: 10px;
}

.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper
  .recommended-funds
  ul,
.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper
  .search-funds
  ul {
  box-shadow: none;
}

.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper
  .recommended-funds
  ul
  li,
.client-page-container
  .investment-nudge-category-container
  .input-search-wrapper
  .search-funds
  ul
  li {
  border-bottom: none;
  cursor: pointer;
}

.client-page-container
  .investment-nudge-category-container
  .category-container {
  display: flex;
  flex-wrap: wrap;
}

.client-page-container
  .investment-nudge-category-container
  .category-container
  .category {
  flex: 0 0 33%;
  margin-top: 30px;
  text-align: center;
}

.client-page-container
  .investment-nudge-category-container
  .category-container
  .category
  .category-icon {
  cursor: pointer;
  height: 60px;
  margin: 0 auto;
  width: 60px;
}

.client-page-container
  .investment-nudge-category-container
  .category-container
  .category
  p {
  color: #222;
  line-height: 32px;
  margin-top: 5px;
}

.client-page-container .investment-nudge-category-container .btn-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.client-page-container
  .investment-nudge-category-container
  .btn-container
  .cta-button {
  align-items: center;
  border-radius: 10px;
  font-size: 1.143em;
  justify-content: center;
  line-height: 30px;
  padding: 15px 30px;
  text-transform: uppercase;
  width: 330px;
  background-color: #f74;
  color: #fff;
  display: flex;
  font-family: sspBold;
  text-align: center;
}

.client-page-container
  .investment-nudge-category-container
  .btn-container
  .cta-button
  img {
  height: 18px;
  margin-left: 10px;
  width: 21px;
}

.client-page-container .interest-calculator {
  background-color: #fefaf7;
  background-position: 20% 80%;
  background-repeat: no-repeat;
  padding: 46px 20px 60px;
}

.client-page-container .interest-calculator .heading {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
}

.client-page-container .interest-calculator .heading h3 {
  display: inline;
  font-family: sspBolder;
  font-size: 1.857em;
  line-height: 40px;
}

.client-page-container .interest-calculator .heading h3 b {
  color: #f16122;
}

.client-page-container .interest-calculator .heading img {
  width: 40px;
}

.client-page-container .interest-calculator .sub-heading {
  color: #666;
  font-size: 1em;
  line-height: 22px;
  margin-bottom: 24px;
}

.client-page-container .interest-calculator .switch-item-container {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 100px;
  display: flex;
  height: 54px;
  margin-bottom: 40px;
  padding: 5px;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.05);
}

.client-page-container .interest-calculator .switch-item-container li {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 100px;
  color: #222;
  cursor: pointer;
  display: flex;
  flex: 1 1;
  font-size: 1em;
  justify-content: center;
  line-height: 30px;
  transition: border 0.2s ease-in-out 0s;
}

.client-page-container .interest-calculator .switch-item-container li.active {
  border-color: #f16122;
  color: #f16122;
}

.client-page-container
  .interest-calculator
  .interest-ranges-sliders
  .range-slider-container {
  margin-bottom: 25px;
}

.client-page-container
  .interest-calculator
  .interest-ranges-sliders
  .range-slider-container:last-child {
  margin-bottom: 30px;
}

.client-page-container
  .interest-calculator
  .interest-ranges-sliders
  .upper-section {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.client-page-container
  .interest-calculator
  .interest-ranges-sliders
  .upper-section
  label {
  color: #222;
  flex: 0 0 65%;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 17px;
}

.client-page-container
  .interest-calculator
  .interest-ranges-sliders
  .upper-section
  .value-section {
  flex: 0 0 35%;
}

.client-page-container
  .interest-calculator
  .interest-ranges-sliders
  .upper-section
  .value-section
  input {
  height: 38px;
}

.client-page-container
  .interest-calculator
  .interest-ranges-sliders
  .upper-section
  .value-section.type-investmentAmt
  input {
  padding-left: 20px;
}

.client-page-container
  .interest-calculator
  .interest-ranges-sliders
  .upper-section
  .value-section.type-investmentAmt
  .input-container:after {
  content: "â‚¹";
  font-size: 1.143em;
  left: 8px;
  position: absolute;
  top: 11px;
}

.client-page-container .interest-calculator .calculated-investments-section {
  background: hsla(0, 0%, 100%, 0.5);
  border: 2px solid #fff;
  border-radius: 10px;
  display: flex;
  padding: 24px 20px;
  box-shadow: 0 0 10px 0 rgba(135, 104, 80, 0.03);
}

.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values {
  width: 50%;
}

.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .interest-amt,
.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .invested-amt,
.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .total-amt {
  display: flex;
  margin-bottom: 24px;
}

.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .interest-amt
  p:before,
.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .invested-amt
  p:before,
.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .total-amt
  p:before {
  content: "â‚¹";
  margin-right: 4px;
}

.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .interest-amt
  label,
.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .invested-amt
  label,
.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .total-amt
  label {
  color: #666;
  font-size: 0.857em;
  line-height: 15px;
  margin-bottom: 4px;
}

.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .interest-amt
  p,
.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .invested-amt
  p,
.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .total-amt
  p {
  color: #222;
  font-family: sspBolder;
  font-size: 1.286em;
  line-height: 23px;
}

.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .interest-amt {
  margin-bottom: 0;
}

.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .interest-amt
  p {
  color: #0ac88f;
}

.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .invested-amt
  p {
  color: #23a7e5;
}

.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .dot {
  border-radius: 50%;
  display: inline-block;
  height: 6px;
  margin: 4px 8px 0 0;
  width: 6px;
}

.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .dot.green {
  background-color: #0ac88f;
}

.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .dot.orange {
  background-color: #f16122;
}

.client-page-container
  .interest-calculator
  .calculated-investments-section
  .numerical-values
  .dot.blue {
  background-color: #23a7e5;
}

.client-page-container
  .interest-calculator
  .calculated-investments-section
  .pie-chart-section {
  width: 50%;
}

.client-page-container .faq .content-wrapper .heading {
  margin-bottom: 23px;
}

.client-page-container .faq .content-wrapper .heading h3 img {
  height: 30px;
  width: 25px;
}

.client-page-container .faq .content-wrapper .list-data .list-item ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-top: 10px;
}

.client-page-container .faq .content-wrapper .list-data .list-item ul li {
  color: #222;
  font-size: 1em;
  line-height: 22px;
  opacity: 0.6;
}

.client-page-container
  .faq
  .content-wrapper
  .list-data
  .list-item
  ul
  li:not(:last-child) {
  margin-bottom: 5px;
}

.client-page-container
  .faq
  .content-wrapper
  .list-data
  .list-item.active
  .lower-section {
  padding-bottom: 25px;
}

.client-page-container
  .faq
  .content-wrapper
  .list-data
  .list-item
  .lower-section {
  padding: 0 20px;
}

.client-page-container .faq.transparent-faq {
  background: none;
}

.client-page-container .faq.transparent-faq .content-wrapper .heading span {
  margin-right: 0;
}

.client-page-container .faq.faq-with-doughnut-section {
  background: none;
  padding: 35px 20px 47px;
}

.client-page-container
  .faq.faq-with-doughnut-section
  .content-wrapper
  .heading {
  margin-bottom: 0;
}

.client-page-container
  .faq.faq-with-doughnut-section
  .content-wrapper
  .list-data {
  margin-top: 0;
}

.client-page-container
  .faq.faq-with-doughnut-section
  .content-wrapper
  .list-data
  .list-item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.client-page-container
  .faq.faq-with-doughnut-section
  .content-wrapper
  .list-item {
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 0;
}

.client-page-container
  .faq.faq-with-doughnut-section
  .content-wrapper
  .list-item
  .upper-section {
  align-items: center;
  display: flex;
  padding: 22px 0;
}

.client-page-container
  .faq.faq-with-doughnut-section
  .content-wrapper
  .list-item
  .header-icon {
  margin-right: 18px;
}

.client-page-container
  .faq.faq-with-doughnut-section
  .content-wrapper
  .list-item
  .lower-section {
  color: #666;
  line-height: 20px;
  padding-left: 54px;
}

.client-page-container
  .faq.faq-with-doughnut-section
  .content-wrapper
  .list-item.active
  .upper-section {
  padding-bottom: 6px;
}

.client-page-container
  .faq.faq-with-doughnut-section
  .content-wrapper
  .list-item.active
  .lower-section {
  padding-bottom: 20px;
}

.client-page-container .faq.faq-with-doughnut-section .doughnut-chart {
  font-size: 1em;
  margin: 45px auto 0;
  max-width: 425px;
}

.client-page-container .faq.faq-with-doughnut-section .doughnut-chart canvas {
  cursor: pointer;
}

.client-page-container .investment-links-section {
  margin: 0 auto;
  padding: 54px 20px 34px;
}

.client-page-container .investment-links-section .link {
  color: #444;
  margin-bottom: 18px;
}

.client-page-container .investment-links-section .link p {
  font-family: sspBold;
  font-size: 1em;
  line-height: 22px;
  margin-bottom: 5px;
}

.client-page-container .investment-links-section .link a {
  color: #444;
  line-height: 22px;
  text-decoration: none;
}

.client-page-container
  .investment-links-section
  .link
  a:not(:last-child):after {
  content: "|";
  margin: 0 8px;
}

.client-page-container .features-with-score {
  padding: 35px 20px;
}

.client-page-container .features-with-score .content-section {
  margin-bottom: 35px;
}

.client-page-container .features-with-score .content-section .left-section h1 {
  font-family: sspBolder;
  font-size: 1.857em;
  line-height: 32px;
  margin-bottom: 27px;
}

.client-page-container
  .features-with-score
  .content-section
  .left-section
  h1
  b {
  color: #f74;
}

.client-page-container .features-with-score .content-section .left-section h2 {
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 22px;
  margin-bottom: 10px;
}

.client-page-container .features-with-score .content-section .left-section p {
  color: #666;
  line-height: 20px;
  margin-bottom: 30px;
}

.client-page-container .features-with-score .content-section .right-section h3 {
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 22px;
  margin-bottom: 20px;
}

.client-page-container
  .features-with-score
  .content-section
  .right-section
  .features
  div {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.client-page-container
  .features-with-score
  .content-section
  .right-section
  .features
  div
  .benefit-img {
  height: 24px;
  margin-right: 10px;
  width: 24px;
}

.client-page-container
  .features-with-score
  .content-section
  .right-section
  .features
  div
  p {
  line-height: 20px;
}

.client-page-container .features-with-score .score-section .score-bar {
  cursor: pointer;
  display: flex;
  margin: 0 auto;
  max-width: 800px;
}

.client-page-container
  .features-with-score
  .score-section
  .score-bar
  .score-container:not(:last-child) {
  margin-right: 2px;
}

.client-page-container
  .features-with-score
  .score-section
  .score-bar
  .score-background {
  height: 10px;
  position: relative;
}

.client-page-container
  .features-with-score
  .score-section
  .score-bar
  .score-value {
  display: flex;
  justify-content: flex-end;
}

.client-page-container
  .features-with-score
  .score-section
  .score-bar
  .score-value.between {
  justify-content: space-between;
}

.client-page-container
  .features-with-score
  .score-section
  .score-bar
  .score-container:first-child
  .score-background {
  border-radius: 10px 0 0 10px;
}

.client-page-container
  .features-with-score
  .score-section
  .score-bar
  .score-container:last-child
  .score-background {
  border-radius: 0 10px 10px 0;
}

.client-page-container .features-with-score .tooltip-shaped-container {
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 15px;
  height: 175px;
  margin: 0 auto 25px;
  max-width: 752px;
  padding: 20px;
  position: relative;
  text-align: center;
  transition: all 0.5s ease-in-out 0s;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
}

.client-page-container .features-with-score .tooltip-shaped-container h1 {
  font-family: sspBolder;
  font-size: 1.571em;
  line-height: 26px;
  margin-bottom: 3px;
}

.client-page-container
  .features-with-score
  .tooltip-shaped-container
  .sub-text {
  font-family: sspBold;
  font-size: 1.143em;
  line-height: 20px;
  margin-bottom: 13px;
}

.client-page-container .features-with-score .tooltip-shaped-container .para {
  font-family: sspRegular;
  font-size: 1em;
  line-height: 24px;
}

.client-page-container .features-with-score .tooltip-arrow {
  background: #fff;
  border-bottom: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  bottom: -11px;
  height: 20px;
  position: absolute;
  width: 20px;
  transform: rotate(45deg);
  transition: all 0.5s ease-in-out 0s;
}

.client-page-container .tabbed-lists {
  background-color: #fefaf7;
  padding-top: 32px;
}

.client-page-container .tabbed-lists h1 {
  font-family: sspBolder;
  font-size: 1.857em;
  line-height: 32px;
  margin-bottom: 27px;
  padding: 0 20px;
}

.client-page-container .tabbed-lists h1 b {
  color: #f74;
}

.client-page-container .tabbed-lists ul li {
  align-items: flex-start;
  display: flex;
  font-size: 1em;
  line-height: 20px;
  margin-bottom: 14px;
}

.client-page-container .tabbed-lists ul img {
  height: 20px;
  margin-right: 12px;
  width: 20px;
}

.client-page-container .tabbed-lists .swipeable-tabs-wrapper .tab-labels {
  background-color: #fefaf7;
  border-bottom: 1px solid rgba(68, 68, 68, 0.15);
  padding: 0 20px;
}

.client-page-container
  .tabbed-lists
  .swipeable-tabs-wrapper
  .tab-labels
  .tab-option {
  font-size: 1.143em;
  line-height: 22px;
  padding-bottom: 5px;
  padding-top: 0;
  text-transform: capitalize;
}

.client-page-container
  .tabbed-lists
  .swipeable-tabs-wrapper
  .tab-labels
  .tab-option.active {
  border-bottom: 3px solid #f74;
  font-family: sspBold;
}

.client-page-container
  .tabbed-lists
  .swipeable-tabs-wrapper
  .tab-labels
  .tab-option.border-under-text {
  margin-left: 0;
  margin-right: 0;
}

.client-page-container
  .tabbed-lists
  .swipeable-tabs-wrapper
  .tab-labels
  .tab-option.border-under-text:not(:last-child) {
  margin-right: 30px;
}

.client-page-container
  .tabbed-lists
  .swipeable-tabs-wrapper
  .tab-content-wrapper {
  background: #fefaf7;
  padding: 20px;
}

.client-page-container .services-overview.with-top-padding {
  padding-top: 20px;
}

.client-page-container .services-overview.with-bottom-padding {
  padding-bottom: 50px;
}

.client-page-container
  .services-overview
  .services-container
  .service:nth-child(2) {
  margin-top: 20px;
}

.client-page-container
  .services-overview
  .services-container.multiple
  .service {
  padding-bottom: 0;
}

.client-page-container
  .services-overview
  .services-container.multiple
  .service
  .section-img {
  margin-top: 80px;
}

.client-page-container
  .services-overview
  .services-container.multiple
  .service:nth-child(2)
  .section-img {
  padding-bottom: 50px;
}

.client-page-container .services-overview .heading h3 {
  font-family: sspBold;
  font-size: 2.428em;
  line-height: 40px;
  margin-bottom: 12px;
  padding: 55px 30px 0;
  text-align: center;
}

.client-page-container .services-overview .sub-heading {
  font-size: 1.143em;
  line-height: 22px;
  margin-bottom: 40px;
  padding: 0 25px;
  text-align: center;
}

.client-page-container .services-overview .service {
  background: #fff;
  padding: 50px 20px;
}

.client-page-container .services-overview .service .btn-section {
  display: flex;
  margin-bottom: 50px;
}

.client-page-container
  .services-overview
  .service
  .btn-section
  .download-icons {
  margin-right: 10px;
}

.client-page-container .services-overview .service .btn-section img {
  height: 50px;
  width: 155px;
}

.client-page-container .services-overview .service .btn-section .know-more-btn {
  background: #fff;
  border: 1px solid #f16122;
  border-radius: 10px;
  color: #f16122;
  font-family: sspBold;
  font-size: 1em;
  height: 50px;
  width: 125px;
}

.client-page-container
  .services-overview
  .service
  .btn-section
  .know-more-btn:hover {
  background: #f16122;
  color: #fff;
  transition: all 0.5s ease-in-out 0s;
}

.client-page-container .services-overview .service.with-margin {
  border-radius: 15px;
  margin: 0 15px;
}

.client-page-container .services-overview .service .service-logo {
  align-items: center;
  display: flex;
  margin-bottom: 22px;
}

.client-page-container .services-overview .service .service-logo img {
  height: 54px;
  margin-right: 15px;
}

.client-page-container .services-overview .service .service-logo p {
  font-family: sspBold;
  font-size: 1.143em;
}

.client-page-container .services-overview .service h3 {
  font-family: sspBold;
  font-size: 2em;
  line-height: 35px;
  margin-bottom: 16px;
}

.client-page-container .services-overview .service .sub-text {
  font-size: 1.143em;
  line-height: 22px;
  margin-bottom: 27px;
}

.client-page-container .services-overview .service .service-features {
  font-size: 1.143em;
  line-height: 22px;
  margin-bottom: 35px;
}

.client-page-container .services-overview .service .service-features li {
  padding-left: 37px;
  position: relative;
}

.client-page-container .services-overview .service .service-features li:before {
  border-radius: 1px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  content: "";
  display: block;
  height: 8px;
  left: 7px;
  position: absolute;
  top: 6px;
  width: 4px;
  z-index: 1;
  transform: rotate(45deg);
}

.client-page-container .services-overview .service .service-features li:after {
  background: #0ac88f;
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 20px;
}

.client-page-container
  .services-overview
  .service
  .service-features
  li:not(:last-child) {
  margin-bottom: 10px;
}

.client-page-container .services-overview .service .section-img img {
  max-width: 100%;
}

.client-page-container .services-overview .service .lottie-section {
  max-width: 400px;
  width: calc(100% - 70px);
}

.client-page-container .security-container .content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 44px 20px 65px;
  width: 100%;
}

.client-page-container .security-container .security-badge {
  text-align: center;
}

.client-page-container .security-container .security-badge img {
  height: 183px;
  width: 146px;
}

.client-page-container .security-container .security-info {
  margin-top: 43px;
}

.client-page-container .security-container .security-info .header {
  color: #fff;
  font-family: sspBold;
  font-size: 2.428em;
  line-height: 40px;
}

.client-page-container .security-container .security-info .sub-header {
  color: #fff;
  font-size: 1.143em;
  line-height: 22px;
  margin: 16px 0 35px;
}

.client-page-container .security-container .security-info .certified-container {
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  height: 73px;
  justify-content: space-between;
  padding: 25px;
}

.client-page-container .download-app-container {
  color: #fff;
  height: 348px;
  width: 100%;
}

.client-page-container .download-app-container .content-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.client-page-container .download-app-container .qr-section {
  display: flex;
  margin: 40px 0 0 100px;
}

.client-page-container .download-app-container .qr-section .qr-code {
  background-color: #fff;
  border-radius: 25px;
  height: 237px;
  padding: 30px;
  width: 237px;
}

.client-page-container .download-app-container .qr-section img {
  transform: translate(33px, 30px);
}

.client-page-container .download-app-container .heading-1 {
  font-size: 1.429em;
  line-height: 28px;
  margin: 45px 0 15px;
}

.client-page-container .download-app-container .heading-2 {
  font-family: sspBold;
  font-size: 2.428em;
  line-height: 40px;
}

.client-page-container .download-app-container .heading-3 {
  font-size: 1.143em;
  line-height: 22px;
  margin-top: 15px;
  opacity: 0.8;
}

.client-page-container .download-app-container .download-app {
  margin-top: 30px;
}

.client-page-container .download-app-container .download-app img {
  height: 46px;
  width: 144px;
}

.client-page-container .videos-preview-wrapper {
  background: linear-gradient(#fff 55%, #ebf2fb 0);
  padding: 50px 0;
}

.client-page-container .videos-preview-wrapper h2 {
  color: #000;
  font-family: sspBold;
  font-size: 2em;
  line-height: 35px;
  margin-bottom: 25px;
  text-align: center;
}

.client-page-container .videos-preview-wrapper .sub-heading-img {
  display: block;
  margin: 0 auto;
  width: 285px;
}

.client-page-container .videos-preview-wrapper .slider-wrapper {
  margin: 0;
}

.client-page-container .videos-preview-wrapper .video-preview-slider {
  margin-top: 30px;
  text-align: center;
}

.client-page-container
  .videos-preview-wrapper
  .video-preview-slider
  .thumbnail-image {
  position: relative;
}

.client-page-container
  .videos-preview-wrapper
  .video-preview-slider
  .thumbnail-image
  > img {
  border-radius: 20px;
  width: 100%;
  box-shadow: 0 0 0 5px #fff;
}

.client-page-container
  .videos-preview-wrapper
  .video-preview-slider
  .thumbnail-title {
  color: #000;
  display: inline-block;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 18px;
  margin-top: 20px;
}

.client-page-container
  .videos-preview-wrapper
  .video-preview-slider
  .thumbnail-overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.client-page-container
  .videos-preview-wrapper
  .video-preview-slider
  .thumbnail-overlay
  span {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 45px;
  justify-content: center;
  padding-left: 3px;
  width: 45px;
}

.client-page-container
  .videos-preview-wrapper
  .video-preview-slider
  .thumbnail-overlay
  span
  img {
  width: 14px;
}

.client-page-container .videos-preview-wrapper .arrows {
  margin-top: 40px;
  text-align: center;
}

.client-page-container .videos-preview-wrapper .arrows img {
  cursor: pointer;
  margin: 0 10px;
}

.client-page-container .videos-preview-wrapper .arrows .left-arrow:hover {
  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 0.5s;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  animation-name: animate-left;
  animation-timing-function: linear;
}

.client-page-container .videos-preview-wrapper .arrows .right-arrow:hover {
  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 0.5s;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  animation-name: animate-right;
  animation-timing-function: linear;
}

.client-page-container .videos-preview-wrapper .videos-preview-pop-up .pop-up {
  align-items: center;
  background: transparent;
  display: flex;
  justify-content: center;
  min-height: 100%;
  padding: 15px;
}

.client-page-container
  .videos-preview-wrapper
  .videos-preview-pop-up
  .pop-up
  .close-popup-icon-wrapper {
  background-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 20px;
  padding: 7px 10px;
}

.client-page-container
  .videos-preview-wrapper
  .videos-preview-pop-up
  .video-wrapper {
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
}

.client-page-container .blogs-generator {
  padding: 50px 0;
}

.client-page-container .blogs-generator h2 {
  color: #000;
  font-family: sspBold;
  font-size: 2em;
  line-height: 35px;
  margin-bottom: 25px;
  text-align: center;
}

.client-page-container .blogs-generator .slider-wrapper {
  margin: 0;
}

.client-page-container .blogs-generator .blog-cards-slider .blog-card {
  border: 1px solid #ebebeb;
  border-radius: 25px;
  min-height: 100%;
  overflow: hidden;
}

.client-page-container
  .blogs-generator
  .blog-cards-slider
  .blog-card
  .image-container {
  position: relative;
}

.client-page-container
  .blogs-generator
  .blog-cards-slider
  .blog-card
  .image-container
  .card-image {
  width: 100%;
}

.client-page-container
  .blogs-generator
  .blog-cards-slider
  .blog-card
  .image-container
  video {
  border-radius: 10px;
  width: 100%;
}

.client-page-container
  .blogs-generator
  .blog-cards-slider
  .blog-card
  .image-container
  .play-btn {
  bottom: 0;
  height: 80px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
}

.client-page-container
  .blogs-generator
  .blog-cards-slider
  .blog-card
  .image-container
  .play-time {
  align-items: center;
  border-radius: 13px;
  display: flex;
  padding: 5px 10px;
  position: absolute;
}

.client-page-container
  .blogs-generator
  .blog-cards-slider
  .blog-card
  .image-container
  .play-time
  img {
  height: 12px;
  width: 12px;
}

.client-page-container
  .blogs-generator
  .blog-cards-slider
  .blog-card
  .image-container
  .play-time
  span {
  color: #fff;
  font-family: sspRegular;
  font-size: 0.857em;
}

.client-page-container
  .blogs-generator
  .blog-cards-slider
  .blog-card
  .text-container {
  padding: 20px 25px;
}

.client-page-container
  .blogs-generator
  .blog-cards-slider
  .blog-card
  .text-details
  .post-category {
  color: #ee5726;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 18px;
  margin-bottom: 10px;
}

.client-page-container
  .blogs-generator
  .blog-cards-slider
  .blog-card
  .text-details
  .post-title {
  -webkit-box-orient: vertical;
  color: #222;
  display: -webkit-box;
  font-family: sspBold;
  font-size: 1.429em;
  height: 52px;
  -webkit-line-clamp: 2;
  line-height: 26px;
  margin-bottom: 20px;
  overflow: hidden;
  text-transform: capitalize;
}

.client-page-container
  .blogs-generator
  .blog-cards-slider
  .blog-card
  .date-readtime {
  color: #717171;
  font-family: sspRegular;
}

.client-page-container
  .blogs-generator
  .blog-cards-slider
  .blog-card
  .date-readtime
  .date {
  font-size: 0.857em;
  line-height: 22px;
  margin: 0 10px 0 0;
}

.client-page-container
  .blogs-generator
  .blog-cards-slider
  .blog-card
  .date-readtime
  .dot {
  background-color: #717171;
  border-radius: 50%;
  display: inline-block;
  height: 4px;
  margin: 2px 0;
  width: 4px;
}

.client-page-container
  .blogs-generator
  .blog-cards-slider
  .blog-card
  .date-readtime
  .read-time {
  font-size: 0.857em;
  line-height: 22px;
  margin: 0 0 0 10px;
}

.client-page-container .blogs-generator .arrows {
  margin-top: 30px;
  text-align: center;
}

.client-page-container .blogs-generator .arrows img {
  cursor: pointer;
  margin: 0 10px;
}

.client-page-container .blogs-generator .arrows .left-arrow:hover {
  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 0.5s;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  animation-name: animate-left;
  animation-timing-function: linear;
}

.client-page-container .blogs-generator .arrows .right-arrow:hover {
  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 0.5s;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  animation-name: animate-right;
  animation-timing-function: linear;
}

.client-page-container .seo-grid-section {
  padding: 25px 14px 50px;
}

.client-page-container .seo-grid-section h2 {
  color: #000;
  font-family: sspBold;
  font-size: 2em;
  line-height: 35px;
  margin: 0 auto 12px;
  text-align: center;
  width: 60%;
}

.client-page-container .seo-grid-section p {
  color: #000;
  font-family: sspRegular;
  font-size: 1.143em;
  line-height: 22px;
  margin: 0 auto 25px;
  text-align: center;
  width: 80%;
}

.client-page-container .seo-grid-section ul {
  display: grid;
  grid-gap: 12px 20px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 35px;
}

.client-page-container .seo-grid-section ul li {
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 135px;
  padding: 10px;
  text-align: center;
}

.client-page-container .seo-grid-section ul li img {
  max-width: 100%;
  width: 58px;
}

.client-page-container .seo-grid-section ul li label {
  color: #222;
  font-family: sspRegular;
  font-size: 1em;
  line-height: 18px;
}

.client-page-container .seo-grid-section ul li span {
  color: #f16122;
  font-family: sspBold;
  font-size: 0.714em;
  line-height: 14px;
  margin-top: 5px;
  white-space: nowrap;
}

.client-page-container .seo-grid-section button {
  border-color: #f16122;
  border-radius: 10px;
  color: #f16122;
  display: block;
  font-family: sspBold;
  font-size: 1em;
  line-height: 18px;
  margin: 0 auto;
  padding: 12px;
  text-transform: capitalize;
  width: 122px;
}

.client-page-container .gold-loan-calculator-wrapper {
  text-align: center;
}

.client-page-container .gold-loan-calculator-wrapper .heading {
  font-family: sspBold;
  font-size: 1.857em;
  line-height: 34px;
  margin-bottom: 8px;
}

.client-page-container .gold-loan-calculator-wrapper .sub-heading {
  font-size: 1.143em;
  line-height: 22px;
  margin-bottom: 36px;
  opacity: 0.7;
}

.client-page-container
  .gold-loan-calculator-wrapper
  .gold-loan-calculator.with-range-slider {
  border: 1px solid #e1e1e1;
  border-radius: 20px;
  margin: 0 20px;
  box-shadow: 0 10px 35px 0 rgba(31, 54, 84, 0.04);
}

.client-page-container
  .gold-loan-calculator-wrapper
  .gold-loan-calculator
  .title-container {
  display: none;
}

.client-page-container .custom-form-wrapper.form-wrapper .form .gold-loan-tnc {
  margin-top: 10px;
  order: 999;
}

.client-page-container
  .custom-form-wrapper.form-wrapper
  .form
  .gold-loan-tnc
  label {
  justify-content: center;
}

.client-page-container .disclaimer {
  font-size: 0.714em;
  line-height: 13px;
  padding: 20px;
  text-align: center;
}

.services-popup.show-pop-up .pop-up {
  border-radius: 50px;
  max-height: 562px;
  max-width: 890px;
}

.services-popup.show-pop-up .pop-up .close-popup-icon-wrapper {
  right: 20px;
  top: 20px;
}

.services-popup.show-pop-up .pop-up .download-app-container {
  align-items: center;
  color: #222;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 80px;
  width: 100%;
}

.services-popup.show-pop-up .pop-up .download-app-container .qr-section {
  display: flex;
  margin: 0 0 0 100px;
}

.services-popup.show-pop-up
  .pop-up
  .download-app-container
  .qr-section
  .qr-code {
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 25px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.04);
  height: 237px;
  padding: 30px;
  width: 237px;
}

.services-popup.show-pop-up .pop-up .download-app-container .qr-section img {
  transform: translate(33px, 40px);
}

.services-popup.show-pop-up .pop-up .download-app-container .heading-1 {
  font-size: 1.286em;
  line-height: 30px;
  margin: 23px 0 0;
}

.services-popup.show-pop-up .pop-up .download-app-container .heading-2 {
  font-family: sspBold;
  font-size: 2.857em;
  line-height: 52px;
}

.services-popup.show-pop-up .pop-up .download-app-container .download-app {
  margin: 26px 0 0;
}

.services-popup.show-pop-up .pop-up .download-app-container .download-app img {
  height: 36px;
  width: 114px;
}

.services-popup.show-pop-up
  .pop-up
  .download-app-container
  .download-app
  .google-play {
  margin-right: 15px;
}

.services-popup.form-pop-up .pop-up {
  border-radius: 20px 20px 0 0;
  padding-top: 10px;
}

@keyframes animate {
  0% {
    transform: translateZ(0);
  }

  20% {
    transform: translate3d(22px, 0, 0);
  }

  40% {
    visibility: hidden;
    transform: translate3d(50px, 0, 0);
  }

  60% {
    visibility: hidden;
    transform: translate3d(-55px, 0, 0);
  }

  80% {
    visibility: visible;
    transform: translate3d(-22px, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

@media (max-width: 767px) {
  .client-page-container
    .layout-3.layout-id-5:first-child
    .overview-image-section {
    display: none;
  }

  .client-page-container .layout-3.layout-id-5 .content-wrapper,
  .client-page-container .layout-3.layout-id-6 .content-wrapper {
    display: flex;
    flex-direction: column;
  }

  .client-page-container
    .layout-3.layout-id-5
    .content-wrapper
    .content-section,
  .client-page-container
    .layout-3.layout-id-6
    .content-wrapper
    .content-section {
    margin-left: 10px;
  }

  .client-page-container .layout-3.layout-id-5 .overview-image-section,
  .client-page-container .layout-3.layout-id-6 .overview-image-section {
    order: -1;
  }

  .client-page-container .layout-3.layout-id-6 .overview-image-section img {
    margin-left: 40px;
  }

  .client-page-container .single-banner.layoutId-2 .feature-text li img {
    margin-right: 12px;
  }

  .client-page-container .security-container .security-info .header {
    width: 260px;
  }

  .client-page-container .security-container .security-info .sub-header {
    width: 270px;
  }

  .client-page-container
    .security-container
    .security-info
    .certified-container {
    align-items: center;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    height: 137px;
    justify-content: space-between;
  }

  .client-page-container
    .security-container
    .security-info
    .certified-container
    .logos:nth-child(2) {
    margin: auto;
  }

  .client-page-container
    .security-container
    .security-info
    .certified-container
    .logos:last-child,
  .client-page-container
    .security-container
    .security-info
    .certified-container
    .logos:nth-child(3) {
    margin-left: auto;
  }

  .client-page-container
    .security-container
    .security-info
    .certified-container
    .logos:last-child,
  .client-page-container
    .security-container
    .security-info
    .certified-container
    .logos:nth-child(4) {
    margin-top: 20px;
  }

  .client-page-container
    .security-container
    .security-info
    .certified-container
    .logos:nth-child(4) {
    grid-column: span 1;
  }

  .client-page-container
    .security-container
    .security-info
    .certified-container
    .logos:last-child {
    grid-column: span 2;
  }

  .client-page-container .download-app-container .heading-2 {
    width: 310px;
  }

  .client-page-container .download-app-container .heading-3 {
    width: 260px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .client-page-container .layout-1 .layout-slide.slide-5 .content-wrapper {
    padding: 50px 30px 90px;
  }

  .client-page-container .layout-1 .layout-slide.slide-5 p {
    font-size: 1.143em;
    line-height: 24px;
    max-width: 296px;
  }

  .client-page-container .layout-1 h2 {
    font-size: 2.571em;
    line-height: 40px;
    margin-bottom: 8px;
    max-width: 296px;
  }

  .client-page-container .layout-3.layout-id-6 .overview-image-section img {
    margin-left: 20px;
  }

  .client-page-container .layout-3.left-aligned {
    padding-left: 0;
    padding-right: 0;
  }

  .client-page-container .layout-3.left-aligned .content-wrapper {
    display: block;
  }

  .client-page-container .layout-4.how-to-check .step-item {
    align-items: flex-start;
  }

  .client-page-container .layout-4.stepper .step-items {
    display: block;
  }

  .client-page-container .why-section {
    padding: 47px 30px 55px;
  }

  .client-page-container .why-section .section-img {
    box-shadow: 8px 8px 0 0 #fef8f5;
    margin-right: 34px;
    max-width: 215px;
  }

  .client-page-container .why-section .sup-heading {
    font-size: 1em;
    margin-bottom: 2px;
  }

  .client-page-container .why-section h2 {
    font-size: 2.29em;
    line-height: 1.2;
    margin-bottom: 36px;
  }

  .client-page-container .benefit-section {
    align-items: center;
    padding: 42px 30px 20px;
  }

  .client-page-container .benefit-section .content .desc {
    font-size: 1.143em;
    margin-bottom: 25px;
  }

  .client-page-container .benefit-section .sup-heading {
    text-align: center;
  }

  .client-page-container .benefit-section h3 {
    font-size: 2.29em;
    line-height: 36px;
    max-width: 370px;
  }

  .client-page-container .benefit-section .btn {
    display: block;
    margin: 0 auto 40px;
    padding: 17px 32px;
  }

  .client-page-container .benefit-section .left,
  .client-page-container .benefit-section .right {
    width: 50%;
  }

  .client-page-container .benefit-section .card {
    margin-bottom: 35px;
    margin-right: 17px;
  }

  .client-page-container
    .partners-section
    .content-wrapper
    .partners-container
    .partner
    .partners-list
    img:nth-child(3n + 1) {
    justify-self: flex-start;
  }

  .client-page-container
    .partners-section
    .content-wrapper
    .partners-container
    .partner
    .partners-list
    img:nth-child(3n + 3) {
    justify-self: flex-end;
  }

  .client-page-container .card-layout .content-wrapper h2 {
    font-family: sspBolder;
    font-size: 1.714em;
  }

  .client-page-container .card-layout .content-wrapper .subtitle {
    font-family: sspRegular;
    font-size: 1em;
  }

  .client-page-container .card-layout .content-wrapper .card-body .icon {
    width: 60px;
  }

  .client-page-container .card-layout .content-wrapper .card-body .title {
    font-size: 1.857em;
    line-height: 32px;
    margin: 10px 0 20px 5px;
  }

  .client-page-container .card-layout .content-wrapper .card-body .desc {
    font-size: 24px;
    line-height: 32px;
  }

  .client-page-container .single-banner {
    background-color: #fff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;
  }

  .client-page-container .single-banner .upper-section {
    justify-content: space-around;
  }

  .client-page-container .single-banner .heading-text h4 {
    margin-bottom: 15px;
  }

  .client-page-container .single-banner .heading-text .banner-text {
    font-size: 2.857em;
    line-height: 50px;
    margin: 0 0 25px;
  }

  .client-page-container .single-banner .heading-text .banner-text img {
    width: 45px;
  }

  .client-page-container .single-banner .banner-paragraph {
    font-size: 1.286em;
    line-height: 30px;
    margin: 16px 0;
  }

  .client-page-container .single-banner .cta-btn > span {
    align-items: center;
    background-color: #f16122;
    border-radius: 15px;
    color: #fff;
    display: flex;
    font-family: sspBold;
    font-size: 1.286em;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 15px;
    padding: 15px;
    text-transform: uppercase;
    width: 240px;
  }

  .client-page-container .single-banner .cta-btn > span .next-img {
    margin-left: 13px;
  }

  .client-page-container .single-banner .image-section {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 10px;
    padding-bottom: 25px;
    vertical-align: bottom;
  }

  .client-page-container .single-banner .image-section .secure-img {
    width: 35px;
  }

  .client-page-container .single-banner .image-section .certificate-img {
    width: 70px;
  }

  .client-page-container .single-banner.layoutId-2 .cta-btn > span,
  .client-page-container .single-banner.layoutId-3 .cta-btn > span {
    background-color: #f74;
    margin-bottom: 30px;
    line-height: 24px;
    font-size: 1.143em;
    border-radius: 10px;
    width: 242px;
    box-shadow: 1px 8px 20px 0 rgba(255, 119, 68, 0.15);
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section
    .section-img
    img,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section
    .section-img
    img {
    max-width: 400px;
  }

  .client-page-container .faq2 .content-wrapper .heading {
    margin-bottom: 20px;
  }

  .client-page-container .services-overview .service .section-img {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .client-page-wrapper.show-cta,
  .client-page-wrapper.show-cta.footer-info,
  .client-page-wrapper.show-cta.footer-underlining-info {
    margin-bottom: 0;
  }

  .client-page-container .layout-1 .content-wrapper {
    min-height: 280px;
  }

  .client-page-container .layout-1 .layout-slide.slide-1 .content-wrapper {
    padding: 75px;
  }

  .client-page-container .layout-1 .layout-slide.slide-1 h3 {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  .client-page-container .layout-1 .layout-slide.slide-2 .content-wrapper {
    padding: 50px 75px;
  }

  .client-page-container .layout-1 .layout-slide.slide-2 .content-section {
    margin: 30px;
  }

  .client-page-container .layout-1 .layout-slide.slide-3 .content-wrapper {
    padding: 30px 75px 60px;
  }

  .client-page-container .layout-1 .layout-slide.slide-3 h1,
  .client-page-container .layout-1 .layout-slide.slide-3 p {
    color: #fff;
  }

  .client-page-container .layout-1 .layout-slide.slide-3 p {
    max-width: 100%;
  }

  .client-page-container .layout-1 .banner-image img {
    height: 40px;
  }

  .client-page-container .layout-1 .banner-image span {
    font-size: 0.714em;
    line-height: 14px;
  }

  .client-page-container .layout-1 h3 {
    font-size: 1.714em;
    line-height: 32px;
  }

  .client-page-container .layout-1 p {
    font-size: 1em;
    line-height: 18px;
    margin: 10px 0;
    max-width: 50%;
  }

  .client-page-container .layout-1 p.btn-underlining-text {
    font-size: 0.857em;
    line-height: 15px;
  }

  .client-page-container .layout-1 p.btn-underlining-text a {
    color: #f74;
    cursor: pointer;
    font-family: sspBolder;
  }

  .client-page-container .layout-1 .slider-indicators {
    bottom: 25px;
  }

  .client-page-container .layout-1 .video-wrapper {
    height: 250px;
    margin: 0 auto;
    width: 550px;
  }

  .client-page-container .layout-1 .image-section {
    border-top: 1px solid rgba(34, 34, 34, 0.3);
    margin-top: 35px;
    width: 50%;
  }

  .client-page-container .layout-1 .image-section .secured-img {
    width: 35px;
  }

  .client-page-container .layout-1 .image-section .iso-img {
    margin-left: 20px;
    width: 64px;
  }

  .client-page-container .layout-1 .slide-btn {
    background-color: #f74;
    color: #fff;
    display: inline-block;
    font-family: sspBold;
    text-align: center;
    border-radius: 10px;
    font-size: 1.143em;
    padding: 17px;
    text-transform: capitalize;
    width: 250px;
  }

  .client-page-container .layout-3 {
    padding-left: 60px;
    padding-right: 40px;
  }

  .client-page-container .layout-3.layout-id-2 p {
    font-size: 1.143em;
    line-height: 24px;
  }

  .client-page-container .layout-3.layout-id-3 .content-wrapper,
  .client-page-container .layout-3.layout-id-4 .content-wrapper {
    flex-direction: row;
    padding: 40px 40px 25px 60px;
  }

  .client-page-container
    .layout-3.layout-id-3
    .content-wrapper
    .content-section,
  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .content-section {
    flex: 0 0 60%;
  }

  .client-page-container
    .layout-3.layout-id-3
    .content-wrapper
    .content-section
    h3,
  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .content-section
    h3 {
    font-size: 2.571em;
    line-height: 44px;
    margin-bottom: 40px;
  }

  .client-page-container
    .layout-3.layout-id-3
    .content-wrapper
    .content-section
    .extra-info,
  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .content-section
    .extra-info {
    border-radius: 12px;
    padding: 12px 16px;
  }

  .client-page-container
    .layout-3.layout-id-3
    .content-wrapper
    .content-section
    .extra-info
    p,
  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .content-section
    .extra-info
    p {
    font-size: 1em;
    line-height: 18px;
  }

  .client-page-container .layout-3.layout-id-3 .content-wrapper .text-content p,
  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .text-content
    p {
    font-size: 1.286em;
    line-height: 24px;
    margin: 0 20px 30px 0;
  }

  .client-page-container
    .layout-3.layout-id-3
    .content-wrapper
    .overview-image-section,
  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .overview-image-section {
    flex: 0 0 40%;
  }

  .client-page-container
    .layout-3.layout-id-3
    .content-wrapper
    .overview-image-section
    img,
  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .overview-image-section
    img {
    width: 90%;
  }

  .client-page-container .layout-3.layout-id-4 .content-wrapper {
    flex-direction: row-reverse;
    padding: 40px;
  }

  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .content-section {
    flex: 0 0 55%;
  }

  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .content-section
    h3 {
    font-size: 2.143em;
    line-height: normal;
    margin: 0 16px 20px;
  }

  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .text-content
    p {
    font-size: 1em;
    line-height: 20px;
    margin: 0 16px 20px;
  }

  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .overview-image-section {
    align-self: center;
    flex: 0 0 45%;
    text-align: left;
  }

  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .overview-image-section
    img {
    width: 85%;
  }

  .client-page-container
    .layout-3.layout-id-5
    .content-wrapper
    .content-section
    .extra-info,
  .client-page-container
    .layout-3.layout-id-6
    .content-wrapper
    .content-section
    .extra-info {
    align-self: flex-start;
    margin-bottom: 4px;
  }

  .client-page-container .layout-3.layout-id-5 .content-wrapper .overview-btn,
  .client-page-container .layout-3.layout-id-6 .content-wrapper .overview-btn {
    margin-top: 30px;
  }

  .client-page-container .layout-3 .content-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .client-page-container .layout-3 .content-section {
    align-items: flex-start;
    flex: 0 0 50%;
    margin-top: 0;
  }

  .client-page-container .layout-3 .overview-image-section {
    flex: 0 0 47%;
  }

  .client-page-container .layout-3 .overview-image-section img {
    width: 100%;
  }

  .client-page-container .layout-3 h3 {
    margin-bottom: 20px;
    text-align: left;
  }

  .client-page-container .layout-3 p {
    padding: 0;
    text-align: left;
  }

  .client-page-container .layout-3 .overview-btn {
    background-color: #f74;
    color: #fff;
    display: inline-block;
    font-family: sspBold;
    text-align: center;
    border-radius: 10px;
    font-size: 1.286em;
    line-height: 24px;
    margin-bottom: 15px;
    padding: 15px;
    width: 240px;
  }

  .client-page-container .layout-4 {
    padding: 0 35px;
  }

  .client-page-container .layout-4 .heading {
    font-size: 1.714em;
    line-height: 32px;
    text-align: center;
  }

  .client-page-container .layout-4 .step-items {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .client-page-container .layout-4 .step-item {
    flex: 0 0 32%;
    margin-bottom: 0;
  }

  .client-page-container .layout-4 .step-item-oval {
    font-size: 1.714em;
    height: 32px;
    width: 32px;
  }

  .client-page-container .layout-4 .step-subtitle {
    font-size: 1.143em;
    line-height: 22px;
    margin-top: 10px;
  }

  .client-page-container .layout-4 .actionable-steps-button {
    background-color: #f74;
    color: #fff;
    display: block;
    font-family: sspBold;
    text-align: center;
    border-radius: 10px;
    font-size: 1.286em;
    line-height: 24px;
    margin: 0 auto;
    padding: 15px 32px;
    width: -moz-max-content;
    width: max-content;
  }

  .client-page-container .layout-4.how-to-check .actionable-steps-button {
    background-color: #f74;
    width: 242px;
    font-size: 1.143em;
    border-radius: 10px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 8px 20px 0 rgba(255, 119, 68, 0.15);
  }

  .client-page-container .layout-4.how-to-check .step-items {
    justify-content: center;
    margin-top: 30px;
  }

  .client-page-container
    .layout-4.how-to-check
    .step-items
    .step-item:not(:last-child) {
    margin-right: 120px;
  }

  .client-page-container .layout-5 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
  }

  .client-page-container .layout-5 .users-count-section {
    flex: 0 0 255px;
    margin-left: 20px;
  }

  .client-page-container .layout-5 .content-section {
    flex: 0 0 50%;
  }

  .client-page-container .layout-5 .text-content {
    font-size: 1.143em;
    line-height: 22px;
  }

  .client-page-container .layout-5.secondary .content-section {
    flex: 0 0 100%;
  }

  .client-page-container .layout-5.secondary h3 {
    text-align: center;
  }

  .client-page-container .layout-5.secondary .features-list {
    display: flex;
    justify-content: space-between;
  }

  .client-page-container .layout-6 .content-wrapper {
    padding: 70px 0;
  }

  .client-page-container .layout-6 .actionable-desc-title {
    font-size: 1.714em;
    line-height: 32px;
  }

  .client-page-container .layout-6 .actionable-desc-subtitle {
    font-size: 1.143em;
    line-height: 22px;
    margin-bottom: 32px;
    max-width: 300px;
  }

  .client-page-container .layout-6 .actionable-desc-button {
    border-radius: 80px;
    font-size: 1.143em;
    line-height: 22px;
    padding: 12px 24px;
  }

  .client-page-container .layout-7 {
    background-size: contain;
    margin-bottom: 60px;
    margin-top: 60px;
    padding: 0 35px;
  }

  .client-page-container .layout-7 h3 {
    margin-bottom: 10px;
  }

  .client-page-container .layout-7 .flex-container {
    display: flex;
    justify-content: center;
  }

  .client-page-container .layout-7 .slider-wrapper {
    margin-right: 30px;
    width: 30%;
  }

  .client-page-container .layout-7 .slider-wrapper .slider-indicators {
    margin-top: 0;
  }

  .client-page-container .layout-7 .layout-slide img {
    width: 200px;
  }

  .client-page-container .layout-7 .reasons-list {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(2, 220px);
    margin-top: 30px;
  }

  .client-page-container .layout-7 .reasons-list li {
    align-items: flex-end;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    min-height: 172px;
    padding: 40px 20px 20px;
    transition: all 0.2s ease-in-out 0s;
    box-shadow: 1px 4px 12px 0 rgba(34, 34, 34, 0.1);
  }

  .client-page-container .layout-7 .reasons-list li:nth-child(2n) {
    transform: translateY(30px);
  }

  .client-page-container .layout-7 .reasons-list li.active {
    border: 1px solid #f74;
  }

  .client-page-container .layout-7 .reasons-list li.active:first-child {
    transform: translate3d(-10px, -10px, 0);
    box-shadow: 5px 5px 10px 0 rgba(255, 119, 68, 0.4);
  }

  .client-page-container .layout-7 .reasons-list li.active:nth-child(2) {
    transform: translate3d(10px, 20px, 0);
    box-shadow: -5px 5px 10px 0 rgba(255, 119, 68, 0.4);
  }

  .client-page-container .layout-7 .reasons-list li.active:nth-child(3) {
    transform: translate3d(-10px, 10px, 0);
    box-shadow: 5px -5px 10px 0 rgba(255, 119, 68, 0.4);
  }

  .client-page-container .layout-7 .reasons-list li.active:nth-child(4) {
    transform: translate3d(10px, 40px, 0);
    box-shadow: -5px -5px 10px 0 rgba(255, 119, 68, 0.4);
  }

  .client-page-container .layout-7 .reasons-list li h4 {
    color: #222;
    font-family: sspBolder;
    font-size: 1.429em;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .client-page-container .layout-7 .reasons-list li p {
    color: #000;
    font-family: sspRegular;
    font-size: 0.857em;
    line-height: 18px;
  }

  .client-page-container .layout-8 h3 {
    font-size: 1.714em;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .client-page-container .layout-8 .list-data {
    padding: 10px 30px;
  }

  .client-page-container .layout-8 .list-data .list-item.active .lower-section {
    padding-bottom: 30px;
  }

  .client-page-container .layout-8 .list-data .list-item .upper-section {
    padding: 30px 40px 30px 30px;
  }

  .client-page-container .layout-8 .list-data .list-item .upper-section:after {
    height: 9px;
    width: 9px;
  }

  .client-page-container .layout-8 .list-data .list-item .upper-section p {
    font-size: 1.286em;
    line-height: 24px;
  }

  .client-page-container .layout-8 .list-data .list-item .lower-section {
    padding: 0 30px;
  }

  .client-page-container .layout-8 .list-data .list-item .lower-section p {
    font-size: 1.143em;
    line-height: 24px;
  }

  .client-page-container .layout-8 .btn-section {
    margin-top: 30px;
  }

  .client-page-container .layout-8 button {
    font-size: 1.286em;
    height: 54px;
    line-height: 24px;
    width: 150px;
  }

  .client-page-container .layout-9 {
    background-size: 100% 250px;
  }

  .client-page-container .layout-9 h3 {
    font-size: 1.714em;
    line-height: 32px;
    margin: 40px 0 25px;
  }

  .client-page-container .layout-9 .million-container,
  .client-page-container .layout-9 .video-player-container {
    width: 50%;
  }

  .client-page-container .layout-9 .million-container img {
    left: -100px;
    top: 15px;
    width: 130px;
  }

  .client-page-container .layout-10 {
    padding: 0 60px;
  }

  .client-page-container .layout-10 .expandable-info-para {
    font-size: 1.143em;
    line-height: 22px;
    margin-top: 20px;
  }

  .client-page-container .layout-10 .expandable-info-button {
    font-size: 1.143em;
    line-height: 22px;
    margin-top: 40px;
    width: 130px;
  }

  .client-page-container .layout-11 .content-wrapper {
    padding: 20px 24px;
  }

  .client-page-container .layout-11 .subtitle {
    font-size: 1.143em;
    line-height: 22px;
    margin-bottom: 40px;
  }

  .client-page-container .layout-11 .steps {
    flex-direction: row;
    justify-content: space-between;
  }

  .client-page-container .layout-11 .step {
    flex: 0 0 31%;
    margin-bottom: 0;
  }

  .client-page-container .layout-11 .screen {
    margin-bottom: 25px;
  }

  .client-page-container .layout-11 h6 {
    font-size: 1.286em;
    line-height: 24px;
    padding: 0;
  }

  .client-page-container .layout-11 .step-subtitle {
    font-size: 1em;
    line-height: 20px;
    padding: 0;
  }

  .client-page-container .layout-11 .divider-img {
    margin-top: 0;
    position: absolute;
    right: -14%;
    top: 45%;
    width: 45px;
    z-index: 1;
  }

  .client-page-container .layout-11.secondary {
    padding: 20px 30px;
  }

  .client-page-container .layout-11.secondary .content-wrapper {
    border-radius: 16px;
    margin: 0;
    padding: 50px 40px 70px;
  }

  .client-page-container .layout-11.secondary h3 {
    font-size: 2.143em;
    line-height: 40px;
    padding: 0;
    text-align: center;
  }

  .client-page-container .layout-11.secondary h6 {
    font-size: 1.286em;
    line-height: 26px;
    text-align: left;
  }

  .client-page-container .layout-11.secondary .subtitle {
    color: #222;
    font-size: 1.286em;
    line-height: 26px;
    margin-bottom: 40px;
    text-align: center;
  }

  .client-page-container .layout-11.secondary .steps {
    grid-column-gap: 28px;
    -moz-column-gap: 28px;
    column-gap: 28px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .client-page-container .layout-11.secondary .step {
    border-radius: 16px;
    height: 100%;
    margin-bottom: 0;
    padding: 16px 24px 0;
  }

  .client-page-container .layout-11.secondary .step-header {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 18px;
  }

  .client-page-container .layout-11.secondary .screen {
    height: 50px;
    margin-bottom: 10px;
    margin-right: 0;
    width: auto;
  }

  .client-page-container .layout-11.secondary .sub-step {
    margin-bottom: 12px;
  }

  .client-page-container .layout-11.secondary .sub-step p {
    font-size: 0.857em;
    line-height: 16px;
  }

  .client-page-container .layout-11.secondary .sub-step-index-oval {
    align-items: center;
    background-color: #222;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-family: sspBold;
    font-size: 0.714em;
    justify-content: center;
    min-height: 14px;
    min-width: 14px;
    margin-top: 2px;
  }

  .client-page-container .layout-11.secondary .btn-section {
    margin-top: 60px;
  }

  .client-page-container .layout-11.secondary .how-it-works-button {
    background-color: #f74;
    color: #fff;
    display: block;
    font-family: sspBold;
    text-align: center;
    border-radius: 10px;
    font-size: 1.286em;
    line-height: 20px;
    margin: 0 auto;
    padding: 14px 72px;
    width: -moz-max-content;
    width: max-content;
  }

  .client-page-container .layout-11.secondary .desc-wrapper {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin: 0 -24px;
    padding: 18px 24px;
  }

  .client-page-container .layout-11.secondary .desc-wrapper p {
    font-size: 0.857em;
    line-height: 16px;
  }

  .client-page-container .layout-12 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 30px 0 30px 60px;
  }

  .client-page-container .layout-12 .recommended-blogs-title-section {
    flex: 0 0 32%;
  }

  .client-page-container .layout-12 .recommended-blogs-title {
    font-size: 1.714em;
    line-height: 32px;
  }

  .client-page-container .layout-12 .recommended-blogs-title b {
    display: block;
  }

  .client-page-container .layout-12 .recommended-blogs-button {
    font-size: 1.143em;
    line-height: 22px;
    padding: 16px;
    width: 180px;
  }

  .client-page-container .layout-12 .recommended-blogs-slider {
    flex: 0 0 60%;
    margin-top: 0;
  }

  .client-page-container
    .layout-12
    .recommended-blogs-slider-wrapper.slider-arrows
    .slider-track
    .arrow {
    background-color: #fff;
    border-radius: 50%;
    bottom: auto;
    padding-bottom: 16px;
    padding-top: 16px;
    top: calc(50% - 60px);
    z-index: 1;
    box-shadow: 0 3px 10px 0 #7a7a7a;
  }

  .client-page-container
    .layout-12
    .recommended-blogs-slider-wrapper.slider-arrows
    .slider-track
    .arrow:disabled {
    cursor: not-allowed;
  }

  .client-page-container
    .layout-12
    .recommended-blogs-slider-wrapper.slider-arrows
    .slider-track
    .arrow
    span {
    height: 8px;
    width: 8px;
  }

  .client-page-container
    .layout-12
    .recommended-blogs-slider-wrapper.slider-arrows
    .slider-track
    .left-arrow {
    left: -18px;
    padding-left: 17px;
    padding-right: 15px;
  }

  .client-page-container
    .layout-12
    .recommended-blogs-slider-wrapper.slider-arrows
    .slider-track
    .right-arrow {
    padding-left: 15px;
    padding-right: 17px;
    right: -18px;
  }

  .client-page-container .layout-12 .recommended-blog-slide-title {
    font-size: 1.286em;
    line-height: 22px;
  }

  .client-page-container .layout-12 .recommended-blog-slide-subtitle {
    margin-top: 20px;
  }

  .client-page-container .layout-13 p {
    margin-bottom: 50px;
    width: 50%;
  }

  .client-page-container .layout-13 .paylater-logo-wrapper {
    left: calc(50% - 24px);
    top: -24px;
  }

  .client-page-container .layout-13 .paylater-logo-wrapper img {
    width: 48px;
  }

  .client-page-container .layout-14 {
    padding: 0 50px 0 60px;
  }

  .client-page-container .layout-14 .content-section {
    align-items: flex-start;
    flex-direction: row;
    text-align: left;
  }

  .client-page-container .layout-14 .content-section img {
    margin-bottom: 0;
    width: 45%;
  }

  .client-page-container .layout-14 .text-content {
    flex: 0 0 55%;
    margin-top: 20px;
  }

  .client-page-container .layout-14 p {
    font-size: 1.143em;
    line-height: 22px;
    margin: 0 0 30px;
  }

  .client-page-container .layout-14 .items-list {
    justify-content: flex-start;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .client-page-container .layout-14 .item {
    margin-bottom: 0;
  }

  .client-page-container .layout-14 .pay-bills-button {
    background-color: #f74;
    color: #fff;
    display: inline-block;
    font-family: sspBold;
    text-align: center;
    border-radius: 10px;
    font-size: 1.286em;
    line-height: 24px;
    padding: 15px 24px;
    width: -moz-max-content;
    width: max-content;
  }

  .client-page-container .layout-14.secondary {
    padding: 70px 30px 60px;
  }

  .client-page-container .layout-14.secondary h3 {
    font-size: 2.143em;
    margin-bottom: 12px;
  }

  .client-page-container .layout-14.secondary p {
    font-size: 1.286em;
    line-height: 24px;
  }

  .client-page-container .layout-14.secondary .text-content {
    flex-basis: 80%;
    margin: 0;
  }

  .client-page-container .layout-14.secondary .text-content p {
    margin-bottom: 46px;
  }

  .client-page-container .layout-14.secondary .items-list {
    align-items: flex-start;
    display: flex;
    margin-bottom: 0;
  }

  .client-page-container .layout-14.secondary .item {
    display: block;
    flex: 1 1;
    margin: 0 40px 0 0;
  }

  .client-page-container .layout-14.secondary .item:last-child {
    margin: 0;
  }

  .client-page-container .layout-14.secondary .item p {
    font-size: 1em;
    line-height: 18px;
    margin-bottom: 0;
  }

  .client-page-container .layout-14.secondary .image-container {
    height: 64px;
    margin: 0;
    width: 64px;
  }

  .client-page-container .layout-14.secondary .image-container img {
    height: 64px;
    margin-bottom: 12px;
    width: 64px;
  }

  .client-page-container .layout-14.secondary h6 {
    font-size: 1.429em;
    line-height: 28px;
    margin-bottom: 12px;
  }

  .client-page-container .layout-14.secondary h6 b {
    font-size: 1.286em;
  }

  .client-page-container .layout-15 .content-wrapper {
    padding: 60px;
  }

  .client-page-container .layout-15 h3 {
    font-size: 2.428em;
    margin-bottom: 50px;
  }

  .client-page-container .layout-15 .slider-wrapper .slider-indicators {
    margin-top: 25px;
  }

  .client-page-container
    .layout-15
    .slider-wrapper
    .slider-indicators
    ul
    li
    .indicator {
    height: 8px;
    width: 8px;
  }

  .client-page-container .layout-16 {
    margin-top: 40px;
    padding: 60px 100px;
  }

  .client-page-container .layout-16 .layout-heading h3 {
    font-size: 2.571em;
    line-height: 42px;
    margin: 0 auto 60px;
  }

  .client-page-container .layout-16 .list-data {
    display: grid;
    grid-gap: 60px;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }

  .client-page-container .layout-16 .list-data li {
    box-shadow: none;
    flex-direction: column;
    padding: 0;
  }

  .client-page-container .layout-16 .list-data li:last-child {
    align-self: center;
  }

  .client-page-container .layout-16 .list-data li h3 {
    font-size: 1.429em;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .client-page-container .layout-16 .list-data li p {
    font-size: 1em;
    line-height: 20px;
  }

  .client-page-container .layout-16 .list-data .image-section {
    margin-bottom: 15px;
  }

  .client-page-container .layout-16 .list-data .image-section img {
    width: 80px;
  }

  .client-page-container .layout-16 .list-data .btn {
    border-radius: 10px;
    font-size: 1.143em;
    height: auto;
    padding: 16px 32px;
    text-transform: capitalize;
    width: auto;
  }

  .client-page-container .layout-17 .text-content {
    margin: 20px 0;
  }

  .client-page-container .layout-17 h3 {
    font-size: 2.571em;
    line-height: 42px;
    margin-bottom: 20px;
  }

  .client-page-container .layout-17 h4 {
    font-family: sspBold;
    font-size: 1.857em;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .client-page-container .layout-17 p {
    font-size: 1.286em;
    line-height: 28px;
    margin: 0 30px;
  }

  .client-page-container .layout-17 .external-link {
    font-size: 1.286em;
    line-height: 24px;
    width: 430px;
  }

  .client-page-container .layout-18 h3 {
    font-size: 2.571em;
    line-height: 42px;
    margin: 20px auto 30px;
  }

  .client-page-container .layout-18 p {
    font-size: 1.286em;
    line-height: 28px;
    margin: 0 30px;
  }

  .client-page-container .layout-19 .content-section {
    margin: 0 30px;
  }

  .client-page-container .layout-19 .content-section h3 {
    font-size: 2.571em;
    line-height: 42px;
    margin-bottom: 20px;
    width: auto;
  }

  .client-page-container .layout-19 .slider-wrapper {
    margin-bottom: 50px;
  }

  .client-page-container .layout-19 .slider-wrapper .slider-section:before {
    top: 35%;
    transform: scale(9);
  }

  .client-page-container
    .layout-19
    .slider-wrapper
    .slider-indicators
    ul
    li.active
    .indicator {
    height: 14px;
    width: 14px;
  }

  .client-page-container
    .layout-19
    .slider-wrapper
    .slider-indicators
    ul
    li
    .indicator {
    height: 10px;
    width: 10px;
  }

  .client-page-container .layout-19 .text-content {
    margin-bottom: 20px;
  }

  .client-page-container .layout-19 .text-content p {
    font-size: 1.286em;
    line-height: 28px;
  }

  .client-page-container .layout-19 .images-section img {
    width: 400px;
  }

  .client-page-container .layout-19 button {
    border-radius: 10px;
    font-family: sspBold;
    font-size: 1.286em;
    height: 60px;
    text-transform: capitalize;
    width: 250px;
  }

  .client-page-container .layout-21 .accountRegSteps-container {
    padding: 0 30px 10px;
  }

  .client-page-container .layout-21 .accountRegSteps-main-content {
    border-radius: 15px;
    padding: 30px;
  }

  .client-page-container .layout-21 .accountRegSteps-main-content h3 {
    font-size: 2.143em;
    line-height: normal;
    margin: 20px 30px 0;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-main-content
    .text-content
    p {
    color: #222;
    font-size: 0.857em;
    line-height: 16px;
    padding: 5px 0 0;
    width: 50%;
  }

  .client-page-container .layout-21 .accountRegSteps-content {
    padding: 15px 30px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .regSteps-heading-container {
    margin: 0 0 5px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .regSteps-heading-container
    h3 {
    font-family: sspRegular;
    font-size: 1em;
    line-height: 16px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .reg-primary-step-container {
    display: flex;
    flex-direction: row;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .reg-primary-step-container
    .regSteps-main-item-container {
    align-items: center;
    flex-direction: row;
    margin: 10px 0;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .reg-primary-step-container
    .regSteps-main-item-container
    .image-section
    img {
    height: 60px;
    width: 60px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .regSteps-title-container {
    align-items: baseline;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .regSteps-title-container
    h3 {
    font-size: 2.143em;
    margin: 0 0 0 15px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .regSteps-title-container
    p {
    font-size: 1.286em;
    margin: 0 40px 0 5px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white {
    border-radius: 15px;
    padding: 25px 30px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .regSteps-main-item-container {
    margin: 8px 0;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .reg-primary-step-container {
    flex-direction: column;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .regSteps-heading-container {
    margin: 0 0 17px;
    width: 100%;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .regSteps-heading-container
    h3 {
    font-family: sspBold;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .regSteps-title-count-wrapper {
    height: 16px;
    min-height: 16px;
    min-width: 16px;
    width: 16px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .regSteps-title-count-wrapper
    h3 {
    font-size: 0.857em;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .regSteps-title-container
    p {
    font-size: 0.857em;
    line-height: 12px;
    margin-left: 10px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .innerSteps-container {
    margin: 15px 0 10px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .innerSteps-container:before {
    width: 16px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .innerSteps-container
    .regSteps-title-container
    h3 {
    font-size: 0.857em;
    line-height: 6px;
    margin-left: 10px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .innerSteps-container
    .regSteps-title-count-wrapper {
    display: flex;
    height: 16px;
    min-height: 16px;
    min-width: 16px;
    width: 16px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .innerSteps-container
    .regSteps-title-count-wrapper
    h3 {
    margin: auto;
  }

  .client-page-container .why-section {
    align-items: center;
    display: flex;
  }

  .client-page-container .why-section .points h5 {
    font-size: 1.286em;
  }

  .client-page-container .why-section .points p {
    font-size: 1.143em;
    line-height: 1.56;
    margin-bottom: 36px;
  }

  .client-page-container .why-section .sup-heading {
    color: #aaa;
    font-family: sspBold;
    font-size: 1.286em;
    line-height: 1.67;
    margin-bottom: 5px;
  }

  .client-page-container .why-section .sub-heading {
    font-size: 1.286em;
    line-height: 1.67;
    margin-bottom: 42px;
  }

  .client-page-container .benefit-section,
  .client-page-container .benefit-section .container {
    display: flex;
  }

  .client-page-container .benefit-section .card {
    box-shadow: 10px 10px 0 0 #fef8f5;
  }

  .client-page-container .benefit-section .card h6 {
    font-size: 1.286em;
    line-height: 1.44;
  }

  .client-page-container .benefit-section .card .info {
    font-size: 1.143em;
  }

  .client-page-container .benefit-section .right .card {
    margin-left: 17px;
    margin-right: 0;
  }

  .client-page-container .layout-22 {
    padding: 40px 30px;
  }

  .client-page-container .layout-22 h3 {
    font-size: 2.571em;
    margin-bottom: 8px;
  }

  .client-page-container .layout-22 p {
    font-size: 1.143em;
    line-height: 24px;
  }

  .client-page-container .layout-22 .cards-section {
    grid-gap: 18px;
    margin-top: 48px;
  }

  .client-page-container .layout-22 .primary-image {
    height: 44px;
    margin-bottom: 20px;
  }

  .client-page-container .layout-22 .card-item {
    padding: 22px 20px 26px;
  }

  .client-page-container .layout-22 h6 {
    font-size: 1.286em;
    margin-bottom: 8px;
  }

  .client-page-container .layout-22 span {
    font-size: 1.143em;
    line-height: 22px;
  }

  .client-page-container .layout-22 .grocery-item-card-btn {
    font-size: 1em;
    margin-top: 24px;
    padding: 10px 45px;
  }

  .client-page-container .partners-section .content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 50px 8% 70px;
  }

  .client-page-container .partners-section .content-wrapper h3 {
    font-size: 3em;
    line-height: 53px;
    text-align: center;
  }

  .client-page-container
    .partners-section
    .content-wrapper
    .partners-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .client-page-container
    .partners-section
    .content-wrapper
    .partners-container
    .partner {
    flex: 0 1 45%;
    margin-top: 70px;
  }

  .client-page-container
    .partners-section
    .content-wrapper
    .partners-container
    .partner
    .partner-heading {
    font-size: 1.714em;
    line-height: 30px;
    margin-bottom: 30px;
  }

  .client-page-container .card-layout .content-wrapper .card-section {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
  }

  .client-page-container .card-layout .content-wrapper .card-body {
    height: 100%;
    padding: 35px;
  }

  .client-page-container .single-banner .left-side {
    flex: 0 0 47%;
  }

  .client-page-container .single-banner .right-side {
    flex: 0 0 45%;
  }

  .client-page-container .single-banner .lower-section {
    align-items: center;
    background: hsla(0, 0%, 100%, 0.5);
    border: 2px solid #fff;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(135, 104, 80, 0.031);
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    padding: 30px;
  }

  .client-page-container .single-banner .lower-section > div {
    align-items: center;
    display: flex;
  }

  .client-page-container .single-banner .lower-section > div .img-section {
    align-items: center;
    background-color: #fff;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    height: 48px;
    margin-right: 20px;
    width: 48px;
  }

  .client-page-container .single-banner .lower-section > div img {
    width: 20px;
  }

  .client-page-container .single-banner .lower-section > div p {
    font-family: sspRegular;
    font-size: 1.143em;
    line-height: 22px;
  }

  .client-page-container .single-banner.layoutId-2 .upper-section,
  .client-page-container .single-banner.layoutId-3 .upper-section {
    display: flex;
    justify-content: flex-start;
  }

  .client-page-container .single-banner.layoutId-2 .upper-section .section-img,
  .client-page-container .single-banner.layoutId-3 .upper-section .section-img {
    display: flex;
    align-items: flex-end;
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .left-side,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .left-side {
    text-align: left;
    flex: unset;
    max-width: 561px;
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .left-side
    .feature-text,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .left-side
    .feature-text {
    display: block;
    padding-left: 30px;
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .left-side
    .feature-text
    li
    p,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .left-side
    .feature-text
    li
    p {
    color: #fff;
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .left-side
    .cta-btn,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .left-side
    .cta-btn {
    margin: unset;
    margin-bottom: 20px;
    margin-left: 30px;
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .left-side
    .cta-btn
    > span,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .left-side
    .cta-btn
    > span {
    background: unset;
    box-shadow: none;
    width: unset;
    height: unset;
    margin: 0;
    display: block;
    padding: 0;
    text-transform: none;
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .section-img,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .section-img {
    flex: unset;
  }

  .client-page-container .single-banner.layoutId-3 {
    background-position: 50% 50%;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .upper-section {
    display: flex;
    position: relative;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .upper-section
    .left-side {
    margin-bottom: 60px;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .upper-section
    .left-side
    .cta-btn {
    margin-bottom: 90px;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .upper-section
    .left-side
    .cta-btn
    > span {
    position: absolute;
    left: 0;
    right: 0;
    margin: 40px auto;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .upper-section
    .section-img {
    align-items: flex-start;
    margin-left: 40px;
  }

  .client-page-container .benefits-main-container {
    padding: 80px 40px;
  }

  .client-page-container .benefits-main-container .upper-container {
    text-align: center;
  }

  .client-page-container .benefits-main-container .upper-container h1 {
    font-size: 2.571em;
    line-height: 48px;
  }

  .client-page-container .benefits-main-container .upper-container img {
    width: 50px;
  }

  .client-page-container .benefits-main-container .upper-container p {
    color: #444;
    font-size: 1.286em;
    line-height: 30px;
    margin: 15px 0 0;
  }

  .client-page-container .benefits-main-container .lower-container {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 30px;
    padding: 0 15px;
  }

  .client-page-container
    .benefits-main-container
    .lower-container
    .benefit-wrapper {
    align-items: center;
    flex-direction: column;
    margin: 0;
    padding: 30px 10%;
  }

  .client-page-container
    .benefits-main-container
    .lower-container
    .benefit-wrapper
    .image-container
    img {
    height: 28px;
    width: auto;
  }

  .client-page-container
    .benefits-main-container
    .lower-container
    .benefit-wrapper
    .benefit-details {
    margin: 20px 0 0;
    text-align: center;
  }

  .client-page-container
    .benefits-main-container
    .lower-container
    .benefit-wrapper
    .benefit-details
    p {
    margin: 10px 0 0;
  }

  .client-page-container .benefits-main-container .btn-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .client-page-container .benefits-main-container .btn-container .cta-button {
    align-items: center;
    border-radius: 15px;
    justify-content: center;
    padding: 15px 45px;
    text-transform: uppercase;
    width: 260px;
    background-color: #f74;
    color: #fff;
    display: flex;
    font-family: sspBold;
    text-align: center;
  }

  .client-page-container .benefits-main-container .btn-container .cta-button p {
    font-size: 1.286em;
    line-height: 30px;
    margin-right: 10px;
  }

  .client-page-container
    .benefits-main-container
    .btn-container
    .cta-button
    img {
    height: 18px;
    width: 21px;
  }

  .client-page-container
    .benefits-main-container.layoutId-2
    .lower-container
    .benefit-wrapper {
    align-items: flex-start;
  }

  .client-page-container
    .benefits-main-container.layoutId-2
    .lower-container
    .benefit-wrapper
    .benefit-details {
    text-align: left;
  }

  .client-page-container .benefits-main-container.layoutId-2 .btn-container {
    margin-top: 60px;
  }

  .client-page-container
    .benefits-main-container.layoutId-2
    .btn-container
    .cta-button {
    padding: 18px 42px;
  }

  .client-page-container
    .benefits-main-container.layoutId-2
    .btn-container
    .cta-button
    p {
    line-height: 24px;
    font-size: 1.143em;
  }

  .client-page-container .investment-nudge-category-container {
    padding: 115px 35px;
    text-align: center;
  }

  .client-page-container .investment-nudge-category-container .heading img {
    width: 45px;
  }

  .client-page-container .investment-nudge-category-container h3 {
    font-size: 2.571em;
    line-height: 48px;
  }

  .client-page-container .investment-nudge-category-container p {
    font-size: 1.143em;
    line-height: 26px;
    margin-top: 5px;
  }

  .client-page-container
    .investment-nudge-category-container
    .input-search-wrapper {
    margin: 35px auto 10px;
    max-width: 45%;
    min-width: 350px;
  }

  .client-page-container
    .investment-nudge-category-container
    .category-container {
    flex-wrap: nowrap;
    justify-content: space-evenly;
    padding-top: 30px;
  }

  .client-page-container
    .investment-nudge-category-container
    .category-container
    .category {
    flex: 0 0 16.6%;
    margin-top: 0;
  }

  .client-page-container
    .investment-nudge-category-container
    .category-container
    .category
    p {
    font-size: 1.143em;
  }

  .client-page-container .investment-nudge-category-container .btn-container {
    margin-top: 50px;
  }

  .client-page-container
    .investment-nudge-category-container
    .btn-container
    .cta-button {
    font-size: 1.286em;
  }

  .client-page-container .interest-calculator {
    background-position: 15% 110%;
    padding: 72px 32px 95px;
  }

  .client-page-container .interest-calculator .heading {
    justify-content: center;
  }

  .client-page-container .interest-calculator .heading h3 {
    font-size: 3em;
    line-height: 60px;
  }

  .client-page-container .interest-calculator .heading img {
    width: 55px;
  }

  .client-page-container .interest-calculator .sub-heading {
    color: #444;
    font-size: 1.143em;
    line-height: 22px;
    margin-bottom: 35px;
    text-align: center;
  }

  .client-page-container .interest-calculator .switch-item-container {
    height: 60px;
    margin: 0 auto 60px;
    max-width: 450px;
  }

  .client-page-container .interest-calculator .switch-item-container li {
    font-size: 1.143em;
  }

  .client-page-container .interest-calculator .interest-ranges-sliders-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .client-page-container .interest-calculator .interest-ranges-sliders {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }

  .client-page-container
    .interest-calculator
    .interest-ranges-sliders
    .range-slider-container {
    margin-bottom: 30px;
  }

  .client-page-container
    .interest-calculator
    .interest-ranges-sliders
    .range-slider-container:last-child {
    margin-bottom: 0;
  }

  .client-page-container
    .interest-calculator
    .interest-ranges-sliders
    .upper-section
    label {
    font-size: 1.143em;
  }

  .client-page-container .interest-calculator .pie-chart-section {
    width: 250px;
  }

  .client-page-container .interest-calculator .calculated-investments-section {
    border-radius: 25px;
    display: block;
    padding: 20px 24px;
  }

  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .interest-amt,
  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .invested-amt,
  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .total-amt {
    margin-bottom: 0;
  }

  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .interest-amt
    .dot,
  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .invested-amt
    .dot,
  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .total-amt
    .dot {
    height: 10px;
    margin-top: 0;
    width: 10px;
  }

  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .interest-amt
    label,
  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .invested-amt
    label,
  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .total-amt
    label {
    margin-bottom: 8px;
  }

  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .interest-amt
    p,
  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .invested-amt
    p,
  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .total-amt
    p {
    font-size: 1.429em;
    line-height: 26px;
  }

  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .cta-btn {
    align-items: center;
    background-color: #f16122;
    border-radius: 15px;
    color: #fff;
    display: flex;
    font-family: sspBold;
    font-size: 1.286em;
    justify-content: center;
    line-height: 24px;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 240px;
  }

  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .cta-btn
    .next-img {
    margin-left: 13px;
  }

  .client-page-container .faq2.section-wrapper {
    padding: 80px 40px;
  }

  .client-page-container .faq .content-wrapper .heading h3 img {
    height: 36px;
    width: 30px;
  }

  .client-page-container .faq.faq-with-doughnut-section .content-wrapper {
    display: flex;
  }

  .client-page-container
    .faq.faq-with-doughnut-section
    .content-wrapper
    .doughnut-chart {
    display: flex;
    align-items: center;
    min-width: 325px;
    margin-left: 40px;
  }

  .client-page-container .faq.faq-with-doughnut-section .cta-btn > span {
    align-items: center;
    background-color: #f74;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: sspBold;
    font-size: 1.143em;
    justify-content: center;
    line-height: 24px;
    padding: 15px;
    text-transform: uppercase;
    width: 242px;
    margin: 60px auto 0;
    box-shadow: 1px 8px 20px 0 rgba(255, 119, 68, 0.15);
  }

  .client-page-container .banner-section.vcc-banner-1 .vcc-card-image {
    width: 465px;
  }

  .client-page-container .banner-section.vcc-banner-3 .vcc-card-image {
    width: 275px;
  }

  .client-page-container .cards-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    margin: 30px auto;
    padding: 0 20px;
  }

  .client-page-container .cards-section .banner-section,
  .client-page-container .cards-section .vcc-image-section {
    margin: 0;
  }

  .client-page-container .cards-section .vcc-card-image {
    height: 100%;
  }

  .client-page-container .features-with-score .cta-btn > span {
    align-items: center;
    background-color: #f74;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: sspBold;
    font-size: 1.143em;
    justify-content: center;
    line-height: 24px;
    padding: 15px;
    text-transform: uppercase;
    width: 242px;
    margin: 60px auto 15px;
    box-shadow: 1px 8px 20px 0 rgba(255, 119, 68, 0.15);
  }

  .client-page-container
    .features-with-score
    .score-section
    .score-bar
    .score-container
    .score-background {
    margin-bottom: 4px;
  }

  .client-page-container .tabbed-lists {
    padding: 20px 20px 50px;
  }

  .client-page-container .tabbed-lists h1 {
    padding: 0;
  }

  .client-page-container .tabbed-lists .cta-btn > span {
    align-items: center;
    background-color: #f74;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: sspBold;
    font-size: 1.143em;
    justify-content: center;
    line-height: 24px;
    padding: 15px;
    text-transform: uppercase;
    width: 242px;
    margin: 0 auto;
    box-shadow: 1px 8px 20px 0 rgba(255, 119, 68, 0.15);
  }

  .client-page-container .tabbed-lists .list-content {
    display: flex;
    margin-bottom: 40px;
  }

  .client-page-container .tabbed-lists .list-content ul h2 {
    font-family: sspBold;
    font-size: 1.286em;
    line-height: 24px;
    margin-bottom: 25px;
  }

  .client-page-container .tabbed-lists .list-content ul:not(:last-child) {
    margin-right: 40px;
  }

  .client-page-container
    .videos-preview-wrapper
    .slider-wrapper
    .slider-section {
    padding: 0 5px;
  }

  .client-page-container
    .videos-preview-wrapper
    .video-preview-slider
    .thumbnail-overlay
    span {
    height: 72px;
    width: 72px;
  }

  .client-page-container
    .videos-preview-wrapper
    .video-preview-slider
    .thumbnail-overlay
    span
    img {
    width: 22px;
  }

  .client-page-container
    .videos-preview-wrapper
    .videos-preview-pop-up
    .pop-up {
    padding: 50px;
  }

  .client-page-container .seo-grid-section ul {
    grid-template-columns: repeat(5, 1fr);
  }

  .client-page-container .seo-grid-section ul li {
    min-height: 180px;
    padding: 25px 35px;
  }

  .client-page-container .seo-grid-section ul li img {
    width: 75px;
  }

  .client-page-container .seo-grid-section ul li label {
    font-size: 1.143em;
    line-height: 22px;
  }

  .client-page-container .seo-grid-section ul li span {
    font-size: 0.857em;
    line-height: 16px;
  }

  .services-popup.form-pop-up .pop-up {
    border-radius: 20px;
    min-height: unset;
  }
}

@media (max-width: 1024px) {
  .client-page-container .services-overview .service .lottie-section {
    margin: 0 auto;
  }
}

@media (min-width: 1025px) {
  .homepage .content-wrapper,
  .homepage .main-wrapper {
    max-width: 1320px;
  }

  .client-page-container .layout-1 .slider-arrows {
    overflow: hidden;
    padding: 0;
  }

  .client-page-container .layout-1 .slider-arrows .slider-track .arrow {
    background-color: rgba(34, 34, 34, 0.2);
    z-index: 1;
    box-shadow: 0 0 50px 50px rgba(34, 34, 34, 0.2);
  }

  .client-page-container
    .layout-1
    .slider-arrows
    .slider-track
    .arrow:disabled {
    cursor: not-allowed;
  }

  .client-page-container
    .layout-1
    .slider-arrows
    .slider-track
    .arrow.left-arrow {
    left: 0;
    padding: 20px 20px 20px 50px;
  }

  .client-page-container
    .layout-1
    .slider-arrows
    .slider-track
    .arrow.right-arrow {
    padding: 20px 50px 20px 20px;
    right: 0;
  }

  .client-page-container .layout-1 .slider-arrows .slider-track .arrow span {
    border-color: #fff;
    height: 18px;
    width: 18px;
  }

  .client-page-container .layout-1 .slider-indicators {
    bottom: 40px;
  }

  .client-page-container .layout-1 .content-wrapper {
    min-height: 485px;
  }

  .client-page-container .layout-1 .banner-image {
    margin-bottom: 10px;
  }

  .client-page-container .layout-1 .banner-image img {
    height: 56px;
    margin-right: 20px;
  }

  .client-page-container .layout-1 .banner-image span {
    font-size: 1.286em;
    line-height: 22px;
  }

  .client-page-container .layout-1 h3 {
    font-size: 3em;
    line-height: 56px;
  }

  .client-page-container .layout-1 p {
    font-size: 1.714em;
    line-height: 32px;
    margin: 20px 0;
  }

  .client-page-container .layout-1 .layout-slide.slide-1 .content-wrapper {
    padding: 80px 150px;
  }

  .client-page-container .layout-1 .layout-slide.slide-2 .content-wrapper {
    padding-left: 150px;
    padding-right: 150px;
  }

  .client-page-container .layout-1 .layout-slide.slide-3 .content-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 100px;
  }

  .client-page-container
    .layout-1
    .layout-slide.slide-3
    .content-wrapper
    .content-section {
    flex: 0 0 35%;
  }

  .client-page-container .layout-1 .layout-slide.slide-5 .content-wrapper {
    padding: 99px 150px 175px;
  }

  .client-page-container .layout-1 .layout-slide.slide-5 p {
    font-size: 1.286em;
    line-height: 1.67;
    margin-bottom: 50px;
    max-width: 574px;
  }

  .client-page-container .layout-1 .video-wrapper {
    flex: 0 0 60%;
    height: 350px;
    width: auto;
  }

  .client-page-container .layout-1 .image-section {
    margin-top: 75px;
  }

  .client-page-container .layout-1 .slide-btn {
    font-size: 1.286em;
    padding: 21px;
  }

  .client-page-container .layout-1 h2 {
    font-size: 3.6em;
    line-height: 55px;
    margin-bottom: 12px;
    max-width: 418px;
  }

  .client-page-container .layout-2 .tab-labels {
    top: 75px;
  }

  .client-page-container .layout-2.scrollable-tabs-wrapper,
  .client-page-container .layout-2.swipeable-tabs-wrapper {
    margin-top: 10px;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .tab-labels
    .tab-option,
  .client-page-container
    .layout-2.swipeable-tabs-wrapper
    .tab-labels
    .tab-option {
    font-size: 1.286em;
    margin-left: 20px;
    margin-right: 20px;
  }

  .client-page-container .layout-2.scrollable-tabs-wrapper .tab-labels {
    border-radius: 15px;
    padding-left: 30px;
    padding-right: 16px;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .tab-labels
    .tab-option {
    font-size: 1.143em;
  }

  .client-page-container .layout-2.scrollable-tabs-wrapper .disclaimer,
  .client-page-container .layout-2.scrollable-tabs-wrapper .security-container,
  .client-page-container .layout-2.scrollable-tabs-wrapper .stepper {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
  }

  .client-page-container .layout-2.scrollable-tabs-wrapper .security-container {
    margin-top: 60px;
    padding: 80px 0;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .security-container
    .content-wrapper {
    padding-top: 0;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .security-container
    .security-info {
    text-align: left;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .security-container
    .security-info
    .header {
    line-height: 68px;
    font-size: 3.8em;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .security-container
    .security-info
    .sub-header {
    line-height: 30px;
    font-size: 1.429em;
    max-width: 470px;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .security-container
    .security-badge {
    margin-right: 20%;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .security-container
    .security-badge
    img {
    margin-right: 0;
  }

  .client-page-container .layout-2.scrollable-tabs-wrapper .stepper {
    max-width: unset;
    margin-top: 120px;
  }

  .client-page-container .layout-2.scrollable-tabs-wrapper .services-overview {
    margin-top: 50px;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .services-overview
    .service
    h3 {
    font-size: 3em;
    line-height: 68px;
    margin-bottom: 55px;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .services-overview
    .service
    .service-features {
    font-size: unset;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .services-overview
    .service
    .service-features
    h3 {
    line-height: 30px;
    font-size: 1.429em;
    margin-bottom: 30px;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .services-overview
    .service
    .service-features
    li {
    font-size: 1.286em;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .services-overview
    .service
    .service-features
    .sub-list {
    margin-bottom: 40px;
  }

  .client-page-container .layout-2.scrollable-tabs-wrapper .layout-8 {
    margin-top: 120px;
  }

  .client-page-container .layout-2.scrollable-tabs-wrapper .layout-8 h3 {
    font-size: 3em;
    margin-bottom: 37px;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .layout-8
    .list-data
    .list-item {
    border-radius: 25px;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .layout-8
    .list-data
    .list-item
    .upper-section
    p {
    line-height: 24px;
    font-size: 1.429em;
  }

  .client-page-container
    .layout-2.scrollable-tabs-wrapper
    .layout-8
    .list-data
    .list-item
    .lower-section
    p {
    font-size: 1.143em;
  }

  .client-page-container .layout-3 {
    position: relative;
  }

  .client-page-container
    .layout-3.layout-id-3
    .content-wrapper
    .content-section
    h3,
  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .content-section
    h3 {
    font-size: 3.428em;
    line-height: 64px;
    margin-bottom: 32px;
  }

  .client-page-container
    .layout-3.layout-id-3
    .content-wrapper
    .content-section
    .extra-info,
  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .content-section
    .extra-info {
    padding: 20px 24px;
  }

  .client-page-container
    .layout-3.layout-id-3
    .content-wrapper
    .content-section
    .extra-info
    p,
  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .content-section
    .extra-info
    p {
    font-size: 1.429em;
    line-height: 24px;
  }

  .client-page-container .layout-3.layout-id-3 .content-wrapper .text-content p,
  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .text-content
    p {
    font-size: 1.571em;
    line-height: normal;
    margin: 0 20px 40px 0;
  }

  .client-page-container
    .layout-3.layout-id-3
    .content-wrapper
    .overview-image-section
    img,
  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .overview-image-section
    img {
    width: 100%;
  }

  .client-page-container .layout-3.layout-id-3 .content-wrapper {
    padding: 40px 125px;
  }

  .client-page-container .layout-3.layout-id-4 .content-wrapper {
    flex-direction: row-reverse;
    padding: 40px 80px;
  }

  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .content-section
    h3 {
    font-size: 3em;
    line-height: normal;
    margin: 0 24px 20px;
  }

  .client-page-container
    .layout-3.layout-id-4
    .content-wrapper
    .text-content
    p {
    font-size: 1.429em;
    line-height: 28px;
    margin: 0 24px 30px;
  }

  .client-page-container .layout-3.layout-id-5 .content-wrapper,
  .client-page-container .layout-3.layout-id-6 .content-wrapper {
    margin-top: 40px;
    padding: 0 60px;
  }

  .client-page-container
    .layout-3.layout-id-5
    .content-wrapper
    .content-section
    h3,
  .client-page-container
    .layout-3.layout-id-6
    .content-wrapper
    .content-section
    h3 {
    margin-bottom: 24px;
  }

  .client-page-container
    .layout-3.layout-id-5
    .content-wrapper
    .content-section
    .text-content
    p,
  .client-page-container
    .layout-3.layout-id-6
    .content-wrapper
    .content-section
    .text-content
    p {
    font-size: 1.714em;
    margin-bottom: 40px;
  }

  .client-page-container
    .layout-3.layout-id-5
    .content-wrapper
    .content-section
    .list-content
    li,
  .client-page-container
    .layout-3.layout-id-6
    .content-wrapper
    .content-section
    .list-content
    li {
    font-size: 1.714em;
    line-height: 32px;
    margin-bottom: 40px;
    margin-left: 10px;
    padding-left: 50px;
    position: relative;
  }

  .client-page-container
    .layout-3.layout-id-5
    .content-wrapper
    .content-section
    .list-content
    li:before,
  .client-page-container
    .layout-3.layout-id-6
    .content-wrapper
    .content-section
    .list-content
    li:before {
    border-radius: 2px;
    border: solid #f74;
    border-width: 0 3px 3px 0;
    content: "";
    display: block;
    height: 12px;
    left: 11px;
    position: absolute;
    top: 9px;
    width: 6px;
    z-index: 1;
    transform: rotate(45deg);
  }

  .client-page-container
    .layout-3.layout-id-5
    .content-wrapper
    .content-section
    .list-content
    li:after,
  .client-page-container
    .layout-3.layout-id-6
    .content-wrapper
    .content-section
    .list-content
    li:after {
    background: #fff3df;
    border-radius: 50%;
    content: "";
    display: block;
    height: 32px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 32px;
  }

  .client-page-container
    .layout-3.layout-id-5
    .content-wrapper
    .content-section
    .extra-info,
  .client-page-container
    .layout-3.layout-id-6
    .content-wrapper
    .content-section
    .extra-info {
    align-self: flex-start;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  .client-page-container
    .layout-3.layout-id-5
    .content-wrapper
    .content-section
    .extra-info
    p,
  .client-page-container
    .layout-3.layout-id-6
    .content-wrapper
    .content-section
    .extra-info
    p {
    font-size: 1.714em;
  }

  .client-page-container .layout-3.layout-id-5 .content-wrapper .overview-btn,
  .client-page-container .layout-3.layout-id-6 .content-wrapper .overview-btn {
    height: 60px;
    line-height: 20px;
    padding: 20px 27px;
    width: 251px;
  }

  .client-page-container .layout-3.layout-id-2,
  .client-page-container .layout-3.layout-id-6 {
    padding-bottom: 70px;
    padding-top: 70px;
  }

  .client-page-container .layout-3.layout-id-2 p,
  .client-page-container .layout-3.layout-id-6 p {
    font-size: 1.286em;
    line-height: 24px;
  }

  .client-page-container .layout-3.layout-id-2 .content-wrapper,
  .client-page-container .layout-3.layout-id-6 .content-wrapper {
    padding-left: 50px;
  }

  .client-page-container .layout-3.layout-id-2 .content-section,
  .client-page-container .layout-3.layout-id-6 .content-section {
    order: 2;
  }

  .client-page-container .layout-3.layout-id-2 .overview-image-section,
  .client-page-container .layout-3.layout-id-6 .overview-image-section {
    order: 1;
    text-align: left;
  }

  .client-page-container .layout-3 .content-wrapper {
    padding-left: 150px;
    padding-right: 80px;
  }

  .client-page-container .layout-3 h3 {
    font-size: 3.428em;
    line-height: 56px;
  }

  .client-page-container .layout-3 p {
    font-size: 1.714em;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .client-page-container .layout-3 .image-section .secured-img {
    width: 43px;
  }

  .client-page-container .layout-3 .image-section .iso-img {
    width: 63px;
  }

  .client-page-container .layout-3 .overview-btn {
    margin-bottom: 20px;
  }

  .client-page-container .layout-3.left-aligned {
    padding-bottom: 100px;
  }

  .client-page-container .layout-3.left-aligned .content-wrapper {
    border-radius: 50px;
    margin: 0 auto;
    padding: 70px 70px 70px 100px;
  }

  .client-page-container .layout-3.left-aligned h3 {
    font-family: sspBold;
    font-size: 3.8em;
    line-height: 68px;
    margin-bottom: 12px;
    padding-right: 0;
  }

  .client-page-container .layout-3.left-aligned p {
    color: #222;
    font-size: 1.429em;
    line-height: 30px;
    max-width: 502px;
    padding-right: 0;
  }

  .client-page-container .layout-3.left-aligned .download-btn {
    align-items: center;
    background: #f16122;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: sspBold;
    font-size: 1.143em;
    height: 56px;
    justify-content: center;
    width: 225px;
  }

  .client-page-container .layout-3.left-aligned .download-btn span {
    margin-right: 15px;
  }

  .client-page-container .layout-3.left-aligned .download-btn img {
    height: 18px;
    margin-right: 8px;
    width: 15px;
  }

  .client-page-container .layout-3.left-aligned .download-icons.qrcode {
    align-items: center;
    display: flex;
    margin-top: 0;
  }

  .client-page-container .layout-3.left-aligned .download-icons.qrcode img {
    margin-right: 10px;
  }

  .client-page-container
    .layout-3.left-aligned
    .download-icons
    .qr-code-wrapper {
    margin-right: 25px;
  }

  .client-page-container .layout-3.left-aligned .download-icons .qr-code-text {
    font-size: 1.286em;
    line-height: 30px;
    margin-bottom: 13px;
  }

  .client-page-container .layout-3.refer {
    padding: 120px 100px;
  }

  .client-page-container .layout-3.refer .download-btn {
    align-items: center;
    background: #f16122;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: sspBold;
    font-size: 1.143em;
    height: 56px;
    justify-content: center;
    width: 225px;
  }

  .client-page-container .layout-3.refer .download-btn span {
    margin-right: 15px;
  }

  .client-page-container .layout-3.refer .download-btn img {
    height: 18px;
    margin-right: 8px;
    width: 15px;
  }

  .client-page-container .layout-3.refer .content-wrapper {
    border-radius: 50px;
    padding: 120px 100px 0;
  }

  .client-page-container .layout-3.refer h3 {
    font-size: 2.71em;
    line-height: 48px;
    margin-bottom: 12px;
  }

  .client-page-container .layout-3.refer p {
    font-size: 1.286em;
    line-height: 28px;
    margin-bottom: 28px;
    max-width: 554px;
  }

  .client-page-container .layout-3.refer .content-section {
    align-self: flex-start;
  }

  .client-page-container .layout-3.refer .overview-image-section {
    margin-top: 30px;
  }

  .client-page-container .layout-3.refer .overview-image-section img {
    width: 440px;
  }

  .client-page-container .layout-4 {
    padding: 0 140px;
  }

  .client-page-container .layout-4 .heading {
    font-size: 3em;
    line-height: 48px;
    margin-bottom: 70px;
  }

  .client-page-container .layout-4 .step-items {
    margin-bottom: 60px;
  }

  .client-page-container .layout-4 .step-item {
    flex: 0 0 25%;
    position: relative;
  }

  .client-page-container .layout-4 .step-item:before {
    /* background-image: url(../../../../images/payLater/seoPage/dotted-background.png); */
    background-repeat: no-repeat;
    content: "";
    height: 100px;
    left: -80px;
    position: absolute;
    top: -45px;
    width: 130px;
  }

  .client-page-container .layout-4 .step-item-oval {
    font-size: 3em;
    height: 52px;
    padding-bottom: 3px;
    width: 52px;
  }

  .client-page-container .layout-4 .step-title {
    font-size: 3em;
    line-height: 48px;
  }

  .client-page-container .layout-4 .step-subtitle {
    font-size: 1.286em;
    line-height: 28px;
  }

  .client-page-container .layout-4.how-to-check {
    max-width: unset;
    padding: 105px 20px 120px;
  }

  .client-page-container .layout-4.how-to-check .heading {
    line-height: 60px;
    font-size: 3.428em;
    margin-bottom: 60px;
  }

  .client-page-container .layout-4.how-to-check .step-items {
    margin: 60px auto;
    max-width: 1106px;
  }

  .client-page-container .layout-4.how-to-check .step-items .step-item {
    flex: 0 0 28%;
    margin-bottom: 0;
  }

  .client-page-container .layout-4.how-to-check .step-item-oval {
    font-size: 4.28em;
    line-height: 72px;
  }

  .client-page-container .layout-4.how-to-check .step-subtitle {
    font-size: 1.286em;
    line-height: 28px;
  }

  .client-page-container .layout-4.stepper {
    position: relative;
    padding: 90px 0 10px;
  }

  .client-page-container .layout-4.stepper .step-items {
    flex-wrap: wrap;
    max-width: 1320px;
    margin: 56px auto 60px;
  }

  .client-page-container .layout-4.stepper .step-item:not(:last-child):after {
    content: "";
    position: relative;
    top: 27px;
    left: 50%;
    height: 2px;
    order: -1;
    border-bottom: 2px dashed #ffc89a;
  }

  .client-page-container .layout-4.stepper .step-item {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .client-page-container .layout-4.stepper .step-item:before {
    content: none;
  }

  .client-page-container .layout-4.stepper .step-item-oval {
    margin-bottom: 36px;
  }

  .client-page-container .layout-4.stepper .heading {
    font-size: 3em;
    margin-bottom: 26px;
  }

  .client-page-container .layout-4.stepper .sub-heading {
    line-height: 24px;
    font-size: 1.429em;
  }

  .client-page-container .layout-4.stepper .step-title {
    font-size: 1.429em;
    line-height: 26px;
    max-width: 216px;
    margin: 0 auto 24px;
  }

  .client-page-container .layout-4.stepper .step-subtitle {
    font-size: 1.286em;
    line-height: 24px;
    max-width: 234px;
    margin: auto;
  }

  .client-page-container .layout-5 {
    padding: 40px 120px;
  }

  .client-page-container .layout-5 .users-count-section {
    flex-basis: 440px;
    margin-left: 0;
  }

  .client-page-container .layout-5 .users-count-container {
    border-radius: 40px;
    bottom: 40px;
    left: 60px;
    padding: 40px;
    right: 100px;
    top: 50px;
  }

  .client-page-container .layout-5 .users-count-container img {
    margin-bottom: 20px;
    width: 80px;
  }

  .client-page-container .layout-5 .users-count-container span {
    font-size: 1.286em;
    line-height: 24px;
  }

  .client-page-container .layout-5 .users-count-container span:not(.count) {
    max-width: 80%;
  }

  .client-page-container .layout-5 .users-count-container .count {
    font-size: 3.428em;
    line-height: 56px;
  }

  .client-page-container .layout-5 h3 {
    font-size: 3em;
    line-height: 48px;
    margin-bottom: 40px;
  }

  .client-page-container .layout-5 .content-section {
    flex: 0 0 55%;
  }

  .client-page-container .layout-5 .features-list {
    grid-template-columns: repeat(4, 1fr);
  }

  .client-page-container .layout-5 .features-list img {
    width: 80px;
  }

  .client-page-container .layout-5 .text-content {
    font-size: 1.286em;
    line-height: 26px;
  }

  .client-page-container .layout-5.secondary .content-section {
    flex: 0 0 100%;
  }

  .client-page-container .layout-6 .content-wrapper {
    padding: 100px 0 80px;
  }

  .client-page-container .layout-6 .actionable-desc-title {
    font-size: 3em;
    line-height: 48px;
  }

  .client-page-container .layout-6 .actionable-desc-subtitle {
    font-size: 1.714em;
    line-height: 32px;
    max-width: 400px;
  }

  .client-page-container .layout-7 {
    padding: 30px 160px;
  }

  .client-page-container .layout-7 h3 {
    font-size: 3em;
    line-height: 48px;
    margin-bottom: 40px;
  }

  .client-page-container .layout-7 .flex-container {
    margin-left: 0;
  }

  .client-page-container .layout-7 .image-container-desktop {
    flex: 0 0 30%;
    margin-right: 60px;
  }

  .client-page-container .layout-7 .image-container-desktop img {
    width: 100%;
  }

  .client-page-container .layout-7 .slider-wrapper {
    margin-right: 60px;
  }

  .client-page-container
    .layout-7
    .slider-wrapper
    .slider-indicators
    .indicator {
    height: 5px;
    width: 20px;
  }

  .client-page-container .layout-7 .layout-slide img {
    width: 100%;
  }

  .client-page-container .layout-7 .reasons-list {
    grid-gap: 30px;
    grid-template-columns: repeat(2, 275px);
  }

  .client-page-container .layout-7 .reasons-list li {
    min-height: 200px;
    padding: 48px 30px 30px;
  }

  .client-page-container .layout-7 .reasons-list li:nth-child(2n) {
    transform: translateY(40px);
  }

  .client-page-container .layout-7 .reasons-list li.active:first-child {
    transform: translate3d(-15px, -15px, 0);
    box-shadow: 10px 10px 15px 0 rgba(255, 119, 68, 0.4);
  }

  .client-page-container .layout-7 .reasons-list li.active:nth-child(2) {
    transform: translate3d(15px, 25px, 0);
    box-shadow: -10px 10px 15px 0 rgba(255, 119, 68, 0.4);
  }

  .client-page-container .layout-7 .reasons-list li.active:nth-child(3) {
    transform: translate3d(-15px, 15px, 0);
    box-shadow: 10px -10px 15px 0 rgba(255, 119, 68, 0.4);
  }

  .client-page-container .layout-7 .reasons-list li.active:nth-child(4) {
    transform: translate3d(15px, 55px, 0);
    box-shadow: -10px -10px 15px 0 rgba(255, 119, 68, 0.4);
  }

  .client-page-container .layout-7 .reasons-list li h4 {
    font-size: 1.857em;
    line-height: 34px;
  }

  .client-page-container .layout-7 .reasons-list li p {
    font-size: 1.143em;
    line-height: 22px;
  }

  .client-page-container .layout-7 .reason-apply-btn {
    background-color: #f74;
    color: #fff;
    display: block;
    font-family: sspBold;
    text-align: center;
    border-radius: 10px;
    font-size: 1.286em;
    line-height: 24px;
    margin: 70px auto 0;
    padding: 18px;
    width: 330px;
  }

  .client-page-container .layout-8 {
    padding-left: 125px;
    padding-right: 125px;
  }

  .client-page-container .layout-8 h3 {
    font-size: 3em;
    line-height: 48px;
  }

  .client-page-container .layout-9 {
    background-size: 100% 480px;
  }

  .client-page-container .layout-9 h3 {
    font-size: 3em;
    line-height: 48px;
    margin: 70px 0 45px;
  }

  .client-page-container .layout-9 .million-container img {
    left: -185px;
    width: 240px;
  }

  .client-page-container .layout-10 {
    padding: 20px 160px 0;
  }

  .client-page-container .layout-10 .expandable-info-title {
    font-size: 3em;
    line-height: 48px;
  }

  .client-page-container .layout-10 .expandable-info-title h3 {
    display: flex;
  }

  .client-page-container .layout-10 .expandable-info-title b {
    margin-left: 10px;
  }

  .client-page-container .layout-11 .content-wrapper {
    padding: 60px 85px;
  }

  .client-page-container .layout-11 h3 {
    font-size: 3em;
    line-height: 48px;
  }

  .client-page-container .layout-11 .subtitle {
    font-size: 1.286em;
    line-height: 24px;
  }

  .client-page-container .layout-11 .screen {
    margin-bottom: 40px;
  }

  .client-page-container .layout-11 .divider-img {
    right: -50px;
    width: 55px;
  }

  .client-page-container .layout-11 h6 {
    font-size: 1.714em;
    line-height: 32px;
  }

  .client-page-container .layout-11 .step-subtitle {
    font-size: 1.286em;
    line-height: 24px;
    margin: 0 10%;
  }

  .client-page-container .layout-11.secondary {
    background-color: #ffeee8;
    padding: 0;
  }

  .client-page-container .layout-11.secondary .content-wrapper {
    border-radius: 0;
    margin: auto;
    padding: 60px 120px 90px;
  }

  .client-page-container .layout-11.secondary h3 {
    font-size: 3em;
    line-height: 48px;
    margin-bottom: 20px;
  }

  .client-page-container .layout-11.secondary h6 {
    font-size: 2em;
    line-height: 32px;
  }

  .client-page-container .layout-11.secondary .subtitle {
    font-size: 1.714em;
    line-height: 36px;
  }

  .client-page-container .layout-11.secondary .steps {
    -moz-column-gap: 42px;
    column-gap: 42px;
    row-gap: 30px;
  }

  .client-page-container .layout-11.secondary .step {
    border-radius: 24px;
    padding: 24px 32px 0;
  }

  .client-page-container .layout-11.secondary .step-header {
    margin-bottom: 28px;
  }

  .client-page-container .layout-11.secondary .screen {
    height: 80px;
    margin-bottom: 14px;
  }

  .client-page-container .layout-11.secondary .sub-steps {
    padding-bottom: 28px;
  }

  .client-page-container .layout-11.secondary .sub-step {
    margin-bottom: 20px;
  }

  .client-page-container .layout-11.secondary .sub-step p {
    font-size: 1.286em;
    line-height: 24px;
  }

  .client-page-container .layout-11.secondary .sub-step-index-oval {
    align-items: center;
    background-color: #222;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-family: sspBold;
    font-size: 1em;
    justify-content: center;
    min-height: 21px;
    min-width: 21px;
    margin-right: 10px;
  }

  .client-page-container .layout-11.secondary .btn-section {
    margin-top: 80px;
  }

  .client-page-container .layout-11.secondary .how-it-works-button {
    padding: 20px 105px;
  }

  .client-page-container .layout-11.secondary .desc-wrapper {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    margin: 0 -32px;
    padding: 28px;
  }

  .client-page-container .layout-11.secondary .desc-wrapper p {
    font-size: 1.286em;
    line-height: 22px;
  }

  .client-page-container .layout-12 {
    padding: 40px 160px;
  }

  .client-page-container .layout-12 .recommended-blogs-title {
    font-size: 3em;
    line-height: 48px;
  }

  .client-page-container .layout-12 .recommended-blogs-button {
    margin-top: 40px;
  }

  .client-page-container .layout-12 .recommended-blogs-slider {
    flex: 0 0 65%;
  }

  .client-page-container .layout-13 h1 {
    font-size: 3em;
    line-height: 48px;
  }

  .client-page-container .layout-13 p {
    font-size: 1.714em;
    line-height: 32px;
    width: 45%;
  }

  .client-page-container .layout-13 .slider-wrapper {
    margin: 5px 25px;
  }

  .client-page-container .layout-14 {
    padding: 60px 150px 80px;
  }

  .client-page-container .layout-14 h3 {
    font-size: 3em;
    line-height: 56px;
  }

  .client-page-container .layout-14 p {
    font-size: 1.286em;
    line-height: 28px;
  }

  .client-page-container .layout-14 .text-content {
    flex: 0 0 45%;
    margin-top: 60px;
  }

  .client-page-container .layout-14 .items-list {
    margin-bottom: 40px;
  }

  .client-page-container .layout-14 .item {
    margin: 0 36px;
  }

  .client-page-container .layout-14 .item h6 {
    font-size: 1.429em;
    line-height: 28px;
  }

  .client-page-container .layout-14 .image-container {
    height: 72px;
    width: 72px;
  }

  .client-page-container .layout-14 .image-container img {
    height: 36px;
    width: auto;
  }

  .client-page-container .layout-14 .extra-info {
    margin-top: 25px;
  }

  .client-page-container .layout-14.secondary {
    padding: 80px 80px 60px;
  }

  .client-page-container .layout-14.secondary h3 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  .client-page-container .layout-14.secondary p {
    color: #222;
    font-size: 1.571em;
    line-height: 26px;
  }

  .client-page-container .layout-14.secondary .text-content {
    flex-basis: 60%;
  }

  .client-page-container .layout-14.secondary .text-content p {
    line-height: 28px;
    margin-bottom: 50px;
  }

  .client-page-container .layout-14.secondary .image-container img {
    margin-bottom: 16px;
  }

  .client-page-container .layout-14.secondary .item h6 {
    font-size: 1.714em;
    line-height: 32px;
    margin-bottom: 32px;
  }

  .client-page-container .layout-14.secondary .item p {
    font-size: 1.429em;
    line-height: 26px;
  }

  .client-page-container .layout-16 {
    padding: 50px;
  }

  .client-page-container .layout-16 .layout-heading h3 {
    font-size: 3em;
    line-height: 48px;
    margin: 0 0 60px;
    max-width: 100%;
    text-align: left;
  }

  .client-page-container .layout-16 .list-data {
    grid-template-columns: repeat(3, 1fr);
  }

  .client-page-container .layout-16 .list-data li h3 {
    font-size: 1.714em;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .client-page-container .layout-16 .list-data li p {
    font-size: 1em;
    line-height: 20px;
    max-width: 75%;
  }

  .client-page-container .layout-17 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px 50px;
    text-align: left;
  }

  .client-page-container .layout-17 .image-section {
    flex: 0 0 45%;
  }

  .client-page-container .layout-17 .content-section {
    flex: 0 0 35%;
    margin-right: 15%;
    padding: 0;
  }

  .client-page-container .layout-17 h3 {
    font-size: 3em;
    line-height: 48px;
    max-width: 100%;
    text-align: left;
  }

  .client-page-container .layout-17 h4 {
    font-size: 2.29em;
    line-height: 38px;
    max-width: 100%;
    text-align: left;
  }

  .client-page-container .layout-17 p {
    font-size: 1.286em;
    line-height: 28px;
    margin: 0;
    text-align: left;
  }

  .client-page-container .layout-17 .external-link {
    margin: 0;
    width: 100%;
  }

  .client-page-container .layout-18 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 50px;
  }

  .client-page-container .layout-18 .image-section {
    flex: 0 1 50%;
    order: 2;
  }

  .client-page-container .layout-18 .content-section {
    flex: 0 1 35%;
    order: 1;
  }

  .client-page-container .layout-18 h3 {
    font-size: 3em;
    line-height: 48px;
    margin: 0 0 20px;
    max-width: 100%;
    text-align: left;
  }

  .client-page-container .layout-18 p {
    margin: 0;
    text-align: left;
  }

  .client-page-container .layout-19 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 75px auto;
    padding: 0 50px;
  }

  .client-page-container .layout-19 .content-section {
    padding: 0;
    text-align: left;
    width: 40%;
  }

  .client-page-container .layout-19 .content-section h3 {
    font-size: 3em;
    line-height: 48px;
    margin: 0 0 20px;
    text-align: left;
    width: 100%;
  }

  .client-page-container .layout-19 .slider-wrapper {
    width: 50%;
  }

  .client-page-container .layout-19 .slider-wrapper .slider-indicators {
    margin-top: 15px;
  }

  .client-page-container
    .layout-19
    .slider-wrapper
    .slider-indicators
    ul
    li.active
    .indicator {
    height: 14px;
    width: 14px;
  }

  .client-page-container
    .layout-19
    .slider-wrapper
    .slider-indicators
    ul
    li
    .indicator {
    height: 10px;
    width: 10px;
  }

  .client-page-container .layout-19 .text-content {
    margin-bottom: 50px;
  }

  .client-page-container .layout-19 .text-content p {
    text-align: left;
  }

  .client-page-container .layout-21 .accountRegSteps-container {
    padding: 40px 60px;
  }

  .client-page-container .layout-21 .accountRegSteps-main-content {
    border-radius: 25px;
    padding: 15px;
  }

  .client-page-container .layout-21 .accountRegSteps-main-content h3 {
    font-size: 3em;
    line-height: normal;
    margin: 45px 45px 0;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-main-content
    .text-content
    p {
    color: #222;
    font-size: 1.429em;
    line-height: 28px;
    padding: 40px 15px 20px;
    width: 85%;
  }

  .client-page-container .layout-21 .accountRegSteps-content {
    padding: 20px 45px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .regSteps-heading-container {
    margin: 0 0 5px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .regSteps-heading-container
    h3 {
    font-family: sspRegular;
    font-size: 1.714em;
    line-height: 36px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .reg-primary-step-container {
    display: flex;
    flex-direction: row;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .reg-primary-step-container
    .regSteps-main-item-container {
    align-items: center;
    flex-direction: row;
    margin: 20px 0;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .reg-primary-step-container
    .regSteps-main-item-container
    .image-section
    img {
    height: 84px;
    width: 84px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .regSteps-title-container {
    align-items: baseline;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .regSteps-title-container
    h3 {
    font-size: 3.428em;
    margin: 0 0 0 20px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content
    .regSteps-title-container
    p {
    font-size: 2em;
    margin: 0 50px 0 8px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white {
    background: #fff;
    border-radius: 25px;
    padding: 40px 45px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .regSteps-main-item-container {
    margin: 15px 0;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .reg-primary-step-container {
    flex-direction: column;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .regSteps-heading-container {
    margin: 0 0 25px;
    width: 70%;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .regSteps-heading-container
    h3 {
    font-family: sspBold;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .regSteps-title-count-wrapper {
    height: 34px;
    min-height: 34px;
    min-width: 34px;
    width: 34px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .regSteps-title-count-wrapper
    h3 {
    font-size: 1.857em;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .regSteps-title-container
    p {
    font-size: 1.714em;
    line-height: 16px;
    margin-left: 15px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .innerSteps-container {
    margin: 40px 0 10px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .innerSteps-container:before {
    bottom: 17px;
    top: 17px;
    width: 34px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .innerSteps-container
    .regSteps-title-container
    h3 {
    font-family: sspBold;
    font-size: 1.571em;
    line-height: 16px;
    margin-left: 15px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .innerSteps-container
    .regSteps-main-item-container {
    margin: 15px 0;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .innerSteps-container
    .regSteps-title-count-wrapper {
    border-color: rgba(255, 119, 68, 0.4);
    display: flex;
    height: 34px;
    width: 34px;
  }

  .client-page-container
    .layout-21
    .accountRegSteps-content.registration-bg-white
    .innerSteps-container
    .regSteps-title-count-wrapper
    h3 {
    margin: auto;
  }

  .client-page-container .why-section {
    padding: 93px 150px 109px;
  }

  .client-page-container .why-section h2 {
    font-size: 2.857em;
    line-height: 50px;
    margin-bottom: 42px;
  }

  .client-page-container .benefit-section {
    flex-direction: row;
    padding: 84px 130px 80px;
  }

  .client-page-container .benefit-section .content {
    margin-right: 60px;
    max-width: 400px;
    width: 100%;
  }

  .client-page-container .benefit-section .content .desc {
    font-size: 1.286em;
    line-height: 1.67;
    margin-bottom: 50px;
    text-align: left;
  }

  .client-page-container .benefit-section h3 {
    font-size: 2.857em;
    line-height: 50px;
    margin: 0 0 23px;
    max-width: 100%;
    text-align: left;
  }

  .client-page-container .benefit-section .right {
    margin-top: 53px;
  }

  .client-page-container .benefit-section .card {
    margin-bottom: 27px;
    margin-right: 27px;
    padding: 24px;
    text-align: left;
    width: 250px;
  }

  .client-page-container .layout-22 {
    padding: 72px 150px;
  }

  .client-page-container .layout-22 .cards-section {
    grid-template-columns: repeat(4, 1fr);
  }

  .client-page-container .layout-22 .grocery-item-card-btn {
    width: -moz-max-content;
    width: max-content;
  }

  .client-page-container .layout-22 .title {
    width: 350px;
  }

  .client-page-container .layout-22 .title-wrapper {
    align-items: center;
    display: flex;
  }

  .client-page-container .layout-22 .dashed-border {
    border-top: 1px dashed #979797;
    height: 1px;
    opacity: 0.5;
    width: calc(100% - 350px);
  }

  .client-page-container .partners-section .content-wrapper {
    padding: 65px 5% 95px;
  }

  .client-page-container
    .partners-section
    .content-wrapper
    .partners-container
    .partner:nth-child(odd) {
    flex: 0 1 32%;
  }

  .client-page-container
    .partners-section
    .content-wrapper
    .partners-container
    .partner:nth-child(odd)
    .partners-list
    img:nth-child(3n + 1) {
    justify-self: flex-start;
  }

  .client-page-container
    .partners-section
    .content-wrapper
    .partners-container
    .partner:nth-child(odd)
    .partners-list
    img:nth-child(3n + 3) {
    justify-self: flex-end;
  }

  .client-page-container
    .partners-section
    .content-wrapper
    .partners-container
    .partner:nth-child(2n + 2) {
    flex: 0 1 60%;
  }

  .client-page-container
    .partners-section
    .content-wrapper
    .partners-container
    .partner:nth-child(2n + 2)
    .partners-list {
    grid-template-columns: repeat(5, auto);
  }

  .client-page-container
    .partners-section
    .content-wrapper
    .partners-container
    .partner:nth-child(2n + 2)
    .partners-list
    img:nth-child(5n + 1) {
    justify-self: flex-start;
  }

  .client-page-container
    .partners-section
    .content-wrapper
    .partners-container
    .partner:nth-child(2n + 2)
    .partners-list
    img:nth-child(5n + 5) {
    justify-self: flex-end;
  }

  .client-page-container
    .partners-section
    .content-wrapper
    .partners-container
    .partner
    .partner-heading {
    margin-bottom: 50px;
  }

  .client-page-container
    .partners-section
    .content-wrapper
    .partners-container
    .partner
    .partners-list {
    grid-gap: 40px 0;
  }

  .client-page-container .card-layout .content-wrapper {
    padding: 75px;
  }

  .client-page-container .card-layout .content-wrapper h2 {
    font-size: 3.428em;
    line-height: 56px;
  }

  .client-page-container .card-layout .content-wrapper .subtitle {
    font-size: 1.714em;
    line-height: 32px;
  }

  .client-page-container .card-layout .content-wrapper .card-section {
    margin-top: 40px;
  }

  .client-page-container .card-layout .content-wrapper .card-body .icon {
    width: 60px;
  }

  .client-page-container .card-layout .content-wrapper .card-body .title {
    font-size: 2em;
    line-height: 32px;
    margin: 10px 0 20px 5px;
  }

  .client-page-container .card-layout .content-wrapper .card-body .desc {
    font-size: 1.714em;
    line-height: 32px;
  }

  .client-page-container .btn-details .info-text {
    line-height: 32px;
    font-size: 1.857em;
    margin-bottom: 40px;
  }

  .client-page-container .btn-details .info-text span {
    color: #0ac88f;
    font-family: sspBold;
  }

  .client-page-container .btn-details .cta-btn {
    width: 300px;
    height: 56px;
    font-family: sspBold;
    background: #f74;
    border-radius: 10px;
    text-align: center;
    font-size: 1.286em;
    line-height: 22px;
    padding: 17px;
    color: #fff;
    text-transform: uppercase;
  }

  .client-page-container .banner-section.vcc-banner-1 {
    align-items: unset;
    flex-direction: row;
    justify-content: space-between;
    height: unset;
    margin: 0 auto 80px;
    padding: 0 8vw;
  }

  .client-page-container .banner-section.vcc-banner-1 .bg-image {
    display: none;
  }

  .client-page-container .banner-section.vcc-banner-1 .banner-content {
    margin-top: 72px;
    text-align: left;
  }

  .client-page-container .banner-section.vcc-banner-1 .banner-content h1 {
    margin-bottom: 22px;
  }

  .client-page-container .banner-section.vcc-banner-1 .banner-content h2 {
    line-height: 50px;
    font-size: 2.857em;
    max-width: 386px;
    margin-bottom: 22px;
  }

  .client-page-container
    .banner-section.vcc-banner-1
    .banner-content
    .section-icon {
    width: 218px;
    margin: 0 0 23px;
  }

  .client-page-container .banner-section.vcc-banner-1 .banner-content p {
    font-family: sspRegular;
    font-size: 1.857em;
    line-height: 26px;
    display: block;
  }

  .client-page-container .banner-section.vcc-banner-1 .banner-content p span {
    font-family: sspBolder;
    color: #0ac88f;
  }

  .client-page-container .banner-section.vcc-banner-1 .vcc-card-image {
    width: 493px;
    height: 283px;
    margin-top: 207px;
  }

  .client-page-container .banner-section.vcc-banner-3 {
    padding: 0;
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
  }

  .client-page-container .banner-section.vcc-banner-3 h1 {
    line-height: 66px;
    font-size: 3.8em;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .client-page-container .banner-section.vcc-banner-3 h2 {
    margin-left: 0;
    line-height: 32px;
    font-size: 1.857em;
    width: unset;
    margin-bottom: 40px;
  }

  .client-page-container .banner-section.vcc-banner-3 .banner-content {
    margin-right: 340px;
  }

  .client-page-container .banner-section.vcc-banner-3 .vcc-card-image {
    margin-top: 0;
    width: 250px;
    height: 218px;
  }

  .client-page-container .banner-section.vcc-banner-3 p {
    font-size: 1.857em;
    width: auto;
  }

  .client-page-container .banner-section a {
    background: #f74;
    border-radius: 10px;
    display: inline-block;
    color: #fff;
    font-family: sspBold;
    font-size: 1.286em;
    line-height: 22px;
    margin-top: 40px;
    padding: 17px;
    text-align: center;
    text-transform: uppercase;
    width: 300px;
  }

  .client-page-container .vcc-features-wrapper {
    padding: 0;
  }

  .client-page-container .vcc-features-wrapper.dark-theme {
    padding-top: 100px;
    padding-bottom: 95px;
    max-width: unset;
    border-radius: 0;
    margin-bottom: 80px;
  }

  .client-page-container
    .vcc-features-wrapper.dark-theme
    .vcc-features
    .feature-card {
    width: 260px;
    justify-content: space-between;
  }

  .client-page-container
    .vcc-features-wrapper.dark-theme
    .vcc-features
    .feature-card
    img {
    width: 32px;
    height: auto;
    margin-bottom: 22px;
    margin-right: 0;
  }

  .client-page-container
    .vcc-features-wrapper.dark-theme
    .vcc-features
    .feature-card:nth-child(3)
    img {
    width: 19px;
    height: 32px;
    margin-right: 0;
  }

  .client-page-container
    .vcc-features-wrapper.dark-theme
    .feature-card:not(:last-child) {
    margin-right: 111px;
  }

  .client-page-container
    .vcc-features-wrapper.light-theme
    .feature-card:not(:last-child) {
    margin-right: 72px;
  }

  .client-page-container .vcc-features-wrapper.light-theme .vcc-features {
    background: #f5f5f5;
    border-radius: 25px;
    margin: 0 auto 50px;
    padding: 60px 37px;
  }

  .client-page-container
    .vcc-features-wrapper.light-theme
    .feature-card:first-child
    img {
    width: 32px;
    height: 34px;
    margin-bottom: 23px;
  }

  .client-page-container
    .vcc-features-wrapper.light-theme
    .feature-card:nth-child(2) {
    max-width: 281px;
  }

  .client-page-container
    .vcc-features-wrapper.light-theme
    .feature-card:nth-child(2)
    img {
    width: 29px;
    height: 32px;
  }

  .client-page-container
    .vcc-features-wrapper.light-theme
    .feature-card:nth-child(3) {
    max-width: 255px;
  }

  .client-page-container
    .vcc-features-wrapper.light-theme
    .feature-card:nth-child(3)
    img {
    width: 32px;
    height: 32px;
  }

  .client-page-container .vcc-features-wrapper .content-wrapper {
    padding: 0 8vw;
  }

  .client-page-container .vcc-features-wrapper .vcc-features {
    display: flex;
    justify-content: space-around;
  }

  .client-page-container .vcc-features-wrapper .vcc-features .feature-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
  }

  .client-page-container
    .vcc-features-wrapper
    .vcc-features
    .feature-card
    .feature-heading {
    margin-bottom: 19px;
    line-height: 28px;
    font-size: 1.571em;
  }

  .client-page-container
    .vcc-features-wrapper
    .vcc-features
    .feature-card
    .feature-sub-heading {
    line-height: 22px;
    font-size: 1.286em;
  }

  .client-page-container .vcc-features-wrapper .vcc-features .feature-card img {
    margin-right: 0;
    margin-bottom: 26px;
  }

  .client-page-container .cards-section {
    margin-bottom: 80px;
    padding: 0 8vw;
  }

  .client-page-container .cards-section .vcc-image-section {
    padding: 50px;
  }

  .client-page-container .vcc-image-section .brand-icons-wrapper .img-wrapper {
    margin: 0 30px 30px 0;
  }

  .client-page-container .vcc-image-section.template_scroll .heading {
    line-height: 66px;
    font-size: 3.8em;
  }

  .client-page-container .vcc-image-section.template_scroll .sub-heading {
    line-height: 32px;
    font-size: 1.857em;
    margin-bottom: 55px;
  }

  @keyframes marquee {
    0% {
      transform: translateX(0);
    }

    to {
      transform: translateX(-800px);
    }
  }

  .client-page-container
    .vcc-image-section.template_scroll
    .brand-icons-wrapper {
    grid-template-columns: repeat(8, 100px);
  }

  .client-page-container .vcc-image-section.template_fixed {
    text-align: center;
    margin-bottom: 80px;
  }

  .client-page-container
    .vcc-image-section.template_fixed
    .brand-icons-wrapper {
    justify-content: center;
  }

  .client-page-container
    .vcc-image-section.template_fixed
    .brand-icons-wrapper
    .img-wrapper {
    width: 70px;
    height: 70px;
  }

  .client-page-container
    .vcc-image-section.template_fixed
    .btn-details
    .cta-btn {
    margin: 20px auto 0;
  }

  .client-page-container .vcc-image-section.template_fixed .heading,
  .client-page-container .vcc-image-section.template_fixed .sub-heading {
    line-height: 66px;
    font-size: 3.8em;
  }

  .client-page-container .vcc-image-section.template_fixed .sub-heading {
    margin-bottom: 50px;
  }

  .client-page-container .vcc-image-section a {
    background: #f74;
    border-radius: 10px;
    display: inline-block;
    color: #fff;
    font-family: sspBold;
    font-size: 1.286em;
    line-height: 22px;
    margin-top: 20px;
    padding: 17px;
    text-align: center;
    text-transform: uppercase;
    width: 300px;
  }

  .client-page-container .single-banner {
    background-color: #fff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    -o-object-fit: cover;
    object-fit: cover;
    padding: 0;
  }

  .client-page-container .single-banner .left-side {
    flex: 0 0 60%;
  }

  .client-page-container .single-banner .right-side {
    flex: 0 0 40%;
  }

  .client-page-container .single-banner .content-wrapper {
    padding: 80px 30px;
  }

  .client-page-container .single-banner .heading-text h4 {
    font-size: 1.571em;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .client-page-container .single-banner .heading-text .banner-text {
    font-size: 4.28em;
    line-height: 70px;
    margin: 0 0 16px;
  }

  .client-page-container .single-banner .heading-text .banner-text img {
    width: 67px;
    margin-left: 20px;
  }

  .client-page-container .single-banner .banner-paragraph {
    font-size: 1.429em;
    line-height: 30px;
    margin: 16px 100px 16px 0;
  }

  .client-page-container .single-banner .image-section {
    display: flex;
    gap: 25px;
    justify-content: flex-start;
    margin-top: 10px;
    vertical-align: bottom;
  }

  .client-page-container .single-banner .cta-btn > span {
    align-items: center;
    background-color: #f16122;
    border-radius: 15px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: sspBold;
    font-size: 1.286em;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 29px;
    margin-top: 33px;
    padding: 20px;
    text-transform: uppercase;
    width: 240px;
  }

  .client-page-container .single-banner .cta-btn > span .next-img {
    margin-left: 13px;
  }

  .client-page-container .single-banner .images-section {
    justify-content: flex-start;
  }

  .client-page-container .single-banner .banner-img {
    margin-top: 0;
  }

  .client-page-container .single-banner .lower-section {
    margin-top: 50px;
    padding: 35px;
    box-shadow: 0 0 10px 0 rgba(135, 104, 80, 0.08);
  }

  .client-page-container .single-banner .lower-section > div img {
    width: 24px;
  }

  .client-page-container .single-banner .lower-section > div p {
    font-size: 1.429em;
    line-height: 26px;
  }

  .client-page-container .single-banner.layoutId-2,
  .client-page-container .single-banner.layoutId-3 {
    padding-bottom: 0;
  }

  .client-page-container .single-banner.layoutId-2 .left-side,
  .client-page-container .single-banner.layoutId-3 .left-side {
    flex: 0 0 52%;
  }

  .client-page-container .single-banner.layoutId-2 .section-img,
  .client-page-container .single-banner.layoutId-3 .section-img {
    flex: 0 0 48%;
  }

  .client-page-container .single-banner.layoutId-2 .upper-section .left-side,
  .client-page-container .single-banner.layoutId-3 .upper-section .left-side {
    padding: 0;
  }

  .client-page-container .single-banner.layoutId-2 .upper-section.with-form,
  .client-page-container .single-banner.layoutId-3 .upper-section.with-form {
    position: relative;
    justify-content: space-between;
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .form-wrapper,
  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .left-side,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .form-wrapper,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .left-side {
    z-index: 1;
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .section-img,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .section-img {
    bottom: 0;
    left: calc(50% - 196px);
    position: absolute;
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .section-img
    img,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .section-img
    img {
    width: 392px;
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .left-side
    .feature-text
    li,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .left-side
    .feature-text
    li {
    margin-right: 0;
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .left-side
    .feature-text
    li:not(:last-child),
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .left-side
    .feature-text
    li:not(:last-child) {
    margin-bottom: 20px;
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .heading-text
    .banner-text,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .heading-text
    .banner-text {
    line-height: 62px;
    font-size: 4em;
    margin-bottom: 54px;
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .form-wrapper
    h3,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .form-wrapper
    h3 {
    font-size: 1.429em;
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .form-wrapper.custom-form-wrapper,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .form-wrapper.custom-form-wrapper {
    margin-left: 70px;
    margin-bottom: 70px;
    width: 404px;
    align-self: flex-start;
    background: #fff;
    padding: 50px 40px 40px;
    border-radius: 20px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  }

  .client-page-container
    .single-banner.layoutId-2
    .upper-section.with-form
    .form-wrapper.custom-form-wrapper
    .form
    .field-wrapper
    .input-container
    input,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section.with-form
    .form-wrapper.custom-form-wrapper
    .form
    .field-wrapper
    .input-container
    input {
    width: 324px;
  }

  .client-page-container .single-banner.layoutId-2 .content-wrapper,
  .client-page-container .single-banner.layoutId-3 .content-wrapper {
    padding-top: 60px;
    padding-bottom: 0;
  }

  .client-page-container .single-banner.layoutId-2 .heading-text .banner-text,
  .client-page-container .single-banner.layoutId-3 .heading-text .banner-text {
    line-height: 72px;
    font-size: 4.28em;
    margin-bottom: 25px;
  }

  .client-page-container
    .single-banner.layoutId-2
    .heading-text
    .banner-paragraph,
  .client-page-container
    .single-banner.layoutId-3
    .heading-text
    .banner-paragraph {
    line-height: 24px;
    font-size: 1.429em;
    margin: 0 0 40px;
  }

  .client-page-container .single-banner.layoutId-2 .cta-btn > span,
  .client-page-container .single-banner.layoutId-3 .cta-btn > span {
    background-color: #f74;
    margin-bottom: 63px;
    line-height: 24px;
    font-size: 1.286em;
    border-radius: 10px;
    width: 242px;
    box-shadow: 1px 8px 20px 0 rgba(255, 119, 68, 0.15);
  }

  .client-page-container .single-banner.layoutId-2 .feature-text,
  .client-page-container .single-banner.layoutId-3 .feature-text {
    display: flex;
    margin-bottom: 60px;
  }

  .client-page-container .single-banner.layoutId-2 .feature-text li,
  .client-page-container .single-banner.layoutId-3 .feature-text li {
    margin-right: 27px;
    margin-bottom: 0;
  }

  .client-page-container .single-banner.layoutId-2 .feature-text li img,
  .client-page-container .single-banner.layoutId-3 .feature-text li img {
    width: 26px;
    height: 26px;
    margin-right: 16px;
  }

  .client-page-container .single-banner.layoutId-2 .feature-text li p,
  .client-page-container .single-banner.layoutId-3 .feature-text li p {
    line-height: 24px;
    font-size: 1.429em;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .heading-text
    .banner-text {
    line-height: 60px;
    font-size: 3.428em;
    margin-bottom: 20px;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .heading-text
    .banner-paragraph {
    line-height: 32px;
    font-size: 1.714em;
    margin-bottom: 45px;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .feature-text {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 16px;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .feature-text
    li {
    margin-bottom: 44px;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .feature-text
    li
    img {
    width: 28px;
    height: 28px;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .feature-text
    li
    p {
    line-height: 28px;
    font-size: 1.286em;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .left-side {
    flex: 0 0 55%;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .upper-section {
    justify-content: space-between;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .upper-section
    .section-img
    img {
    height: 311px;
    width: 375px;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .upper-section
    .left-side {
    margin-bottom: 110px;
  }

  .client-page-container
    .single-banner.tips-banner
    .content-wrapper
    .upper-section
    .left-side
    .cta-btn
    > span {
    margin-top: 0;
  }

  .client-page-container .benefits-main-container {
    padding: 100px 12%;
  }

  .client-page-container .benefits-main-container .upper-container h1 {
    font-size: 3.6em;
    line-height: 63px;
  }

  .client-page-container .benefits-main-container .upper-container img {
    width: 77px;
    margin-left: 10px;
  }

  .client-page-container .benefits-main-container p {
    font-size: 1.429em;
    line-height: 30px;
  }

  .client-page-container .benefits-main-container .lower-container {
    grid-gap: 25px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 50px;
    padding: 0;
  }

  .client-page-container
    .benefits-main-container
    .lower-container
    .benefit-wrapper {
    border: 1px solid transparent;
    box-shadow: none;
    padding: 45px 10px;
  }

  .client-page-container
    .benefits-main-container
    .lower-container
    .benefit-wrapper:hover {
    border: 1px solid #f16122;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.04);
  }

  .client-page-container
    .benefits-main-container
    .lower-container
    .benefit-wrapper
    .image-container
    img {
    height: 32px;
  }

  .client-page-container
    .benefits-main-container
    .lower-container
    .benefit-wrapper
    .benefit-details {
    margin: 30px 0 0;
  }

  .client-page-container
    .benefits-main-container
    .lower-container
    .benefit-wrapper
    .benefit-details
    h3 {
    font-size: 1.571em;
    line-height: 28px;
  }

  .client-page-container
    .benefits-main-container
    .lower-container
    .benefit-wrapper
    .benefit-details
    p {
    font-size: 1.143em;
    line-height: 24px;
    margin: 15px 0 0;
  }

  .client-page-container .benefits-main-container.layoutId-2 {
    padding: 100px 4%;
  }

  .client-page-container
    .benefits-main-container.layoutId-2
    .slider-wrapper.slider-arrows
    .slider-track
    .arrow {
    background: #fff;
    border-radius: 50%;
    height: 45px;
    margin: auto;
    width: 45px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  }

  .client-page-container
    .benefits-main-container.layoutId-2
    .slider-wrapper.slider-arrows
    .slider-track
    .left-arrow {
    left: -65px;
    padding-left: 18px;
  }

  .client-page-container
    .benefits-main-container.layoutId-2
    .slider-wrapper.slider-arrows
    .slider-track
    .right-arrow {
    right: -65px;
    padding-left: 14px;
  }

  .client-page-container .benefits-main-container.layoutId-2 .lower-container {
    display: block;
  }

  .client-page-container
    .benefits-main-container.layoutId-2
    .lower-container
    .benefit-wrapper {
    display: block;
    min-height: 100%;
    padding: 40px 32px;
  }

  .client-page-container
    .benefits-main-container.layoutId-2
    .lower-container
    .benefit-wrapper:hover {
    border-radius: 20px;
    border: 1px solid #f74;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  }

  .client-page-container
    .benefits-main-container.layoutId-2
    .lower-container
    .benefit-wrapper
    .benefit-details {
    text-align: left;
    margin-top: 16px;
  }

  .client-page-container
    .benefits-main-container.layoutId-2
    .lower-container
    .benefit-wrapper
    .benefit-details
    h3 {
    font-size: 1.714em;
    line-height: 28px;
  }

  .client-page-container
    .benefits-main-container.layoutId-2
    .lower-container
    .benefit-wrapper
    .benefit-details
    p {
    margin-top: 16px;
    font-size: 1.286em;
    line-height: 30px;
  }

  .client-page-container
    .benefits-main-container.layoutId-2
    .lower-container
    .benefit-wrapper
    .image-container {
    height: 52px;
  }

  .client-page-container
    .benefits-main-container.layoutId-2
    .lower-container
    .benefit-wrapper
    .image-container
    img {
    width: 52px;
    height: 52px;
  }

  .client-page-container .benefits-main-container.layoutId-3 {
    padding-left: 0;
    padding-right: 0;
  }

  .client-page-container
    .benefits-main-container.layoutId-3
    .upper-container
    h3 {
    font-size: 3em;
    font-family: sspBold;
    margin-bottom: 24px;
  }

  .client-page-container
    .benefits-main-container.layoutId-3
    .upper-container
    p {
    line-height: 24px;
    font-size: 1.429em;
  }

  .client-page-container .benefits-main-container.layoutId-3 .lower-container {
    margin-top: 24px;
    gap: 30px;
  }

  .client-page-container
    .benefits-main-container.layoutId-3
    .lower-container
    .benefit-wrapper {
    border-radius: 40px;
    padding: 30px 60px 40px;
    margin-bottom: 0;
  }

  .client-page-container
    .benefits-main-container.layoutId-3
    .lower-container
    .benefit-wrapper:hover {
    border: none;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.04);
  }

  .client-page-container
    .benefits-main-container.layoutId-3
    .lower-container
    .benefit-wrapper
    .image-container {
    margin-bottom: 18px;
  }

  .client-page-container
    .benefits-main-container.layoutId-3
    .lower-container
    .benefit-wrapper
    .image-container
    img {
    width: 60px;
    height: 60px;
  }

  .client-page-container
    .benefits-main-container.layoutId-3
    .lower-container
    .benefit-wrapper
    .benefit-details
    h3 {
    line-height: 28px;
    font-size: 1.429em;
    margin-bottom: 10px;
  }

  .client-page-container .investment-nudge-category-container {
    padding: 115px 15%;
  }

  .client-page-container .investment-nudge-category-container .heading img {
    width: 58px;
    margin-left: 20px;
  }

  .client-page-container .investment-nudge-category-container h3 {
    font-size: 3.428em;
    line-height: 60px;
  }

  .client-page-container .investment-nudge-category-container p {
    font-size: 1.429em;
    margin-top: 15px;
  }

  .client-page-container .investment-nudge-category-container .input-wrapper {
    font-size: 1.286em;
    line-height: 22px;
    margin: 50px auto 20px;
    min-width: 50%;
  }

  .client-page-container
    .investment-nudge-category-container
    .category-container
    .category
    img {
    height: 70px;
    width: 70px;
  }

  .client-page-container .faq .heading h3,
  .client-page-container .faq2 .heading h3 {
    font-size: 3.6em;
    line-height: 63px;
  }

  .client-page-container .faq .heading h3 img,
  .client-page-container .faq2 .heading h3 img {
    height: 57px;
  }

  .client-page-container .faq .content-wrapper .list-data .list-item,
  .client-page-container .faq2 .content-wrapper .list-data .list-item {
    box-shadow: 0 2px 10px 0 rgba(69, 44, 32, 0.05);
  }

  .client-page-container
    .faq
    .content-wrapper
    .list-data
    .list-item
    .upper-section,
  .client-page-container
    .faq2
    .content-wrapper
    .list-data
    .list-item
    .upper-section {
    padding: 33px 40px 34px;
  }

  .client-page-container
    .faq
    .content-wrapper
    .list-data
    .list-item
    .upper-section:after,
  .client-page-container
    .faq2
    .content-wrapper
    .list-data
    .list-item
    .upper-section:after {
    height: 8px;
    right: 45px;
    width: 8px;
  }

  .client-page-container
    .faq
    .content-wrapper
    .list-data
    .list-item
    .upper-section
    p,
  .client-page-container
    .faq2
    .content-wrapper
    .list-data
    .list-item
    .upper-section
    p {
    font-size: 1.571em;
    line-height: 28px;
  }

  .client-page-container
    .faq
    .content-wrapper
    .list-data
    .list-item
    .lower-section
    p,
  .client-page-container
    .faq2
    .content-wrapper
    .list-data
    .list-item
    .lower-section
    p {
    font-size: 1.286em;
    line-height: 28px;
  }

  .client-page-container .faq .content-wrapper .btn-section,
  .client-page-container .faq2 .content-wrapper .btn-section {
    margin-top: 40px;
  }

  .client-page-container .faq .content-wrapper button,
  .client-page-container .faq2 .content-wrapper button {
    font-size: 1.143em;
    padding: 15px 46px;
  }

  .client-page-container .faq2 {
    display: flex;
    justify-content: center;
  }

  .client-page-container .faq2.layoutId-2.section-wrapper,
  .client-page-container .faq2.layoutId-3.section-wrapper {
    padding-top: 105px;
    padding-bottom: 110px;
  }

  .client-page-container .faq2.layoutId-2 > .content-wrapper,
  .client-page-container .faq2.layoutId-3 > .content-wrapper {
    flex-wrap: wrap;
  }

  .client-page-container .faq2.layoutId-2 .content-wrapper .heading,
  .client-page-container .faq2.layoutId-3 .content-wrapper .heading {
    flex: 0 0 100%;
    text-align: center;
  }

  .client-page-container .faq2.layoutId-2 .content-wrapper .heading h3,
  .client-page-container .faq2.layoutId-3 .content-wrapper .heading h3 {
    line-height: 60px;
    font-size: 3.428em;
  }

  .client-page-container
    .faq2.layoutId-2
    .content-wrapper
    .list-data
    .list-item,
  .client-page-container
    .faq2.layoutId-3
    .content-wrapper
    .list-data
    .list-item {
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 20px;
    margin-bottom: 10px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.05);
  }

  .client-page-container
    .faq2.layoutId-2
    .content-wrapper
    .list-data
    .list-item
    .upper-section,
  .client-page-container
    .faq2.layoutId-3
    .content-wrapper
    .list-data
    .list-item
    .upper-section {
    padding: 32px;
  }

  .client-page-container
    .faq2.layoutId-2
    .content-wrapper
    .list-data
    .list-item
    .upper-section
    p,
  .client-page-container
    .faq2.layoutId-3
    .content-wrapper
    .list-data
    .list-item
    .upper-section
    p {
    font-size: 1.429em;
    line-height: 26px;
  }

  .client-page-container
    .faq2.layoutId-2
    .content-wrapper
    .list-data
    .list-item
    .upper-section:after,
  .client-page-container
    .faq2.layoutId-3
    .content-wrapper
    .list-data
    .list-item
    .upper-section:after {
    right: 45px;
  }

  .client-page-container
    .faq2.layoutId-2
    .content-wrapper
    .list-data
    .list-item
    .lower-section,
  .client-page-container
    .faq2.layoutId-3
    .content-wrapper
    .list-data
    .list-item
    .lower-section {
    padding: 0 36px;
  }

  .client-page-container
    .faq2.layoutId-2
    .content-wrapper
    .list-data
    .list-item
    .lower-section
    ul,
  .client-page-container
    .faq2.layoutId-3
    .content-wrapper
    .list-data
    .list-item
    .lower-section
    ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .client-page-container
    .faq2.layoutId-2
    .content-wrapper
    .list-data
    .list-item
    .lower-section
    ul
    li,
  .client-page-container
    .faq2.layoutId-3
    .content-wrapper
    .list-data
    .list-item
    .lower-section
    ul
    li {
    margin-bottom: 18px;
    margin-right: 10px;
    font-size: 1.286em;
    line-height: 22px;
    padding-left: 32px;
    position: relative;
  }

  .client-page-container
    .faq2.layoutId-2
    .content-wrapper
    .list-data
    .list-item
    .lower-section
    ul
    li:before,
  .client-page-container
    .faq2.layoutId-3
    .content-wrapper
    .list-data
    .list-item
    .lower-section
    ul
    li:before {
    border-radius: 1px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    content: "";
    display: block;
    height: 8px;
    left: 7px;
    position: absolute;
    top: 6px;
    width: 4px;
    z-index: 1;
    transform: rotate(45deg);
  }

  .client-page-container
    .faq2.layoutId-2
    .content-wrapper
    .list-data
    .list-item
    .lower-section
    ul
    li:after,
  .client-page-container
    .faq2.layoutId-3
    .content-wrapper
    .list-data
    .list-item
    .lower-section
    ul
    li:after {
    background: #11d1ac;
    border-radius: 50%;
    content: "";
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 20px;
  }

  .client-page-container
    .faq2.layoutId-2
    .content-wrapper
    .list-data
    .list-item
    .lower-section
    a,
  .client-page-container
    .faq2.layoutId-3
    .content-wrapper
    .list-data
    .list-item
    .lower-section
    a {
    margin-top: 7px;
    margin-bottom: 0;
    line-height: 24px;
    font-size: 1.143em;
    padding: 18px 42px;
  }

  .client-page-container
    .faq2.layoutId-2
    .content-wrapper
    .list-data
    .list-item.active
    .upper-section,
  .client-page-container
    .faq2.layoutId-3
    .content-wrapper
    .list-data
    .list-item.active
    .upper-section {
    padding-bottom: 21px;
  }

  .client-page-container
    .faq2.layoutId-2
    .content-wrapper
    .list-data
    .list-item.active
    .upper-section
    p,
  .client-page-container
    .faq2.layoutId-3
    .content-wrapper
    .list-data
    .list-item.active
    .upper-section
    p {
    line-height: 30px;
    font-size: 1.714em;
  }

  .client-page-container .faq2.section-wrapper {
    padding: 114px 40px 90px;
  }

  .client-page-container .faq2 > .content-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
  }

  .client-page-container .faq2 > .content-wrapper > section {
    flex: 0 0 50%;
  }

  .client-page-container .faq2 > .content-wrapper > div {
    flex: 0 0 45%;
  }

  .client-page-container .faq2 section.content-wrapper {
    margin: 0;
    max-width: none;
  }

  .client-page-container .faq2 .content-wrapper .heading {
    margin-bottom: 26px;
  }

  .client-page-container .faq2 .content-wrapper .heading span {
    margin-right: 36px;
  }

  .client-page-container .faq2 .content-wrapper .sub-heading {
    display: none;
  }

  .client-page-container .faq2 .content-wrapper .list-data .list-item {
    background: unset;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
  }

  .client-page-container
    .faq2
    .content-wrapper
    .list-data
    .list-item.active
    .upper-section {
    padding-bottom: 17px;
  }

  .client-page-container
    .faq2
    .content-wrapper
    .list-data
    .list-item.active
    .lower-section {
    padding-bottom: 33px;
  }

  .client-page-container
    .faq2
    .content-wrapper
    .list-data
    .list-item
    .upper-section {
    padding-left: 0;
  }

  .client-page-container
    .faq2
    .content-wrapper
    .list-data
    .list-item
    .upper-section:after {
    right: 5px;
  }

  .client-page-container
    .faq2
    .content-wrapper
    .list-data
    .list-item
    .lower-section {
    padding-left: 0;
  }

  .client-page-container
    .faq2
    .content-wrapper
    .list-data
    .list-item:last-child {
    border-bottom: none;
  }

  .client-page-container .faq .content-wrapper {
    max-width: 1000px;
    padding: 114px 0 130px;
  }

  .client-page-container .faq .content-wrapper .heading {
    margin-bottom: 66px;
    text-align: center;
    width: 100%;
  }

  .client-page-container .faq .content-wrapper .heading h3 img {
    height: 70px;
    width: 58px;
  }

  .client-page-container
    .faq
    .content-wrapper
    .list-data
    .list-item.active
    .upper-section {
    padding-bottom: 25px;
  }

  .client-page-container
    .faq
    .content-wrapper
    .list-data
    .list-item.active
    .lower-section {
    padding-bottom: 32px;
  }

  .client-page-container
    .faq
    .content-wrapper
    .list-data
    .list-item
    .lower-section {
    padding: 0 100px 0 40px;
  }

  .client-page-container
    .faq
    .content-wrapper
    .list-data
    .list-item
    .lower-section
    ul
    li {
    font-size: 1.286em;
    line-height: 28px;
  }

  .client-page-container .faq.transparent-faq {
    padding-top: 0;
    padding-bottom: 0;
  }

  .client-page-container .faq.transparent-faq .content-wrapper {
    max-width: 1366px;
  }

  .client-page-container .faq.faq-with-doughnut-section {
    padding-bottom: 120px;
  }

  .client-page-container .faq.faq-with-doughnut-section .content-wrapper {
    justify-content: space-between;
    max-width: 1366px;
    padding-top: 70px;
    padding-bottom: 0;
    position: relative;
  }

  .client-page-container
    .faq.faq-with-doughnut-section
    .content-wrapper
    .accordion-section
    .list-data {
    margin-top: 110px;
    max-width: 620px;
  }

  .client-page-container
    .faq.faq-with-doughnut-section
    .content-wrapper
    .accordion-section
    .list-data
    .list-item {
    box-shadow: none;
  }

  .client-page-container
    .faq.faq-with-doughnut-section
    .content-wrapper
    .accordion-section
    .list-data
    .list-item.active
    .upper-section {
    padding-bottom: 17px;
  }

  .client-page-container
    .faq.faq-with-doughnut-section
    .content-wrapper
    .accordion-section
    .list-data
    .list-item
    .upper-section {
    padding: 20px 0 17px;
  }

  .client-page-container
    .faq.faq-with-doughnut-section
    .content-wrapper
    .accordion-section
    .list-data
    .list-item
    .upper-section:after {
    right: 8px;
  }

  .client-page-container
    .faq.faq-with-doughnut-section
    .content-wrapper
    .accordion-section
    .list-data
    .list-item
    .upper-section
    .header-icon {
    width: 52px;
    height: 52px;
    margin-right: 24px;
  }

  .client-page-container
    .faq.faq-with-doughnut-section
    .content-wrapper
    .accordion-section
    .list-data
    .list-item
    .upper-section
    p {
    line-height: 32px;
    font-size: 1.714em;
  }

  .client-page-container
    .faq.faq-with-doughnut-section
    .content-wrapper
    .accordion-section
    .list-data
    .list-item
    .lower-section {
    line-height: 28px;
    font-size: 1.286em;
    padding-left: 80px;
    padding-right: 0;
  }

  .client-page-container
    .faq.faq-with-doughnut-section
    .content-wrapper
    .heading {
    position: absolute;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
  }

  .client-page-container
    .faq.faq-with-doughnut-section
    .content-wrapper
    .heading
    h3 {
    line-height: 60px;
    font-size: 3.428em;
  }

  .client-page-container
    .faq.faq-with-doughnut-section
    .content-wrapper
    .doughnut-chart {
    margin-right: unset;
    min-width: 445px;
    max-width: 445px;
    margin-left: 0;
    font-size: 1.571em;
  }

  .client-page-container .faq.faq-with-doughnut-section .cta-btn > span {
    margin-top: 30px;
  }

  .client-page-container .interest-calculator {
    background-position: 40% 100%;
    padding: 98px 15% 130px;
  }

  .client-page-container .interest-calculator .heading h3 {
    font-size: 3.6em;
    line-height: 80px;
  }

  .client-page-container .interest-calculator .heading img {
    width: 85px;
  }

  .client-page-container .interest-calculator .sub-heading {
    font-size: 1.429em;
    line-height: 28px;
    margin-bottom: 46px;
  }

  .client-page-container .interest-calculator .switch-item-container {
    height: 68px;
    margin-bottom: 76px;
  }

  .client-page-container .interest-calculator .switch-item-container li {
    font-size: 1.429em;
  }

  .client-page-container .interest-calculator .interest-ranges-sliders-wrapper {
    margin-bottom: 60px;
  }

  .client-page-container
    .interest-calculator
    .interest-ranges-sliders
    .upper-section
    label {
    font-size: 1.286em;
  }

  .client-page-container
    .interest-calculator
    .interest-ranges-sliders
    .upper-section
    .value-section
    input {
    font-size: 1.286em;
    height: 48px;
  }

  .client-page-container
    .interest-calculator
    .interest-ranges-sliders
    .upper-section
    .value-section.type-investmentAmt
    .input-container:after {
    font-size: 1.286em;
    top: 15px;
  }

  .client-page-container .interest-calculator .pie-chart-section {
    width: 280px;
  }

  .client-page-container .interest-calculator .calculated-investments-section {
    padding: 40px 60px;
  }

  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .interest-amt
    .dot,
  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .invested-amt
    .dot,
  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .total-amt
    .dot {
    height: 14px;
    margin-right: 12px;
    width: 14px;
  }

  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .interest-amt
    label,
  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .invested-amt
    label,
  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .total-amt
    label {
    font-size: 1em;
    line-height: 18px;
  }

  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .interest-amt
    p,
  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .invested-amt
    p,
  .client-page-container
    .interest-calculator
    .calculated-investments-section
    .numerical-values
    .total-amt
    p {
    font-size: 1.714em;
    line-height: 30px;
  }

  .client-page-container .investment-links-section {
    max-width: 1320px;
  }

  .client-page-container .investment-links-section .link a,
  .client-page-container .investment-links-section .link p {
    font-size: 1.143em;
  }

  .client-page-container .features-with-score {
    padding-top: 105px;
  }

  .client-page-container .features-with-score .content-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
  }

  .client-page-container .features-with-score .content-section .left-section {
    max-width: 575px;
  }

  .client-page-container
    .features-with-score
    .content-section
    .left-section
    h1 {
    font-size: 3.428em;
    line-height: 60px;
    margin-bottom: 20px;
  }

  .client-page-container
    .features-with-score
    .content-section
    .left-section
    h2 {
    font-size: 1.714em;
    line-height: 32px;
    margin-bottom: 25px;
  }

  .client-page-container .features-with-score .content-section .left-section p {
    font-size: 1.286em;
    line-height: 28px;
    margin-bottom: 0;
  }

  .client-page-container .features-with-score .content-section .right-section {
    max-width: 612px;
    margin-top: 22px;
  }

  .client-page-container
    .features-with-score
    .content-section
    .right-section
    h3 {
    font-size: 1.714em;
    line-height: 32px;
    margin-bottom: 25px;
  }

  .client-page-container
    .features-with-score
    .content-section
    .right-section
    .features {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .client-page-container
    .features-with-score
    .content-section
    .right-section
    .features
    div {
    margin-bottom: 24px;
  }

  .client-page-container
    .features-with-score
    .content-section
    .right-section
    .features
    div
    .benefit-img {
    width: 28px;
    height: 28px;
    margin-right: 22px;
  }

  .client-page-container
    .features-with-score
    .content-section
    .right-section
    .features
    div
    p {
    font-size: 1.286em;
    line-height: 28px;
  }

  .client-page-container
    .features-with-score
    .content-section
    .right-section
    .features
    div:nth-child(odd) {
    margin-right: 76px;
  }

  .client-page-container .features-with-score .tooltip-shaped-container {
    height: 185px;
    padding: 20px 62px 32px;
  }

  .client-page-container .features-with-score .tooltip-shaped-container h1 {
    font-size: 2.571em;
    line-height: 43px;
    margin-bottom: 2px;
  }

  .client-page-container
    .features-with-score
    .tooltip-shaped-container
    .sub-text {
    font-size: 1.286em;
    line-height: 22px;
  }

  .client-page-container .features-with-score .score-section .score-value,
  .client-page-container .features-with-score .tooltip-shaped-container .para {
    font-size: 1.143em;
    line-height: 24px;
  }

  .client-page-container .features-with-score .cta-btn > span {
    margin-bottom: 85px;
  }

  .client-page-container .tabbed-lists {
    padding: 104px 40px 120px;
  }

  .client-page-container .tabbed-lists.content-wrapper {
    max-width: unset;
  }

  .client-page-container .tabbed-lists.content-wrapper h1 {
    text-align: center;
    line-height: 60px;
    font-size: 3.428em;
    margin-bottom: 60px;
  }

  .client-page-container .tabbed-lists.content-wrapper .list-content {
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto 60px;
  }

  .client-page-container .tabbed-lists.content-wrapper .list-content h2 {
    line-height: 32px;
    font-size: 1.714em;
    margin-bottom: 37px;
  }

  .client-page-container .tabbed-lists.content-wrapper .list-content ul li {
    font-size: 1.286em;
    line-height: 28px;
    margin-bottom: 25px;
  }

  .client-page-container .tabbed-lists.content-wrapper .list-content ul li img {
    width: 24px;
    height: 24px;
  }

  .client-page-container
    .tabbed-lists.content-wrapper
    .list-content
    ul:not(:last-child) {
    margin-right: 100px;
  }

  .client-page-container .services-overview .heading h3 {
    font-size: 3.8em;
    line-height: 68px;
    padding-top: 80px;
  }

  .client-page-container .services-overview.with-bottom-padding {
    padding-bottom: 80px;
  }

  .client-page-container .services-overview.with-top-padding {
    padding-top: 80px;
  }

  .client-page-container .services-overview.spacing-with-header {
    padding-top: 30px;
  }

  .client-page-container .services-overview.spacing-with-header .service {
    padding: 80px;
  }

  .client-page-container .services-overview .sub-heading {
    font-size: 1.429em;
    line-height: 30px;
  }

  .client-page-container .services-overview.custom-content-wrapper {
    max-width: unset;
  }

  .client-page-container .services-overview.custom-content-wrapper .service {
    margin: auto;
    max-width: 1320px;
  }

  .client-page-container
    .services-overview.custom-content-wrapper
    .service.with-margin {
    margin: auto;
  }

  .client-page-container .services-overview.custom-section-img .service {
    padding: 80px 0 80px 80px;
  }

  .client-page-container .services-overview .service {
    padding: 80px 120px;
  }

  .client-page-container
    .services-overview
    .service
    .btn-section
    .know-more-btn {
    height: 56px;
    width: 137px;
    font-size: 1.143em;
  }

  .client-page-container
    .services-overview
    .service
    .btn-section
    .download-icons {
    margin-right: 0;
  }

  .client-page-container
    .services-overview
    .service
    .btn-section
    .download-icons.qrcode {
    align-items: center;
    display: flex;
    margin-top: 15px;
  }

  .client-page-container
    .services-overview
    .service
    .btn-section
    .download-icons.qrcode
    img {
    margin-right: 10px;
  }

  .client-page-container
    .services-overview
    .service
    .btn-section
    .download-icons
    .qr-code-wrapper {
    margin-right: 25px;
  }

  .client-page-container
    .services-overview
    .service
    .btn-section
    .download-icons
    .qr-code-text {
    font-size: 1.286em;
    line-height: 30px;
    margin-bottom: 13px;
  }

  .client-page-container .services-overview .service.with-margin {
    margin: 0;
  }

  .client-page-container
    .services-overview
    .service.grid-features
    .service-features {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .client-page-container .services-overview .service.with-padding {
    padding: 80px;
    position: relative;
  }

  .client-page-container
    .services-overview
    .service.with-padding
    .lottie-section {
    width: 42%;
  }

  .client-page-container .services-overview .service .outer-circle {
    position: absolute;
    height: 100%;
    width: 50%;
    background-color: #fff;
    opacity: 0.2;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }

  .client-page-container .services-overview .service .inner-circle {
    position: absolute;
    height: calc(100% - 144px);
    width: calc(50% - 144px);
    background-color: #fff;
    opacity: 0.4;
    top: 50%;
    right: 25%;
    border-radius: 50%;
    transform: translate(50%, -50%);
  }

  .client-page-container .services-overview .service .service-logo {
    margin-bottom: 28px;
  }

  .client-page-container .services-overview .service .service-logo p {
    font-size: 1.286em;
  }

  .client-page-container .services-overview .service h3 {
    font-size: 2.71em;
    line-height: 48px;
    margin-bottom: 12px;
  }

  .client-page-container .services-overview .service .sub-text {
    font-size: 1.286em;
    line-height: 28px;
    max-width: 480px;
  }

  .client-page-container .services-overview .service .service-features {
    font-size: 1.286em;
    line-height: 28px;
  }

  .client-page-container .services-overview .service .service-features li {
    padding-left: 37px;
    position: relative;
  }

  .client-page-container
    .services-overview
    .service
    .service-features
    li:before {
    border-radius: 1px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: "";
    display: block;
    height: 8px;
    left: 7px;
    position: absolute;
    top: 9px;
    width: 4px;
    z-index: 1;
    transform: rotate(45deg);
  }

  .client-page-container
    .services-overview
    .service
    .service-features
    li:after {
    background: #0ac88f;
    border-radius: 50%;
    content: "";
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 20px;
    top: 5px;
  }

  .client-page-container .services-overview .service .lottie-section {
    padding-bottom: 0;
    z-index: 1;
    width: 35%;
  }

  .client-page-container .services-overview .service .lottie-section div {
    height: 550px;
  }

  .client-page-container .services-overview .service .download-btn {
    align-items: center;
    background: #f16122;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: sspBold;
    font-size: 1.143em;
    height: 56px;
    justify-content: center;
    margin-right: 10px;
    width: 225px;
  }

  .client-page-container .services-overview .service .download-btn span {
    margin-right: 15px;
  }

  .client-page-container .services-overview .service .download-btn img {
    height: 18px;
    margin-right: 8px;
    width: 15px;
  }

  .client-page-container
    .services-overview
    .services-container.layoutId-2
    .service
    .section-img {
    order: -1;
    margin-right: 70px;
  }

  .client-page-container
    .services-overview
    .services-container.layoutId-2
    .service
    .lottie-section {
    order: -1;
  }

  .client-page-container
    .services-overview
    .services-container.single
    .service {
    align-items: center;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
  }

  .client-page-container .services-overview .services-container.multiple {
    display: flex;
  }

  .client-page-container
    .services-overview
    .services-container.multiple
    .service {
    width: 50%;
    padding: 80px 80px 0;
  }

  .client-page-container
    .services-overview
    .services-container.multiple
    .service:first-child {
    border-radius: 50px 0 0 50px;
  }

  .client-page-container
    .services-overview
    .services-container.multiple
    .service:nth-child(2) {
    border-radius: 0 50px 50px 0;
    margin-top: 0;
  }

  .client-page-container
    .videos-preview-wrapper
    .video-preview-slider
    .thumbnail-overlay {
    transition: background-color 0.2s ease-in-out 0s;
  }

  .client-page-container
    .videos-preview-wrapper
    .video-preview-slider
    .thumbnail-overlay:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .client-page-container
    .videos-preview-wrapper
    .video-preview-slider
    .thumbnail-overlay
    span {
    overflow: hidden;
  }

  .client-page-container
    .videos-preview-wrapper
    .video-preview-slider
    .thumbnail-overlay
    span:hover
    img {
    animation-delay: 0s;
    animation-direction: normal;
    animation-duration: 0.6s;
    animation-fill-mode: none;
    animation-iteration-count: 1;
    animation-name: animate;
    animation-timing-function: linear;
  }

  .client-page-container .security-container .content-wrapper {
    align-items: center;
    flex-direction: row;
    padding: 85px 20px;
  }

  .client-page-container .security-container .security-badge {
    display: flex;
    justify-content: center;
    margin-left: 8%;
  }

  .client-page-container .security-container .security-badge img {
    height: 250px;
    margin-right: 175px;
    width: 200px;
  }

  .client-page-container .security-container .security-info {
    margin-top: 0;
  }

  .client-page-container .security-container .security-info .header {
    font-family: sspBold;
    font-size: 3.8em;
    line-height: 68px;
  }

  .client-page-container .security-container .security-info .sub-header {
    font-size: 1.429em;
    line-height: 30px;
    margin: 6px 0 30px;
  }

  .client-page-container
    .security-container
    .security-info
    .certified-container {
    padding: 0 30px;
  }

  .client-page-container .download-app-container {
    height: 677px;
  }

  .client-page-container .download-app-container .heading-2 {
    font-size: 3.6em;
    line-height: 68px;
  }

  .client-page-container .seo-grid-section h2 {
    font-size: 2.71em;
    line-height: 48px;
  }

  .client-page-container .seo-grid-section p {
    font-size: 1.286em;
    line-height: 28px;
    margin-bottom: 35px;
  }

  .client-page-container .seo-grid-section ul {
    grid-template-columns: repeat(7, 1fr);
  }

  .client-page-container .seo-grid-section ul li {
    min-height: 200px;
  }

  .client-page-container .seo-grid-section ul li img {
    width: 100px;
  }

  .client-page-container .seo-grid-section ul li span {
    font-size: 1em;
    line-height: 18px;
  }

  .client-page-container .gold-loan-calculator-wrapper .heading {
    font-size: 3em;
    margin-bottom: 24px;
  }

  .client-page-container .gold-loan-calculator-wrapper .sub-heading {
    font-size: 1.429em;
    margin-bottom: 45px;
  }

  .client-page-container
    .gold-loan-calculator-wrapper
    .gold-loan-calculator
    .left-section {
    align-self: center;
  }

  .client-page-container
    .custom-form-wrapper.form-wrapper
    .form
    .field-wrapper {
    margin-bottom: 18px;
  }

  .client-page-container
    .custom-form-wrapper.form-wrapper
    .form
    .field-wrapper
    .error-message {
    position: absolute;
    margin: 0;
  }

  .client-page-container .disclaimer {
    margin-top: 50px;
    text-align: center;
    padding: 20px;
    font-size: 0.857em;
  }
}

@media (min-width: 1300px) {
  .client-page-container
    .single-banner.layoutId-2
    .upper-section
    .section-img
    img,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section
    .section-img
    img {
    max-width: 600px;
  }
}

@media (min-width: 1490px) {
  .client-page-container
    .single-banner.layoutId-2
    .upper-section
    .section-img
    img,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section
    .section-img
    img {
    max-width: 700px;
  }
}

@media (min-width: 1600px) {
  .client-page-container
    .single-banner.layoutId-2
    .upper-section
    .section-img
    img,
  .client-page-container
    .single-banner.layoutId-3
    .upper-section
    .section-img
    img {
    max-width: 750px;
  }

  .client-page-container
    .scrollable-tabs-wrapper
    .security-container
    .security-badge {
    margin-left: -60px;
  }
}

.swipeable-tabs-wrapper {
  background-color: #f2f5fc;
}

.swipeable-tabs-wrapper .tab-labels-heading {
  color: #222;
  font-family: sspBold;
  font-size: 1em;
  line-height: 18px;
  margin-bottom: 12px;
}

.swipeable-tabs-wrapper .tab-labels {
  background-color: #fff;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.swipeable-tabs-wrapper .tab-labels .tab-option {
  border-bottom: 2px solid transparent;
  color: #9c9c9c;
  cursor: pointer;
  font-size: 0.857em;
  font-weight: 300;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  transition: border-bottom 0.3s cubic-bezier(1, 1.01, 1, 1) 0s;
}

.swipeable-tabs-wrapper .tab-labels .tab-option.active {
  border-bottom: 2px solid #e3714d;
  color: #464646;
  font-family: sspBold;
  transition: border-bottom 0.3s cubic-bezier(1, 1.01, 1, 1) 0s;
}

.swipeable-tabs-wrapper .tab-labels .tab-option.border-under-text {
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 0;
  padding-right: 0;
  width: auto;
}

.swipeable-tabs-wrapper .tab-labels::-webkit-scrollbar {
  display: none;
}

.swipeable-tabs-wrapper .tab-content-wrapper {
  overflow-x: hidden;
}

.swipeable-tabs-wrapper .tab-content-wrapper .tab-content {
  display: flex;
  flex-wrap: nowrap;
}

.swipeable-tabs-wrapper .tab-content-wrapper .tab-content :focus {
  outline: none;
}

.swipeable-tabs-wrapper .tab-content-wrapper .tab-content > div {
  flex: 0 0 auto;
  flex-shrink: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  width: 100%;
}

.swipeable-tabs-wrapper .tab-content-wrapper .tab-content > div.active {
  max-height: 100%;
  opacity: 1;
}

@media (min-width: 768px) {
  .swipeable-tabs-wrapper .tab-content.animation-fade > div {
    position: absolute;
    z-index: -1;
    transition: opacity 0.75s ease-in-out 0s;
  }

  .swipeable-tabs-wrapper .tab-content.animation-fade > .active {
    position: static;
    z-index: 1;
  }
}

.range-slider-container .error-message {
  color: red;
  display: block;
  font-size: 12px;
  line-height: 16px;
  margin: 8px 0;
  text-transform: capitalize;
}

.range-slider-container .with-border input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border: 2px solid #f07742;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  margin-top: -14px;
  width: 30px;
  box-shadow: 0 2px 4px 0 #bdd4fb;
}

.range-slider-container .with-border input[type="range"]::-moz-range-track {
  background: transparent;
}

.range-slider-container .with-border input[type="range"]::-ms-track {
  background: transparent;
}

.range-slider-container
  .with-border
  input[type="range"]::-webkit-slider-runnable-track {
  background: transparent;
}

.range-slider-container .with-gradients input[type="range"] {
  height: 8px;
  margin: 14px 0;
}

.range-slider-container
  .with-gradients
  input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(90deg, #ffa730 6.51%, #ec532e 81.2%) border-box;
  border: 8px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  margin-top: -14px;
  width: 30px;
  box-shadow: 0 2px 4px 0 #bdd4fb;
}

.range-slider-container .with-gradients input[type="range"]::-moz-range-thumb {
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(90deg, #ffa730 6.51%, #ec532e 81.2%) border-box;
  border: 10px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  height: 15px;
  width: 15px;
  box-shadow: 0 2px 4px 0 #bdd4fb;
}

.range-slider-container
  .with-gradients
  input[type="range"]::-webkit-slider-runnable-track {
  background: transparent;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  height: 4px;
  margin: 5px 0;
  width: 100%;
}

.range-slider-container .with-gradients input[type="range"]::-moz-range-track {
  background: transparent;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  height: 4px;
  margin: 5px 0;
  width: 100%;
}

.range-slider-container input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border-radius: 2px;
  height: 4px;
  margin: 14px 0 12px;
  width: 100%;
}

.range-slider-container input[type="range"]:focus {
  outline: none;
}

.range-slider-container input[type="range"]::-ms-track {
  border-color: transparent;
  color: transparent;
}

.range-slider-container input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  margin-top: -14px;
  width: 30px;
  box-shadow: 0 2px 4px 0 #bdd4fb;
}

.range-slider-container input[type="range"]::-moz-range-thumb {
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  width: 30px;
  box-shadow: 0 2px 4px 0 #bdd4fb;
}

.range-slider-container input[type="range"]::-ms-thumb {
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  width: 30px;
  box-shadow: 0 2px 4px 0 #bdd4fb;
}

.range-slider-container input[type="range"]::-webkit-slider-runnable-track {
  background: transparent;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  height: 4px;
  margin: 5px 0;
  width: 100%;
}

.range-slider-container input[type="range"]::-moz-range-track {
  background: transparent;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  height: 4px;
  margin: 5px 0;
  width: 100%;
}

.range-slider-container input[type="range"]::-ms-track {
  background: transparent;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  height: 4px;
  margin: 5px 0;
  width: 100%;
}

.range-slider-container .min-max-value {
  display: flex;
  justify-content: space-between;
  padding: 0 2px;
}

.range-slider-container .min-max-value span {
  color: #666;
  font-family: sspRegular;
  font-size: 0.857em;
}

.gold-loan-calculator {
  margin-bottom: 10px;
}

.gold-loan-calculator .title-container {
  align-items: center;
  display: flex;
  margin-bottom: 40px;
}

.gold-loan-calculator .title-container .title {
  color: #222;
  flex-shrink: 0;
  font-family: sspBold;
  font-size: 1.143em;
  margin-right: 15px;
}

.gold-loan-calculator .title-container .separator {
  border: 0;
  border-top: 1px dashed #979797;
  height: 2px;
  margin: 0;
  opacity: 0.23;
  padding: 0;
  width: 100%;
}

.gold-loan-calculator .field-label-container {
  display: flex;
  justify-content: space-between;
}

.gold-loan-calculator .field-label-container .remove-btn {
  color: #f1713c;
  font-family: sspBold;
  font-size: 1em;
}

.gold-loan-calculator .gold-loan-calc-form-container {
  position: relative;
  width: 100%;
}

.gold-loan-calculator .gold-loan-calc-form-container .gold-loan-calc-form {
  width: 100%;
}

.gold-loan-calculator
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .gold-loan-calc-fields-container.select-container {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
}

.gold-loan-calculator
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .gold-loan-calc-fields-container.select-container
  .jewellery-type-container {
  margin: 0 0 25px;
  width: 100%;
}

.gold-loan-calculator
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .gold-loan-calc-fields-container.select-container
  .caret-details-container,
.gold-loan-calculator
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .gold-loan-calc-fields-container.select-container
  .weight-container {
  width: 47.87%;
}

.gold-loan-calculator
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .gold-loan-calc-fields-container.select-container
  .remove-icon-container {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  min-width: 20px;
}

.gold-loan-calculator
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .gold-loan-calc-fields-container.select-container
  .remove-icon-container
  img {
  cursor: pointer;
  height: 15px;
  margin-top: 15px;
  width: 15px;
}

.gold-loan-calculator .fields-container-separator {
  border: 0;
  border-top: 1px dashed #979797;
  height: 1px;
  margin-bottom: 25px;
  margin-top: 7px;
  opacity: 0.28;
}

.gold-loan-calculator .add-more-button-container {
  width: 100%;
}

.gold-loan-calculator .add-more-button-container .add-more-button {
  background-color: rgba(241, 113, 60, 0);
  border: 1px solid #f1713c;
  border-radius: 4px;
  cursor: pointer;
  padding: 6px 12px;
  width: -moz-fit-content;
  width: fit-content;
}

.gold-loan-calculator .add-more-button-container .add-more-button p {
  color: #f1713c;
  font-family: sspBold;
  font-size: 1em;
}

.gold-loan-calculator .eligibility-box {
  background-color: rgba(255, 224, 214, 0.16);
  border: 1px solid #ffe0d6;
  border-radius: 5px;
  padding: 15px 45px 12px 22px;
  width: -moz-fit-content;
  width: fit-content;
}

.gold-loan-calculator .eligibility-box .desc {
  color: #444;
  font-family: sspRegular;
  font-size: 0.857em;
  margin-bottom: 2px;
}

.gold-loan-calculator .eligibility-box .amount {
  color: #444;
  font-family: sspBold;
  font-size: 30px;
}

.gold-loan-calculator.with-range-slider .fields-container-separator {
  margin-top: 15px;
}

.gold-loan-calculator.with-range-slider .range-slider-container {
  padding: 20px 20px 0;
}

.gold-loan-calculator.with-range-slider .upper-section .value-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.gold-loan-calculator.with-range-slider .upper-section .value-container .title {
  font-size: 1em;
}

.gold-loan-calculator.with-range-slider
  .upper-section
  .value-container
  .tenure-value {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  font-family: sspBold;
  font-size: 0.857em;
  padding: 9px 19px;
}

.gold-loan-calculator.with-range-slider .gold-loan-calc-form-container {
  margin-top: 24px;
  padding: 0 20px;
}

.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .gold-loan-calc-fields-container {
  row-gap: 25px;
}

.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .gold-loan-calc-fields-container
  .jewellery-type-container {
  margin-bottom: 0;
  margin-right: 10px;
}

.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .gold-loan-calc-fields-container
  .weight-container {
  margin-right: 18px;
  max-width: 170px;
  width: 131px;
}

.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .gold-loan-calc-fields-container
  .caret-details-container {
  align-self: end;
}

.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .gold-loan-calc-fields-container
  .carat-selector:after,
.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .gold-loan-calc-fields-container
  .ornament-selector:after {
  border-color: #222;
}

.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .field-label {
  font-size: 1em;
  line-height: 20px;
  margin-bottom: 12px;
  text-align: left;
}

.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .field-label-container {
  margin-bottom: 12px;
}

.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .field-label-container
  .field-label {
  margin-bottom: 0;
}

.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  .leads-select-placeholder-color {
  color: #a2a2a2;
  font-size: 1em;
}

.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  input,
.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  select {
  border-radius: 10px;
  color: #222;
  font-family: sspBold;
  font-size: 1.143em;
  height: 40px;
}

.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  input::-moz-placeholder,
.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  select::-moz-placeholder {
  color: #a2a2a2;
  font-size: 0.857em;
}

.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  input:-ms-input-placeholder,
.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  select:-ms-input-placeholder {
  color: #a2a2a2;
  font-size: 0.857em;
}

.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  input::placeholder,
.gold-loan-calculator.with-range-slider
  .gold-loan-calc-form-container
  .gold-loan-calc-form
  select::placeholder {
  color: #a2a2a2;
  font-size: 0.857em;
}

.gold-loan-calculator.with-range-slider .add-more-button-container {
  margin-bottom: 24px;
  margin-top: 24px;
  position: unset;
}

.gold-loan-calculator.with-range-slider
  .add-more-button-container
  .add-more-button {
  border-radius: 10px;
  font-size: 1em;
  margin: 0;
  padding: 13px 22px;
}

.gold-loan-calculator.with-range-slider .eligibility-box {
  background: #ffeab3;
  border: none;
  border-radius: 0 0 20px 20px;
  padding: 30px 20px 12px;
  width: 100%;
}

.gold-loan-calculator.with-range-slider .eligibility-box .desc {
  font-size: 1em;
  margin-bottom: 11px;
}

.gold-loan-calculator.with-range-slider .eligibility-box .amount {
  font-size: 1.714em;
  line-height: 22px;
  margin-bottom: 21px;
}

.gold-loan-calculator.with-range-slider .eligibility-box .gross-value {
  font-size: 0.857em;
}

.gold-loan-calculator.with-range-slider .eligibility-box hr {
  border: 0;
  border-top: 1px solid #222;
  height: 1px;
  margin-bottom: 11px;
  margin-top: 23px;
  opacity: 0.1;
}

.gold-loan-calculator.with-range-slider .eligibility-box .note {
  font-size: 0.571em;
  line-height: 11px;
}

@media (max-width: 767px) {
  .gold-loan-calculator .title-container {
    margin-bottom: 30px;
  }

  .gold-loan-calculator
    .gold-loan-calc-form-container
    .gold-loan-calc-form
    .gold-loan-calc-fields-container.select-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .gold-loan-calculator
    .gold-loan-calc-form-container
    .gold-loan-calc-form
    .gold-loan-calc-fields-container.select-container
    .remove-icon-container {
    display: none;
  }

  .gold-loan-calculator .add-more-button-container .add-more-button {
    border-radius: 15.5px;
    margin: 0 auto 20px;
    padding: 6px 14px;
  }

  .gold-loan-calculator .eligibility-box {
    margin-top: 0;
    padding: 10px 0 10px 15px;
    width: 100%;
  }

  .gold-loan-calculator .eligibility-box .amount {
    font-size: 25px;
  }
}

@media (min-width: 768px) {
  .gold-loan-calculator {
    margin-bottom: 0;
  }

  .gold-loan-calculator .title-container .title {
    color: #413f3f;
    font-family: sspBold;
    font-size: 1.143em;
    text-transform: uppercase;
  }

  .gold-loan-calculator .field-label-container .remove-btn {
    display: none;
  }

  .gold-loan-calculator
    .gold-loan-calc-form-container
    .gold-loan-calc-form
    .gold-loan-calc-fields-container.select-container:last-child {
    margin-bottom: 30px;
  }

  .gold-loan-calculator
    .gold-loan-calc-form-container
    .gold-loan-calc-form
    .gold-loan-calc-fields-container.select-container
    .jewellery-type-container {
    margin: 0 17px 0 0;
    padding: 0;
    width: 34.76%;
  }

  .gold-loan-calculator
    .gold-loan-calc-form-container
    .gold-loan-calc-form
    .gold-loan-calc-fields-container.select-container
    .caret-details-container {
    margin: 0 15px 0 0;
    padding: 0;
    width: 27.9%;
  }

  .gold-loan-calculator
    .gold-loan-calc-form-container
    .gold-loan-calc-form
    .gold-loan-calc-fields-container.select-container
    .weight-container {
    margin: 0 20px 0 0;
    padding: 0;
    width: 26.37%;
  }

  .gold-loan-calculator .fields-container-separator {
    display: none;
  }

  .gold-loan-calculator .add-more-button-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 93%;
  }

  .gold-loan-calculator.with-range-slider .gold-loan-calc-fields-container {
    align-items: flex-end;
    justify-content: space-between;
  }

  .gold-loan-calculator.with-range-slider
    .gold-loan-calc-fields-container
    .remove-icon-container {
    margin-bottom: 10px;
  }

  .gold-loan-calculator.with-range-slider .add-more-button-container {
    justify-content: flex-start;
  }
}

@media (min-width: 1025px) {
  .gold-loan-calculator.with-range-slider {
    display: flex;
    padding: 60px;
  }

  .gold-loan-calculator.with-range-slider .left-section {
    width: 50%;
  }

  .gold-loan-calculator.with-range-slider .add-more-button-container {
    margin-bottom: 0;
  }

  .gold-loan-calculator.with-range-slider
    .add-more-button-container
    .add-more-button {
    font-size: 1.143em;
    height: 50px;
    line-height: 20px;
    width: 180px;
  }

  .gold-loan-calculator.with-range-slider .eligibility-box {
    align-self: flex-start;
    border-radius: 20px;
    margin-left: 78px;
    padding: 65px 35px 29px;
    width: 523px;
  }

  .gold-loan-calculator.with-range-slider .eligibility-box .desc {
    font-size: 1.571em;
    margin-bottom: 20px;
  }

  .gold-loan-calculator.with-range-slider .eligibility-box .amount {
    font-family: sspBolder;
    font-size: 3.6em;
    margin-bottom: 45px;
  }

  .gold-loan-calculator.with-range-slider .eligibility-box .gross-value {
    font-size: 2em;
    margin-bottom: 50px;
  }

  .gold-loan-calculator.with-range-slider .eligibility-box .note {
    font-size: 0.857em;
    line-height: 18px;
  }

  .gold-loan-calculator.with-range-slider .eligibility-box hr {
    margin-bottom: 27px;
  }

  .gold-loan-calculator.with-range-slider .range-slider-container {
    margin-bottom: 60px;
    padding: 0;
  }

  .gold-loan-calculator.with-range-slider
    .range-slider-container
    .min-max-value
    span {
    font-size: 1em;
  }

  .gold-loan-calculator.with-range-slider
    .upper-section
    .value-container
    .title {
    font-size: 1.429em;
  }

  .gold-loan-calculator.with-range-slider
    .upper-section
    .value-container
    .tenure-value {
    font-size: 1.429em;
    padding: 10px 28px;
  }

  .gold-loan-calculator.with-range-slider .gold-loan-calc-form-container {
    padding: 0;
  }

  .gold-loan-calculator.with-range-slider
    .gold-loan-calc-form-container
    .gold-loan-calc-form
    .field-label {
    font-size: 1.429em;
    margin-bottom: 15px;
    white-space: nowrap;
  }

  .gold-loan-calculator.with-range-slider
    .gold-loan-calc-form-container
    .gold-loan-calc-form
    .leads-select-placeholder-color {
    font-size: 1.143em;
  }

  .gold-loan-calculator.with-range-slider
    .gold-loan-calc-form-container
    .gold-loan-calc-form
    input,
  .gold-loan-calculator.with-range-slider
    .gold-loan-calc-form-container
    .gold-loan-calc-form
    select {
    font-size: 1.286em;
  }

  .gold-loan-calculator.with-range-slider
    .gold-loan-calc-form-container
    .gold-loan-calc-form
    .gold-loan-calc-fields-container:last-child {
    margin-bottom: 40px;
  }
}
